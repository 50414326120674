<!-- tag.component.html -->
<div class="tag-badge-wrapper">
  <span [class]="tagClass"
        [ngbTooltip]="tooltipContent ? tooltipTemplate : null"
        [tooltipClass]="'tag-inner'"
        placement="top"
        container="body"
        [openDelay]="300">
    {{ tagText }}
  </span>
</div>

<ng-template #tooltipTemplate>
  <div class="tooltip-content" *ngIf="tooltipContent">
    <div class="tag-info {{ tag.replace('#', '') }}">
      <div class="title">{{ tooltipContent.title }}</div>
      <div class="section">
        <div class="label">Coverage:</div>
        <div class="content">{{ tooltipContent.coverage }}</div>
      </div>
      <div class="section">
        <div class="label">{{ tooltipContent.isCore ? 'Why it\'s useful' : 'When to use it' }}:</div>
        <div class="content">{{ tooltipContent.purpose }}</div>
      </div>
    </div>
  </div>
</ng-template>
