<!-- File Upload Options -->
<div class="row">
  <!-- Story Upload Section -->
  <div class="col-md-6">
    <div class="upload-card">
      <div class="card-header ">
        <h5 class="mb-0">
          <i class="fa fa-file-text text-primary me-2 mr-2"></i> Upload Story
        </h5>
        <p class="small text-muted mb-0">Upload your core story file to begin</p>
      </div>
      <div class="card-body">
        <div class="upload-zone p-3 text-center border rounded" [class.has-file]="fileName">
          <div class="upload-content" *ngIf="!fileName">
            <i class="fa fa-file-text fa-2x mb-2"></i>
            <p class="mb-3">Import a Story File</p>
            <input type="file"
                   id="coreFile"
                   class="d-none"
                   (change)="onStorySelected()"
                   #fileInputDocx>
            <label for="coreFile" class="btn btn-outline-primary">
              Choose File
            </label>
          </div>
          <div class="file-info d-flex align-items-center justify-content-between p-2" *ngIf="fileName">
            <div class="d-flex align-items-center">
              <i class="fa fa-check-circle text-success me-2"></i>
              <span class="filename">{{fileName}}</span>
            </div>
            <button class="btn btn-sm btn-danger" (click)="clearFile()" title="Clear file">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Workspace Upload Section -->
  <div class="col-md-6">
    <div class="upload-card">
      <div class="card-header">
        <h5 class="mb-0">
          <i class="fa fa-folder text-primary me-2 mr-2"></i> Import Workspace
        </h5>
        <p class="small text-muted mb-0">Resume your previous work by importing a workspace file</p>
      </div>
      <div class="card-body">
        <ng-container *ngIf="fileName$ | async as workspaceFileName; else uploadZone">
          <div class="d-flex align-items-center border rounded p-2">
            <i class="fa fa-check-circle text-success me-2"></i>
            <span class="flex-grow-1 text-truncate">{{workspaceFileName}}</span>
            <button (click)="clearFile()" class="btn btn-sm btn-danger ms-2">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </ng-container>

        <ng-template #uploadZone>
          <div class="upload-zone p-3 text-center border rounded">
            <div class="upload-content">
              <i class="fa fa-folder-open fa-2x mb-2"></i>
              <p class="mb-3">Import a workspace file</p>
              <input type="file"
                     id="workspaceFile"
                     class="d-none"
                     (change)="onWorkspaceSelected($event)"
                     accept=".html"
                     #workspaceInput>
              <label for="workspaceFile" class="btn btn-outline-primary">
                Import Workspace
              </label>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<!-- Template Mismatch Alert -->
<div *ngIf="showMismatchContent" class="alert alert-warning mt-3">
  <h6><i class="fa fa-exclamation-triangle me-2"></i> Template Mismatch</h6>
  <p>The uploaded file contains columns that don't match any available template:</p>
  <ul class="mb-0">
    <li *ngFor="let col of mismatchColumns">{{ col }}</li>
  </ul>
</div>

<!-- This div is needed for the table visibility toggle -->
<div class="test-table-container" [class.d-none]="showMismatchContent"></div>
