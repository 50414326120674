// tag.component.ts
import { Component, Input } from '@angular/core';
import { TAG_DESCRIPTIONS, TagDescription } from './tag-descriptions';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @Input() tag!: string;

  get tagText(): string {
    return this.tag?.replace('#', '').replace(/_/g, ' ') || '';
  }

  get tagClass(): string {
    const baseTag = this.tag?.replace('#', '') || '';
    return `tag-badge tag-${baseTag}`;
  }

  get tooltipContent(): { title: string, coverage: string, purpose: string, isCore: boolean } | null {
    if (!this.tag) return null;

    const baseTag = this.tag.replace('#', '');
    const description = TAG_DESCRIPTIONS[baseTag];

    if (!description) return null;

    return {
      title: description.title,
      coverage: description.coverage,
      purpose: description.purpose,
      isCore: description.isCore
    };
  }
}
