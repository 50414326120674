import {Component, OnDestroy, OnInit, ViewChild, ElementRef, HostListener, SecurityContext, ChangeDetectorRef} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {combineLatest, Subject} from 'rxjs';
import { ApiService } from 'app/shared/services/api.service';
import { StatoSessioneService } from 'app/shared/services/stato-sessione.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../shared/auth/auth.service';
import {switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {TestComponent} from '../test/test.component';
import {ProgettiService} from '../../../shared/services/progetti.service';
import {SpinnerService} from '../../../shared/services/spinner.service';
import Swal from 'sweetalert2';
import { trigger, state, style, transition, animate } from '@angular/animations';
import {TokenCount} from '../../../shared/templateGen/templateInterfaces';
import {ModelPricingService, TokenCostCalculation} from '../../../shared/services/model-pricing.service';
import {DownloaderComponent} from '../test/downloader/downloader.component';
import {SessionPersistenceService} from '../session-manager/session-persistence.service';
import {BaseFileResponse, ImageAnalysisResponse} from '../../../shared/interfaces/fileUploadInterfaces';

export interface SupportFile extends File {
  purpose?: string;
  content?: string;
  fileType?: 'document' | 'image'; // property to distinguish between document and image files
}

export interface TokenCostHistory {
  previousTokenCount: TokenCount;
  previousTokenCost: TokenCostCalculation;
}

@Component({
  selector: 'tool-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss'],
  animations: [
    trigger('slideIn', [
      state('hidden', style({
        opacity: 0,
        transform: 'translateY(-20px)'
      })),
      state('visible', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      transition('hidden => visible', [
        animate('300ms ease-out')
      ]),
      transition('visible => hidden', [
        animate('200ms ease-in')
      ])
    ])
  ]
})
export class StoryComponent implements OnInit, OnDestroy {
  @ViewChild('fileInputDocx') fileInputDocx: ElementRef<HTMLInputElement>;
  @ViewChild(TestComponent) testComponent: TestComponent; // Add this line

  // Existing variables
  tokenCount: TokenCount;
  tokenCost: TokenCostCalculation = {
    inputCost: 0,
    outputCost: 0,
    totalCost: 0
  };

  tokenHistory: TokenCostHistory = {
    previousTokenCount: {
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0
    },
    previousTokenCost: {
      inputCost: 0,
      outputCost: 0,
      totalCost: 0
    }
  };

  ruolo: string;
  istruzioniScriviTest: string;
  isAgenticMode: boolean;
  models: string[];
  templates: any[];
  templateNames: any[];
  testStyle: string;
  selectedModel: string;
  selectedTemplate: string;
  refinedStoryString: string;
  fileName: string;
  projectLanguage: string;
  uploadCompleted = false;
  additionalNotes: string;
  projectId: string;
  timing = false;
  elapsedTime = 0;
  private destroy$: Subject<void> = new Subject<void>();
  isSticky = false;
  stickyThreshold = 150;
  isQAnalystOrManager = false;
  hasSubtable = false;

  supportFiles: SupportFile[] = [];  // property to track active tab
  activeTab: 'story' | 'testing' = 'story';

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.isSticky = window.scrollY > this.stickyThreshold;
  }

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private sessioneService: StatoSessioneService,
    private router: Router,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private progettiService: ProgettiService,
    private spinnerService: SpinnerService,
    private modelPricingService: ModelPricingService,
    private downloader: DownloaderComponent,
    private sessionPersistenceService: SessionPersistenceService
  ) {}

  ngOnInit(): void {
    this.initializeProjectData();
    this.initializeComponentData();
    this.subscribeToSessionService();
    // Check if a session was loaded from API
    this.route.queryParams.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      if (params['sessionLoaded'] === 'true') {
        // Force update the UI with a slight delay to ensure data is loaded
        setTimeout(() => {
          // If we have story content but no fileName, set a default file name
          if (this.refinedStoryString && !this.fileName) {
            this.fileName = 'Loaded Session';
            this.sessioneService.updateFileName(this.fileName);
          }

          // If a specific template was provided in the query params, set it
          if (params['template']) {
            const templateName = params['template'];
            if (this.templateNames && this.templateNames.includes(templateName)) {
              this.selectedTemplate = templateName;
              this.sessioneService.updateSelectedTemplate(templateName);
              this.checkForSubtable();
            }
          }

          // Check for generated tests and switch tab if needed
          this.sessioneService.generatedTests$.pipe(take(1)).subscribe(tests => {
            if (tests && tests.length > 0) {
              this.activeTab = 'testing';
            }
          });

          // Force change detection to update UI
          this.changeDetectorRef.detectChanges();
        }, 300);
      }
    });

    this.isQAnalystOrManager = this.ruolo === 'Admin' || this.ruolo === 'Manager' || this.ruolo === 'QA Analyst';


    // subscription to support files
    this.sessioneService.supportFiles$
      .pipe(takeUntil(this.destroy$))
      .subscribe(files => {
        this.supportFiles = files;
      });

    // subscription for token count
    combineLatest([
      this.sessioneService.tokenUsage$,
      this.sessioneService.selectedModel$
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([tokenCount, selectedModel]) => {
      if (tokenCount && selectedModel) {
        // Store previous values before updating
        this.tokenHistory.previousTokenCount = { ...this.tokenCount };
        this.tokenHistory.previousTokenCost = { ...this.tokenCost };

        // Update current values
        this.tokenCount = tokenCount;
        this.tokenCost = this.modelPricingService.calculateCost(selectedModel, tokenCount);
      }
    });
  }

  openSupportFileUpload(fileType: 'document' | 'image') {
    const input = document.createElement('input');
    input.type = 'file';

    // Set accepted file types based on fileType parameter
    if (fileType === 'document') {
      input.accept = '.txt,.docx,.pdf';
    } else if (fileType === 'image') {
      input.accept = '.jpg,.jpeg,.png,.gif,.bmp';
    }

    input.onchange = (e: any) => {
      if (!e.target.files || !e.target.files[0]) { return; }

      const file = e.target.files[0];

      // Check file extension based on type
      const fileExtension = file.name.toLowerCase().split('.').pop();
      let allowedExtensions: string[] = [];

      if (fileType === 'document') {
        allowedExtensions = ['txt', 'docx', 'pdf'];
      } else if (fileType === 'image') {
        allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      }

      if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        alert(`Only ${allowedExtensions.join(', ')} files are supported for this type`);
        return;
      }

      // Create a new object with our custom properties
      const supportFile = {
        ...file,
        purpose: '',
        fileType: fileType, // Add fileType property to track what kind of file this is
        // Add these to maintain File interface compatibility
        lastModified: file.lastModified,
        name: file.name,
        size: file.size,
        type: file.type,
        arrayBuffer: () => file.arrayBuffer(),
        slice: (start?: number, end?: number, contentType?: string) => file.slice(start, end, contentType),
        stream: () => file.stream(),
        text: () => file.text()
      } as SupportFile;

      // Upload the file to get its content
      this.spinnerService.show();
      this.apiService.uploadFile(file).subscribe(
        (response: { filename: string, contents: string | string[] }) => {
          let fileContent: string;

          // Handle array or string response
          if (Array.isArray(response.contents)) {
            fileContent = response.contents.join('');
          } else {
            fileContent = response.contents;
          }

          // Create a new object with the content
          const supportFileWithContent = {
            ...supportFile,
            content: fileContent
          } as SupportFile;

          // Update the session service with the new file and its content
          const updatedFiles = [...this.supportFiles, supportFileWithContent];
          this.sessioneService.updateSupportFiles(updatedFiles);
          this.sessioneService.updateSupportFileContent(file.name, fileContent);

          this.spinnerService.hide();
        },
        (error: any) => {
          console.error('There was an error uploading the support file!', error);

          // Still add the file even without content
          const updatedFiles = [...this.supportFiles, supportFile];
          this.sessioneService.updateSupportFiles(updatedFiles);

          this.spinnerService.hide();
        }
      );
    };

    input.click();
  }

  removeSupportFile(index: number) {
    const updatedFiles = this.supportFiles.filter((_, i) => i !== index) as SupportFile[];
    this.sessioneService.updateSupportFiles(updatedFiles);
  }

  private initializeProjectData(): void {
    this.route.params.pipe(
      tap(params => {
        this.projectId = params['project_id'];
        // console.log('Project ID changed:', this.projectId);
        // this.sessioneService.updateProjectId(this.projectId);
        // this.sessioneService.resetAll();
        this.fetchTemplateNames(this.projectId);
      }),
      switchMap(params => this.progettiService.getProjectLanguage(params['project_id'])),
      takeUntil(this.destroy$)
    ).subscribe({
      next: (language) => {
        if (language) {
          this.projectLanguage = language;
          this.sessioneService.updateProjectLanguage(language);
          console.log('Project language:', language);
        }
      },
      error: (error) => {
        console.error('Error fetching project language:', error);
        // Handle error (e.g., show a notification to the user)
      }
    });
  }


  private initializeComponentData(): void {
    this.models = ['Gemini 2', 'Mistral', '4o-mini', 'Sonnet', 'Gemma', 'QWQ', 'Deepseek R1', 'Deepseek', 'Gemini pro 2.5'];

    const currentModel = this.sessioneService.currentSelectedModel;
    if (!this.models.includes(currentModel)) {
      this.sessioneService.updateSelectedModel('Gemini 2');
    }

    const currentAgenticMode = this.sessioneService.getAgenticMode();

    if (currentAgenticMode !== true && currentAgenticMode !== false) {
      this.sessioneService.updateAgenticMode(true);
    }
  }

  private subscribeToSessionService(): void {
    this.sessioneService.fileName$.pipe(takeUntil(this.destroy$)).subscribe(filename => this.fileName = filename);
    this.sessioneService.additionalContext$.pipe(takeUntil(this.destroy$)).subscribe(add_notes => this.additionalNotes = add_notes);
    this.sessioneService.selectedModel$.pipe(takeUntil(this.destroy$)).subscribe(model => this.selectedModel = model);
    this.sessioneService.selectedTemplate$.pipe(takeUntil(this.destroy$)).subscribe(template => this.selectedTemplate = template);
    this.sessioneService.templates$.pipe(takeUntil(this.destroy$)).subscribe(templates => {
      this.templates = templates;
      this.templateNames = this.templates.map(template => template.templateName);
      this.sessioneService.updateSelectedTemplate(this.templateNames[0]);
    });
    this.sessioneService.selectedTestStyle$.pipe(takeUntil(this.destroy$)).subscribe(testStyle => this.testStyle = testStyle);
    this.sessioneService.istruzioniScriviTest$.pipe(takeUntil(this.destroy$)).subscribe(instruct => this.istruzioniScriviTest = instruct);
    this.sessioneService.refinedStoryString$.pipe(takeUntil(this.destroy$)).subscribe(refinedStoryString => this.refinedStoryString = refinedStoryString);
    this.authService.ruolo$.pipe(takeUntil(this.destroy$)).subscribe(ruolo => this.ruolo = ruolo);
    this.sessioneService.isAgenticMode$.pipe(takeUntil(this.destroy$)).subscribe(isAgentic => this.isAgenticMode = isAgentic);
  }

  updateSelectedModel() {
    this.sessioneService.updateSelectedModel(this.selectedModel);
  }

  updateAgenticMode() {
    this.sessioneService.updateAgenticMode(this.isAgenticMode);
  }

  updateSelectedTemplate() {
    this.sessioneService.updateSelectedTemplate(this.selectedTemplate);
  }

  updateIstruzioniScriviTest() {
    this.sessioneService.updateIstruzioniScriviTest(this.istruzioniScriviTest);
  }

  // Helper methods to calculate differences
  getDifferenceTokens(current: number, previous: number): number {
    return current - previous;
  }

  getDifferenceCost(current: number, previous: number): number {
    return current - previous;
  }

  checkForSubtable(): void {
    if (this.selectedTemplate && this.templates) {
      const selectedTemplateObj = this.templates.find(template => template.templateName === this.selectedTemplate);
      if (selectedTemplateObj) {
        this.hasSubtable = this.templateHasSubtable(selectedTemplateObj);
      }
    }
  }

  templateHasSubtable(template: any): boolean {
    if (template && template.columns && Array.isArray(template.columns)) {
      return template.columns.some(column =>
        column.list && Array.isArray(column.subColumns) && column.subColumns.length > 0
      );
    }
    return false;
  }

  goBackToSessions(): void {
    this.router.navigate(['/projects/sessions', this.projectId]);
  }

  goToTests() {
    this.activeTab = 'testing';
  }

  openGeneraTestsModal(modalContent: any) {
    this.modalService.dismissAll();
    this.modalService.open(modalContent, { size: 'lg', scrollable: true });
  }

  fetchTemplateNames(projectId: string) {
    this.apiService.getTemplates(projectId).subscribe(
      data => {
        this.sessioneService.updateTemplates(data);
      },
      error => {
        console.error('Error fetching template names', error);
      }
    );
  }

  handleGenerateTests(testStyle: string) {
    this.activeTab = 'testing';
    this.sessioneService.resetTests();
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();

      if (this.testComponent) {
        this.testComponent.generateTests(testStyle);
      } else {
        console.error('TestComponent not found after tab change');
      }

      console.log('generando test');
    }, 0);
  }

  downloadGeneratedTests() {
    this.downloader.saveCurrentState(
      this.ruolo,
      this.fileName,
    );
  }

  // method to save the session on demand
  saveCurrentWorkspace(): void {
    this.spinnerService.show();

    // Generate a default name based on the current file and timestamp
    const saveName = `${this.fileName || 'Untitled'} - ${new Date().toLocaleTimeString()}`;

    this.sessionPersistenceService.saveSessionOnDemand(saveName)
      .pipe(
        take(1)
      )
      .subscribe({
        next: (response) => {
          this.spinnerService.hide();
          Swal.fire({
            title: 'Saved!',
            text: 'Workspace saved successfully',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            timerProgressBar: true
          });
        },
        error: (error) => {
          this.spinnerService.hide();
          console.error('Error saving workspace:', error);
          Swal.fire({
            title: 'Error',
            text: 'Failed to save workspace',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false
          });
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
