import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import * as ExcelJS from 'exceljs';
import { StatoSessioneService } from '../../../../shared/services/stato-sessione.service';
import { MacroScenarioWithSelection } from '../../template/template.component';
import {
  Ambiguity,
  AnalysisContent,
  DataParametersAnalysis, DataParametersResponse, StoryDescriptionResponse,
  SupportiveAnalysisContent
} from '../../../../shared/templateGen/templateInterfaces';
import {TestWithMetadata} from '../test-id.service';


interface SavedAppState {
  version: string;
  timestamp: string;
  metadata: {
    projectId: string;
    author: string;
    selectedModel: string;
    projectLanguage: string;
    templateName: string;
    totalTests: number;
  };
  criteriaGroups: any[];
  generatedTests: any[];
  selectedTemplate: string;
  selectedTemplateColumns: string[];
  context: string;
  istruzioniScriviTest: string;
  // Additional state fields with precise types
  ambiguities?: Ambiguity[];
  coreAnalysis?: AnalysisContent | null;
  supportiveAnalysis?: SupportiveAnalysisContent | null;
  macroScenarios?: MacroScenarioWithSelection[];
  dataParameters?: {
    analysis_results: DataParametersAnalysis;
    // Omit token_usage as requested
  } | null;
  storyDescription?: {
    story_id: string;
    title: string;
    description: string;
    // Omit token_usage as requested
  } | null;
}

@Injectable({
  providedIn: 'root'
})
export class DownloaderComponent {
  private readonly STATE_VERSION = '1.1.0'; // Increment version to reflect enhanced state
  private memoizedSubTableRows: Map<string, any[]> = new Map<string, any[]>();
  constructor(private sessioneService: StatoSessioneService) {}

  saveCurrentState(author: string, filename: string): void {
    // Combine all necessary observables to get the current state
    combineLatest([
      this.sessioneService.generatedTests$,
      this.sessioneService.criteriaGroups$,
      this.sessioneService.selectedTemplate$,
      this.sessioneService.templates$,
      this.sessioneService.projectId$,
      this.sessioneService.selectedModel$,
      this.sessioneService.projectLanguage$,
      this.sessioneService.istruzioniScriviTest$,
      this.sessioneService.refinedStoryString$,
      // Add new state observables
      this.sessioneService.ambiguities$,
      this.sessioneService.coreAnalysis$,
      this.sessioneService.supportiveAnalysis$,
      this.sessioneService.macroScenarios$,
      this.sessioneService.dataParameters$,
      this.sessioneService.storyDescription$
    ]).pipe(
      take(1)
    ).subscribe(([
                   tests, // Explicitly named as 'tests' for clarity
                   criteriaGroups,
                   selectedTemplate,
                   templates,
                   projectId,
                   selectedModel,
                   projectLanguage,
                   istruzioniScriviTest,
                   context,
                   // New state variables
                   ambiguities,
                   coreAnalysis,
                   supportiveAnalysis,
                   macroScenarios,
                   dataParameters,
                   storyDescription
                 ]) => {
      // Explicitly cast to the correct type
      const generatedTests = tests as TestWithMetadata[];

      // Preserve the full test structure with metadata, but add sequential ID to the data
      const sanitizedGeneratedTests = Array.isArray(generatedTests)
        ? generatedTests.map(test => {
          // Clone the test to avoid modifying the original
          const testClone = {
            metadata: { ...test.metadata },
            data: { ...test.data }
          };

          // Add sequential ID to the data for display purposes
          testClone.data.ID = test.metadata.sequentialId || 0;

          return testClone;
        })
        : [];

      // Ensure `criteriaGroups` is always an array
      const sanitizedCriteriaGroups = Array.isArray(criteriaGroups) ? criteriaGroups : [];

      // Ensure `selectedTemplate` is a string (fallback to empty string)
      const sanitizedSelectedTemplate = typeof selectedTemplate === 'string' ? selectedTemplate : '';

      // Ensure `selectedTemplateColumns` is an array
      const sanitizedSelectedTemplateColumns = Array.isArray(templates)
        ? (templates.find(template =>
          template && typeof template === 'object' && 'templateName' in template &&
          template.templateName === selectedTemplate
        )?.columns || [])
        : [];

      // Ensure `projectId` is a string
      const sanitizedProjectId = typeof projectId === 'string' ? projectId : '';

      // Ensure `selectedModel` is a string
      const sanitizedSelectedModel = typeof selectedModel === 'string' ? selectedModel : '';

      // Ensure `projectLanguage` is a string
      const sanitizedProjectLanguage = typeof projectLanguage === 'string' ? projectLanguage : '';

      // Ensure `istruzioniScriviTest` is a string
      const sanitizedIstruzioniScriviTest = typeof istruzioniScriviTest === 'string' ? istruzioniScriviTest : '';

      // Ensure `context` is a string
      const sanitizedContext = typeof context === 'string' ? context : '';

      // Sanitize new state fields
      const sanitizedAmbiguities = Array.isArray(ambiguities) ? ambiguities : [];
      const sanitizedMacroScenarios = Array.isArray(macroScenarios) ? macroScenarios : [];

      // Process dataParameters to exclude token_usage - with proper type checking
      const sanitizedDataParameters = this.processDataParameters(dataParameters);

      // Process storyDescription to exclude token_usage - with proper type checking
      const sanitizedStoryDescription = this.processStoryDescription(storyDescription);

      const state: SavedAppState = {
        version: this.STATE_VERSION,
        timestamp: new Date().toISOString(),
        metadata: {
          projectId: sanitizedProjectId,
          author,  // Assuming `author` is already validated (direct input)
          selectedModel: sanitizedSelectedModel,
          projectLanguage: sanitizedProjectLanguage,
          templateName: sanitizedSelectedTemplate,
          totalTests: sanitizedGeneratedTests.length
        },
        criteriaGroups: sanitizedCriteriaGroups,
        generatedTests: sanitizedGeneratedTests,
        selectedTemplate: sanitizedSelectedTemplate,
        selectedTemplateColumns: sanitizedSelectedTemplateColumns,
        context: sanitizedContext,
        istruzioniScriviTest: sanitizedIstruzioniScriviTest,
        // Add cleaned state fields
        ambiguities: sanitizedAmbiguities,
        coreAnalysis: this.processAnalysisContent(coreAnalysis),
        supportiveAnalysis: this.processSupportiveAnalysisContent(supportiveAnalysis),
        macroScenarios: sanitizedMacroScenarios,
        dataParameters: sanitizedDataParameters,
        storyDescription: sanitizedStoryDescription
      };

      this.generateHTMLFile(state, filename);
    });
  }

  /**
   * Safely processes dataParameters to extract analysis_results without token_usage
   */
  private processDataParameters(dataParameters: any): {
    analysis_results: DataParametersAnalysis;
  } | null {
    if (!dataParameters) return null;

    // Check if it's a DataParametersResponse object with analysis_results
    if (dataParameters &&
      typeof dataParameters === 'object' &&
      'analysis_results' in dataParameters) {
      return {
        analysis_results: dataParameters.analysis_results
      };
    }
    return null;
  }

  /**
   * Safely processes storyDescription to extract needed fields without token_usage
   */
  private processStoryDescription(storyDescription: any): {
    story_id: string;
    title: string;
    description: string;
  } | null {
    if (!storyDescription) return null;

    // Check if it's a StoryDescriptionResponse object with required fields
    if (storyDescription &&
      typeof storyDescription === 'object' &&
      'story_id' in storyDescription &&
      'title' in storyDescription &&
      'description' in storyDescription) {
      return {
        story_id: storyDescription.story_id,
        title: storyDescription.title,
        description: storyDescription.description
      };
    }
    return null;
  }

  /**
   * Safely processes AnalysisContent to ensure it has the correct structure
   */
  private processAnalysisContent(analysis: any): AnalysisContent | null {
    if (!analysis) return null;

    // Check if it's a proper AnalysisContent object
    if (analysis &&
      typeof analysis === 'object' &&
      'scenario_analysis' in analysis &&
      'config_testdata_analysis' in analysis) {
      return {
        scenario_analysis: analysis.scenario_analysis,
        config_testdata_analysis: analysis.config_testdata_analysis
      };
    }

    // It might be inside an AnalysisResult object
    if (analysis &&
      typeof analysis === 'object' &&
      'core_analysis' in analysis &&
      typeof analysis.core_analysis === 'object') {
      return this.processAnalysisContent(analysis.core_analysis);
    }

    return null;
  }

  /**
   * Safely processes SupportiveAnalysisContent to ensure it has the correct structure
   */
  private processSupportiveAnalysisContent(analysis: any): SupportiveAnalysisContent | null {
    if (!analysis) return null;

    // Check if it's a proper SupportiveAnalysisContent object
    if (analysis &&
      typeof analysis === 'object' &&
      'scenario_enrichments' in analysis &&
      'config_testdata_enrichments' in analysis) {
      return {
        scenario_enrichments: analysis.scenario_enrichments,
        config_testdata_enrichments: analysis.config_testdata_enrichments
      };
    }

    // It might be inside an AnalysisResult object
    if (analysis &&
      typeof analysis === 'object' &&
      'supportive_analysis' in analysis) {
      return this.processSupportiveAnalysisContent(analysis.supportive_analysis);
    }

    return null;
  }

  private generateHTMLFile(state: SavedAppState, filename: string): void {
    // Get unique column headers from all tests
    const headers = new Set<string>();
    state.generatedTests.forEach(test => {
      const testData = test.data || test;
      Object.keys(testData).forEach(key => headers.add(key));
    });

    // Convert to array and ensure consistent order
    const tableHeaders = Array.from(headers);

    // Generate table rows
    const tableRows = state.generatedTests.map(test => {
      const testData = test.data || test;
      return `<tr>
        ${tableHeaders.map(header => {
        const value = testData[header];
        return `<td>${this.formatTableCell(value)}</td>`;
      }).join('')}
      </tr>`;
    }).join('');

    const htmlContent = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Generated Tests</title>
    <script type="application/json" id="app-state">
        ${JSON.stringify(state, null, 2)}
    </script>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            color: #333;
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px;
            background-color: #f4f4f4;
        }
        .metadata-container {
            background-color: #fff;
            padding: 20px;
            border-radius: 5px;
            margin-bottom: 20px;
            box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        }
        .metadata-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 20px;
        }
        .metadata-item {
            padding: 10px;
            background-color: #f8f9fa;
            border-radius: 4px;
        }
        .metadata-label {
            font-weight: bold;
            color: #2c3e50;
            margin-bottom: 5px;
        }
        .state-info {
            margin-top: 20px;
            padding: 10px;
            background-color: #e9ecef;
            border-radius: 4px;
        }
        .tests-container {
            background-color: #fff;
            padding: 20px;
            border-radius: 5px;
            margin: 20px 0;
            box-shadow: 0 1px 3px rgba(0,0,0,0.2);
            overflow-x: auto;
        }
        table {
            border-collapse: collapse;
            width: 100%;
            margin-bottom: 20px;
            background-color: #fff;
            box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        }
        th, td {
            border: 1px solid #ddd;
            padding: 12px;
            text-align: left;
        }
        th {
            background-color: #3498db;
            color: white;
            font-weight: bold;
        }
        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
        ul {
            margin: 0;
            padding-left: 20px;
        }
        #restore-instructions {
            background-color: #fff;
            padding: 20px;
            border-radius: 5px;
            margin-top: 20px;
            box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        }
    </style>
</head>
<body>
    <div class="metadata-container">
        <h2>Test State Information</h2>
        <div class="metadata-grid">
            <div class="metadata-item">
                <div class="metadata-label">Project ID</div>
                <div>${state.metadata.projectId}</div>
            </div>
            <div class="metadata-item">
                <div class="metadata-label">Author</div>
                <div>${state.metadata.author}</div>
            </div>
            <div class="metadata-item">
                <div class="metadata-label">Model</div>
                <div>${state.metadata.selectedModel}</div>
            </div>
            <div class="metadata-item">
                <div class="metadata-label">Language</div>
                <div>${state.metadata.projectLanguage}</div>
            </div>
            <div class="metadata-item">
                <div class="metadata-label">Template</div>
                <div>${state.metadata.templateName}</div>
            </div>
            <div class="metadata-item">
                <div class="metadata-label">Total Tests</div>
                <div>${state.metadata.totalTests}</div>
            </div>
        </div>
        <div class="state-info">
            <p>State Version: ${state.version}</p>
            <p>Saved: ${new Date(state.timestamp).toLocaleString()}</p>
        </div>
    </div>

    <div class="tests-container">
        <h2>Generated Tests (${state.generatedTests.length})</h2>
        <table>
            <thead>
                <tr>
                    ${tableHeaders.map(header =>
      `<th>${this.escapeHtml(header)}</th>`
    ).join('')}
                </tr>
            </thead>
            <tbody>
                ${tableRows}
            </tbody>
        </table>
    </div>

    <div id="restore-instructions">
        <h3>How to Restore</h3>
        <p>To restore this state:</p>
        <ol>
            <li>Upload this file back to the application</li>
            <li>The application will automatically restore your test cases and criteria</li>
            <li>All test metadata and relationships will be preserved</li>
        </ol>
    </div>
</body>
</html>`;

    // Create and download the file
    const blob = new Blob([htmlContent], { type: 'text/html;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.html`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private formatTableCell(value: any): string {
    if (value === null || value === undefined) {
      return '—';
    }

    if (Array.isArray(value)) {
      return this.escapeHtml(value.join('; '));
    }

    if (typeof value === 'object') {
      try {
        return this.escapeHtml(JSON.stringify(value));
      } catch (e) {
        return '[Complex Object]';
      }
    }

    return this.escapeHtml(String(value));
  }

  private escapeHtml(unsafe: string): string {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }

  async loadSavedState(file: File): Promise<SavedAppState | null> {
    try {
      const content = await this.readFileContent(file);
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
      const stateScript = doc.getElementById('app-state');

      if (!stateScript) {
        throw new Error('No state data found in file');
      }

      const state: SavedAppState = JSON.parse(stateScript.textContent || '');

      // Validate state version and structure
      if (!this.isValidStateVersion(state.version)) {
        throw new Error('Incompatible state version');
      }

      return state;
    } catch (error) {
      console.error('Error loading saved state:', error);
      return null;
    }
  }

  private isValidStateVersion(version: string): boolean {
    // Add version compatibility logic here
    const currentVersion = this.STATE_VERSION.split('.');
    const fileVersion = version.split('.');

    // Major version must match
    return currentVersion[0] === fileVersion[0];
  }

  private readFileContent(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target?.result as string);
      reader.onerror = (e) => reject(e);
      reader.readAsText(file);
    });
  }

  /**
   * Restores application state from a saved state object
   * @param state The saved application state to restore
   * @param includeTests Whether to restore test data (false if template doesn't match)
   * @param updateTemplate Whether to update the template selection (false to preserve current template)
   * @returns Promise resolving to true if restore was successful
   */
  async restoreState(state: SavedAppState, includeTests: boolean = true, updateTemplate: boolean = true): Promise<boolean> {
    try {
      // First, restore basic project properties
      this.sessioneService.updateSelectedModel(state.metadata.selectedModel);
      this.sessioneService.updateProjectLanguage(state.metadata.projectLanguage);

      // Next, restore template only if updateTemplate is true
      if (updateTemplate && state.selectedTemplate) {
        this.sessioneService.updateSelectedTemplate(state.selectedTemplate);
      }

      // Always restore criteria groups
      this.sessioneService.updateCriteriaGroups(state.criteriaGroups);

      // Restore context and instructions
      this.sessioneService.updateRefinedStoryString(state.context);
      this.sessioneService.updateIstruzioniScriviTest(state.istruzioniScriviTest);

      // Restore analysis components if they exist
      if (state.ambiguities) {
        this.sessioneService.updateAmbiguities(state.ambiguities);
      }

      if (state.coreAnalysis !== undefined && state.supportiveAnalysis !== undefined) {
        this.sessioneService.updateAnalysisData(state.coreAnalysis, state.supportiveAnalysis);
      }

      if (state.macroScenarios) {
        this.sessioneService.updateMacroScenarios(state.macroScenarios);
      }

      if (state.dataParameters) {
        // Reconstruct DataParametersResponse with empty token usage for compatibility
        const fullDataParameters: DataParametersResponse = {
          analysis_results: state.dataParameters.analysis_results,
          token_usage: { prompt_tokens: 0, completion_tokens: 0, total_tokens: 0 }
        };
        this.sessioneService.updateDataParameters(fullDataParameters);
      }

      if (state.storyDescription) {
        // Reconstruct StoryDescriptionResponse with empty token usage for compatibility
        const fullStoryDescription: StoryDescriptionResponse = {
          story_id: state.storyDescription.story_id,
          title: state.storyDescription.title,
          description: state.storyDescription.description,
          token_usage: { prompt_tokens: 0, completion_tokens: 0, total_tokens: 0 }
        };
        this.sessioneService.updateStoryDescription(fullStoryDescription);
      }

      // Only restore generated tests if includeTests is true (template matches)
      if (includeTests && Array.isArray(state.generatedTests) && state.generatedTests.length > 0) {
        this.sessioneService.updateGeneratedTests(state.generatedTests);
      } else {
        // Clear any existing tests when not restoring tests
        this.sessioneService.updateGeneratedTests([]);
      }

      console.log('State restored successfully', {
        modelRestored: state.metadata.selectedModel,
        templateUpdated: updateTemplate,
        templateValue: updateTemplate ? state.selectedTemplate : 'preserved current template',
        criteriaGroupsRestored: state.criteriaGroups.length,
        testsRestored: includeTests ? state.generatedTests.length : 0,
        testsSkipped: !includeTests,
        ambiguitiesRestored: state.ambiguities?.length || 0,
        hasAnalysisData: !!state.coreAnalysis,
        hasMacroScenarios: !!state.macroScenarios,
        hasDataParameters: !!state.dataParameters,
        hasStoryDescription: !!state.storyDescription
      });

      return true;
    } catch (error) {
      console.error('Error restoring state:', error);
      return false;
    }
  }

  isSubTable(value: any): boolean {
    const result = value &&
      typeof value === 'object' &&
      (
        (value.headers && Array.isArray(value.headers) && value.rows && Array.isArray(value.rows)) ||
        (Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' && !Array.isArray(value[0]))
      );

    return result;
  }

  private getSubTableRowCount(subTableData: any): number {
    if (Array.isArray(subTableData)) {
      return subTableData.length;
    }
    return subTableData?.rows?.length || 0;
  }

  private sanitizeCSVValue(value: any, delimiter: string): string {
    if (value === null || value === undefined) {
      return '';
    }

    const stringValue = String(value);

    // Check if value contains delimiter, newline, or quotes
    if (stringValue.includes(delimiter) ||
      stringValue.includes('\n') ||
      stringValue.includes('"')) {
      // Escape quotes and wrap in quotes
      return `"${stringValue.replace(/"/g, '""')}"`;
    }

    return stringValue;
  }

  exportTestsAsCSVWithColumns(filename: string, selectedColumns: string[], delimiter: string = '|'): void {
    // Get the current tests
    this.sessioneService.generatedTests$
      .pipe(take(1))
      .subscribe((generatedTests) => {
        // Create CSV content with BOM for Excel UTF-8 compatibility
        let csvContent = '\ufeff';

        // Add headers
        csvContent += selectedColumns.join(delimiter) + '\n';

        // Process each test
        generatedTests.forEach(test => {
          // Create a test data object with sequential ID
          const testData = { ...test.data };
          // Use the sequential ID from metadata
          testData.ID = test.metadata.sequentialId || 0;

          const row = selectedColumns.map(column => {
            const cellValue = testData[column];

            // Handle different data types
            if (this.isSubTable(cellValue)) {
              // For sub-tables, create a summary
              return `SubTable(${this.getSubTableRowCount(cellValue)} rows)`;
            } else if (Array.isArray(cellValue)) {
              // For arrays, join with semicolons
              return cellValue.map(item => this.sanitizeCSVValue(item, delimiter)).join(';');
            } else {
              // For simple values
              return this.sanitizeCSVValue(cellValue, delimiter);
            }
          });

          csvContent += row.join(delimiter) + '\n';
        });

        // Create and download the file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  private formatDataWithSequentialIds(tests: TestWithMetadata[]): any[] {
    return tests.map(test => {
      const formattedData = { ...test.data };
      // Add the sequential ID to the data object
      formattedData.ID = test.metadata.sequentialId || 0;
      return formattedData;
    });
  }

  // EXCEL

  /**
   * Export tests to Excel with flattened subtable rows, supporting column selection and Excel formatting options
   * @param filename The base filename without extension
   * @param selectedColumns Array of column names to include in export
   * @param options Excel formatting options (sheetName, addFilters, freezeHeader)
   */
  exportTestsAsExcelWithSubtables(
    filename: string,
    selectedColumns: string[] = [],
    options: {
      sheetName?: string;
      addFilters?: boolean;
      freezeHeader?: boolean;
    } = {}
  ): void {
    // Set default options
    const excelOptions = {
      sheetName: options.sheetName || 'Tests',
      addFilters: options.addFilters !== undefined ? options.addFilters : true,
      freezeHeader: options.freezeHeader !== undefined ? options.freezeHeader : true
    };

    // Get the current tests from the service
    this.sessioneService.generatedTests$
      .pipe(take(1))
      .subscribe((generatedTests) => {
        if (!generatedTests || generatedTests.length === 0) {
          console.warn('No tests to export');
          return;
        }

        // Get all columns from all tests (to handle dynamic columns)
        const allColumns = new Set<string>();
        generatedTests.forEach(test => {
          Object.keys(test.data).forEach(key => {
            if (key !== 'metadata') {
              allColumns.add(key);
            }
          });
        });
        // Add ID to the columns if not already present
        allColumns.add('ID');

        // If selectedColumns is empty, use all columns; otherwise, filter to only use selected columns
        const columns = selectedColumns.length > 0
          ? selectedColumns.filter(col => allColumns.has(col))
          : Array.from(allColumns);

        console.log('All columns:', Array.from(allColumns));
        console.log('Selected columns for export:', columns);

        // Use the existing isSubTable function to identify subtable columns
        const subtableColumns: string[] = [];

        columns.forEach(col => {
          // Find the first non-null value for this column across all tests
          for (const test of generatedTests) {
            if (test.data[col] !== null && test.data[col] !== undefined) {
              // Use the existing isSubTable function that works in other contexts
              if (this.isSubTable(test.data[col])) {
                subtableColumns.push(col);
              }
              break;
            }
          }
        });

        console.log('Identified subtable columns:', subtableColumns);

        // Get the non-subtable columns
        const regularColumns = columns.filter(col => !subtableColumns.includes(col));
        console.log('Regular columns:', regularColumns);

        // Track columns that will be expanded from subtables
        const expandedSubtableColumns = new Map<string, string[]>();

        // For each subtable column, collect all possible headers across all tests
        subtableColumns.forEach(col => {
          const allSubtableHeaders = new Set<string>();

          generatedTests.forEach(test => {
            if (this.isSubTable(test.data[col])) {
              const subHeaders = this.getSubtableHeaders(test.data[col]);
              subHeaders.forEach(header => allSubtableHeaders.add(header));
            }
          });

          expandedSubtableColumns.set(col, Array.from(allSubtableHeaders));
        });

        // Create the expanded Excel rows array that includes flattened subtables
        const expandedRows: any[] = [];

        // Add a hidden property to track test boundaries (not added as a column)
        let testCounter = 0;

        // Process each test
        generatedTests.forEach(test => {
          testCounter++;

          // Create a test data object with sequential ID
          const testData = { ...test.data };
          // Use the sequential ID from metadata
          testData.ID = test.metadata.sequentialId || 0;

          // Check if this test has any subtables that need expansion
          const hasSubtablesToExpand = subtableColumns.some(col =>
            this.isSubTable(testData[col])
          );

          if (!hasSubtablesToExpand) {
            // Simple case: no subtables to expand, just add the row with all columns
            const row: any = { '__testGroup': testCounter };

            // Add regular columns
            regularColumns.forEach(col => {
              row[col] = this.formatCellValueForExcel(testData[col]);
            });

            expandedRows.push(row);
          } else {
            // Complex case: need to expand subtables

            // Get the maximum number of rows across all subtables in this test
            const maxSubtableRows = Math.max(
              ...subtableColumns.map(col => {
                if (this.isSubTable(testData[col])) {
                  return this.getSubTableRowCount(testData[col]);
                }
                return 1;
              }),
              1  // Ensure at least 1 row
            );

            // Create multiple rows for this test (one for each subtable row)
            for (let i = 0; i < maxSubtableRows; i++) {
              const row: any = { '__testGroup': testCounter };

              // Add regular column values to each row
              regularColumns.forEach(col => {
                row[col] = this.formatCellValueForExcel(testData[col]);
              });

              // Add subtable values for this row index
              subtableColumns.forEach(col => {
                if (this.isSubTable(testData[col])) {
                  const subtableRows = this.getSubTableRows(testData[col]);
                  const subtableRowData = subtableRows[i] || {};

                  // Get the expanded headers for this subtable
                  const expandedHeaders = expandedSubtableColumns.get(col) || [];

                  // Add each subtable value with column prefix
                  expandedHeaders.forEach(header => {
                    const columnKey = `${col} - ${header}`;
                    row[columnKey] = subtableRowData[header] !== undefined ?
                      this.formatCellValueForExcel(subtableRowData[header]) : '';
                  });
                } else {
                  // This column should be a subtable, but isn't in this test
                  const expandedHeaders = expandedSubtableColumns.get(col) || [];
                  expandedHeaders.forEach(header => {
                    const columnKey = `${col} - ${header}`;
                    row[columnKey] = '';
                  });
                }
              });

              expandedRows.push(row);
            }
          }
        });

        // Create column headers for the Excel file
        // First add regular columns
        const flattenedColumns = [...regularColumns];

        // Then add expanded subtable columns
        subtableColumns.forEach(col => {
          const expandedHeaders = expandedSubtableColumns.get(col) || [];
          expandedHeaders.forEach(header => {
            flattenedColumns.push(`${col} - ${header}`);
          });
        });

        console.log('Flattened columns for Excel:', flattenedColumns);

        // Check if we have any rows to generate
        if (expandedRows.length > 0) {
          console.log('Sample of first row data:', expandedRows[0]);
        } else {
          console.log('No rows to export');
        }

        // Generate Excel file using XLSX library with the specified options
        this.generateExcelFile(flattenedColumns, expandedRows, filename, excelOptions);
      });
  }

  /**
   * Gets all headers from a subtable
   */
  private getSubtableHeaders(subtable: any): string[] {
    if (!subtable) return [];

    if (subtable.headers && Array.isArray(subtable.headers)) {
      return subtable.headers;
    } else if (Array.isArray(subtable) && subtable.length > 0) {
      // Get all unique keys from all objects
      const allKeys = new Set<string>();
      subtable.forEach(row => {
        if (row && typeof row === 'object') {
          Object.keys(row).forEach(key => allKeys.add(key));
        }
      });
      return Array.from(allKeys);
    }

    return [];
  }

  /**
   * Creates a flattened row for Excel with expanded subtables
   */
  private createExcelRow(
    test: TestWithMetadata,
    columns: string[],
    subtableColumns: string[],
    expandedSubtableColumns: Map<string, string[]>,
    subtableRowIndex: number = 0
  ): any {
    const row: any = {};

    // Process each column
    columns.forEach(col => {
      if (subtableColumns.includes(col)) {
        // This is a subtable column
        if (this.isSubTable(test.data[col])) {
          const subtableRows = this.getSubTableRows(test.data[col]);
          const subtableRowData = subtableRows[subtableRowIndex] || {};

          // Get the expanded headers for this subtable
          const expandedHeaders = expandedSubtableColumns.get(col) || [];

          // Add each subtable value with column prefix
          expandedHeaders.forEach(header => {
            const columnKey = `${col} - ${header}`;
            row[columnKey] = subtableRowData[header] || '';
          });
        } else {
          // This column should be a subtable, but isn't in this test
          const expandedHeaders = expandedSubtableColumns.get(col) || [];
          expandedHeaders.forEach(header => {
            const columnKey = `${col} - ${header}`;
            row[columnKey] = '';
          });
        }
      } else {
        // Regular column, just copy the value - make sure this works for all data types
        row[col] = this.formatCellValueForExcel(test.data[col]);
      }
    });

    return row;
  }

  /**
   * Format cell value for Excel compatibility with bullet point formatting
   */
  private formatCellValueForExcel(value: any): any {
    if (value === null || value === undefined) {
      return '';
    }

    // Handle arrays by converting to bullet points
    if (Array.isArray(value)) {
      return value.map(item => `• ${String(item)}`).join('\n');
    }

    // For string values that contain semicolons, convert to bullet points with compact formatting
    if (typeof value === 'string' && value.includes(';')) {
      // Split by semicolons, but use more compact bullet points formatting
      return value.split(';').map(item => item.trim())
        .filter(item => item.length > 0)
        .map(item => `• ${item}`)
        .join('\n');
    }

    // For objects that aren't subtables, convert to JSON string
    if (typeof value === 'object') {
      try {
        return JSON.stringify(value);
      } catch (e) {
        return '[Complex Object]';
      }
    }

    // Return strings, numbers, booleans as is
    return value;
  }

  /**
   * Gets the flattened column headers for Excel
   */
  private getFlattenedColumns(
    columns: string[],
    subtableColumns: string[],
    expandedSubtableColumns: Map<string, string[]>
  ): string[] {
    const flattenedColumns: string[] = [];

    columns.forEach(col => {
      if (subtableColumns.includes(col)) {
        // Expand this subtable column
        const expandedHeaders = expandedSubtableColumns.get(col) || [];
        expandedHeaders.forEach(header => {
          flattenedColumns.push(`${col} - ${header}`);
        });
      } else {
        // Regular column - make sure we include it
        flattenedColumns.push(col);
      }
    });

    console.log('flattened columns:' + flattenedColumns)
    return flattenedColumns;
  }

  /**
   * Gets the rows from a subtable in a consistent format
   */
  private getSubTableRows(subTable: any): any[] {
    if (!subTable) return [];

    // Return cached result if we have it
    const cacheKey = JSON.stringify(subTable);
    if (this.memoizedSubTableRows && this.memoizedSubTableRows.has(cacheKey)) {
      return this.memoizedSubTableRows.get(cacheKey)!;
    }

    let rows: any[] = [];

    // Handle case where we have headers and array rows
    if (subTable.headers && Array.isArray(subTable.rows)) {
      rows = subTable.rows.map((row: any[]) => {
        // Create an object combining headers with row values
        return subTable.headers.reduce((obj: any, header: string, index: number) => {
          obj[header] = row[index];
          return obj;
        }, {});
      });
    } else if (Array.isArray(subTable)) {
      rows = subTable;
    } else if (subTable.rows && Array.isArray(subTable.rows)) {
      rows = subTable.rows;
    }

    // Cache the result if memoization is available
    if (this.memoizedSubTableRows) {
      this.memoizedSubTableRows.set(cacheKey, rows);
    }

    return rows;
  }


  /**
   * Initialize the memoization cache for subtable rows if it doesn't exist
   */
  private ensureMemoizationCache(): void {
    if (!this.memoizedSubTableRows) {
      this.memoizedSubTableRows = new Map<string, any[]>();
    }
  }

  /**
   * Generate Excel file using ExcelJS with optimized subtable rows and proper handling of test groups
   */
  private generateExcelFile(
    columns: string[],
    data: any[],
    filename: string,
    options: {
      sheetName: string;
      addFilters: boolean;
      freezeHeader: boolean;
    }
  ): void {
    try {
      // First optimize the data to remove unnecessary subtable rows
      const optimizedData = this.optimizeSubtableRows(data);

      // Create a new workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(options.sheetName);

      // Define colors
      const primaryGreen = '92D050';
      const secondaryGreen = '70AD47';
      const alternateRowColor = 'F5F5F5';
      const borderColor = 'D9D9D9';
      const separatorLineColor = 'AAAAAA';

      // First, set up columns with minimum widths
      worksheet.columns = columns.map(header => {
        return {
          header: header,
          key: header,
          width: Math.max(12, Math.min(30, header.length * 1.2))
        };
      });

      // Add the optimized rows to the worksheet
      worksheet.addRows(optimizedData);

      // Style the header row
      const headerRow = worksheet.getRow(1);
      headerRow.font = { bold: true, color: { argb: '000000' } };
      headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      headerRow.height = 30;

      // Identify subtable columns
      const subtableColumnIndices = columns
        .map((header, index) => header.includes(' - ') ? index + 1 : -1)
        .filter(index => index !== -1);

      // Apply header styles based on column type
      headerRow.eachCell((cell, colNumber) => {
        // Header styling code remains the same...
        if (subtableColumnIndices.includes(colNumber)) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: primaryGreen }
          };
        } else {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F2F2F2' }
          };
        }

        cell.border = {
          top: { style: 'thin', color: { argb: borderColor } },
          left: { style: 'thin', color: { argb: borderColor } },
          bottom: { style: 'thin', color: { argb: borderColor } },
          right: { style: 'thin', color: { argb: borderColor } }
        };
      });

      // Group rows by test group to identify different tests
      const testGroups = [];
      const rowCount = optimizedData.length;

      // Test grouping code remains the same...
      if (rowCount > 0) {
        let startRow = 2;
        let currentTestGroup = optimizedData[0]['__testGroup'];

        for (let i = 1; i < optimizedData.length; i++) {
          const nextTestGroup = optimizedData[i]['__testGroup'];

          if (nextTestGroup !== currentTestGroup) {
            testGroups.push({
              startRow: startRow,
              endRow: i + 1,
              testGroup: currentTestGroup
            });

            startRow = i + 2;
            currentTestGroup = nextTestGroup;
          }
        }

        if (startRow <= rowCount + 1) {
          testGroups.push({
            startRow: startRow,
            endRow: rowCount + 1,
            testGroup: currentTestGroup
          });
        }
      }

      // Apply styles to each test group to create visual separation
      testGroups.forEach((group, index) => {
        const isAlternateGroup = index % 2 === 1;

        for (let rowIndex = group.startRow; rowIndex <= group.endRow; rowIndex++) {
          const row = worksheet.getRow(rowIndex);

          // Apply row height based on content - more compact approach
          let maxLines = 1;
          row.eachCell({ includeEmpty: false }, cell => {
            if (cell && cell.value !== null && cell.value !== undefined) {
              const cellValue = String(cell.value);

              // Count actual line breaks for height calculation
              if (cellValue.includes('\n')) {
                const lines = cellValue.split('\n').length;
                // Use a more compact line count calculation
                maxLines = Math.max(maxLines, lines);
              } else {
                // For cells without line breaks, use column width to estimate wrapping
                const colWidth = worksheet.getColumn(cell.col).width || 10;
                const estimatedCharsPerLine = Math.floor(colWidth / 0.9);
                if (cellValue.length > estimatedCharsPerLine) {
                  const estimatedLines = Math.ceil(cellValue.length / estimatedCharsPerLine);
                  maxLines = Math.max(maxLines, Math.min(estimatedLines, 5)); // Cap at 5 lines for non-bulleted text
                }
              }
            }
          });

          // Improved row height calculation - prioritize readability over compactness
          // This creates more spacious rows for better user experience
          if (maxLines <= 2) {
            // For 1-2 lines, use comfortable height
            row.height = Math.max(22, 20 + (maxLines - 1) * 15);
          } else {
            // For more lines, ensure adequate space while preventing excessive height
            // More generous spacing for better readability
            row.height = Math.min(150, Math.max(40, 35 + (maxLines - 2) * 18));
          }

          // Apply cell styling with better spacing for readability
          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            // Use more generous cell alignment settings for better readability
            cell.alignment = {
              vertical: 'middle', // Center vertically for better appearance
              wrapText: true,
              shrinkToFit: false, // Disable shrink to fit to ensure text is readable
              indent: cell.value && String(cell.value).includes('•') ? 2 : 0 // Increased indentation
            };

            // Add borders
            cell.border = {
              top: { style: 'thin', color: { argb: borderColor } },
              left: { style: 'thin', color: { argb: borderColor } },
              bottom: { style: 'thin', color: { argb: borderColor } },
              right: { style: 'thin', color: { argb: borderColor } }
            };

            // Apply alternating greens to subtable columns based on test group
            if (subtableColumnIndices.includes(colNumber)) {
              const subtableColor = isAlternateGroup ? secondaryGreen : primaryGreen;
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: subtableColor }
              };
            }
            // Apply alternate background to non-subtable columns for visual grouping
            else if (isAlternateGroup) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: alternateRowColor }
              };
            } else {
              // Ensure non-alternate rows have white background
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF' }
              };
            }
          });
        }

        // Add a thick separator line between test groups
        if (index < testGroups.length - 1) {
          const lastRowOfGroup = worksheet.getRow(group.endRow);
          lastRowOfGroup.eachCell({ includeEmpty: true }, (cell) => {
            cell.border = {
              top: { style: 'thin', color: { argb: borderColor } },
              left: { style: 'thin', color: { argb: borderColor } },
              right: { style: 'thin', color: { argb: borderColor } },
              bottom: { style: 'medium', color: { argb: separatorLineColor } }
            };
          });
        }
      });

      // Make sure the very last row has proper formatting
      if (rowCount > 0) {
        const lastRow = worksheet.getRow(rowCount + 1);
        lastRow.eachCell({ includeEmpty: true }, (cell) => {
          cell.border = {
            top: { style: 'thin', color: { argb: borderColor } },
            left: { style: 'thin', color: { argb: borderColor } },
            right: { style: 'thin', color: { argb: borderColor } },
            bottom: { style: 'thin', color: { argb: borderColor } }
          };
        });
      }

      // Dynamically adjust column widths but make them wider for bullet points
      columns.forEach((col, i) => {
        let maxLength = col.length;
        let hasBulletPoints = false;
        let maxBulletLines = 0;

        // Process each row to find the maximum content length
        for (let rowIndex = 2; rowIndex <= optimizedData.length + 1; rowIndex++) {
          const cell = worksheet.getCell(rowIndex, i + 1);
          if (cell && cell.value) {
            const cellValue = String(cell.value);

            // Check if we have bullet points
            if (cellValue.includes('•')) {
              hasBulletPoints = true;
              const lines = cellValue.split('\n');
              maxBulletLines = Math.max(maxBulletLines, lines.length);

              // Find the longest line
              const longestLine = Math.max(...lines.map(line => line.length));
              maxLength = Math.max(maxLength, longestLine);
            } else {
              const contentLength = cellValue.length;
              if (contentLength > maxLength) {
                maxLength = contentLength;
              }
            }
          }
        }

        // Calculate width with additional space for bullet points
        let estimatedWidth;

        if (hasBulletPoints) {
          // Columns with bullet points need more width
          estimatedWidth = Math.min(70, Math.max(25, maxLength * 0.9 + 5));

          // Add extra width based on number of bullet points
          if (maxBulletLines > 3) {
            estimatedWidth = Math.min(80, estimatedWidth + 5);
          }
        } else if (columns[i].includes(' - ')) {
          // Subtable columns - medium width
          estimatedWidth = Math.min(40, Math.max(12, maxLength * 0.8));
        } else {
          // Regular columns - default sizing
          estimatedWidth = Math.min(35, Math.max(12, maxLength * 0.9));
        }

        // Update column width
        worksheet.getColumn(i + 1).width = estimatedWidth;
      });

      // Add filters if requested
      if (options.addFilters) {
        worksheet.autoFilter = {
          from: { row: 1, column: 1 },
          to: { row: 1, column: columns.length }
        };
      }

      // Freeze header row if requested
      if (options.freezeHeader) {
        worksheet.views = [
          { state: 'frozen', xSplit: 0, ySplit: 1, topLeftCell: 'A2', activeCell: 'A2' }
        ];
      }

      // Generate Excel file
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.error('Error generating Excel file with ExcelJS:', error);
    }
  }

  /**
   * Optimizes data rows by removing redundant empty subtable rows
   * This helps reduce unnecessary expansion of tests with subtables
   */
  private optimizeSubtableRows(data: any[]): any[] {
    if (!data || data.length === 0) { return []; }

    // Group rows by the test group indicator
    const rowsByTestGroup = {};
    data.forEach(row => {
      const testGroup = row['__testGroup'];
      if (!rowsByTestGroup[testGroup]) {
        rowsByTestGroup[testGroup] = [];
      }
      rowsByTestGroup[testGroup].push(row);
    });

    // Process each group to optimize subtable rows
    const optimizedData: any[] = [];

    Object.keys(rowsByTestGroup).forEach(testGroup => {
      const rows = rowsByTestGroup[testGroup];

      // If only one row, no optimization needed
      if (rows.length <= 1) {
        optimizedData.push(...rows);
        return;
      }

      // Identify subtable columns (those with " - " in their name)
      const subtableColumns = Object.keys(rows[0])
        .filter(key => key.includes(' - ') && key !== '__testGroup');

      // If no subtable columns, just keep the first row
      if (subtableColumns.length === 0) {
        optimizedData.push(rows[0]);
        return;
      }

      // For each row, check if it has any filled subtable values
      const rowsWithContent = [];

      rows.forEach(row => {
        // Check if any subtable column has content
        const hasFilledSubtable = subtableColumns.some(col =>
          row[col] !== null &&
          row[col] !== undefined &&
          row[col] !== ''
        );

        if (hasFilledSubtable) {
          rowsWithContent.push(row);
        }
      });

      // If we found rows with subtable content, use only those
      if (rowsWithContent.length > 0) {
        optimizedData.push(...rowsWithContent);
      } else {
        // Otherwise, just keep the first row
        optimizedData.push(rows[0]);
      }
    });

    return optimizedData;
  }
}
