<div class="criteria-groups mt-3" *ngIf="dataParameters?.analysis_results">
  <div class="section-header">
    <h2 class="h5">TEST DATA CONFIGURATION</h2>
  </div>

  <!-- Configuration Parameters Section - Only display if present -->
  <div class="criteria-group" *ngIf="dataParameters.analysis_results.has_config_parameters || showConfigParamForm">
    <div class="feature-section">
      <h3 class="h5">Configuration Parameters</h3>
    </div>

    <div class="background-wrapper py-2">
      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th>Parameter</th>
            <th>Type</th>
            <th>Allowed Values</th>
            <th>Default</th>
            <th>Used In</th>
            <th>Impact</th>
            <th class="actions-column-minimal">Actions</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let param of dataParameters.analysis_results.configuration_parameters; let i = index">
            <!-- View mode with dblclick handlers -->
            <tr *ngIf="editingConfigParam !== i || (editingConfigParam === i && editingField)">
              <!-- Parameter cell -->
              <td (dblclick)="startInlineEdit('configParam', i, 'parameter', param.parameter)"
                  [class.cell-being-edited]="editingConfigParam === i && editingField === 'parameter'"
                  data-table="configParam">
                <ng-container *ngIf="!(editingConfigParam === i && editingField === 'parameter')">
                  {{ param.parameter }}
                </ng-container>
                <input *ngIf="editingConfigParam === i && editingField === 'parameter'"
                       type="text"
                       class="form-control form-control-sm"
                       [formControl]="inlineEditControl"
                       (blur)="saveInlineEdit('configParam')"
                       (keyup.enter)="saveInlineEdit('configParam')"
                       (keyup.escape)="cancelInlineEdit()"
                       (keydown)="handleKeyboardNavigation($event, 'configParam', i, 'parameter')"
                       #inlineEditInput>
              </td>

              <!-- Type cell -->
              <td (dblclick)="startInlineEdit('configParam', i, 'type', param.type)"
                  [class.cell-being-edited]="editingConfigParam === i && editingField === 'type'"
                  data-table="configParam">
                <ng-container *ngIf="!(editingConfigParam === i && editingField === 'type')">
                  {{ param.type }}
                </ng-container>
                <input *ngIf="editingConfigParam === i && editingField === 'type'"
                       type="text"
                       class="form-control form-control-sm"
                       [formControl]="inlineEditControl"
                       (blur)="saveInlineEdit('configParam')"
                       (keyup.enter)="saveInlineEdit('configParam')"
                       (keyup.escape)="cancelInlineEdit()"
                       (keydown)="handleKeyboardNavigation($event, 'configParam', i, 'type')"
                       #inlineEditInput>
              </td>

              <!-- Allowed Values cell -->
              <td (dblclick)="startInlineEdit('configParam', i, 'allowed_values', isArray(param.allowed_values) ? param.allowed_values.join(', ') : param.allowed_values)"
                  [class.cell-being-edited]="editingConfigParam === i && editingField === 'allowed_values'"
                  data-table="configParam">
                <ng-container *ngIf="!(editingConfigParam === i && editingField === 'allowed_values')">
                  <ng-container *ngIf="param.allowed_values">
                    <span *ngIf="!isArray(param.allowed_values)">{{ param.allowed_values }}</span>
                    <ul class="mb-0 ps-3" *ngIf="isArray(param.allowed_values)">
                      <li *ngFor="let value of param.allowed_values">{{ value }}</li>
                    </ul>
                  </ng-container>
                </ng-container>
                <textarea *ngIf="editingConfigParam === i && editingField === 'allowed_values'"
                          class="form-control form-control-sm"
                          [formControl]="inlineEditControl"
                          (blur)="saveInlineEdit('configParam')"
                          (keyup.enter)="saveInlineEdit('configParam')"
                          (keyup.escape)="cancelInlineEdit()"
                          (keydown)="handleKeyboardNavigation($event, 'configParam', i, 'allowed_values')"
                          rows="3"
                          #inlineEditInput>
                </textarea>
              </td>

              <!-- Default cell -->
              <td (dblclick)="startInlineEdit('configParam', i, 'default', param.default)"
                  [class.cell-being-edited]="editingConfigParam === i && editingField === 'default'"
                  data-table="configParam">
                <ng-container *ngIf="!(editingConfigParam === i && editingField === 'default')">
                  {{ param.default }}
                </ng-container>
                <input *ngIf="editingConfigParam === i && editingField === 'default'"
                       type="text"
                       class="form-control form-control-sm"
                       [formControl]="inlineEditControl"
                       (blur)="saveInlineEdit('configParam')"
                       (keyup.enter)="saveInlineEdit('configParam')"
                       (keyup.escape)="cancelInlineEdit()"
                       (keydown)="handleKeyboardNavigation($event, 'configParam', i, 'default')"
                       #inlineEditInput>
              </td>

              <!-- Used In cell -->
              <td (dblclick)="startInlineEdit('configParam', i, 'used_in', param.used_in ? param.used_in.join(', ') : '')"
                  [class.cell-being-edited]="editingConfigParam === i && editingField === 'used_in'"
                  data-table="configParam">
                <ng-container *ngIf="!(editingConfigParam === i && editingField === 'used_in')">
                  {{ param.used_in ? param.used_in.join(', ') : '' }}
                </ng-container>
                <input *ngIf="editingConfigParam === i && editingField === 'used_in'"
                       type="text"
                       class="form-control form-control-sm"
                       [formControl]="inlineEditControl"
                       (blur)="saveInlineEdit('configParam')"
                       (keyup.enter)="saveInlineEdit('configParam')"
                       (keyup.escape)="cancelInlineEdit()"
                       (keydown)="handleKeyboardNavigation($event, 'configParam', i, 'used_in')"
                       #inlineEditInput>
              </td>

              <!-- Impact cell -->
              <td class="text-wrap"
                  (dblclick)="startInlineEdit('configParam', i, 'impact', param.impact)"
                  [class.cell-being-edited]="editingConfigParam === i && editingField === 'impact'"
                  data-table="configParam">
                <ng-container *ngIf="!(editingConfigParam === i && editingField === 'impact')">
                  {{ param.impact }}
                </ng-container>
                <textarea *ngIf="editingConfigParam === i && editingField === 'impact'"
                          class="form-control form-control-sm large-content"
                          [formControl]="inlineEditControl"
                          (blur)="saveInlineEdit('configParam')"
                          (keyup.enter)="saveInlineEdit('configParam')"
                          (keyup.escape)="cancelInlineEdit()"
                          (keydown)="handleKeyboardNavigation($event, 'configParam', i, 'impact')"
                          rows="4"
                          #inlineEditInput>
                </textarea>
              </td>

              <td class="actions-column">
                <ng-container *ngIf="!(editingConfigParam === i && editingField)">
                  <button class="btn btn-sm btn-primary me-1" (click)="startEditing('configParam', i)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button class="btn btn-sm btn-danger ml-2" (click)="deleteConfigParameter(i)">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-container>
                <ng-container *ngIf="editingConfigParam === i && editingField">
                  <button class="btn btn-sm btn-success me-1" (click)="saveInlineEdit('configParam')">
                    <i class="fa fa-check"></i>
                  </button>
                  <button class="btn btn-sm btn-secondary ml-2" (click)="cancelInlineEdit()">
                    <i class="fa fa-times"></i>
                  </button>
                </ng-container>
              </td>
            </tr>

            <!-- Full row edit mode -->
            <tr *ngIf="editingConfigParam === i && !editingField">
              <td colspan="7">
                <form [formGroup]="configParamForm" (ngSubmit)="saveEdit('configParam')" class="edit-form p-2">
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Parameter:</label>
                      <input type="text" class="form-control" formControlName="parameter" required>
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Type:</label>
                      <input type="text" class="form-control" formControlName="type" required>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Allowed Values (comma separated):</label>
                      <input type="text" class="form-control" formControlName="allowed_values">
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Default:</label>
                      <input type="text" class="form-control" formControlName="default">
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Used In (comma separated):</label>
                      <input type="text" class="form-control" formControlName="used_in">
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Impact:</label>
                      <textarea class="form-control" formControlName="impact" rows="2"></textarea>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end mt-2">
                    <button type="button" class="btn btn-secondary me-2" (click)="cancelEdit('configParam')">Cancel</button>
                    <button type="submit" class="btn btn-success ml-2" [disabled]="!configParamForm.valid">Save</button>
                  </div>
                </form>
              </td>
            </tr>
          </ng-container>

          <!-- Add new parameter form -->
          <tr>
            <td colspan="7">
              <button class="btn btn-sm btn-success" (click)="toggleConfigParamForm()" *ngIf="editingConfigParam === null">
                <i class="bi bi-plus-circle"></i> Add New Parameter
              </button>

              <form *ngIf="editingConfigParam === null && showConfigParamForm" [formGroup]="configParamForm" (ngSubmit)="addConfigParameter()" class="add-form p-2 mt-2 border rounded">                <div class="row mb-2">
                <div class="col-md-6">
                  <label class="form-label">Parameter:</label>
                  <input type="text" class="form-control" formControlName="parameter" required>
                </div>
                <div class="col-md-6">
                  <label class="form-label">Type:</label>
                  <input type="text" class="form-control" formControlName="type" required>
                </div>
              </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <label class="form-label">Allowed Values (comma separated):</label>
                    <input type="text" class="form-control" formControlName="allowed_values">
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Default:</label>
                    <input type="text" class="form-control" formControlName="default">
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <label class="form-label">Used In (comma separated):</label>
                    <input type="text" class="form-control" formControlName="used_in">
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Impact:</label>
                    <textarea class="form-control" formControlName="impact" rows="2"></textarea>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-2">
                  <button type="button" class="btn btn-secondary me-2" (click)="toggleConfigParamForm()">Cancel</button>
                  <button type="submit" class="btn btn-success ml-2" [disabled]="!configParamForm.valid">Add Parameter</button>
                </div>
              </form>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Test Data Management Section - Only display if at least one subsection is present -->
  <div class="criteria-group" *ngIf="dataParameters.analysis_results.has_test_data_management ||
                                    showBaseDatasetForm || showDynamicDataForm ||
                                    showSensitiveDataForm || showEdgeCaseForm">
    <div class="feature-section">
      <h3 class="h5">Test Data Management</h3>
    </div>

    <!-- Base Dataset Section - Only show if present -->
    <div class="background-wrapper py-2" *ngIf="dataParameters.analysis_results.has_base_dataset || showBaseDatasetForm">
      <div class="background-header d-flex justify-content-between align-items-center">
        <h4 class="h6 mb-2">Base Dataset</h4>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Field</th>
            <th>Valid Values</th>
            <th>Invalid Values</th>
            <th>Rules</th>
            <th class="actions-column-minimal">Actions</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let item of dataParameters.analysis_results.test_data_management.base_dataset; let i = index">
            <!-- View mode with dblclick handlers -->
            <tr *ngIf="editingBaseDataset !== i || (editingBaseDataset === i && editingField)">
              <td (dblclick)="startInlineEdit('baseDataset', i, 'field', item.field)"
                  [class.cell-being-edited]="editingBaseDataset === i && editingField === 'field'">
                <ng-container *ngIf="!(editingBaseDataset === i && editingField === 'field')">
                  {{ item.field }}
                </ng-container>
                <input *ngIf="editingBaseDataset === i && editingField === 'field'"
                       type="text"
                       class="form-control form-control-sm"
                       [formControl]="inlineEditControl"
                       (blur)="saveInlineEdit('baseDataset')"
                       (keyup.enter)="saveInlineEdit('baseDataset')"
                       (keyup.escape)="cancelInlineEdit()"
                       #inlineEditInput>
              </td>

              <td (dblclick)="startInlineEdit('baseDataset', i, 'valid_values', isArray(item.valid_values) ? item.valid_values.join(', ') : item.valid_values)"
                  [class.cell-being-edited]="editingBaseDataset === i && editingField === 'valid_values'">
                <ng-container *ngIf="!(editingBaseDataset === i && editingField === 'valid_values')">
                  <ng-container *ngIf="isArray(item.valid_values) && item.valid_values.length > 0">
                    <ul class="mb-0 ps-3">
                      <li *ngFor="let value of item.valid_values">{{ formatSpecialValue(value) }}</li>
                    </ul>
                  </ng-container>
                  <ng-container *ngIf="!isArray(item.valid_values)">
                    <span>{{ formatSpecialValue(item.valid_values.toString()) }}</span>
                  </ng-container>
                  <ng-container *ngIf="isArray(item.valid_values) && item.valid_values.length === 0">
                    <span>None</span>
                  </ng-container>
                </ng-container>
                <textarea *ngIf="editingBaseDataset === i && editingField === 'valid_values'"
                          class="form-control form-control-sm"
                          [formControl]="inlineEditControl"
                          (blur)="saveInlineEdit('baseDataset')"
                          (keyup.enter)="saveInlineEdit('baseDataset')"
                          (keyup.escape)="cancelInlineEdit()"
                          rows="3"
                          #inlineEditInput>
                </textarea>
              </td>

              <td (dblclick)="startInlineEdit('baseDataset', i, 'invalid_values', isArray(item.invalid_values) ? item.invalid_values.join(', ') : item.invalid_values)"
                  [class.cell-being-edited]="editingBaseDataset === i && editingField === 'invalid_values'">
                <ng-container *ngIf="!(editingBaseDataset === i && editingField === 'invalid_values')">
                  <ng-container *ngIf="isArray(item.invalid_values) && item.invalid_values.length > 0">
                    <ul class="mb-0 ps-3">
                      <li *ngFor="let value of item.invalid_values">{{ formatSpecialValue(value) }}</li>
                    </ul>
                  </ng-container>
                  <ng-container *ngIf="!isArray(item.invalid_values)">
                    <span>{{ formatSpecialValue(item.invalid_values.toString()) }}</span>
                  </ng-container>
                  <ng-container *ngIf="isArray(item.invalid_values) && item.invalid_values.length === 0">
                    <span>None</span>
                  </ng-container>
                </ng-container>
                <textarea *ngIf="editingBaseDataset === i && editingField === 'invalid_values'"
                          class="form-control form-control-sm"
                          [formControl]="inlineEditControl"
                          (blur)="saveInlineEdit('baseDataset')"
                          (keyup.enter)="saveInlineEdit('baseDataset')"
                          (keyup.escape)="cancelInlineEdit()"
                          rows="3"
                          #inlineEditInput>
                </textarea>
              </td>

              <td class="text-wrap"
                  (dblclick)="startInlineEdit('baseDataset', i, 'rules', item.rules)"
                  [class.cell-being-edited]="editingBaseDataset === i && editingField === 'rules'">
                <ng-container *ngIf="!(editingBaseDataset === i && editingField === 'rules')">
                  {{ item.rules }}
                </ng-container>
                <textarea *ngIf="editingBaseDataset === i && editingField === 'rules'"
                          class="form-control form-control-sm"
                          [formControl]="inlineEditControl"
                          (blur)="saveInlineEdit('baseDataset')"
                          (keyup.enter)="saveInlineEdit('baseDataset')"
                          (keyup.escape)="cancelInlineEdit()"
                          rows="2"
                          #inlineEditInput>
                </textarea>
              </td>

              <td class="actions-column">
                <ng-container *ngIf="!(editingBaseDataset === i && editingField)">
                  <button class="btn btn-sm btn-primary me-1" (click)="startEditing('baseDataset', i)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button class="btn btn-sm btn-danger ml-2" (click)="deleteBaseDataset(i)">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-container>
                <ng-container *ngIf="editingBaseDataset === i && editingField">
                  <button class="btn btn-sm btn-success me-1" (click)="saveInlineEdit('baseDataset')">
                    <i class="fa fa-check"></i>
                  </button>
                  <button class="btn btn-sm btn-secondary ml-2" (click)="cancelInlineEdit()">
                    <i class="fa fa-times"></i>
                  </button>
                </ng-container>
              </td>
            </tr>

            <!-- Full row edit mode -->
            <tr *ngIf="editingBaseDataset === i && !editingField">
              <td colspan="5">
                <form [formGroup]="baseDatasetForm" (ngSubmit)="saveEdit('baseDataset')" class="edit-form p-2">
                  <div class="row mb-2">
                    <div class="col-md-12">
                      <label class="form-label">Field:</label>
                      <input type="text" class="form-control" formControlName="field" required>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Valid Values (comma separated):</label>
                      <textarea class="form-control" formControlName="valid_values" rows="3"></textarea>
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Invalid Values (comma separated):</label>
                      <textarea class="form-control" formControlName="invalid_values" rows="3"></textarea>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-12">
                      <label class="form-label">Rules:</label>
                      <textarea class="form-control" formControlName="rules" rows="2"></textarea>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end mt-2">
                    <button type="button" class="btn btn-secondary me-2" (click)="cancelEdit('baseDataset')">Cancel</button>
                    <button type="submit" class="btn btn-success ml-2" [disabled]="!baseDatasetForm.valid">Save</button>
                  </div>
                </form>
              </td>
            </tr>
          </ng-container>

          <!-- Add new base dataset item form -->
          <tr>
            <td colspan="5">
              <button class="btn btn-sm btn-success" (click)="toggleBaseDatasetForm()" *ngIf="editingBaseDataset === null">
                <i class="bi bi-plus-circle"></i> Add New Base Dataset Item
              </button>

              <form *ngIf="editingBaseDataset === null && showBaseDatasetForm" [formGroup]="baseDatasetForm" (ngSubmit)="addBaseDataset()" class="add-form p-2 mt-2 border rounded">
                <div class="row mb-2">
                  <div class="col-md-12">
                    <label class="form-label">Field:</label>
                    <input type="text" class="form-control" formControlName="field" required>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <label class="form-label">Valid Values (comma separated):</label>
                    <textarea class="form-control" formControlName="valid_values" rows="3"></textarea>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Invalid Values (comma separated):</label>
                    <textarea class="form-control" formControlName="invalid_values" rows="3"></textarea>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-12">
                    <label class="form-label">Rules:</label>
                    <textarea class="form-control" formControlName="rules" rows="2"></textarea>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-2">
                  <button type="button" class="btn btn-secondary me-2" (click)="toggleBaseDatasetForm()">Cancel</button>
                  <button type="submit" class="btn btn-success ml-2" [disabled]="!baseDatasetForm.valid">Add Item</button>
                </div>
              </form>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Dynamic Data Section - Only show if present -->
    <div class="background-wrapper py-2" *ngIf="dataParameters.analysis_results.has_dynamic_data || showDynamicDataForm">
      <div class="background-header d-flex justify-content-between align-items-center">
        <h4 class="h6 mb-2">Dynamic Data</h4>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Field</th>
            <th>Generation Method</th>
            <th class="actions-column-minimal">Actions</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let item of dataParameters.analysis_results.test_data_management.dynamic_data; let i = index">
            <!-- View mode with dblclick handlers -->
            <tr *ngIf="editingDynamicData !== i || (editingDynamicData === i && editingField)">
              <td (dblclick)="startInlineEdit('dynamicData', i, 'field', item.field)"
                  [class.cell-being-edited]="editingDynamicData === i && editingField === 'field'">
                <ng-container *ngIf="!(editingDynamicData === i && editingField === 'field')">
                  {{ item.field }}
                </ng-container>
                <input *ngIf="editingDynamicData === i && editingField === 'field'"
                       type="text"
                       class="form-control form-control-sm"
                       [formControl]="inlineEditControl"
                       (blur)="saveInlineEdit('dynamicData')"
                       (keyup.enter)="saveInlineEdit('dynamicData')"
                       (keyup.escape)="cancelInlineEdit()"
                       #inlineEditInput>
              </td>

              <td class="text-wrap"
                  (dblclick)="startInlineEdit('dynamicData', i, 'generation', item.generation)"
                  [class.cell-being-edited]="editingDynamicData === i && editingField === 'generation'">
                <ng-container *ngIf="!(editingDynamicData === i && editingField === 'generation')">
                  {{ item.generation }}
                </ng-container>
                <textarea *ngIf="editingDynamicData === i && editingField === 'generation'"
                          class="form-control form-control-sm"
                          [formControl]="inlineEditControl"
                          (blur)="saveInlineEdit('dynamicData')"
                          (keyup.enter)="saveInlineEdit('dynamicData')"
                          (keyup.escape)="cancelInlineEdit()"
                          rows="2"
                          #inlineEditInput>
                </textarea>
              </td>

              <td class="actions-column">
                <ng-container *ngIf="!(editingDynamicData === i && editingField)">
                  <button class="btn btn-sm btn-primary me-1" (click)="startEditing('dynamicData', i)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button class="btn btn-sm btn-danger ml-2" (click)="deleteDynamicData(i)">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-container>
                <ng-container *ngIf="editingDynamicData === i && editingField">
                  <button class="btn btn-sm btn-success me-1" (click)="saveInlineEdit('dynamicData')">
                    <i class="fa fa-check"></i>
                  </button>
                  <button class="btn btn-sm btn-secondary ml-2" (click)="cancelInlineEdit()">
                    <i class="fa fa-times"></i>
                  </button>
                </ng-container>
              </td>
            </tr>

            <!-- Full row edit mode -->
            <tr *ngIf="editingDynamicData === i && !editingField">
              <td colspan="3">
                <form [formGroup]="dynamicDataForm" (ngSubmit)="saveEdit('dynamicData')" class="edit-form p-2">
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Field:</label>
                      <input type="text" class="form-control" formControlName="field" required>
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Generation Method:</label>
                      <textarea class="form-control" formControlName="generation" rows="2" required></textarea>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end mt-2">
                    <button type="button" class="btn btn-secondary me-2" (click)="cancelEdit('dynamicData')">Cancel</button>
                    <button type="submit" class="btn btn-success ml-2" [disabled]="!dynamicDataForm.valid">Save</button>
                  </div>
                </form>
              </td>
            </tr>
          </ng-container>

          <!-- Add new dynamic data item form -->
          <tr>
            <td colspan="3">
              <button class="btn btn-sm btn-success" (click)="toggleDynamicDataForm()" *ngIf="editingDynamicData === null">
                <i class="bi bi-plus-circle"></i> Add New Dynamic Data
              </button>

              <form *ngIf="editingDynamicData === null && showDynamicDataForm" [formGroup]="dynamicDataForm" (ngSubmit)="addDynamicData()" class="add-form p-2 mt-2 border rounded">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <label class="form-label">Field:</label>
                    <input type="text" class="form-control" formControlName="field" required>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Generation Method:</label>
                    <textarea class="form-control" formControlName="generation" rows="2" required></textarea>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-2">
                  <button type="button" class="btn btn-secondary me-2" (click)="toggleDynamicDataForm()">Cancel</button>
                  <button type="submit" class="btn btn-success ml-2" [disabled]="!dynamicDataForm.valid">Add Dynamic Data</button>
                </div>
              </form>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Sensitive Data Section - Only show if present -->
    <div class="background-wrapper py-2" *ngIf="dataParameters.analysis_results.has_sensitive_data || showSensitiveDataForm">
      <div class="background-header d-flex justify-content-between align-items-center">
        <h4 class="h6 mb-2">Sensitive Data</h4>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Type</th>
            <th>Handling</th>
            <th class="actions-column-minimal">Actions</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let item of dataParameters.analysis_results.test_data_management.sensitive_data; let i = index">
            <!-- View mode with dblclick handlers -->
            <tr *ngIf="editingSensitiveData !== i || (editingSensitiveData === i && editingField)">
              <td (dblclick)="startInlineEdit('sensitiveData', i, 'type', item.type)"
                  [class.cell-being-edited]="editingSensitiveData === i && editingField === 'type'">
                <ng-container *ngIf="!(editingSensitiveData === i && editingField === 'type')">
                  {{ item.type }}
                </ng-container>
                <input *ngIf="editingSensitiveData === i && editingField === 'type'"
                       type="text"
                       class="form-control form-control-sm"
                       [formControl]="inlineEditControl"
                       (blur)="saveInlineEdit('sensitiveData')"
                       (keyup.enter)="saveInlineEdit('sensitiveData')"
                       (keyup.escape)="cancelInlineEdit()"
                       #inlineEditInput>
              </td>

              <td class="text-wrap"
                  (dblclick)="startInlineEdit('sensitiveData', i, 'handling', item.handling)"
                  [class.cell-being-edited]="editingSensitiveData === i && editingField === 'handling'">
                <ng-container *ngIf="!(editingSensitiveData === i && editingField === 'handling')">
                  {{ item.handling }}
                </ng-container>
                <textarea *ngIf="editingSensitiveData === i && editingField === 'handling'"
                          class="form-control form-control-sm"
                          [formControl]="inlineEditControl"
                          (blur)="saveInlineEdit('sensitiveData')"
                          (keyup.enter)="saveInlineEdit('sensitiveData')"
                          (keyup.escape)="cancelInlineEdit()"
                          rows="2"
                          #inlineEditInput>
          </textarea>
              </td>

              <td class="actions-column">
                <ng-container *ngIf="!(editingSensitiveData === i && editingField)">
                  <button class="btn btn-sm btn-primary me-1" (click)="startEditing('sensitiveData', i)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button class="btn btn-sm btn-danger ml-2" (click)="deleteSensitiveData(i)">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-container>
                <ng-container *ngIf="editingSensitiveData === i && editingField">
                  <button class="btn btn-sm btn-success me-1" (click)="saveInlineEdit('sensitiveData')">
                    <i class="fa fa-check"></i>
                  </button>
                  <button class="btn btn-sm btn-secondary ml-2" (click)="cancelInlineEdit()">
                    <i class="fa fa-times"></i>
                  </button>
                </ng-container>
              </td>
            </tr>

            <!-- Full row edit mode -->
            <tr *ngIf="editingSensitiveData === i && !editingField">
              <td colspan="3">
                <form [formGroup]="sensitiveDataForm" (ngSubmit)="saveEdit('sensitiveData')" class="edit-form p-2">
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Type:</label>
                      <input type="text" class="form-control" formControlName="type" required>
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Handling:</label>
                      <textarea class="form-control" formControlName="handling" rows="2" required></textarea>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end mt-2">
                    <button type="button" class="btn btn-secondary me-2" (click)="cancelEdit('sensitiveData')">Cancel</button>
                    <button type="submit" class="btn btn-success ml-2" [disabled]="!sensitiveDataForm.valid">Save</button>
                  </div>
                </form>
              </td>
            </tr>
          </ng-container>

          <!-- Add new sensitive data item form -->
          <tr>
            <td colspan="3">
              <button class="btn btn-sm btn-success" (click)="toggleSensitiveDataForm()" *ngIf="editingSensitiveData === null">
                <i class="bi bi-plus-circle"></i> Add New Sensitive Data
              </button>

              <form *ngIf="editingSensitiveData === null && showSensitiveDataForm" [formGroup]="sensitiveDataForm" (ngSubmit)="addSensitiveData()" class="add-form p-2 mt-2 border rounded">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <label class="form-label">Type:</label>
                    <input type="text" class="form-control" formControlName="type" required>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Handling:</label>
                    <textarea class="form-control" formControlName="handling" rows="2" required></textarea>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-2">
                  <button type="button" class="btn btn-secondary me-2" (click)="toggleSensitiveDataForm()">Cancel</button>
                  <button type="submit" class="btn btn-success ml-2" [disabled]="!sensitiveDataForm.valid">Add Sensitive Data</button>
                </div>
              </form>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Edge Cases Section - Only show if present -->
    <div class="background-wrapper py-2" *ngIf="dataParameters.analysis_results.has_edge_cases || showEdgeCaseForm">
      <div class="background-header d-flex justify-content-between align-items-center">
        <h4 class="h6 mb-2">Edge Cases</h4>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Scenario</th>
            <th>Relevance</th>
            <th class="actions-column-minimal">Actions</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let item of dataParameters.analysis_results.test_data_management.edge_cases; let i = index">
            <!-- View mode with dblclick handlers -->
            <tr *ngIf="editingEdgeCase !== i || (editingEdgeCase === i && editingField)">
              <td class="text-wrap"
                  (dblclick)="startInlineEdit('edgeCase', i, 'scenario', item.scenario)"
                  [class.cell-being-edited]="editingEdgeCase === i && editingField === 'scenario'">
                <ng-container *ngIf="!(editingEdgeCase === i && editingField === 'scenario')">
                  {{ item.scenario }}
                </ng-container>
                <textarea *ngIf="editingEdgeCase === i && editingField === 'scenario'"
                          class="form-control form-control-sm"
                          [formControl]="inlineEditControl"
                          (blur)="saveInlineEdit('edgeCase')"
                          (keyup.enter)="saveInlineEdit('edgeCase')"
                          (keyup.escape)="cancelInlineEdit()"
                          rows="2"
                          #inlineEditInput>
          </textarea>
              </td>

              <td class="text-wrap"
                  (dblclick)="startInlineEdit('edgeCase', i, 'relevance', item.relevance)"
                  [class.cell-being-edited]="editingEdgeCase === i && editingField === 'relevance'">
                <ng-container *ngIf="!(editingEdgeCase === i && editingField === 'relevance')">
                  {{ item.relevance }}
                </ng-container>
                <textarea *ngIf="editingEdgeCase === i && editingField === 'relevance'"
                          class="form-control form-control-sm"
                          [formControl]="inlineEditControl"
                          (blur)="saveInlineEdit('edgeCase')"
                          (keyup.enter)="saveInlineEdit('edgeCase')"
                          (keyup.escape)="cancelInlineEdit()"
                          rows="2"
                          #inlineEditInput>
          </textarea>
              </td>

              <td class="actions-column">
                <ng-container *ngIf="!(editingEdgeCase === i && editingField)">
                  <button class="btn btn-sm btn-primary me-1" (click)="startEditing('edgeCase', i)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button class="btn btn-sm btn-danger ml-2" (click)="deleteEdgeCase(i)">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-container>
                <ng-container *ngIf="editingEdgeCase === i && editingField">
                  <button class="btn btn-sm btn-success me-1" (click)="saveInlineEdit('edgeCase')">
                    <i class="fa fa-check"></i>
                  </button>
                  <button class="btn btn-sm btn-secondary ml-2" (click)="cancelInlineEdit()">
                    <i class="fa fa-times"></i>
                  </button>
                </ng-container>
              </td>
            </tr>

            <!-- Full row edit mode -->
            <tr *ngIf="editingEdgeCase === i && !editingField">
              <td colspan="3">
                <form [formGroup]="edgeCaseForm" (ngSubmit)="saveEdit('edgeCase')" class="edit-form p-2">
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Scenario:</label>
                      <textarea class="form-control" formControlName="scenario" rows="2" required></textarea>
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Relevance:</label>
                      <textarea class="form-control" formControlName="relevance" rows="2" required></textarea>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end mt-2">
                    <button type="button" class="btn btn-secondary me-2" (click)="cancelEdit('edgeCase')">Cancel</button>
                    <button type="submit" class="btn btn-success ml-2" [disabled]="!edgeCaseForm.valid">Save</button>
                  </div>
                </form>
              </td>
            </tr>
          </ng-container>

          <!-- Add new edge case form -->
          <tr>
            <td colspan="3">
              <button class="btn btn-sm btn-success" (click)="toggleEdgeCaseForm()" *ngIf="editingEdgeCase === null">
                <i class="bi bi-plus-circle"></i> Add New Edge Case
              </button>

              <form *ngIf="editingEdgeCase === null && showEdgeCaseForm" [formGroup]="edgeCaseForm" (ngSubmit)="addEdgeCase()" class="add-form p-2 mt-2 border rounded">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <label class="form-label">Scenario:</label>
                    <textarea class="form-control" formControlName="scenario" rows="2" required></textarea>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Relevance:</label>
                    <textarea class="form-control" formControlName="relevance" rows="2" required></textarea>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-2">
                  <button type="button" class="btn btn-secondary me-2" (click)="toggleEdgeCaseForm()">Cancel</button>
                  <button type="submit" class="btn btn-success ml-2" [disabled]="!edgeCaseForm.valid">Add Edge Case</button>
                </div>
              </form>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Display message when no data is available -->
  <div class="alert alert-info" *ngIf="!dataParameters.analysis_results.has_config_parameters &&
                                   !dataParameters.analysis_results.has_test_data_management &&
                                   !showConfigParamForm && !showBaseDatasetForm &&
                                   !showDynamicDataForm && !showSensitiveDataForm &&
                                   !showEdgeCaseForm">
    <p>No data parameters were found for this user story. You can:</p>
    <ul>
      <li>Add configuration parameters manually using the buttons below</li>
      <li>Modify your user story to include more specific data requirements</li>
      <li>Try a different analysis model or approach</li>
    </ul>
    <div class="mt-3">
      <button class="btn btn-primary me-2" (click)="toggleConfigParamForm()">
        <i class="bi bi-plus-circle"></i> Add Configuration Parameter
      </button>
      <button class="btn btn-secondary me-2" (click)="toggleBaseDatasetForm()">
        <i class="bi bi-plus-circle"></i> Add Base Dataset
      </button>
      <button class="btn btn-secondary me-2" (click)="toggleDynamicDataForm()">
        <i class="bi bi-plus-circle"></i> Add Dynamic Data
      </button>
      <button class="btn btn-secondary me-2" (click)="toggleSensitiveDataForm()">
        <i class="bi bi-plus-circle"></i> Add Sensitive Data
      </button>
      <button class="btn btn-secondary" (click)="toggleEdgeCaseForm()">
        <i class="bi bi-plus-circle"></i> Add Edge Case
      </button>
    </div>
  </div>
</div>
