import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {TestWithMetadata} from '../../../test/test-id.service';

@Component({
  selector: 'app-rigenera-test-modal',
  templateUrl: './rigenera-test-modal.component.html',
  styleUrls: ['./rigenera-test-modal.component.scss']
})
export class RigeneraTestModalComponent implements OnInit {
  @Input() oldTests: TestWithMetadata[] = [];
  @Input() newTests: TestWithMetadata[] = [];

  selectedTests: { [key: string]: boolean } = {};
  displayColumns: string[] = [];
  allSubTablesExpanded = false;

  constructor(public activeModal: NgbActiveModal) {}

  private getSelectionKey(test: TestWithMetadata, isOld: boolean): string {
    return `${isOld ? 'old' : 'new'}-${test.metadata.displayId}`;
  }

  ngOnInit() {
    // Initialize selection state
    this.oldTests.forEach(test => {
      this.selectedTests[this.getSelectionKey(test, true)] = false;
    });

    this.newTests.forEach(test => {
      this.selectedTests[this.getSelectionKey(test, false)] = true;
    });

    // Get display columns from the first test's data
    const firstTest = this.oldTests[0] || this.newTests[0];
    if (firstTest) {
      this.displayColumns = Object.keys(firstTest.data);
    }
  }

  isTestSelected(test: TestWithMetadata, isOld: boolean): boolean {
    return this.selectedTests[this.getSelectionKey(test, isOld)] || false;
  }

  toggleTestSelection(test: TestWithMetadata, isOld: boolean): void {
    const key = this.getSelectionKey(test, isOld);
    this.selectedTests[key] = !this.selectedTests[key];
  }

  isSubTable(value: any): boolean {
    return Array.isArray(value) && value.length > 0 && typeof value[0] === 'object';
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  getSubTableColumns(subTable: any[]): string[] {
    if (!this.isSubTable(subTable) || subTable.length === 0) {
      return [];
    }
    return Object.keys(subTable[0]);
  }

  getSubTableRows(subTable: any[]): any[] {
    if (!this.isSubTable(subTable)) {
      return [];
    }
    return subTable;
  }

  getSubTableRowCount(subTable: any[]): number {
    if (!this.isSubTable(subTable)) {
      return 0;
    }
    return subTable.length;
  }

  selectAll(selected: boolean) {
    this.oldTests.forEach(test => {
      this.selectedTests[this.getSelectionKey(test, true)] = selected;
    });
    this.newTests.forEach(test => {
      this.selectedTests[this.getSelectionKey(test, false)] = selected;
    });
  }

  confirm() {
    const selectedTests = [
      ...this.oldTests.filter(test => this.selectedTests[this.getSelectionKey(test, true)]),
      ...this.newTests.filter(test => this.selectedTests[this.getSelectionKey(test, false)])
    ];
    this.activeModal.close(selectedTests);
  }

  getColumnStyle(column: string): { [key: string]: string } {
    switch (column) {
      case 'ID':
        return { width: '120px' };
      case 'description':
        return { minWidth: '300px' };
      default:
        return {};
    }
  }
}
