<div class="string-highlighter-container">

  <!-- Error message if any -->
  <div *ngIf="error" class="error-message">
    {{ error }}
  </div>

  <!-- Main content area with highlighted text -->
  <div class="content-container" *ngIf="originalString">
    <div class="highlighted-content">
      <span *ngFor="let segment of processedSegments"
            [ngClass]="{'highlighted': segment.isHighlighted, 'selected': selectedSegment?.id === segment.id}"
            (click)="segment.isHighlighted && selectSegment(segment)">
        {{ segment.text }}
      </span>
    </div>

    <!-- Side panel showing info for selected highlight -->
    <div class="side-panel" *ngIf="selectedSegment && selectedSegment.ambiguity">
      <div class="panel-header">
        <h3>Ambiguity Information</h3>
        <button class="close-btn" (click)="closeSidePanel()">×</button>
      </div>
      <div class="panel-content">
        <div class="segment-text">
          <h4>Description</h4>
          <p>{{ selectedSegment.ambiguity.description }}</p>
        </div>

        <div class="ambiguity-info">
          <div class="clarification">
            <h4>Clarification Needed</h4>
            <p>{{ selectedSegment.ambiguity.clarification_needed }}</p>
          </div>

          <div class="impact">
            <h4>Impact</h4>
            <p [ngClass]="getImpactClass(selectedSegment.ambiguity.impact)">
              {{ selectedSegment.ambiguity.impact }}
            </p>
          </div>

          <div class="resolution" *ngIf="selectedSegment.ambiguity.resolution">
            <h4>Resolution</h4>
            <p>{{ selectedSegment.ambiguity.resolution }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
