<div class="notes-container">
  <div class="notes-toggle">
    <div class="d-flex align-items-center">
      <h5 class="mr-auto">{{ isCore ? 'User Story' : fileName }}</h5>

      <span class="text-muted mr-3">
        <small>
          {{ (refinedStoryString?.length > 0 ? refinedStoryString.length - 1 : 0) }} chars | ~{{ getEstimatedTokens() }} tokens
          <span *ngIf="isImage"> (Image data)</span>
        </small>
      </span>

      <button *ngIf="isCollapsed"
              type="button"
              class="btn btn-outline-dark"
              (click)="isCollapsed = !isCollapsed"
              aria-controls="addnotes"
              [attr.aria-expanded]="!isCollapsed">
        {{ isCollapsed ? 'Show' : 'Hide' }}
      </button>

      <button *ngIf="!isCollapsed"
              type="button"
              class="btn btn-dark"
              (click)="isCollapsed = !isCollapsed"
              aria-controls="addnotes"
              [attr.aria-expanded]="!isCollapsed">
        {{ isCollapsed ? 'Show' : 'Hide' }}
      </button>
    </div>
  </div>

  <div class="notes-content" [ngClass]="{'show': !isCollapsed}" [id]="'story-content-' + (fileName || 'core')">
    <div class="card">
      <!-- Card header with buttons -->
      <div class="card-header d-flex justify-content-between align-items-center">
        <!-- Left side buttons - Download and Copy -->
        <div class="d-flex align-items-center">
          <button class="btn btn-outline-primary ml-1" (click)="downloadHtmlFile()">
            <i class="ft-download mr-1"></i>{{ isCore ? 'Download Story' : 'Download Content' }}
          </button>
          <button ngxClipboard [cbContent]="getEditorContent()" class="btn btn-outline-primary ml-1">
            {{ isCore ? 'Copy Story' : 'Copy Content' }}
          </button>
        </div>

        <!-- Right side - View mode toggle buttons (only for core story) -->
        <div class="btn-group" role="group" *ngIf="isCore">
          <button
            type="button"
            class="btn btn-sm"
            [class.btn-primary]="viewMode === 'edit'"
            [class.btn-outline-primary]="viewMode !== 'edit'"
            (click)="setViewMode('edit')">
            <i class="ft-edit-2 mr-1"></i> Edit Mode
          </button>
          <button
            type="button"
            class="btn btn-sm"
            [class.btn-primary]="viewMode === 'analysis'"
            [class.btn-outline-primary]="viewMode !== 'analysis'"
            (click)="setViewMode('analysis')">
            <i class="ft-bar-chart-2 mr-1"></i> Analysis Mode
          </button>
        </div>
      </div>

      <!-- Editor content -->
      <div *ngIf="viewMode === 'edit'" class="card-body">
        <div #editor></div>
      </div>

      <!-- Analysis content -->
      <div *ngIf="viewMode === 'analysis'" class="card-body">
        <app-story-highlight></app-story-highlight>
      </div>
    </div>
  </div>
</div>
