<ngx-spinner>
</ngx-spinner>

<!-- CARDS -->
<div class="test-info-card-container custom-margin">

  <!-- progress card -->
  <div class="test-info-card progress-card" *ngIf="filteredProgress$ | async as progress">
    <div class="card-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <line x1="12" y1="20" x2="12" y2="10"></line>
        <line x1="18" y1="20" x2="18" y2="4"></line>
        <line x1="6" y1="20" x2="6" y2="16"></line>
      </svg>
    </div>
    <div class="card-content">
      <div class="d-flex align-items-center gap-2">
        <h3 class="card-value mb-0">{{progress.completedCriteria}}/{{progress.totalCriteria}}</h3>
        <span class="spinner-border text-primary"
              *ngIf="progress.completedCriteria < progress.totalCriteria"
              style="width: 1.5rem; height: 1.5rem;">
      </span>
      </div>
      <p class="card-label mb-0">Scenarios Generated</p>
    </div>
  </div>

  <div class="test-info-card test-count-card" *ngIf="generatedTests.length > 0">
    <div class="card-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
        <polyline points="14 2 14 8 20 8"></polyline>
        <line x1="16" y1="13" x2="8" y2="13"></line>
        <line x1="16" y1="17" x2="8" y2="17"></line>
        <polyline points="10 9 9 9 8 9"></polyline>
      </svg>
    </div>
    <div class="card-content">
      <h3 class="card-value">{{testCount}}</h3>
      <p class="card-label">Number of tests</p>
    </div>
  </div>
</div>

<!-- pulsanti sulla tabella -->
<div class="row-container" *ngIf="generatedTests.length > 0">
  <div class="left-content">
    <!-- pulsanti sulla tabella a sx -->
  </div>
  <div class="right-content">
    <button
      class="btn btn-outline-dark mr-1 mb-1 mb-sm-0"
      data-toggle="tooltip"
      data-placement="top"
      title="Effettua l'esportazione dei test, della story e delle note"
      (click)="downloadGeneratedTests()">
      Export Workspace
    </button>
    <button class="btn btn-outline-dark mr-1 mb-1 mb-sm-0" label="Download Tests CSV" (click)="downloadCSVGeneratedTests()">Export CSV</button>
    <button class="btn btn-outline-dark mr-1 mb-1 mb-sm-0" label="Download Tests XLSX" (click)="downloadExcelGeneratedTests()">Export EXCEL</button>

  </div>
</div>

<!-- styled-test-table.component.html -->
<app-test-table
  [columns]="columns">
</app-test-table>





