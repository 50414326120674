// For the scenario grouping endpoint
export interface ScenarioGroupInfo {
  group_id: string;
  name: string;
  common_preconditions: string[];
  scenarios: string[];
  rationale: string;
}

export interface GroupingResponse {
  groups: ScenarioGroupInfo[];
  standalone_scenarios: string[];
}

// For the template generation endpoint
export interface TemplateGroupInfo {
  group_id: string;
  feature_name: string;
  background: string[];
  scenario_ids: string[];
  group_analysis?: string;
}

export interface AcceptanceCriterion {
  id: string;
  scenario_ref: string;
  name: string;
  tags: string[];
  test_data_elements: string[];
  given: string[];
  when: string[];
  then: string[];
}

export interface ScenarioResponse {
  scenario_groups: Array<{
    group_info: TemplateGroupInfo,
    acceptance_criteria: AcceptanceCriterion[]
  }>;
  token_usage: TokenCount;
}

export interface MacroScenario {
  id: string;
  description: string;
  source: string;
  traceability?: string,
  enrichment_justification?: string;
}

// Updated Ambiguity Interface
export interface Ambiguity {
  id: string;
  description: string;
  source: string;
  clarification_needed: string;
  impact: string;
  resolution?: string; // Field for resolution
  stato?: 'unanswered' | 'answered' | 'solved'; // Property for tracking state
  type?: 'technical' | 'workflow'; // Derived property (computed based on ID)

  // Legacy properties (for backward compatibility)
  linkedKbItemId?: string;
  linkedKbItemTitle?: string;
  linkedKbItemDefinition?: string;

  // New property for multiple knowledge base items
  linkedKbItems?: LinkedKnowledgeBaseItem[]; // Array of linked knowledge base items
}

// New interface for linked KB items
export interface LinkedKnowledgeBaseItem {
  kbItemId: string;
  title: string;
  term: string;
  definition: string;
  examples?: string[];
}

export interface TokenCount {
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
}

export interface MacroAnalysisResult {
  scenarios: MacroScenario[];
  token_usage: TokenCount;
}

// Story description
export interface StoryDescriptionResponse {
  story_id: string;
  title: string;
  description: string;
  token_usage: TokenCount;
}

/**
 * Interface for the analysis result
 */
export interface AnalysisResult {
  core_analysis: AnalysisContent;
  supportive_analysis?: SupportiveAnalysisContent;
  ambiguities: Ambiguity[];
  token_usage: TokenCount;
}


/**
 * Interface for the structured analysis content
 */
export interface AnalysisContent {
  scenario_analysis: string;
  config_testdata_analysis: string;
}

/**
 * Interface for the structured supportive analysis content
 */
export interface SupportiveAnalysisContent {
  scenario_enrichments: string;
  config_testdata_enrichments: string;
}

// TEST DATA MANAGEMENT & PARAMETRI DI CONFIGURAZIONE

export interface ConfigParameter {
  parameter: string;
  type: string;
  allowed_values: string | string[];
  default: any;
  used_in: string[];
  impact: string;
}

export interface BaseDataset {
  field: string;
  valid_values: string[];
  invalid_values: string[];
  rules: string;
}

export interface DynamicData {
  field: string;
  generation: string;
}

export interface SensitiveData {
  type: string;
  handling: string;
}

export interface EdgeCase {
  scenario: string;
  relevance: string;
}

export interface TestDataManagement {
  base_dataset: BaseDataset[];
  dynamic_data: DynamicData[];
  sensitive_data: SensitiveData[];
  edge_cases: EdgeCase[];
}

export interface DataParametersAnalysis {
  // Flag properties to indicate presence of sections (may not exist in older responses)
  has_config_parameters?: boolean;
  has_test_data_management?: boolean;
  has_base_dataset?: boolean;
  has_dynamic_data?: boolean;
  has_sensitive_data?: boolean;
  has_edge_cases?: boolean;

  // The actual data
  configuration_parameters: ConfigParameter[];
  test_data_management: TestDataManagement;
}

export interface DataParametersResponse {
  analysis_results: DataParametersAnalysis;
  token_usage: TokenCount;
}

/**
 * Represents an extracted substring with scenario potential
 */
export interface ExtractedSubstring {
  id: string;
  text: string;
  scenario_potential: string;
  scenario_categories: string[];
}

/**
 * Response model for the scenario extraction endpoint
 */
export interface ExtractionResultModel {
  extracted_substrings: ExtractedSubstring[];
  token_usage?: TokenCount;
}

/** * Interface representing a Knowledge Base item */
export interface KnowledgeBaseItem {
  _id: string;          // MongoDB document ID
  kb_id: string;        // Knowledge base item ID - using snake_case to match backend
  title: string;        // Title of the knowledge base item
  term: string;         // The term being defined
  definition: string;   // Definition of the term
  projectName: string;  // Project name
  userName: string;     // Username of the owner
  createdAt: string;    // Creation timestamp
  lastModified: string; // Last modification timestamp
  examples?: string[];          // Optional examples of the term in use
  related_terms?: string[];     // Optional related terms
  scope?: string;               // Optional scope of the term
  source_story_id?: string;     // Optional source story ID
  original_ambiguity_id?: string; // Optional original ambiguity ID
  type?: string;                // Optional type (e.g., technical, business)
}

/**
 * Represents the structure of the response received from the
 * /findkbmatches/ API endpoint.
 */
export interface FindKbMatchesResponse {
  /**
   * The list of ambiguities processed by the endpoint.
   * Some ambiguities may now have their `linkedKbItems` property populated
   * if the backend found potential matches.
   */
  ambiguities: Ambiguity[];

  /**
   * The aggregated token usage information for all the LLM calls
   * performed during the find-kb-matches operation on the backend.
   */
  token_usage: TokenCount;
}

// Update the response interface to include KB item mappings
export interface ProcessAmbiguitiesResponse {
  core_analysis: AnalysisContent;
  token_usage: TokenCount;
  kb_items_created: number;
  kb_item_mappings: { [ambiguityId: string]: LinkedKnowledgeBaseItem[] };
}
