import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {AuthService} from '../../../shared/auth/auth.service';
import { apiUrl } from '../../../../environments/environment';


// Timeline Response Interface
export interface TimelineDataPoint {
  date: string;
  created: number;
  updated: number;
  tests: number;
  tokens: number;
}

export interface TimelineResponse {
  timeline: TimelineDataPoint[];
  total_created: number;
  total_updated: number;
  total_tests: number;
  total_tokens: number;
}

// User Activity Interfaces
export interface UserActivityData {
  email: string;
  session_count: number;
  test_count: number;
  token_usage: number;
  last_active: string;
}

export interface UserActivityResponse {
  active_users: number;
  user_activity: UserActivityData[];
}

// Project Stats Interfaces
export interface ProjectStatsItem {
  project_id: string;
  project_name: string;
  session_count: number;
  test_count: number;
  user_count: number;
  last_updated: string;
}

export interface ProjectStatsResponse {
  total_projects: number;
  projects: ProjectStatsItem[];
}

// Session Stats Interfaces
export interface SessionStageData {
  stage: string;
  count: number;
  percentage: number;
}

export interface SessionStatsResponse {
  total_sessions: number;
  active_sessions: number;
  stages: SessionStageData[];
  avg_tests_per_session: number;
}

// Token Usage Interfaces
export interface TokenUsageData {
  model: string;
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
  cost_estimate: number;
}

export interface TokenUsageResponse {
  total_tokens: number;
  models_usage: TokenUsageData[];
  tokens_per_test: number;
}

// Filter Parameters Interface
export interface DashboardFilter {
  timeframe?: string;
  start_date?: string;
  end_date?: string;
  project_id?: string;
  email?: string;
  checkpoint_stage?: string;
  limit?: number;
}

@Injectable({
  providedIn: 'root'
})
export class DatanalysisService {
  private apiUrl = apiUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  private getHeaders(): Observable<HttpHeaders> {
    return this.authService.token$.pipe(
      switchMap(token => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return new Observable<HttpHeaders>(observer => {
          observer.next(headers);
          observer.complete();
        });
      })
    );
  }

  private buildParams(filters: DashboardFilter): HttpParams {
    let params = new HttpParams();

    if (filters.timeframe) {
      params = params.set('timeframe', filters.timeframe);
    }

    if (filters.start_date) {
      params = params.set('start_date', filters.start_date);
    }

    if (filters.end_date) {
      params = params.set('end_date', filters.end_date);
    }

    if (filters.project_id) {
      params = params.set('project_id', filters.project_id);
    }

    if (filters.email) {
      params = params.set('email', filters.email);
    }

    if (filters.checkpoint_stage) {
      params = params.set('checkpoint_stage', filters.checkpoint_stage);
    }

    if (filters.limit) {
      params = params.set('limit', filters.limit.toString());
    }

    return params;
  }

  /**
   * Get sessions timeline data
   * @param filters Optional filter parameters
   */
  getSessionsTimeline(filters: DashboardFilter = {}): Observable<TimelineResponse> {
    const params = this.buildParams(filters);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get<TimelineResponse>(`${this.apiUrl}/dashboard/sessions/timeline`, {
          headers,
          params
        })
      ),
      catchError(this.handleError)
    );
  }

  /**
   * Get user activity data
   * @param filters Optional filter parameters
   */
  getUserActivity(filters: DashboardFilter = {}): Observable<UserActivityResponse> {
    const params = this.buildParams(filters);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get<UserActivityResponse>(`${this.apiUrl}/dashboard/users/activity`, {
          headers,
          params
        })
      ),
      catchError(this.handleError)
    );
  }

  /**
   * Get project statistics
   * @param filters Optional filter parameters
   */
  getProjectStats(filters: DashboardFilter = {}): Observable<ProjectStatsResponse> {
    const params = this.buildParams(filters);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get<ProjectStatsResponse>(`${this.apiUrl}/dashboard/projects/stats`, {
          headers,
          params
        })
      ),
      catchError(this.handleError)
    );
  }

  /**
   * Get session statistics
   * @param filters Optional filter parameters
   */
  getSessionStats(filters: DashboardFilter = {}): Observable<SessionStatsResponse> {
    const params = this.buildParams(filters);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get<SessionStatsResponse>(`${this.apiUrl}/dashboard/sessions/stats`, {
          headers,
          params
        })
      ),
      catchError(this.handleError)
    );
  }

  /**
   * Get token usage statistics
   * @param filters Optional filter parameters
   */
  getTokenUsage(filters: DashboardFilter = {}): Observable<TokenUsageResponse> {
    const params = this.buildParams(filters);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get<TokenUsageResponse>(`${this.apiUrl}/dashboard/tokens/usage`, {
          headers,
          params
        })
      ),
      catchError(this.handleError)
    );
  }

  /**
   * Handle HTTP errors
   */
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // Network error (server is off or no network connection)
      console.error('Network error:', error.message);
    } else if (error.status >= 400 && error.status < 500) {
      // Client-side error
      console.error(`Client-side error: ${error.status}, body was: ${error.error}`);
    } else if (error.status >= 500) {
      // Server-side error
      console.error(`Server-side error: ${error.status}, body was: ${error.error}`);
    } else {
      // Other errors
      console.error(`Unexpected error: ${error.status}, body was: ${error.error}`);
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
