// test-id.service.ts
import { Injectable } from '@angular/core';

export interface TestIdentifier {
  groupIndex: number;
  criterionIndex: number;
  testIndex: number;
}

// test-id.service.ts
export interface TestMetadata extends TestIdentifier {
  groupId: string;
  criterionId: string;
  testId: string;
  displayId: string;
  sequentialId?: number;
}

export interface TestWithMetadata {
  metadata: TestMetadata;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class TestIdService {
  createTestId(identifier: TestIdentifier): TestMetadata {
    const { groupIndex, criterionIndex, testIndex } = identifier;

    const groupId = `G${(groupIndex + 1).toString().padStart(2, '0')}`;
    const criterionId = `AC${(criterionIndex + 1).toString().padStart(2, '0')}`;
    const testId = `TC${(testIndex + 1).toString().padStart(2, '0')}`;

    return {
      ...identifier,
      groupId,
      criterionId,
      testId,
      displayId: `${groupId}-${criterionId}-${testId}`
    };
  }

  parseTestId(displayId: string): TestIdentifier | null {
    const match = displayId.match(/G(\d+)-AC(\d+)-TC(\d+)/);
    if (!match) return null;

    return {
      groupIndex: parseInt(match[1], 10) - 1,
      criterionIndex: parseInt(match[2], 10) - 1,
      testIndex: parseInt(match[3], 10) - 1
    };
  }

  generateBatchIds(groupIndex: number, criterionIndex: number, count: number): TestMetadata[] {
    return Array.from({ length: count }, (_, index) =>
      this.createTestId({
        groupIndex,
        criterionIndex,
        testIndex: index
      })
    );
  }

  getGroupTests(tests: TestWithMetadata[], groupIndex: number): TestWithMetadata[] {
    return tests.filter(test => test.metadata.groupIndex === groupIndex);
  }

  getCriterionTests(
    tests: TestWithMetadata[],
    groupIndex: number,
    criterionIndex: number
  ): TestWithMetadata[] {
    return tests.filter(test =>
      test.metadata.groupIndex === groupIndex &&
      test.metadata.criterionIndex === criterionIndex
    );
  }

  createTestWithMetadata(data: any, identifier: TestIdentifier): TestWithMetadata {
    return {
      metadata: this.createTestId(identifier),
      data
    };
  }

// Update the sort function to keep track of sequential IDs
  sortTests(tests: TestWithMetadata[]): TestWithMetadata[] {
    const sortedTests = [...tests].sort((a, b) => {
      if (a.metadata.groupIndex !== b.metadata.groupIndex) {
        return a.metadata.groupIndex - b.metadata.groupIndex;
      }
      if (a.metadata.criterionIndex !== b.metadata.criterionIndex) {
        return a.metadata.criterionIndex - b.metadata.criterionIndex;
      }
      return a.metadata.testIndex - b.metadata.testIndex;
    });

    // Update sequential IDs after sorting
    return this.updateSequentialIds(sortedTests);
  }

  // Add a method to update sequential IDs for all tests
  updateSequentialIds(tests: TestWithMetadata[]): TestWithMetadata[] {
    return tests.map((test, index) => ({
      ...test,
      metadata: {
        ...test.metadata,
        sequentialId: index + 1
      }
    }));
  }
}
