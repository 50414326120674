import { Injectable } from '@angular/core';
import { TokenCount } from '../templateGen/templateInterfaces';

export interface ModelPricing {
  name: string;
  inputPrice: number;  // Price per 1M tokens
  outputPrice: number; // Price per 1M tokens
  cachedInputPrice?: number; // Optional cached input price per 1M tokens
}

export interface TokenCostCalculation {
  inputCost: number;
  outputCost: number;
  totalCost: number;
}

@Injectable({
  providedIn: 'root'
})
export class ModelPricingService {
  private modelPricing: { [key: string]: ModelPricing } = {
    'Gemini 2': {
      name: 'Gemini 2',
      inputPrice: 0.10,  // $0.10 per 1M tokens
      outputPrice: 0.40  // $0.40 per 1M tokens
    },
    '4o-mini': {
      name: '4o-mini',
      inputPrice: 0.15,  // $x per 1M tokens
      outputPrice: 0.60  // $x per 1M tokens
    },
    'Sonnet': {
        name: 'Sonnet',
      inputPrice: 3.00,  // $x per 1M tokens
      outputPrice: 15.00  // $x per 1M tokens
    }
    // Add other models here as needed
  };

  getModelPricing(modelName: string): ModelPricing | undefined {
    return this.modelPricing[modelName];
  }

  calculateCost(modelName: string, tokenCount: TokenCount): TokenCostCalculation {
    const pricing = this.getModelPricing(modelName);

    if (!pricing) {
      return { inputCost: 0, outputCost: 0, totalCost: 0 };
    }

    // Divide by 1M instead of 1K since prices are per million tokens
    const inputCost = (tokenCount.prompt_tokens / 1000000) * pricing.inputPrice;
    const outputCost = (tokenCount.completion_tokens / 1000000) * pricing.outputPrice;
    const totalCost = inputCost + outputCost;

    return {
      inputCost,
      outputCost,
      totalCost
    };
  }
}
