<ngx-spinner>
</ngx-spinner>

<section id="action-form-layout">
  <div class="row match-height">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Edit {{projectId}} Project Settings</h4>
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link" [class.active]="activeTab === 'general'" (click)="activeTab = 'general'">General Settings</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [class.active]="activeTab === 'columns'" (click)="activeTab = 'columns'">Add new Template</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [class.active]="activeTab === 'manage'" (click)="activeTab = 'manage'">Manage Existing Templates</a>
            </li>
          </ul>
        </div>
        <div class="card-content">
          <div class="card-body">
            <div *ngIf="activeTab === 'general'">
              <form [formGroup]="projectForm">
                <div class="form-group">
                  <label for="form-language">Language</label>
                  <select id="form-language" class="form-control" formControlName="language">
                    <option value="it">Italiano</option>
                    <option value="en">English</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="form-action-1">Project Description</label>
                  <textarea id="form-action-1" rows="4" class="form-control"
                            formControlName="projectDescription"></textarea>
                </div>

                <div class="form-group">
                  <label for="form-action-2">Project Manager</label>
                  <input type="text" id="form-action-2" class="form-control" formControlName="projectManager">
                </div>

                <button type="button" class="btn btn-primary mr-2" (click)="onSave()">
                  <i class="ft-check-square mr-1"></i>Save
                </button>
                <button type="button" class="btn btn-secondary" (click)="clearFile()">
                  <i class="ft-x mr-1"></i>Cancel
                </button>
              </form>

            </div>
            <div *ngIf="activeTab === 'columns'">
              <app-columns (tabChange)="onTabChange($event)"></app-columns>
            </div>

            <div *ngIf="activeTab === 'manage'">
              <app-manage [selectedTemplateName]="selectedTemplateName"></app-manage>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
