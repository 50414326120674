import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { StatoSessioneService } from '../../../../shared/services/stato-sessione.service';
import { UserStoryService } from '../../../../shared/templateGen/template-api.service';
import { Ambiguity } from '../../../../shared/templateGen/templateInterfaces';

interface HighlightSegment {
  text: string;
  isHighlighted: boolean;
  id?: string;
  ambiguity?: Ambiguity;
}

@Component({
  selector: 'app-story-highlight',
  templateUrl: './story-highlight.component.html',
  styleUrls: ['./story-highlight.component.scss']
})
export class StoryHighlightComponent implements OnInit, OnDestroy {
  originalString: string = '';
  ambiguities: Ambiguity[] = [];
  processedSegments: HighlightSegment[] = [];
  isLoading: boolean = false;
  error: string | null = null;
  selectedSegment: HighlightSegment | null = null;

  private subscriptions: Subscription = new Subscription();
  selectedModel: string = '';
  currentLanguage: string = 'en'; // Default language

  constructor(
    private statoSessioneService: StatoSessioneService,
    private extractionService: UserStoryService
  ) { }

  ngOnInit(): void {
    // Subscribe to refinedStoryString from StatoSessioneService
    this.subscriptions.add(
      this.statoSessioneService.refinedStoryString$.subscribe(story => {
        this.originalString = story;
        if (story) {
          this.processString();
        }
      })
    );

    // Subscribe to ambiguities from StatoSessioneService
    this.subscriptions.add(
      this.statoSessioneService.ambiguities$.subscribe(ambiguities => {
        this.ambiguities = ambiguities;
        if (this.originalString) {
          this.processString();
        }
      })
    );

    // Subscribe to selectedModel from StatoSessioneService
    this.subscriptions.add(
      this.statoSessioneService.selectedModel$.subscribe(model => {
        this.selectedModel = model;
      })
    );
  }

  ngOnDestroy(): void {
    // Clean up subscriptions when component is destroyed
    this.subscriptions.unsubscribe();
  }

  processString(): void {
    // Reset segments
    this.processedSegments = [];

    if (!this.ambiguities.length) {
      // If no ambiguities, return the whole string as a non-highlighted segment
      this.processedSegments.push({ text: this.originalString, isHighlighted: false });
      return;
    }

    // Find all occurrences of all ambiguity sources and track their positions
    const allMatches: { start: number; end: number; ambiguity: Ambiguity }[] = [];

    this.ambiguities.forEach(ambiguity => {
      const sourceText = ambiguity.source;
      let index = this.originalString.indexOf(sourceText);

      while (index !== -1) {
        allMatches.push({
          start: index,
          end: index + sourceText.length,
          ambiguity
        });
        index = this.originalString.indexOf(sourceText, index + 1);
      }
    });

    // Sort matches by start position
    allMatches.sort((a, b) => a.start - b.start);

    // Handle overlapping matches by keeping only non-overlapping ones
    const nonOverlappingMatches = this.resolveOverlappingMatches(allMatches);

    // Create segments based on matches
    let lastEnd = 0;

    for (const match of nonOverlappingMatches) {
      // Add non-highlighted segment before match if any
      if (match.start > lastEnd) {
        this.processedSegments.push({
          text: this.originalString.substring(lastEnd, match.start),
          isHighlighted: false
        });
      }

      // Add highlighted segment with ambiguity information
      const segmentText = this.originalString.substring(match.start, match.end);

      this.processedSegments.push({
        text: segmentText,
        isHighlighted: true,
        id: match.ambiguity.id,
        ambiguity: match.ambiguity
      });

      lastEnd = match.end;
    }

    // Add remaining text as non-highlighted if any
    if (lastEnd < this.originalString.length) {
      this.processedSegments.push({
        text: this.originalString.substring(lastEnd),
        isHighlighted: false
      });
    }
  }

  // Resolve overlapping matches, prioritizing earlier and longer matches
  private resolveOverlappingMatches(matches: { start: number; end: number; ambiguity: Ambiguity }[]) {
    if (!matches.length) return [];

    const result: { start: number; end: number; ambiguity: Ambiguity }[] = [matches[0]];

    for (let i = 1; i < matches.length; i++) {
      const current = matches[i];
      const prev = result[result.length - 1];

      // If current match doesn't overlap with previous one, add it
      if (current.start >= prev.end) {
        result.push(current);
      }
      // If current match is longer than previous and they overlap, replace previous
      else if (current.end > prev.end && current.start < prev.start) {
        result[result.length - 1] = current;
      }
      // Otherwise, skip the current match (keep the previous one)
    }

    return result;
  }

  // Handle click on a highlighted segment
  selectSegment(segment: HighlightSegment): void {
    if (segment.isHighlighted && segment.id) {
      this.selectedSegment = segment;
    }
  }

  // Helper method to get the CSS class based on impact
  getImpactClass(impact: string): string {
    const lowerImpact = impact.toLowerCase();
    if (lowerImpact.includes('high')) return 'high';
    if (lowerImpact.includes('medium')) return 'medium';
    if (lowerImpact.includes('low')) return 'low';
    return 'default';
  }

  // Close the side panel
  closeSidePanel(): void {
    this.selectedSegment = null;
  }
}
