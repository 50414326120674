import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { catchError, switchMap, take, takeUntil, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

import {Ambiguity, AnalysisContent, KnowledgeBaseItem, LinkedKnowledgeBaseItem} from '../../../../shared/templateGen/templateInterfaces';
import { StatoSessioneService } from '../../../../shared/services/stato-sessione.service';
import { AuthService } from '../../../../shared/auth/auth.service';
import { UserStoryService } from '../../../../shared/templateGen/template-api.service';
import {SessionPersistenceService} from '../../session-manager/session-persistence.service';


@Component({
  selector: 'app-ambiguities-alert',
  templateUrl: './ambiguities-alert.component.html',
  styleUrls: ['./ambiguities-alert.component.scss']
})
export class AmbiguitiesAlertComponent implements OnInit, OnDestroy {
  @ViewChild('ambiguitiesManagerModal') ambiguitiesManagerModal: any;
  @ViewChild('filteredAmbiguitiesModal') filteredAmbiguitiesModal: any;
  @ViewChild('ambiguityDetailsModal') ambiguityDetailsModal: any;
  @ViewChild('knowledgeBaseModalSelector') knowledgeBaseModalSelector: any;

  private destroy$ = new Subject<void>();
  ambiguities: Ambiguity[] = [];
  filteredAmbiguities: Ambiguity[] = [];
  selectedAmbiguity: Ambiguity;
  hasAmbiguities = false;
  private subscriptions: Subscription = new Subscription();

  // Knowledge base items
  knowledgeBaseItems: KnowledgeBaseItem[] = [];
  filteredKnowledgeBaseItems: KnowledgeBaseItem[] = [];
  selectedKnowledgeBaseItem: KnowledgeBaseItem | null = null;
  isLoadingKnowledgeBase = false;
  knowledgeBaseError: string | null = null;
  searchTerm = '';
  knowledgeBaseLoaded = false; // Flag to track if KB items were loaded

  // Filter state
  currentFilter = {
    state: 'unanswered' as 'unanswered' | 'answered' | 'solved',
    type: 'technical' as 'technical' | 'workflow'
  };

  filterTitle = '';

  constructor(
    private modalService: NgbModal,
    private sessioneService: StatoSessioneService,
    private authService: AuthService,
    private userStoryService: UserStoryService,
    private sessionPersistenceService: SessionPersistenceService
  ) { }

  ngOnInit(): void {
    // Flag to track if we're in the middle of a manual update to prevent loops
    let skipNextUpdate = false;

    // Step 1: First, load knowledge base items just once
    this.loadKnowledgeBaseItems();

    // Step 2: Set up ambiguities subscription
    this.subscriptions.add(
      this.sessioneService.ambiguities$.pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          console.error('Error in ambiguities subscription:', error);
          return [];
        })
      ).subscribe(ambiguities => {
        // Skip processing if this update was triggered by our own code
        if (skipNextUpdate) {
          console.log('[ngOnInit sub] Skipping reprocessing due to manual update');
          skipNextUpdate = false; // Reset for next genuine update
          return;
        }

        console.log('[ngOnInit sub] Ambiguities received:', ambiguities.length);

        // Track if any changes were made that need to be sent back to the service
        let hasChanges = false;

        // Initialize the ambiguities with the required properties
        this.ambiguities = ambiguities.map(ambiguity => {
          // Get the state DIRECTLY from the incoming data
          const incomingStato = ambiguity.stato;
          const originalState = incomingStato;

          // --- MODIFIED STATE LOGIC ---
          let finalStato: 'unanswered' | 'answered' | 'solved';

          if (incomingStato === 'solved') {
            // If the service data says it's solved, KEEP IT SOLVED.
            finalStato = 'solved';
          } else if (ambiguity.linkedKbItems && ambiguity.linkedKbItems.length > 0) {
            // If linked items exist (and not already solved), it's answered
            finalStato = 'answered';
          } else if (ambiguity.resolution && ambiguity.resolution.trim()) {
            // If resolution text exists (and not linked/solved), it's answered
            finalStato = 'answered';
          } else {
            // Otherwise, it's unanswered
            finalStato = 'unanswered';
          }

          // Create a properly formatted ambiguity object
          const formattedAmbiguity: Ambiguity = {
            ...ambiguity, // Spread existing properties first
            stato: finalStato, // Apply the determined state
            type: this.determineAmbiguityType(ambiguity.id)
          };

          // Check if we're changing the state
          if (originalState !== finalStato) {
            hasChanges = true;
          }

          // Handle migration from old format to new format
          if (this.knowledgeBaseLoaded && ambiguity.linkedKbItemId && !formattedAmbiguity.linkedKbItems) {
            const matchedKbItem = this.knowledgeBaseItems.find(item => item._id === ambiguity.linkedKbItemId);
            if (matchedKbItem) {
              formattedAmbiguity.linkedKbItems = [{
                kbItemId: ambiguity.linkedKbItemId,
                title: ambiguity.linkedKbItemTitle || matchedKbItem.title,
                definition: ambiguity.linkedKbItemDefinition || matchedKbItem.definition,
                term: matchedKbItem.term,
                examples: matchedKbItem.examples
              }];

              // Mark state as changed since we've modified the structure
              hasChanges = true;

              // Consider setting state to 'answered' if migration happens and it wasn't solved
              if (formattedAmbiguity.stato !== 'solved') {
                formattedAmbiguity.stato = 'answered';
              }
            } else {
              console.warn(`[ngOnInit sub] Migration failed for ${ambiguity.id}: KB Item ${ambiguity.linkedKbItemId} not found.`);
            }
          }

          return formattedAmbiguity;
        });

        this.hasAmbiguities = this.ambiguities.length > 0;

        // Push updated ambiguities back to service ONLY if changes were made
        if (hasChanges) {
          console.log('[ngOnInit sub] Changes detected, updating service');

          // Set the flag before triggering the update
          skipNextUpdate = true;

          // Then update the service
          this.sessioneService.updateAmbiguities(this.ambiguities);
        }
      })
    );
  }

  ngOnDestroy(): void {
    // Cleanup main subscription collection
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }

    // Signal all takeUntil operators to complete
    this.destroy$.next();
    this.destroy$.complete();

    // Close any open modals
    this.modalService.dismissAll();
  }

  // Method to save the current session
  saveCurrentSession(customName?: string): void {
    // Show a loading indicator
    Swal.fire({
      title: 'Saving Session',
      text: 'Please wait while your session is being saved...',
      icon: 'info',
      showConfirmButton: false,
      allowOutsideClick: false
    });

    // Get the filename from StatoSessioneService
    this.sessioneService.fileName$.pipe(
      take(1),
      switchMap(fileName => {
        // Use filename as part of the session name if no custom name is provided
        const sessionName = customName || `Ambiguities for ${fileName || 'Project'}`;

        // Call the saveSessionOnDemand method from the SessionPersistenceService
        return this.sessionPersistenceService.saveSessionOnDemand(sessionName);
      }),
      takeUntil(this.destroy$),
      catchError(error => {
        console.error('Error saving session:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to save the session: ' + (error.message || 'Unknown error'),
          icon: 'error'
        });
        throw error;
      })
    ).subscribe({
      next: (response) => {
        console.log('Session saved successfully:', response);
        // Show success message
        Swal.fire({
          title: 'Success',
          text: 'Your session has been saved successfully!',
          icon: 'success',
          timer: 2000
        });
      },
      error: (error) => {
        console.error('Error in session save subscription:', error);
      }
    });
  }

  // Method to load knowledge base items
  loadKnowledgeBaseItems(): void {
    // Skip if already loading or loaded
    if (this.isLoadingKnowledgeBase || this.knowledgeBaseLoaded) {
      return;
    }

    this.isLoadingKnowledgeBase = true;
    this.knowledgeBaseError = null;

    console.log('[loadKnowledgeBaseItems] Loading KB items...');

    combineLatest([
      this.authService.email$.pipe(take(1)),
      this.sessioneService.projectId$.pipe(take(1))
    ]).pipe(
      switchMap(([email, projectId]) => {
        return this.userStoryService.getKnowledgeBaseItems(email, projectId);
      }),
      map(items => {
        // Remove duplicates based on kb_id
        const uniqueMap = new Map();
        items.forEach(item => {
          // If we haven't seen this kb_id or the item is newer than what we have
          if (!uniqueMap.has(item.kb_id) ||
            new Date(uniqueMap.get(item.kb_id).lastModified) < new Date(item.lastModified)) {
            uniqueMap.set(item.kb_id, item);
          }
        });
        return Array.from(uniqueMap.values());
      }),
      takeUntil(this.destroy$),
      catchError(err => {
        this.knowledgeBaseError = 'Failed to load knowledge base items. Please try again.';
        this.isLoadingKnowledgeBase = false;
        console.error('Error loading knowledge base items:', err);
        return [];
      })
    ).subscribe({
      next: (items) => {
        console.log(`[loadKnowledgeBaseItems] Loaded ${items.length} KB items`);
        this.knowledgeBaseItems = items;
        this.isLoadingKnowledgeBase = false;
        this.knowledgeBaseLoaded = true; // Mark as loaded
      },
      error: (err) => {
        console.error('Error loading knowledge base items:', err);
        this.isLoadingKnowledgeBase = false;
        this.knowledgeBaseError = 'Failed to load knowledge base items. Please try again.';
      }
    });
  }

  // Method to determine the type based on ID prefix
  determineAmbiguityType(id: string): 'technical' | 'workflow' {
    if (id.startsWith('T-')) {
      return 'technical';
    } else if (id.startsWith('W-')) {
      return 'workflow';
    }
    // Default to workflow if doesn't match expected patterns
    return 'workflow';
  }

  // Method to open the ambiguities manager modal
  openAmbiguitiesManager() {
    this.modalService.open(this.ambiguitiesManagerModal, {
      size: 'lg',
      scrollable: true,
      windowClass: 'fixed-size-modal'
    });
  }

  // Show ambiguities filtered by state and type
  showFilteredAmbiguities(state: 'unanswered' | 'answered' | 'solved', type: 'technical' | 'workflow') {
    this.currentFilter = { state, type };
    this.filteredAmbiguities = this.getAmbiguitiesByStateAndType(state, type);

    // Set the title based on the filter
    this.filterTitle = type === 'technical' ? 'Technical Terms' : 'Hidden Workflows';

    // Close the manager modal
    this.modalService.dismissAll();

    // Open the filtered ambiguities modal
    this.modalService.open(this.filteredAmbiguitiesModal, {
      size: 'lg',
      scrollable: true,
      windowClass: 'fixed-size-modal'
    });
  }

  // Navigate back to the manager
  backToManager() {
    this.modalService.dismissAll();
    this.openAmbiguitiesManager();
  }

  // Navigate back to filtered list from ambiguity details
  backToFiltered() {
    this.modalService.dismissAll();
    this.showFilteredAmbiguities(this.currentFilter.state, this.currentFilter.type);
  }

  // Method to open a specific ambiguity for detailed view
  openAmbiguityDetails(ambiguity: Ambiguity) {
    this.selectedAmbiguity = { ...ambiguity };

    // Close the filtered modal if it's open
    this.modalService.dismissAll();

    // Open the details modal
    this.modalService.open(this.ambiguityDetailsModal, {
      size: 'lg',
      scrollable: true,
      windowClass: 'fixed-size-modal'
    }).result.then(
      () => {
        // When detail modal is closed with a result
        this.updateAmbiguityInList(this.selectedAmbiguity);
        this.showFilteredAmbiguities(this.currentFilter.state, this.currentFilter.type);
      },
      () => {
        // When detail modal is dismissed
        this.updateAmbiguityInList(this.selectedAmbiguity);
        this.showFilteredAmbiguities(this.currentFilter.state, this.currentFilter.type);
      }
    );
  }

  // Open knowledge base selector modal - UPDATED to not close details modal
  openKnowledgeBaseSelector() {
    // Ensure knowledge base items are loaded
    if (!this.knowledgeBaseLoaded) {
      this.loadKnowledgeBaseItems();
    }

    // Filter knowledge base items and store current state
    this.filterKnowledgeBaseItemsByType(this.selectedAmbiguity?.type || 'technical');
    this.searchTerm = '';

    // First, dismiss the details modal
    this.modalService.dismissAll();

    // Use setTimeout to ensure the previous modal is fully closed
    setTimeout(() => {
      // Open the KB selector modal
      this.modalService.open(this.knowledgeBaseModalSelector, {
        size: 'lg',
        scrollable: true,
        windowClass: 'fixed-size-modal',
        backdrop: 'static',
        ariaLabelledBy: 'kb-selector-title',
        keyboard: false // Disable keyboard ESC to close to prevent accidental closing
      });
    }, 150); // Add a small delay to ensure modal transitions are smooth
  }

  // Method to finalize KB selection and close the modal
  finishKbSelection(): void {
    // Make sure to update the ambiguity in the list
    this.updateAmbiguityInList(this.selectedAmbiguity);

    // Show a success message
    if (this.selectedAmbiguity.linkedKbItems && this.selectedAmbiguity.linkedKbItems.length > 0) {
      Swal.fire({
        title: 'Knowledge Base Items Linked',
        text: `Successfully linked ${this.selectedAmbiguity.linkedKbItems.length} items to this ambiguity.`,
        icon: 'success',
        timer: 2000
      });
    }

    // Close the KB selector modal
    this.modalService.dismissAll();

    // Reopen the ambiguity details modal
    this.openAmbiguityDetails(this.selectedAmbiguity);
  }

  // Method to cancel KB selection and restore original state
  cancelKbSelection(): void {
    // Ask for confirmation if items were added
    if (this.selectedAmbiguity.linkedKbItems && this.selectedAmbiguity.linkedKbItems.length > 0) {
      Swal.fire({
        title: 'Cancel Selection?',
        text: 'Are you sure you want to cancel? The items you selected will still be saved.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, go back',
        cancelButtonText: 'No, continue selecting'
      }).then((result) => {
        if (result.isConfirmed) {
          // User confirmed, close the modal
          this.modalService.dismissAll();

          // Reopen the ambiguity details modal
          this.openAmbiguityDetails(this.selectedAmbiguity);
        }
      });
    } else {
      // No items added, just close
      this.modalService.dismissAll();

      // Reopen the ambiguity details modal
      this.openAmbiguityDetails(this.selectedAmbiguity);
    }
  }

  // Filter knowledge base items by type
  filterKnowledgeBaseItemsByType(type: 'technical' | 'workflow'): void {
    // Assuming knowledge base items have a 'type' field that matches the ambiguity type
    this.filteredKnowledgeBaseItems = this.knowledgeBaseItems.filter(item =>
      item.type === type || !item.type // Include items with no specified type
    );
  }

  // Helper method to check if a knowledge base item is already linked to the current ambiguity
  isItemAlreadyLinked(itemId: string): boolean {
    if (!this.selectedAmbiguity || !this.selectedAmbiguity.linkedKbItems) {
      return false;
    }

    return this.selectedAmbiguity.linkedKbItems.some(item => item.kbItemId === itemId);
  }

  // Search knowledge base items
  searchKnowledgeBaseItems(): void {
    if (!this.searchTerm.trim()) {
      this.filterKnowledgeBaseItemsByType(this.selectedAmbiguity?.type || 'technical');
      return;
    }

    const term = this.searchTerm.toLowerCase().trim();
    this.filteredKnowledgeBaseItems = this.knowledgeBaseItems.filter(item =>
      (item.title.toLowerCase().includes(term) ||
        item.term.toLowerCase().includes(term) ||
        item.definition.toLowerCase().includes(term)) &&
      (item.type === this.selectedAmbiguity?.type || !item.type)
    );
  }

  // Select a knowledge base item to link to the current ambiguity - UPDATED for multiple links
  selectKnowledgeBaseItem(item: KnowledgeBaseItem): void {
    // Create a deep copy of the current ambiguity to avoid reference issues
    const updatedAmbiguity: Ambiguity = { ...this.selectedAmbiguity };

    // Initialize linkedKbItems array if it doesn't exist
    if (!updatedAmbiguity.linkedKbItems) {
      updatedAmbiguity.linkedKbItems = [];
    }

    // Check if this item is already linked
    const isAlreadyLinked = this.isItemAlreadyLinked(item._id);

    if (isAlreadyLinked) {
      // Show a message that the item is already linked
      Swal.fire({
        title: 'Already Selected',
        text: 'This knowledge base item is already linked to this ambiguity.',
        icon: 'info',
        timer: 2000,
        position: 'top-end',
        toast: true,
        showConfirmButton: false
      });
      return;
    }

    // Create a new linked KB item
    const newLinkedItem: LinkedKnowledgeBaseItem = {
      kbItemId: item._id,
      title: item.title,
      term: item.term,
      definition: item.definition,
      examples: item.examples
    };

    // Add to the linkedKbItems array
    updatedAmbiguity.linkedKbItems.push(newLinkedItem);

    // Update the resolution text to include all linked KB definitions
    this.updateResolutionFromLinkedItems(updatedAmbiguity);

    // Mark the ambiguity as answered
    updatedAmbiguity.stato = 'answered';

    // Update the local reference
    this.selectedAmbiguity = updatedAmbiguity;

    // Update the ambiguity in the list
    this.updateAmbiguityInList(updatedAmbiguity);

    // Show a confirmation toast
    Swal.fire({
      title: 'Item Added',
      text: `"${item.title}" has been linked to this ambiguity.`,
      icon: 'success',
      timer: 1500,
      position: 'top-end',
      toast: true,
      showConfirmButton: false
    });

    // IMPORTANT: Don't close the KB selector modal
  }

  // New method to update resolution text from all linked items
  updateResolutionFromLinkedItems(ambiguity: Ambiguity): void {
    if (!ambiguity.linkedKbItems || ambiguity.linkedKbItems.length === 0) {
      return;
    }

    // Combine definitions from all linked KB items
    let combinedResolution = '';

    ambiguity.linkedKbItems.forEach((item, index) => {
      combinedResolution += `${index + 1}. ${item.term}: ${item.definition}\n\n`;
    });

    // Update the resolution with the combined text
    ambiguity.resolution = combinedResolution.trim();
  }

  // Remove linked knowledge base item - UPDATED to remove specific item
  removeKnowledgeBaseLink(kbItemId: string): void {
    if (!this.selectedAmbiguity.linkedKbItems || this.selectedAmbiguity.linkedKbItems.length === 0) {
      return;
    }

    // Ask for confirmation
    Swal.fire({
      title: 'Remove Link?',
      text: 'Are you sure you want to remove this knowledge base item link?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Create a copy of the ambiguity
        const updatedAmbiguity = { ...this.selectedAmbiguity };

        // Remove the specified KB item
        updatedAmbiguity.linkedKbItems = updatedAmbiguity.linkedKbItems.filter(
          item => item.kbItemId !== kbItemId
        );

        // Update the resolution text
        if (updatedAmbiguity.linkedKbItems.length > 0) {
          this.updateResolutionFromLinkedItems(updatedAmbiguity);
        } else {
          // If there are no more linked KB items and no manual resolution
          if (!updatedAmbiguity.resolution || updatedAmbiguity.resolution.trim() === '') {
            updatedAmbiguity.stato = 'unanswered';
          }
        }

        // Update the selected ambiguity
        this.selectedAmbiguity = updatedAmbiguity;

        // Update the ambiguity in the list
        this.updateAmbiguityInList(updatedAmbiguity);

        // Refresh the view
        this.openAmbiguityDetails(updatedAmbiguity);
      }
    });
  }

  // Helper method to check if an ambiguity has linked KB items
  hasLinkedKbItems(ambiguity: Ambiguity): boolean {
    return ambiguity.linkedKbItems && ambiguity.linkedKbItems.length > 0;
  }

  // Helper method to get the count of linked KB items
  getLinkedKbItemsCount(ambiguity: Ambiguity): number {
    return ambiguity.linkedKbItems ? ambiguity.linkedKbItems.length : 0;
  }

  // Update the ambiguity in the list
  updateAmbiguityInList(updatedAmbiguity: Ambiguity) {
    console.log(`[updateAmbiguityInList] Trying to update ${updatedAmbiguity.id} to state: ${updatedAmbiguity.stato}`); // <-- ADD
    const allAmbiguities = [...this.sessioneService.getAmbiguities()];
    const index = allAmbiguities.findIndex(a => a.id === updatedAmbiguity.id);
    console.log(`[updateAmbiguityInList] Found index: ${index}`); // <-- ADD

    if (index !== -1) {
      const updatedCopy: Ambiguity = { ...updatedAmbiguity };
      allAmbiguities[index] = updatedCopy;
      console.log(`[updateAmbiguityInList] Calling sessioneService.updateAmbiguities for ${updatedAmbiguity.id}`); // <-- ADD
      this.sessioneService.updateAmbiguities(allAmbiguities); // <-- CHECK THIS SERVICE METHOD

      // Update filtered list too
      this.filteredAmbiguities = this.filteredAmbiguities.map(a =>
        a.id === updatedAmbiguity.id ? updatedCopy : a
      );
    } else {
      console.warn(`[updateAmbiguityInList] Ambiguity ${updatedAmbiguity.id} NOT FOUND in service list!`); // <-- ADD
    }
  }

  // Method to update the state of an ambiguity based on resolution
  updateAmbiguityState(ambiguity: Ambiguity) {
    // Create a new ambiguity object to avoid modifying the original directly
    const updatedAmbiguity: Ambiguity = { ...ambiguity };

    if ((updatedAmbiguity.resolution && updatedAmbiguity.resolution.trim()) ||
      this.hasLinkedKbItems(updatedAmbiguity)) {
      updatedAmbiguity.stato = 'answered' as 'answered';
    } else {
      updatedAmbiguity.stato = 'unanswered' as 'unanswered';
    }

    // Return the updated ambiguity
    this.selectedAmbiguity = updatedAmbiguity;
    this.updateAmbiguityInList(updatedAmbiguity);
  }

  // Method to get ambiguities by state
  getAmbiguitiesByState(stato: 'unanswered' | 'answered' | 'solved'): Ambiguity[] {
    return this.ambiguities.filter(a => a.stato === stato);
  }

  // Method to get ambiguities by state and type
  getAmbiguitiesByStateAndType(
    stato: 'unanswered' | 'answered' | 'solved',
    type: 'technical' | 'workflow'
  ): Ambiguity[] {
    return this.ambiguities.filter(a => a.stato === stato && a.type === type);
  }

  // Check if there are any answered ambiguities
  hasAnsweredAmbiguities(): boolean {
    return this.ambiguities.some(a => a.stato === 'answered');
  }

  // Get CSS class for state badge
  getStateBadgeClass(state: string): string {
    switch (state) {
      case 'unanswered': return 'bg-warning';
      case 'answered': return 'bg-primary';
      case 'solved': return 'bg-success';
      default: return 'bg-secondary';
    }
  }

  // Method to submit filtered resolutions
  submitFilteredResolutions() {
    // Get only the filtered ambiguities that are in 'answered' state
    const ambigsToSubmit = this.filteredAmbiguities.filter(a => a.stato === 'answered');

    if (ambigsToSubmit.length === 0) {
      Swal.fire({
        title: 'No Resolutions',
        text: 'There are no answered ambiguities to submit.',
        icon: 'info'
      });
      return;
    }

    this.processAmbiguities(ambigsToSubmit);
  }

  // Method to submit all resolutions
  submitResolutions() {
    // Get all ambiguities that are in 'answered' state
    const ambigsToSubmit = this.ambiguities.filter(a => a.stato === 'answered');

    if (ambigsToSubmit.length === 0) {
      Swal.fire({
        title: 'No Resolutions',
        text: 'There are no answered ambiguities to submit.',
        icon: 'info'
      });
      return;
    }

    this.processAmbiguities(ambigsToSubmit);
  }

  // Method to process ambiguities by calling the new service method
  private processAmbiguities(ambiguities: Ambiguity[]) {
    // Show loading state
    Swal.fire({
      title: 'Processing Ambiguities',
      text: 'Please wait while we process your resolutions...',
      icon: 'info',
      showConfirmButton: false,
      allowOutsideClick: false
    });

    // Get current analysis from core analysis
    const coreAnalysis = this.sessioneService.getCoreAnalysis();
    if (!coreAnalysis) {
      Swal.fire({
        title: 'Error',
        text: 'No analysis found. Please generate an analysis first.',
        icon: 'error'
      });
      return;
    }

    // Combine core analysis for sending to API
    const analysisText = `Scenario Analysis:\n${coreAnalysis.scenario_analysis}\n\nConfiguration and Test Data Analysis:\n${coreAnalysis.config_testdata_analysis}`;

    // Get current model
    const currentModel = this.sessioneService.currentSelectedModel;

    // Define the expected response type - now includes kb_item_mappings
    interface ProcessAmbiguitiesResponse {
      core_analysis: {
        scenario_analysis: string;
        config_testdata_analysis: string;
      };
      token_usage: {
        prompt_tokens: number;
        completion_tokens: number;
        total_tokens: number;
      };
      kb_items_created: number;
      kb_item_mappings: { [ambiguityId: string]: LinkedKnowledgeBaseItem[] };
    }

    // Combine all observables
    forkJoin({
      userStory: this.sessioneService.refinedStoryString$.pipe(take(1)),
      email: this.authService.email$.pipe(take(1)),
      projectId: this.sessioneService.projectId$.pipe(take(1)),
      language: this.sessioneService.projectLanguage$.pipe(take(1))
    }).pipe(
      // Only take the first emission
      take(1),
      // Switch to the API call
      switchMap(({ userStory, email, projectId, language }) => {
        // Now we have all values and can proceed with the API call
        return this.userStoryService.processAmbiguities(
          userStory,
          analysisText,
          currentModel,
          language,
          projectId,
          email,
          ambiguities
        ) as Observable<ProcessAmbiguitiesResponse>;
      }),
      // Only take the first API response
      take(1),
      // Add the takeUntil for component destruction
      takeUntil(this.destroy$),
      // Properly handle errors in the pipe
      catchError(error => {
        console.error('Error processing ambiguities:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to process ambiguities: ' + (error.message || 'Unknown error'),
          icon: 'error'
        });
        throw error; // Rethrow to prevent subscribe from executing
      })
    ).subscribe({
      next: (result: ProcessAmbiguitiesResponse) => {
        // Update the analysis in the session
        this.sessioneService.updateCoreAnalysisStrings(
          result.core_analysis.scenario_analysis,
          result.core_analysis.config_testdata_analysis
        );

        // Debug log the KB item mappings
        console.log('KB item mappings:', result.kb_item_mappings);

        // Process each ambiguity
        ambiguities.forEach(ambiguity => {
          // Mark ambiguity as solved
          ambiguity.stato = 'solved';

          // Check if we have KB items for this ambiguity
          const linkedItems = result.kb_item_mappings[ambiguity.id];
          if (linkedItems && linkedItems.length > 0) {
            // Initialize linkedKbItems if needed
            if (!ambiguity.linkedKbItems) {
              ambiguity.linkedKbItems = [];
            }

            // Add the linked KB items
            linkedItems.forEach(kbItem => {
              // Check if item already exists to avoid duplicates
              const exists = ambiguity.linkedKbItems.some(
                existing => existing.kbItemId === kbItem.kbItemId
              );

              if (!exists) {
                ambiguity.linkedKbItems.push(kbItem);
                console.log(`Linked KB item ${kbItem.kbItemId} to ambiguity ${ambiguity.id}`);
              }
            });
          }

          // Update the ambiguity in the list
          this.updateAmbiguityInList(ambiguity);
        });

        // Close all modals
        this.modalService.dismissAll();

        // Count total linked items for the success message
        const totalLinkedItems = Object.values(result.kb_item_mappings)
          .reduce((count, items) => count + items.length, 0);

        // Show success message
        Swal.fire({
          title: 'Success!',
          text: `Processed ${ambiguities.length} ambiguities, created ${result.kb_items_created} knowledge base items, and automatically linked them. Session has been saved.`,
          icon: 'success'
        }).then(() => {
          // Save the session with a descriptive name
          this.saveCurrentSession();

          // If we were in filtered view, go back to it
          if (this.currentFilter) {
            this.showFilteredAmbiguities('solved', this.currentFilter.type);
          }
        });
      },
      error: (error) => {
        console.error('Error processing ambiguities:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to process ambiguities: ' + (error.message || 'Unknown error'),
          icon: 'error'
        });
      },
      complete: () => {
        console.log('Process ambiguities operation completed');
      }
    });
  }

  // Method to delete an ambiguity
  async deleteAmbiguity(ambiguityId: string) {
    const result = await Swal.fire({
      title: 'Delete Ambiguity?',
      text: 'Are you sure you want to delete this ambiguity?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it',
      cancelButtonText: 'No, keep it'
    });

    if (result.isConfirmed) {
      // Find and remove the ambiguity
      this.ambiguities = this.ambiguities.filter(a => a.id !== ambiguityId);
      this.hasAmbiguities = this.ambiguities.length > 0;

      // Update service with the updated ambiguities list
      this.sessioneService.updateAmbiguities(this.ambiguities);

      // Close all modals
      this.modalService.dismissAll();

      // Show success message
      Swal.fire({
        title: 'Deleted!',
        text: 'The ambiguity has been removed.',
        icon: 'success',
        timer: 1500
      }).then(() => {
        // If there are still ambiguities, reopen the manager
        if (this.hasAmbiguities) {
          this.openAmbiguitiesManager();
        }
      });
    }
  }

  // Method to automatically find KB matches for ambiguities
  findAutomaticKbMatches(): void {
    // Show loading state
    Swal.fire({
      title: 'Finding KB Matches',
      text: 'Please wait while we search for knowledge base matches...',
      icon: 'info',
      showConfirmButton: false,
      allowOutsideClick: false
    });

    // Get the unanswered ambiguities
    const unansweredAmbiguities = this.ambiguities.filter(a => a.stato === 'unanswered');

    if (unansweredAmbiguities.length === 0) {
      Swal.fire({
        title: 'No Unanswered Ambiguities',
        text: 'There are no unanswered ambiguities to process.',
        icon: 'info'
      });
      return;
    }

    // Use combineLatest with take(1) to ensure the observable completes after first emission
    combineLatest([
      this.authService.email$.pipe(take(1)),
      this.sessioneService.projectId$.pipe(take(1))
    ]).pipe(
      // Only take the first combined emission
      take(1),
      // Use switchMap to switch to the API call
      switchMap(([email, projectId]) => {
        console.log(`Finding KB matches for ${unansweredAmbiguities.length} ambiguities`);

        // Call the findKbMatches method from the service
        return this.userStoryService.findKbMatches(
          unansweredAmbiguities,
          email,
          projectId
        );
      }),
      // Only take the first result
      take(1),
      // Handle any errors in the pipe
      catchError(error => {
        console.error('Error finding KB matches:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to find KB matches: ' + (error.message || 'Unknown error'),
          icon: 'error'
        });
        throw error; // Rethrow to prevent subscribe from executing
      })
    ).subscribe({
      next: (result) => {
        // Process the updated ambiguities
        const updatedAmbiguities = result.ambiguities;
        const linkedCount = updatedAmbiguities.filter(a =>
          a.linkedKbItems && a.linkedKbItems.length > 0
        ).length;

        console.log(`KB matches found for ${linkedCount} ambiguities`);

        // Update ambiguities in the service
        const allAmbiguities = [...this.sessioneService.getAmbiguities()];

        // Update each ambiguity that has new KB links
        updatedAmbiguities.forEach(updatedAmbiguity => {
          if (updatedAmbiguity.linkedKbItems && updatedAmbiguity.linkedKbItems.length > 0) {
            const index = allAmbiguities.findIndex(a => a.id === updatedAmbiguity.id);

            if (index !== -1) {
              // Mark as answered if it has KB links
              updatedAmbiguity.stato = 'answered';

              // Update resolution text
              this.updateResolutionFromLinkedItems(updatedAmbiguity);

              // Update in main array
              allAmbiguities[index] = { ...updatedAmbiguity };
            }
          }
        });

        // Update the service with all ambiguities
        this.sessioneService.updateAmbiguities(allAmbiguities);

        // Close all modals
        this.modalService.dismissAll();

        // Show success message
        Swal.fire({
          title: 'KB Matches Found',
          text: `Found potential KB matches for ${linkedCount} out of ${unansweredAmbiguities.length} ambiguities.`,
          icon: 'success'
        }).then(() => {
          // Reopen the ambiguities manager
          this.openAmbiguitiesManager();
        });
      },
      error: (error) => {
        console.error('Error processing KB matches:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to process KB matches: ' + (error.message || 'Unknown error'),
          icon: 'error'
        });
      },
      // The complete handler ensures we know when the observable is done
      complete: () => {
        console.log('FindKbMatches operation completed');
      }
    });
  }
}
