import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { StatoSessioneService } from '../../../../shared/services/stato-sessione.service';
// @ts-ignore
import Quill from 'quill';
import { Subscription } from 'rxjs';
import { SupportFile } from '../story.component';

@Component({
  selector: 'app-story-viewer',
  templateUrl: './story-viewer.component.html',
  styleUrls: ['./story-viewer.component.scss']
})
export class StoryViewerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('editor', { static: false }) editorElement: ElementRef;
  @Input() fileName: string;

  refinedStoryString: string = '';
  isCore = true;
  isImage = false;
  isCollapsed = true;
  quill: Quill;
  viewMode: 'edit' | 'analysis' = 'analysis';

  // Track if editor was previously initialized
  private wasInitialized = false;
  // Store the content when switching to analysis mode
  private savedContent: string = '';

  private subscriptions: Subscription[] = [];

  constructor(
    private sessioneService: StatoSessioneService,
  ) { }

  ngOnInit(): void {
    // Determine if this is a support file or the core story
    this.isCore = !this.fileName;

    if (this.isCore) {
      // Subscribe to core story content
      const storySub = this.sessioneService.refinedStoryString$.subscribe(refinedStoryString => {
        this.refinedStoryString = refinedStoryString || '';

        // Only update the editor if it exists and content has changed
        if (this.quill && this.viewMode === 'edit' &&
          this.quill.getText().trim() !== this.refinedStoryString.trim()) {
          this.quill.setText(this.refinedStoryString);
        }
      });
      this.subscriptions.push(storySub);

      // Subscribe to core file name
      const fileNameSub = this.sessioneService.fileName$.subscribe(filename => {
        this.fileName = filename;
      });
      this.subscriptions.push(fileNameSub);
    } else {
      // Get the file's metadata to determine its type
      const supportFile = this.getSupportFile(this.fileName);
      if (supportFile) {
        this.isImage = supportFile.fileType === 'image';

        // Get content for support file
        const fileContent = this.sessioneService.getSupportFileContent(this.fileName);
        if (fileContent) {
          this.refinedStoryString = fileContent;
        }
      }
    }
  }

  ngAfterViewInit() {
    // Initialize editor if we're in edit mode
    if (this.viewMode === 'edit' && this.editorElement) {
      setTimeout(() => {
        this.initializeEditor();
      }, 0);
    }
  }

  ngOnDestroy(): void {
    // Clean up subscriptions
    this.subscriptions.forEach(sub => sub.unsubscribe());

    // Clean up Quill instance if it exists
    if (this.quill) {
      // Remove any event listeners
      this.quill.off('text-change');
    }
  }

  setViewMode(mode: 'edit' | 'analysis'): void {
    if (this.viewMode === mode) return; // No change

    if (this.viewMode === 'edit' && mode === 'analysis') {
      // When switching from edit to analysis
      if (this.quill) {
        // Save current content
        this.savedContent = this.quill.getText() || '';
        // Update service with latest content
        if (this.isCore) {
          this.sessioneService.updateRefinedStoryString(this.savedContent);
        }
      }
    }

    // Update mode
    this.viewMode = mode;

    // When switching back to edit mode
    if (mode === 'edit') {
      // We need to wait for the view to render
      setTimeout(() => {
        // Initialize or re-initialize editor
        this.initializeEditor();
      }, 0);
    }
  }

  initializeEditor(): void {
    if (!this.editorElement) {
      console.error('Editor element not found');
      return;
    }

    // Destroy existing instance if there is one
    if (this.quill) {
      this.quill.off('text-change');

      // We can't directly destroy Quill, so we need to remove its DOM elements
      const editorElement = this.editorElement.nativeElement;
      while (editorElement.firstChild) {
        editorElement.removeChild(editorElement.firstChild);
      }
    }

    // Configure Quill based on whether this is a core or support file
    const toolbarOptions = this.isCore ?
      [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean']
      ] :
      []; // Empty toolbar for support files (read-only)

    // Create a new Quill instance
    this.quill = new Quill(this.editorElement.nativeElement, {
      theme: 'snow',
      modules: {
        toolbar: toolbarOptions
      },
      readOnly: !this.isCore // Make support files read-only
    });

    // Set content - either from saved content or from service
    if (this.savedContent && this.wasInitialized) {
      this.quill.setText(this.savedContent);
    } else {
      this.quill.setText(this.refinedStoryString || '');
    }

    this.wasInitialized = true;

    // Only set up change listener for core story
    if (this.isCore) {
      this.quill.on('text-change', () => {
        this.onStoryChange();
      });
    }
  }

  onStoryChange() {
    // Only update the session service for core story
    if (this.isCore && this.quill) {
      const newStory = this.quill.getText();
      this.refinedStoryString = newStory;
      this.sessioneService.updateRefinedStoryString(newStory);
    }
  }

  getEstimatedTokens(): number {
    if (!this.refinedStoryString) { return 0; }
    // Use 3.5 characters per token as a rough estimate
    return Math.round(this.refinedStoryString.length / 3.5);
  }

  downloadHtmlFile() {
    const blob = new Blob([this.refinedStoryString], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.fileName || 'story'}.txt`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getEditorContent(): string {
    return this.refinedStoryString;
  }

  // Helper method to get the support file object by filename
  private getSupportFile(fileName: string): SupportFile | undefined {
    const supportFiles = this.sessioneService.getSupportFiles();
    return supportFiles.find(file => file.name === fileName);
  }
}
