import {Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewChecked, HostListener} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';
import { DataParametersResponse, ConfigParameter, BaseDataset, DynamicData, SensitiveData, EdgeCase } from '../../../../shared/templateGen/templateInterfaces';
import { StatoSessioneService } from '../../../../shared/services/stato-sessione.service';
import { Subscription } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-data-param-table',
  templateUrl: './data-param-table.component.html',
  styleUrls: ['./data-param-table.component.scss']
})
export class DataParamTableComponent implements OnInit, OnDestroy, AfterViewChecked {
  dataParameters?: DataParametersResponse;
  private destroy$ = new Subject<void>();
  private subscriptions: Subscription = new Subscription();

  // Forms for adding new items
  configParamForm: FormGroup;
  baseDatasetForm: FormGroup;
  dynamicDataForm: FormGroup;
  sensitiveDataForm: FormGroup;
  edgeCaseForm: FormGroup;

  // Track which rows are being edited (full row edit)
  editingConfigParam: number | null = null;
  editingBaseDataset: number | null = null;
  editingDynamicData: number | null = null;
  editingSensitiveData: number | null = null;
  editingEdgeCase: number | null = null;

  // New properties for inline cell editing
  editingField: string | null = null;
  inlineEditControl = new FormControl('');
  inlineEditOriginalValue: any = null;

  // Reference to the input field for auto-focus
  @ViewChild('inlineEditInput') inlineEditInput?: ElementRef;

  // Add these properties to your component
  showConfigParamForm = false;
  showBaseDatasetForm = false;
  showDynamicDataForm = false;
  showSensitiveDataForm = false;
  showEdgeCaseForm = false;

  constructor(
    private sessioneService: StatoSessioneService,
    private fb: FormBuilder
  ) {
    // Initialize forms
    this.configParamForm = this.createConfigParamForm();
    this.baseDatasetForm = this.createBaseDatasetForm();
    this.dynamicDataForm = this.createDynamicDataForm();
    this.sensitiveDataForm = this.createSensitiveDataForm();
    this.edgeCaseForm = this.createEdgeCaseForm();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.sessioneService.dataParameters$.pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          console.error('Error in dataParameters subscription:', error);
          return [];
        })
      ).subscribe(dataParams => {
        this.dataParameters = dataParams;
        this.initializeEmptyCollectionsIfNeeded();
        this.ensureArrays();
      })
    );
  }

  // Initialize empty collections for any missing sections to prevent null/undefined errors
  initializeEmptyCollectionsIfNeeded(): void {
    if (this.dataParameters?.analysis_results) {
      // Ensure configuration parameters exists
      if (!this.dataParameters.analysis_results.configuration_parameters) {
        this.dataParameters.analysis_results.configuration_parameters = [];
      }

      // Ensure test_data_management exists
      if (!this.dataParameters.analysis_results.test_data_management) {
        this.dataParameters.analysis_results.test_data_management = {
          base_dataset: [],
          dynamic_data: [],
          sensitive_data: [],
          edge_cases: []
        };
      } else {
        // Ensure all array properties exist
        if (!this.dataParameters.analysis_results.test_data_management.base_dataset) {
          this.dataParameters.analysis_results.test_data_management.base_dataset = [];
        }
        if (!this.dataParameters.analysis_results.test_data_management.dynamic_data) {
          this.dataParameters.analysis_results.test_data_management.dynamic_data = [];
        }
        if (!this.dataParameters.analysis_results.test_data_management.sensitive_data) {
          this.dataParameters.analysis_results.test_data_management.sensitive_data = [];
        }
        if (!this.dataParameters.analysis_results.test_data_management.edge_cases) {
          this.dataParameters.analysis_results.test_data_management.edge_cases = [];
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.unsubscribe();
  }

  // Helper method to check if value is an array
  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  // Form creation methods
  createConfigParamForm(param?: ConfigParameter): FormGroup {
    return this.fb.group({
      parameter: [param?.parameter || '', Validators.required],
      type: [param?.type || '', Validators.required],
      allowed_values: [param?.allowed_values || ''],
      default: [param?.default || ''],
      used_in: [param?.used_in || []],
      impact: [param?.impact || '']
    });
  }

  createBaseDatasetForm(item?: BaseDataset): FormGroup {
    return this.fb.group({
      field: [item?.field || '', Validators.required],
      valid_values: [item?.valid_values || []],
      invalid_values: [item?.invalid_values || []],
      rules: [item?.rules || '']
    });
  }

  createDynamicDataForm(item?: DynamicData): FormGroup {
    return this.fb.group({
      field: [item?.field || '', Validators.required],
      generation: [item?.generation || '', Validators.required]
    });
  }

  createSensitiveDataForm(item?: SensitiveData): FormGroup {
    return this.fb.group({
      type: [item?.type || '', Validators.required],
      handling: [item?.handling || '', Validators.required]
    });
  }

  createEdgeCaseForm(item?: EdgeCase): FormGroup {
    return this.fb.group({
      scenario: [item?.scenario || '', Validators.required],
      relevance: [item?.relevance || '', Validators.required]
    });
  }

  // Form array helpers for string arrays
  createStringArrayFormControl(values: string[]): FormArray {
    return this.fb.array(values.map(val => this.fb.control(val)));
  }

  // Add new items - Updated to handle existence checks
  addConfigParameter(): void {
    if (this.configParamForm.valid && this.dataParameters?.analysis_results) {
      const newItem = this.configParamForm.value;

      // Convert string to array for allowed_values if needed
      if (typeof newItem.allowed_values === 'string' && newItem.allowed_values.includes(',')) {
        newItem.allowed_values = newItem.allowed_values.split(',').map((val: string) => val.trim());
      }

      // Convert string to array for used_in if needed
      if (typeof newItem.used_in === 'string' && newItem.used_in.includes(',')) {
        newItem.used_in = newItem.used_in.split(',').map((val: string) => val.trim());
      }

      // Add the first entry if that field doesn't exist yet
      if (!this.dataParameters.analysis_results.has_config_parameters) {
        this.dataParameters.analysis_results.has_config_parameters = true;
      }

      this.dataParameters.analysis_results.configuration_parameters.push(newItem);
      this.configParamForm.reset();
      this.showConfigParamForm = false;
      this.saveChanges();
    }
  }

  addBaseDataset(): void {
    if (this.baseDatasetForm.valid && this.dataParameters?.analysis_results) {
      const newItem = this.baseDatasetForm.value;

      // Convert string to arrays if needed
      if (typeof newItem.valid_values === 'string') {
        newItem.valid_values = newItem.valid_values ?
          newItem.valid_values.split(',').map((val: string) => val.trim()) :
          [];
      } else if (!Array.isArray(newItem.valid_values)) {
        newItem.valid_values = [];
      }

      if (typeof newItem.invalid_values === 'string') {
        newItem.invalid_values = newItem.invalid_values ?
          newItem.invalid_values.split(',').map((val: string) => val.trim()) :
          [];
      } else if (!Array.isArray(newItem.invalid_values)) {
        newItem.invalid_values = [];
      }

      // Make sure test_data_management exists
      if (!this.dataParameters.analysis_results.test_data_management) {
        this.dataParameters.analysis_results.test_data_management = {
          base_dataset: [],
          dynamic_data: [],
          sensitive_data: [],
          edge_cases: []
        };
      }

      // Update flags
      this.dataParameters.analysis_results.has_test_data_management = true;
      this.dataParameters.analysis_results.has_base_dataset = true;

      this.dataParameters.analysis_results.test_data_management.base_dataset.push(newItem);
      this.baseDatasetForm.reset();
      this.showBaseDatasetForm = false;
      this.saveChanges();
    }
  }

  addDynamicData(): void {
    if (this.dynamicDataForm.valid && this.dataParameters?.analysis_results) {
      // Make sure test_data_management exists
      if (!this.dataParameters.analysis_results.test_data_management) {
        this.dataParameters.analysis_results.test_data_management = {
          base_dataset: [],
          dynamic_data: [],
          sensitive_data: [],
          edge_cases: []
        };
      }

      // Update flags
      this.dataParameters.analysis_results.has_test_data_management = true;
      this.dataParameters.analysis_results.has_dynamic_data = true;

      this.dataParameters.analysis_results.test_data_management.dynamic_data.push(this.dynamicDataForm.value);
      this.dynamicDataForm.reset();
      this.showDynamicDataForm = false;
      this.saveChanges();
    }
  }

  addSensitiveData(): void {
    if (this.sensitiveDataForm.valid && this.dataParameters?.analysis_results) {
      // Make sure test_data_management exists
      if (!this.dataParameters.analysis_results.test_data_management) {
        this.dataParameters.analysis_results.test_data_management = {
          base_dataset: [],
          dynamic_data: [],
          sensitive_data: [],
          edge_cases: []
        };
      }

      // Update flags
      this.dataParameters.analysis_results.has_test_data_management = true;
      this.dataParameters.analysis_results.has_sensitive_data = true;

      this.dataParameters.analysis_results.test_data_management.sensitive_data.push(this.sensitiveDataForm.value);
      this.sensitiveDataForm.reset();
      this.showSensitiveDataForm = false;
      this.saveChanges();
    }
  }

  addEdgeCase(): void {
    if (this.edgeCaseForm.valid && this.dataParameters?.analysis_results) {
      // Make sure test_data_management exists
      if (!this.dataParameters.analysis_results.test_data_management) {
        this.dataParameters.analysis_results.test_data_management = {
          base_dataset: [],
          dynamic_data: [],
          sensitive_data: [],
          edge_cases: []
        };
      }

      // Update flags
      this.dataParameters.analysis_results.has_test_data_management = true;
      this.dataParameters.analysis_results.has_edge_cases = true;

      this.dataParameters.analysis_results.test_data_management.edge_cases.push(this.edgeCaseForm.value);
      this.edgeCaseForm.reset();
      this.showEdgeCaseForm = false;
      this.saveChanges();
    }
  }

  // Delete items - Updated to update flags when collections become empty
  deleteConfigParameter(index: number): void {
    if (this.dataParameters?.analysis_results) {
      this.dataParameters.analysis_results.configuration_parameters.splice(index, 1);

      // Update flag if collection is now empty
      if (this.dataParameters.analysis_results.configuration_parameters.length === 0) {
        this.dataParameters.analysis_results.has_config_parameters = false;
      }

      this.saveChanges();
    }
  }

  deleteBaseDataset(index: number): void {
    if (this.dataParameters?.analysis_results?.test_data_management) {
      this.dataParameters.analysis_results.test_data_management.base_dataset.splice(index, 1);

      // Update flag if collection is now empty
      if (this.dataParameters.analysis_results.test_data_management.base_dataset.length === 0) {
        this.dataParameters.analysis_results.has_base_dataset = false;
        this.updateTestDataManagementFlag();
      }

      this.saveChanges();
    }
  }

  deleteDynamicData(index: number): void {
    if (this.dataParameters?.analysis_results?.test_data_management) {
      this.dataParameters.analysis_results.test_data_management.dynamic_data.splice(index, 1);

      // Update flag if collection is now empty
      if (this.dataParameters.analysis_results.test_data_management.dynamic_data.length === 0) {
        this.dataParameters.analysis_results.has_dynamic_data = false;
        this.updateTestDataManagementFlag();
      }

      this.saveChanges();
    }
  }

  deleteSensitiveData(index: number): void {
    if (this.dataParameters?.analysis_results?.test_data_management) {
      this.dataParameters.analysis_results.test_data_management.sensitive_data.splice(index, 1);

      // Update flag if collection is now empty
      if (this.dataParameters.analysis_results.test_data_management.sensitive_data.length === 0) {
        this.dataParameters.analysis_results.has_sensitive_data = false;
        this.updateTestDataManagementFlag();
      }

      this.saveChanges();
    }
  }

  deleteEdgeCase(index: number): void {
    if (this.dataParameters?.analysis_results?.test_data_management) {
      this.dataParameters.analysis_results.test_data_management.edge_cases.splice(index, 1);

      // Update flag if collection is now empty
      if (this.dataParameters.analysis_results.test_data_management.edge_cases.length === 0) {
        this.dataParameters.analysis_results.has_edge_cases = false;
        this.updateTestDataManagementFlag();
      }

      this.saveChanges();
    }
  }

  // Helper to update the main test_data_management flag
  updateTestDataManagementFlag(): void {
    if (this.dataParameters?.analysis_results?.test_data_management) {
      const tdm = this.dataParameters.analysis_results.test_data_management;
      this.dataParameters.analysis_results.has_test_data_management =
        tdm.base_dataset.length > 0 ||
        tdm.dynamic_data.length > 0 ||
        tdm.sensitive_data.length > 0 ||
        tdm.edge_cases.length > 0;
    }
  }

  // Edit items
  startEditing(type: string, index: number): void {
    // Reset any active inline editing
    this.resetInlineEdit();

    switch (type) {
      case 'configParam':
        this.editingConfigParam = index;
        const configParam = this.dataParameters?.analysis_results.configuration_parameters[index];
        if (configParam) {
          this.configParamForm = this.createConfigParamForm(configParam);
        }
        break;
      case 'baseDataset':
        this.editingBaseDataset = index;
        const baseDataset = this.dataParameters?.analysis_results.test_data_management.base_dataset[index];
        if (baseDataset) {
          this.baseDatasetForm = this.createBaseDatasetForm(baseDataset);
        }
        break;
      case 'dynamicData':
        this.editingDynamicData = index;
        const dynamicData = this.dataParameters?.analysis_results.test_data_management.dynamic_data[index];
        if (dynamicData) {
          this.dynamicDataForm = this.createDynamicDataForm(dynamicData);
        }
        break;
      case 'sensitiveData':
        this.editingSensitiveData = index;
        const sensitiveData = this.dataParameters?.analysis_results.test_data_management.sensitive_data[index];
        if (sensitiveData) {
          this.sensitiveDataForm = this.createSensitiveDataForm(sensitiveData);
        }
        break;
      case 'edgeCase':
        this.editingEdgeCase = index;
        const edgeCase = this.dataParameters?.analysis_results.test_data_management.edge_cases[index];
        if (edgeCase) {
          this.edgeCaseForm = this.createEdgeCaseForm(edgeCase);
        }
        break;
    }
  }

  saveEdit(type: string): void {
    switch (type) {
      case 'configParam':
        if (this.configParamForm.valid && this.editingConfigParam !== null && this.dataParameters?.analysis_results) {
          const updatedItem = this.configParamForm.value;

          // Convert string to array for allowed_values if needed
          if (typeof updatedItem.allowed_values === 'string' && updatedItem.allowed_values.includes(',')) {
            updatedItem.allowed_values = updatedItem.allowed_values.split(',').map((val: string) => val.trim());
          }

          // Convert string to array for used_in if needed
          if (typeof updatedItem.used_in === 'string' && updatedItem.used_in.includes(',')) {
            updatedItem.used_in = updatedItem.used_in.split(',').map((val: string) => val.trim());
          }

          this.dataParameters.analysis_results.configuration_parameters[this.editingConfigParam] = updatedItem;
          this.editingConfigParam = null;
          this.configParamForm = this.createConfigParamForm();
        }
        break;
      case 'baseDataset':
        if (this.baseDatasetForm.valid && this.editingBaseDataset !== null && this.dataParameters?.analysis_results?.test_data_management) {
          const updatedItem = this.baseDatasetForm.value;

          // Convert string to arrays if needed
          if (typeof updatedItem.valid_values === 'string') {
            updatedItem.valid_values = updatedItem.valid_values ?
              updatedItem.valid_values.split(',').map((val: string) => val.trim()) :
              [];
          } else if (!Array.isArray(updatedItem.valid_values)) {
            updatedItem.valid_values = [];
          }

          if (typeof updatedItem.invalid_values === 'string') {
            updatedItem.invalid_values = updatedItem.invalid_values ?
              updatedItem.invalid_values.split(',').map((val: string) => val.trim()) :
              [];
          } else if (!Array.isArray(updatedItem.invalid_values)) {
            updatedItem.invalid_values = [];
          }

          this.dataParameters.analysis_results.test_data_management.base_dataset[this.editingBaseDataset] = updatedItem;
          this.editingBaseDataset = null;
          this.baseDatasetForm = this.createBaseDatasetForm();
        }
        break;
      case 'dynamicData':
        if (this.dynamicDataForm.valid && this.editingDynamicData !== null && this.dataParameters?.analysis_results?.test_data_management) {
          this.dataParameters.analysis_results.test_data_management.dynamic_data[this.editingDynamicData] = this.dynamicDataForm.value;
          this.editingDynamicData = null;
          this.dynamicDataForm = this.createDynamicDataForm();
        }
        break;
      case 'sensitiveData':
        if (this.sensitiveDataForm.valid && this.editingSensitiveData !== null && this.dataParameters?.analysis_results?.test_data_management) {
          this.dataParameters.analysis_results.test_data_management.sensitive_data[this.editingSensitiveData] = this.sensitiveDataForm.value;
          this.editingSensitiveData = null;
          this.sensitiveDataForm = this.createSensitiveDataForm();
        }
        break;
      case 'edgeCase':
        if (this.edgeCaseForm.valid && this.editingEdgeCase !== null && this.dataParameters?.analysis_results?.test_data_management) {
          this.dataParameters.analysis_results.test_data_management.edge_cases[this.editingEdgeCase] = this.edgeCaseForm.value;
          this.editingEdgeCase = null;
          this.edgeCaseForm = this.createEdgeCaseForm();
        }
        break;
    }
    this.saveChanges();
  }

  cancelEdit(type: string): void {
    switch (type) {
      case 'configParam':
        this.editingConfigParam = null;
        this.configParamForm = this.createConfigParamForm();
        break;
      case 'baseDataset':
        this.editingBaseDataset = null;
        this.baseDatasetForm = this.createBaseDatasetForm();
        break;
      case 'dynamicData':
        this.editingDynamicData = null;
        this.dynamicDataForm = this.createDynamicDataForm();
        break;
      case 'sensitiveData':
        this.editingSensitiveData = null;
        this.sensitiveDataForm = this.createSensitiveDataForm();
        break;
      case 'edgeCase':
        this.editingEdgeCase = null;
        this.edgeCaseForm = this.createEdgeCaseForm();
        break;
    }
  }

  // Save changes to the service
  saveChanges(): void {
    if (this.dataParameters) {
      this.sessioneService.updateDataParameters(this.dataParameters);
    }
  }

  // Helper for array display in forms
  formatArrayToString(arr: string[] | undefined): string {
    if (!arr) { return ''; }
    return arr.join(', ');
  }

  // Toggle methods
  toggleConfigParamForm(): void {
    this.showConfigParamForm = !this.showConfigParamForm;
    if (this.showConfigParamForm) {
      this.configParamForm = this.createConfigParamForm();
    } else {
      this.configParamForm.reset();
    }
  }

  toggleBaseDatasetForm(): void {
    this.showBaseDatasetForm = !this.showBaseDatasetForm;
    if (this.showBaseDatasetForm) {
      this.baseDatasetForm = this.createBaseDatasetForm();
    } else {
      this.baseDatasetForm.reset();
    }
  }

  toggleDynamicDataForm(): void {
    this.showDynamicDataForm = !this.showDynamicDataForm;
    if (this.showDynamicDataForm) {
      this.dynamicDataForm = this.createDynamicDataForm();
    } else {
      this.dynamicDataForm.reset();
    }
  }

  toggleSensitiveDataForm(): void {
    this.showSensitiveDataForm = !this.showSensitiveDataForm;
    if (this.showSensitiveDataForm) {
      this.sensitiveDataForm = this.createSensitiveDataForm();
    } else {
      this.sensitiveDataForm.reset();
    }
  }

  toggleEdgeCaseForm(): void {
    this.showEdgeCaseForm = !this.showEdgeCaseForm;
    if (this.showEdgeCaseForm) {
      this.edgeCaseForm = this.createEdgeCaseForm();
    } else {
      this.edgeCaseForm.reset();
    }
  }

  ensureArrays(): void {
    if (this.dataParameters?.analysis_results?.test_data_management?.base_dataset) {
      this.dataParameters.analysis_results.test_data_management.base_dataset.forEach(item => {
        // For valid_values
        if (!Array.isArray(item.valid_values)) {
          // Use type assertion to help TypeScript understand
          const validValue = item.valid_values as unknown as string;

          if (validValue && typeof validValue === 'string') {
            if (validValue.includes(',')) {
              // Cast the result back to string[] to satisfy TypeScript
              item.valid_values = validValue.split(',').map(val => val.trim()) as unknown as string[];
            } else {
              item.valid_values = [validValue] as unknown as string[];
            }
          } else {
            item.valid_values = [] as string[];
          }
        }

        // For invalid_values
        if (!Array.isArray(item.invalid_values)) {
          // Use type assertion to help TypeScript understand
          const invalidValue = item.invalid_values as unknown as string;

          if (invalidValue && typeof invalidValue === 'string') {
            if (invalidValue.includes(',')) {
              // Cast the result back to string[] to satisfy TypeScript
              item.invalid_values = invalidValue.split(',').map(val => val.trim()) as unknown as string[];
            } else {
              item.invalid_values = [invalidValue] as unknown as string[];
            }
          } else {
            item.invalid_values = [] as string[];
          }
        }
      });
    }
  }

  /**
   * Formats special values like empty strings for display
   */
  formatSpecialValue(value: string | undefined | null): string {
    if (value === undefined || value === null) {
      return '<null>';
    }

    if (value === '') {
      return '<empty string>';
    }

    if (value === ' ') {
      return '<space character>';
    }

    if (value !== null && value !== undefined && value.trim() === '' && value.length > 1) {
      return `<${value.length} whitespace characters>`;
    }

    return value;
  }

  // Start inline editing of a specific cell
  startInlineEdit(type: string, index: number, field: string, value: any): void {
    // Set editing state
    switch (type) {
      case 'configParam':
        this.editingConfigParam = index;
        break;
      case 'baseDataset':
        this.editingBaseDataset = index;
        break;
      case 'dynamicData':
        this.editingDynamicData = index;
        break;
      case 'sensitiveData':
        this.editingSensitiveData = index;
        break;
      case 'edgeCase':
        this.editingEdgeCase = index;
        break;
    }

    // Set the field being edited
    this.editingField = field;

    // Store original value for cancel operation
    this.inlineEditOriginalValue = value;

    // Set the form control value
    this.inlineEditControl.setValue(value !== null && value !== undefined ? value : '');
  }

  // Save inline edit
  saveInlineEdit(type: string): void {
    if (!this.editingField) return;

    const value = this.inlineEditControl.value;

    switch (type) {
      case 'configParam':
        if (this.editingConfigParam !== null && this.dataParameters?.analysis_results?.configuration_parameters) {
          const param = this.dataParameters.analysis_results.configuration_parameters[this.editingConfigParam];

          switch (this.editingField) {
            case 'parameter':
              param.parameter = value;
              break;
            case 'type':
              param.type = value;
              break;
            case 'allowed_values':
              // Handle comma-separated string to array conversion
              param.allowed_values = value ? value.split(',').map((val: string) => val.trim()) : [];
              break;
            case 'default':
              param.default = value;
              break;
            case 'used_in':
              // Handle comma-separated string to array conversion
              param.used_in = value ? value.split(',').map((val: string) => val.trim()) : [];
              break;
            case 'impact':
              param.impact = value;
              break;
          }
        }
        break;

      case 'baseDataset':
        if (this.editingBaseDataset !== null && this.dataParameters?.analysis_results?.test_data_management?.base_dataset) {
          const item = this.dataParameters.analysis_results.test_data_management.base_dataset[this.editingBaseDataset];

          switch (this.editingField) {
            case 'field':
              item.field = value;
              break;
            case 'valid_values':
              // Handle comma-separated string to array conversion
              item.valid_values = value ? value.split(',').map((val: string) => val.trim()) : [];
              break;
            case 'invalid_values':
              // Handle comma-separated string to array conversion
              item.invalid_values = value ? value.split(',').map((val: string) => val.trim()) : [];
              break;
            case 'rules':
              item.rules = value;
              break;
          }
        }
        break;

      case 'dynamicData':
        if (this.editingDynamicData !== null && this.dataParameters?.analysis_results?.test_data_management?.dynamic_data) {
          const item = this.dataParameters.analysis_results.test_data_management.dynamic_data[this.editingDynamicData];

          switch (this.editingField) {
            case 'field':
              item.field = value;
              break;
            case 'generation':
              item.generation = value;
              break;
          }
        }
        break;

      case 'sensitiveData':
        if (this.editingSensitiveData !== null && this.dataParameters?.analysis_results?.test_data_management?.sensitive_data) {
          const item = this.dataParameters.analysis_results.test_data_management.sensitive_data[this.editingSensitiveData];

          switch (this.editingField) {
            case 'type':
              item.type = value;
              break;
            case 'handling':
              item.handling = value;
              break;
          }
        }
        break;

      case 'edgeCase':
        if (this.editingEdgeCase !== null && this.dataParameters?.analysis_results?.test_data_management?.edge_cases) {
          const item = this.dataParameters.analysis_results.test_data_management.edge_cases[this.editingEdgeCase];

          switch (this.editingField) {
            case 'scenario':
              item.scenario = value;
              break;
            case 'relevance':
              item.relevance = value;
              break;
          }
        }
        break;
    }

    // Reset editing state
    this.resetInlineEdit();

    // Save changes
    this.saveChanges();
  }

  // Cancel inline edit
  cancelInlineEdit(): void {
    this.resetInlineEdit();
  }

  // Reset inline edit state
  resetInlineEdit(): void {
    this.editingField = null;
    this.inlineEditControl.setValue('');
    this.inlineEditOriginalValue = null;
    this.editingConfigParam = null;
    this.editingBaseDataset = null;
    this.editingDynamicData = null;
    this.editingSensitiveData = null;
    this.editingEdgeCase = null;
  }

  isMultilineField(field: string): boolean {
    return ['impact', 'rules', 'generation', 'handling', 'scenario', 'relevance', 'valid_values', 'invalid_values'].includes(field);
  }

// 2. method to handle keyboard navigation between cells (optional but useful)
  handleKeyboardNavigation(event: KeyboardEvent, type: string, index: number, field: string): void {
    // Only handle navigation if we're not in the middle of typing text
    if (event.target && (event.target as HTMLElement).tagName === 'INPUT' ||
      (event.target as HTMLElement).tagName === 'TEXTAREA') {
      const value = this.inlineEditControl.value;
      const selStart = (event.target as HTMLInputElement).selectionStart || 0;
      const selEnd = (event.target as HTMLInputElement).selectionEnd || 0;
      const textLength = value ? value.length : 0;

      // Only navigate if at the edges of the text
      if (!(
        (event.key === 'ArrowLeft' && selStart === 0) ||
        (event.key === 'ArrowRight' && selEnd === textLength) ||
        event.key === 'ArrowUp' ||
        event.key === 'ArrowDown'
      )) {
        return;
      }
    }

    // Save current edit if there is one
    if (this.editingField) {
      this.saveInlineEdit(type);
    }

    // Determine which cells are in the current table
    let fields: string[] = [];
    let rowCount = 0;

    switch (type) {
      case 'configParam':
        fields = ['parameter', 'type', 'allowed_values', 'default', 'used_in', 'impact'];
        rowCount = this.dataParameters?.analysis_results?.configuration_parameters?.length || 0;
        break;
      case 'baseDataset':
        fields = ['field', 'valid_values', 'invalid_values', 'rules'];
        rowCount = this.dataParameters?.analysis_results?.test_data_management?.base_dataset?.length || 0;
        break;
      case 'dynamicData':
        fields = ['field', 'generation'];
        rowCount = this.dataParameters?.analysis_results?.test_data_management?.dynamic_data?.length || 0;
        break;
      case 'sensitiveData':
        fields = ['type', 'handling'];
        rowCount = this.dataParameters?.analysis_results?.test_data_management?.sensitive_data?.length || 0;
        break;
      case 'edgeCase':
        fields = ['scenario', 'relevance'];
        rowCount = this.dataParameters?.analysis_results?.test_data_management?.edge_cases?.length || 0;
        break;
    }

    // Find current field index
    const currentFieldIndex = fields.indexOf(field);

    // Calculate new position based on key pressed
    let newRow = index;
    let newFieldIndex = currentFieldIndex;

    switch (event.key) {
      case 'ArrowUp':
        newRow = Math.max(0, index - 1);
        break;
      case 'ArrowDown':
        newRow = Math.min(rowCount - 1, index + 1);
        break;
      case 'ArrowLeft':
        newFieldIndex = Math.max(0, currentFieldIndex - 1);
        break;
      case 'ArrowRight':
        newFieldIndex = Math.min(fields.length - 1, currentFieldIndex + 1);
        break;
      case 'Tab':
        if (event.shiftKey) {
          newFieldIndex = Math.max(0, currentFieldIndex - 1);
          if (newFieldIndex === currentFieldIndex - 1) break;
          newRow = Math.max(0, index - 1);
          newFieldIndex = fields.length - 1;
        } else {
          newFieldIndex = Math.min(fields.length - 1, currentFieldIndex + 1);
          if (newFieldIndex === currentFieldIndex + 1) break;
          newRow = Math.min(rowCount - 1, index + 1);
          newFieldIndex = 0;
        }
        event.preventDefault(); // Prevent default tab behavior
        break;
    }

    // If position changed, move focus to the new cell
    if (newRow !== index || newFieldIndex !== currentFieldIndex) {
      const newField = fields[newFieldIndex];

      // Get the value from the appropriate data structure
      let value;
      switch (type) {
        case 'configParam':
          value = this.dataParameters?.analysis_results?.configuration_parameters[newRow][newField];
          break;
        case 'baseDataset':
          value = this.dataParameters?.analysis_results?.test_data_management?.base_dataset[newRow][newField];
          break;
        case 'dynamicData':
          value = this.dataParameters?.analysis_results?.test_data_management?.dynamic_data[newRow][newField];
          break;
        case 'sensitiveData':
          value = this.dataParameters?.analysis_results?.test_data_management?.sensitive_data[newRow][newField];
          break;
        case 'edgeCase':
          value = this.dataParameters?.analysis_results?.test_data_management?.edge_cases[newRow][newField];
          break;
      }

      // Format array values for display
      if (Array.isArray(value)) {
        value = value.join(', ');
      }

      // Start editing the new cell
      setTimeout(() => {
        this.startInlineEdit(type, newRow, newField, value);
      }, 0);
    }
  }

// 3. Improved focus handling to position cursor at the end of text
  ngAfterViewChecked(): void {
    if (this.inlineEditInput && this.editingField) {
      const element = this.inlineEditInput.nativeElement;

      // Only focus if the element doesn't already have focus
      if (document.activeElement !== element) {
        element.focus();

        // Position cursor at the end for input fields
        if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
          const length = element.value.length;
          element.setSelectionRange(length, length);
        }
      }
    }
  }

// 4. method to check if a cell has been modified
  hasBeenModified(): boolean {
    return this.inlineEditControl.value !== this.inlineEditOriginalValue;
  }

// 5. event handler for clicking outside (if needed for more robust blur handling)
  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent): void {
    // If we're editing and clicked outside the edit field and not on a save/cancel button
    if (this.editingField && this.inlineEditInput) {
      const clickedElement = event.target as HTMLElement;
      const isClickOnInput = this.inlineEditInput.nativeElement.contains(clickedElement);
      const isClickOnSaveCancel = clickedElement.classList.contains('btn-success') ||
        clickedElement.classList.contains('btn-secondary') ||
        clickedElement.closest('.btn-success') ||
        clickedElement.closest('.btn-secondary');

      if (!isClickOnInput && !isClickOnSaveCancel) {
        // If modified, save changes
        if (this.hasBeenModified()) {
          if (this.editingConfigParam !== null) {
            this.saveInlineEdit('configParam');
          } else if (this.editingBaseDataset !== null) {
            this.saveInlineEdit('baseDataset');
          } else if (this.editingDynamicData !== null) {
            this.saveInlineEdit('dynamicData');
          } else if (this.editingSensitiveData !== null) {
            this.saveInlineEdit('sensitiveData');
          } else if (this.editingEdgeCase !== null) {
            this.saveInlineEdit('edgeCase');
          }
        } else {
          // If not modified, just cancel
          this.cancelInlineEdit();
        }
      }
    }
  }
}
