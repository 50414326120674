import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, debounceTime, finalize, pairwise, startWith } from 'rxjs/operators';
import { DatanalysisService } from './datanalysis.service';
import {
  TimelineResponse, UserActivityResponse, ProjectStatsResponse,
  SessionStatsResponse, TokenUsageResponse, DashboardFilter
} from './datanalysis.service';

// Chart.js imports - using ng2-charts as it's already in your dependencies
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-datanalysis',
  templateUrl: './datanalysis.component.html',
  styleUrls: ['./datanalysis.component.scss']
})
export class DatanalysisComponent implements OnInit, OnDestroy {
  // Loading and error states
  loading = {
    timeline: false,
    users: false,
    projects: false,
    sessions: false,
    tokens: false
  };

  error = {
    timeline: null,
    users: null,
    projects: null,
    sessions: null,
    tokens: null
  };

  // Individual subscriptions to manage
  private timelineSubscription: Subscription | null = null;
  private userSubscription: Subscription | null = null;
  private projectSubscription: Subscription | null = null;
  private sessionSubscription: Subscription | null = null;
  private tokenSubscription: Subscription | null = null;

  // Data objects from API
  timelineData: TimelineResponse | null = null;
  userActivityData: UserActivityResponse | null = null;
  projectStatsData: ProjectStatsResponse | null = null;
  sessionStatsData: SessionStatsResponse | null = null;
  tokenUsageData: TokenUsageResponse | null = null;

  // Timeline Chart
  timelineChartData: ChartDataSets[] = [];
  timelineChartLabels: Label[] = [];
  timelineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Count'
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Date'
        }
      }]
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    title: {
      display: true,
      text: 'Session Activity Timeline'
    },
    tooltips: {
      mode: 'index',
      intersect: false
    }
  };
  timelineChartColors: Color[] = [
    { backgroundColor: 'rgba(54, 162, 235, 0.5)', borderColor: 'rgb(54, 162, 235)', borderWidth: 1 },
    { backgroundColor: 'rgba(255, 206, 86, 0.5)', borderColor: 'rgb(255, 206, 86)', borderWidth: 1 },
    { backgroundColor: 'rgba(75, 192, 192, 0.5)', borderColor: 'rgb(75, 192, 192)', borderWidth: 1 }
  ];
  timelineChartLegend = true;
  timelineChartType: ChartType = 'bar';

  // Session Stage Chart
  sessionStageChartData: number[] = [];
  sessionStageChartLabels: Label[] = [];
  sessionStageChartType: ChartType = 'pie';
  sessionStageChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false
      }
    },
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: 'Sessions by Stage'
    }
  };
  sessionStageChartColors = [
    {
      backgroundColor: [
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(153, 102, 255, 0.7)'
      ],
      borderWidth: 1
    }
  ];

  // Token Usage Chart
  tokenUsageChartData: ChartDataSets[] = [];
  tokenUsageChartLabels: Label[] = [];
  tokenUsageChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Token Count'
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'AI Model'
        }
      }]
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    title: {
      display: true,
      text: 'Token Usage by Model'
    }
  };
  tokenUsageChartColors: Color[] = [
    { backgroundColor: 'rgba(54, 162, 235, 0.7)', borderColor: 'rgb(54, 162, 235)', borderWidth: 1 },
    { backgroundColor: 'rgba(75, 192, 192, 0.7)', borderColor: 'rgb(75, 192, 192)', borderWidth: 1 }
  ];
  tokenUsageChartLegend = true;
  tokenUsageChartType: ChartType = 'bar';

  // User Activity Chart
  userActivityChartData: ChartDataSets[] = [];
  userActivityChartLabels: Label[] = [];
  userActivityChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Count'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'User'
        }
      }]
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    title: {
      display: true,
      text: 'Top User Activity'
    }
  };
  userActivityChartColors: Color[] = [
    { backgroundColor: 'rgba(54, 162, 235, 0.7)', borderColor: 'rgb(54, 162, 235)', borderWidth: 1 },
    { backgroundColor: 'rgba(255, 99, 132, 0.7)', borderColor: 'rgb(255, 99, 132)', borderWidth: 1 }
  ];
  userActivityChartLegend = true;
  userActivityChartType: ChartType = 'horizontalBar';

  // Filters
  filterForm: FormGroup;
  timeframeOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'yearly', label: 'Yearly' }
  ];

  // Unsubscribe notifier
  private destroy$ = new Subject<void>();

  constructor(
    private dataService: DatanalysisService,
    private fb: FormBuilder
  ) {
    // Initialize filter form
    this.filterForm = this.fb.group({
      timeframe: ['daily'],
      start_date: [this.getDefaultStartDate()],
      end_date: [this.getDefaultEndDate()],
      project_id: [''],
      email: [''],
      checkpoint_stage: ['']
    });
  }

  ngOnInit(): void {
    // Load initial data
    this.loadAllData();

    // More selective updates based on which form values changed
    this.filterForm.valueChanges.pipe(
      startWith(this.filterForm.value),
      pairwise(),
      takeUntil(this.destroy$),
      debounceTime(500)
    ).subscribe(([prev, current]) => {
      // Always update everything on date/timeframe changes
      if (prev.timeframe !== current.timeframe ||
        prev.start_date !== current.start_date ||
        prev.end_date !== current.end_date) {
        this.loadAllData();
      } else {
        // Otherwise, only reload what's affected by specific filter changes
        if (prev.email !== current.email) {
          this.loadUserActivity();
          // User activity might affect timeline and sessions
          this.loadTimelineData();
          this.loadSessionStats();
        }
        if (prev.project_id !== current.project_id) {
          this.loadProjectStats();
          this.loadTimelineData();
          this.loadSessionStats();
          this.loadTokenUsage();
        }
        if (prev.checkpoint_stage !== current.checkpoint_stage) {
          this.loadSessionStats();
          this.loadTimelineData();
        }
      }
    });
  }

  ngOnDestroy(): void {
    // First alert subscribers to stop
    this.destroy$.next();

    // Explicitly cancel any pending requests
    this.cancelAllRequests();

    // Complete the subject
    this.destroy$.complete();
  }

  private cancelAllRequests(): void {
    if (this.timelineSubscription) {
      this.timelineSubscription.unsubscribe();
      this.timelineSubscription = null;
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
      this.projectSubscription = null;
    }
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe();
      this.sessionSubscription = null;
    }
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
      this.tokenSubscription = null;
    }
  }

  private getDefaultStartDate(): string {
    const date = new Date();
    date.setMonth(date.getMonth() - 1); // One month ago
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }

  private getDefaultEndDate(): string {
    const date = new Date();
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }

  private getFilters(): DashboardFilter {
    return this.filterForm.value;
  }

  loadAllData(): void {
    // Cancel any previous pending requests
    this.cancelAllRequests();

    this.loadTimelineData();
    this.loadUserActivity();
    this.loadProjectStats();
    this.loadSessionStats();
    this.loadTokenUsage();
  }

  loadTimelineData(): void {
    // Cancel previous request if it's still in progress
    if (this.timelineSubscription) {
      this.timelineSubscription.unsubscribe();
      this.timelineSubscription = null;
    }

    this.loading.timeline = true;
    this.error.timeline = null;

    this.timelineSubscription = this.dataService.getSessionsTimeline(this.getFilters())
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          this.timelineData = data;
          this.updateTimelineChart();
          this.loading.timeline = false;
        },
        error: (err) => {
          this.error.timeline = `Failed to load timeline: ${err.message}`;
          console.error('Timeline loading error:', err);
          this.loading.timeline = false;
        }
      });
  }

  loadUserActivity(): void {
    // Cancel previous request if it's still in progress
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }

    this.loading.users = true;
    this.error.users = null;

    this.userSubscription = this.dataService.getUserActivity(this.getFilters())
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          this.userActivityData = data;
          this.updateUserActivityChart();
          this.loading.users = false;
        },
        error: (err) => {
          this.error.users = `Failed to load user activity: ${err.message}`;
          console.error('User activity loading error:', err);
          this.loading.users = false;
        }
      });
  }

  loadProjectStats(): void {
    // Cancel previous request if it's still in progress
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
      this.projectSubscription = null;
    }

    this.loading.projects = true;
    this.error.projects = null;

    this.projectSubscription = this.dataService.getProjectStats(this.getFilters())
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          this.projectStatsData = data;
          this.loading.projects = false;
        },
        error: (err) => {
          this.error.projects = `Failed to load project stats: ${err.message}`;
          console.error('Project stats loading error:', err);
          this.loading.projects = false;
        }
      });
  }

  loadSessionStats(): void {
    // Cancel previous request if it's still in progress
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe();
      this.sessionSubscription = null;
    }

    this.loading.sessions = true;
    this.error.sessions = null;

    this.sessionSubscription = this.dataService.getSessionStats(this.getFilters())
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          this.sessionStatsData = data;
          this.updateSessionStageChart();
          this.loading.sessions = false;
        },
        error: (err) => {
          this.error.sessions = `Failed to load session stats: ${err.message}`;
          console.error('Session stats loading error:', err);
          this.loading.sessions = false;
        }
      });
  }

  loadTokenUsage(): void {
    // Cancel previous request if it's still in progress
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
      this.tokenSubscription = null;
    }

    this.loading.tokens = true;
    this.error.tokens = null;

    this.tokenSubscription = this.dataService.getTokenUsage(this.getFilters())
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          this.tokenUsageData = data;
          this.updateTokenUsageChart();
          this.loading.tokens = false;
        },
        error: (err) => {
          this.error.tokens = `Failed to load token usage: ${err.message}`;
          console.error('Token usage loading error:', err);
          this.loading.tokens = false;
        }
      });
  }

  updateTimelineChart(): void {
    if (!this.timelineData?.timeline.length) return;

    this.timelineChartLabels = this.timelineData.timeline.map(item => item.date);

    const createdData = this.timelineData.timeline.map(item => item.created);
    const updatedData = this.timelineData.timeline.map(item => item.updated);
    const testsData = this.timelineData.timeline.map(item => item.tests);

    this.timelineChartData = [
      { data: createdData, label: 'Created Sessions' },
      { data: updatedData, label: 'Updated Sessions' },
      { data: testsData, label: 'Tests Generated', type: 'line' }
    ];
  }

  updateSessionStageChart(): void {
    if (!this.sessionStatsData?.stages.length) return;

    this.sessionStageChartLabels = this.sessionStatsData.stages.map(item => item.stage);
    this.sessionStageChartData = this.sessionStatsData.stages.map(item => item.count);
  }

  updateTokenUsageChart(): void {
    if (!this.tokenUsageData?.models_usage.length) return;

    this.tokenUsageChartLabels = this.tokenUsageData.models_usage.map(item => item.model);

    const promptTokens = this.tokenUsageData.models_usage.map(item => item.prompt_tokens);
    const completionTokens = this.tokenUsageData.models_usage.map(item => item.completion_tokens);

    this.tokenUsageChartData = [
      { data: promptTokens, label: 'Prompt Tokens' },
      { data: completionTokens, label: 'Completion Tokens' }
    ];
  }

  updateUserActivityChart(): void {
    if (!this.userActivityData?.user_activity.length) return;

    // Take top 10 users for readability
    const topUsers = this.userActivityData.user_activity.slice(0, 10);

    this.userActivityChartLabels = topUsers.map(item => {
      // Truncate long email addresses
      const email = item.email;
      return email.length > 20 ? email.substring(0, 17) + '...' : email;
    });

    const sessionCounts = topUsers.map(item => item.session_count);
    const testCounts = topUsers.map(item => item.test_count);

    this.userActivityChartData = [
      { data: sessionCounts, label: 'Sessions' },
      { data: testCounts, label: 'Tests' }
    ];
  }

  // Filter reset function
  resetFilters(): void {
    // Cancel all pending requests first
    this.cancelAllRequests();

    this.filterForm.reset({
      timeframe: 'daily',
      start_date: this.getDefaultStartDate(),
      end_date: this.getDefaultEndDate(),
      project_id: '',
      email: '',
      checkpoint_stage: ''
    });

    // Load data after reset - no need to call loadAllData()
    // as the valueChanges subscription will trigger it
  }
}
