<!-- Modified container with navigation buttons -->
<div class="container-fluid">
  <!-- New navigation header -->
  <div class="nav-header mb-3">
    <div class="nav-buttons">
      <button class="btn btn-light nav-btn" title="Configuration" (click)="navigateToConfiguration(projectId)">
        <i class="fa fa-gear"></i>
        <span>Configuration</span>
      </button>
      <button class="btn btn-light nav-btn" title="Knowledge Base" (click)="navigateToKnowledgeBase(projectId)">
        <i class="fa fa-book"></i>
        <span>Knowledge Base</span>
      </button>
      <button class="btn btn-light nav-btn" title="Dashboard Analytics">
        <i class="fa ft-bar-chart"></i>
        <span>Analytics</span>
      </button>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h2>You are in {{projectId}}'s Workspace</h2>
      <p>Continue from a previous session, upload a workspace file, or start a new story</p>
    </div>
    <div class="card-body">
      <!-- File Upload Section -->

      <app-test-file-upload
        [templates]="templates"
        [activeUploadMethod]="activeUploadMethod"
        [projectId]="projectId"
        (activeUploadMethodChange)="handleUploadMethodChange($event)"
        (templateSelected)="onTemplateSelected($event)">
      </app-test-file-upload>


      <div class="mt-4 text-center">
        <button class="btn btn-secondary" (click)="continueWithoutSession()">
          <i class="fa fa-pencil"></i> Start New Session
        </button>
      </div>

      <!-- Divider -->
      <hr class="my-4">

      <!-- Saved Sessions Section -->
      <div *ngIf="loading" class="text-center p-4">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p class="mt-2">Loading sessions...</p>
      </div>

      <div *ngIf="!loading && sessions.length === 0" class="empty-state">
        <i class="fa fa-folder-open"></i>
        <p>No saved sessions found for this project.</p>
        <button class="btn btn-primary mt-3" (click)="continueWithoutSession()">
          Start New Session
        </button>
      </div>

      <div *ngIf="!loading && sessions.length > 0" class="sessions-container">
        <h4 class="mb-3">Past Sessions</h4>
        <div class="table-responsive sessions-list">
          <table class="table table-hover">
            <thead>
            <tr>
              <th>Session Name</th>
              <th>Checkpoint</th>
              <th>Created At</th>
              <th>Last Modified</th>
              <th class="text-center">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let session of sessions"
                (click)="loadSession(session.sessionId)"
                data-session-id="{{session.sessionId}}"
                class="session-row">
              <td>{{ session.sessionName }}</td>
              <td>
                  <span class="badge"
                        [ngClass]="{
                          'bg-info': session.checkpointStage === 'scenarios',
                          'bg-warning': session.checkpointStage === 'acs',
                          'bg-success': session.checkpointStage === 'tests'
                        }">
                    {{ getCheckpointStageName(session.checkpointStage) }}
                  </span>
              </td>
              <td>{{ getFormattedDate(session.createdAt) }}</td>
              <td>{{ getFormattedDate(session.lastModified) }}</td>
              <td class="text-center">
                <button class="btn btn-sm btn-danger" (click)="deleteSession(session.sessionId, $event)">
                  <i class="fa fa-trash"></i> Remove
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
