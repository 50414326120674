<div class="test-list-config">
  <h2 class="config-title">Test List Column Configuration</h2>

  <div class="form-group" style="flex: 1; max-width: 60%;">
    <label for="templatemodal" class="gtt-label">Template</label>
    <div style="display: flex; align-items: center;">
      <ng-select id="templatemodal"
                 placeholder="Seleziona un template"
                 [items]="templateNames"
                 [(ngModel)]="selectedTemplate"
                 [clearable]="false"
                 (change)="handleTemplateChange()"
                 style="flex-grow: 1;">
      </ng-select>
      <button (click)="deleteSelectedTemplate()"
              class="btn btn-danger"
              style="margin-left: 10px;"
              [disabled]="!selectedTemplate">
        Delete Template
      </button>
      <ng-container *ngIf="selectedTemplate && !isDefaultTemplate(selectedTemplate); else defaultTemplateText">
        <button (click)="makeDefaultTemplate()"
                class="btn btn-primary"
                style="margin-left: 10px;">
          Make Default Template
        </button>
      </ng-container>
      <ng-template #defaultTemplateText>
        <span style="margin-left: 10px; color: green;">This is the default template</span>
      </ng-template>
    </div>
  </div>

  <ngx-spinner>
  </ngx-spinner>

  <form [formGroup]="columnsForm" class="config-form">
    <div class="template-info">
      <div class="form-group">
        <label for="templateName" class="form-label">Template Name:</label>
        <input id="templateName" [(ngModel)]="templateName" class="template-name-input" placeholder="Enter template name" [ngModelOptions]="{standalone: true}" />
      </div>
      <div class="form-group">
        <label for="templateDescription" class="form-label">Template Description:</label>
        <textarea id="templateDescription" [(ngModel)]="templateDescription" class="template-description-input" placeholder="Enter template description" [ngModelOptions]="{standalone: true}"></textarea>
      </div>
    </div>

    <div class="columns-list">
      <h3 class="columns-title">Columns</h3>

      <!-- Standard columns info message -->
      <div class="standard-columns-info">
        <p>The first two columns (ID and TYPE) are standard columns required for all templates and cannot be modified or reordered.</p>
      </div>

      <div formArrayName="columns" cdkDropList (cdkDropListDropped)="moveColumn($event)">
        <div *ngFor="let columnForm of columnsForm.get('columns')['controls']; let i = index" class="column-wrapper">
          <div [formGroupName]="i" class="column-container" [class.list]="columnForm.get('list').value" [class.readonly-column]="columnForm.get('readonly').value" [cdkDragDisabled]="i < standardColumns.length" cdkDrag>
            <div class="column-drag-area" cdkDragHandle>
              <!-- Show a lock icon for standard columns -->
              <div *ngIf="i < standardColumns.length" class="standard-column-indicator">
                <i class="fas fa-lock"></i> Standard Column (Cannot be modified)
              </div>

              <div class="drag-handle" *ngIf="i >= standardColumns.length">⋮</div>
              <div class="column-header">
                <button *ngIf="columnForm.get('subColumns').controls.length > 0" (click)="toggleExpand(columnForm)" class="btn-toggle mr-1" [class.expanded]="columnForm.get('expanded').value">
                  {{ columnForm.get('expanded').value ? '▼' : '▶' }}
                </button>
                <div class="form-group">
                  <label [for]="'columnName' + i" class="form-label">Column Name:</label>
                  <input [id]="'columnName' + i" formControlName="name" placeholder="Enter column name" class="column-name" [readonly]="columnForm.get('readonly').value" />
                </div>
                <div class="form-group checkbox-group">
                  <label [for]="'list' + i" class="checkbox-label">
                    <input [id]="'list' + i" type="checkbox" formControlName="list" [disabled]="columnForm.get('readonly').value" />
                    <span>List</span>
                  </label>
                </div>
                <!-- Hide add/remove buttons for standard columns -->
                <ng-container *ngIf="i >= standardColumns.length">
                  <button (click)="addSubColumn(i)" class="btn-add">+</button>
                  <button (click)="removeColumn(i)" class="btn-remove">×</button>
                </ng-container>
              </div>
              <div class="form-group">
                <label [for]="'columnDescription' + i" class="form-label">Column Description:</label>
                <textarea [id]="'columnDescription' + i" formControlName="description" placeholder="Enter column description" rows="2" class="column-description" [readonly]="columnForm.get('readonly').value"></textarea>
              </div>
            </div>

            <div *ngIf="columnForm.get('expanded').value" class="sub-columns" formArrayName="subColumns">
              <h4 class="sub-columns-title">Sub-columns</h4>
              <div *ngFor="let subColumnForm of columnForm.get('subColumns')['controls']; let j = index" [formGroupName]="j" class="sub-column-container">
                <div class="column-header">
                  <div class="form-group">
                    <label [for]="'subColumnName' + i + '-' + j" class="form-label">Sub-column Name:</label>
                    <input [id]="'subColumnName' + i + '-' + j" formControlName="name" placeholder="Enter sub-column name" class="column-name" />
                  </div>
                  <div class="form-group checkbox-group">
                    <label [for]="'subList' + i + '-' + j" class="checkbox-label">
                      <input [id]="'subList' + i + '-' + j" type="checkbox" formControlName="list" />
                      <span>List</span>
                    </label>
                  </div>
                  <button (click)="removeSubColumn(i, j)" class="btn-remove">×</button>
                </div>
                <div class="form-group">
                  <label [for]="'subColumnDescription' + i + '-' + j" class="form-label">Sub-column Description:</label>
                  <textarea [id]="'subColumnDescription' + i + '-' + j" formControlName="description" placeholder="Enter sub-column description" rows="2" class="column-description"></textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- Only show insert button after standard columns -->
          <button *ngIf="i >= standardColumns.length - 1" (click)="addColumn(i + 1)" class="btn-insert">Insert Column</button>
        </div>
      </div>
    </div>

    <div class="form-actions">
      <button (click)="addColumn()" class="btn-primary">Add Column</button>
      <button (click)="previewConfiguration()" class="btn-success">Preview Configuration</button>
      <button (click)="saveConfiguration()" class="btn-success">Save Configuration</button>
    </div>
  </form>

  <div class="preview-section">
    <h3 class="preview-title">Test List Preview</h3>
    <table class="preview-table">
      <thead>
      <tr>
        <th *ngFor="let column of previewColumns">{{ column.name }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td *ngFor="let column of previewColumns" [class.nested-column]="column.subColumns">
          <ng-container *ngIf="!column.subColumns">
            {{ column.value }}
          </ng-container>
          <ng-container *ngIf="column.subColumns">
            <button (click)="togglePreviewExpand(column)" class="btn-toggle-preview">
              {{ column.expanded ? 'Hide' : 'Show' }} {{ column.subColumns.length }} {{ column.name.toLowerCase() }}
            </button>
            <table *ngIf="column.expanded" class="nested-table">
              <thead>
              <tr>
                <th *ngFor="let subColumn of column.subColumns">{{ subColumn.name }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td *ngFor="let subColumn of column.subColumns">{{ subColumn.value }}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
