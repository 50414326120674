import { Component, OnInit } from '@angular/core';
import { StatoSessioneService } from '../../../shared/services/stato-sessione.service';
import { AuthService } from '../../../shared/auth/auth.service';
import { UserStoryService } from '../../../shared/templateGen/template-api.service';
import { combineLatest, Observable } from 'rxjs';
import { switchMap, map, take, catchError } from 'rxjs/operators';
import {KnowledgeBaseItem} from '../../../shared/templateGen/templateInterfaces';



@Component({
  selector: 'app-knowledge-base',
  templateUrl: './knowledge-base.component.html',
  styleUrls: ['./knowledge-base.component.scss']
})
export class KnowledgeBaseComponent implements OnInit {
  knowledgeBaseItems: KnowledgeBaseItem[] = [];
  loading = true;
  error: string | null = null;
  projectId = '';
  selectedItem: KnowledgeBaseItem | null = null;
  showDetailsModal = false;

  constructor(
    private sessioneService: StatoSessioneService,
    private authService: AuthService,
    private userStoryService: UserStoryService
  ) { }

  ngOnInit(): void {
    this.loadKnowledgeBaseItems();
  }

  loadKnowledgeBaseItems(): void {
    combineLatest([
      this.authService.email$.pipe(take(1)),
      this.sessioneService.projectId$.pipe(take(1))
    ]).pipe(
      switchMap(([email, projectId]) => {
        this.projectId = projectId;
        return this.userStoryService.getKnowledgeBaseItems(email, projectId);
      }),
      map(items => {
        // Remove duplicates based on kb_id
        const uniqueMap = new Map();
        items.forEach(item => {
          // If we haven't seen this kb_id or the item is newer than what we have
          if (!uniqueMap.has(item.kb_id) ||
            new Date(uniqueMap.get(item.kb_id).lastModified) < new Date(item.lastModified)) {
            uniqueMap.set(item.kb_id, item);
          }
        });
        return Array.from(uniqueMap.values());
      }),
      catchError(err => {
        this.error = 'Failed to load knowledge base items. Please try again.';
        this.loading = false;
        throw err;
      })
    ).subscribe({
      next: (items) => {
        this.knowledgeBaseItems = items;
        this.loading = false;
      },
      error: (err) => {
        console.error('Error loading knowledge base items:', err);
        this.loading = false;
        this.error = 'Failed to load knowledge base items. Please try again.';
      }
    });
  }

  getFormattedDate(dateString: string): string {
    if (!dateString) { return 'N/A'; }
    try {
      const date = new Date(dateString);
      return date.toLocaleString();
    } catch (e) {
      return dateString;
    }
  }

  deleteKnowledgeBaseItem(itemId: string, event: Event): void {
    event.stopPropagation(); // Prevent row click event
    if (confirm('Are you sure you want to delete this knowledge base item?')) {
      // Implement deletion logic here
      // You'll need to add a delete method to your service
      console.log('Deleting item:', itemId);
    }
  }

  editKnowledgeBaseItem(itemId: string, event: Event): void {
    event.stopPropagation(); // Prevent row click event
    // Implement edit logic here
    console.log('Editing item:', itemId);
  }

  viewKnowledgeBaseItem(item: KnowledgeBaseItem): void {
    this.selectedItem = item;
    this.showDetailsModal = true;
  }

  closeDetailsModal(): void {
    this.showDetailsModal = false;
    this.selectedItem = null;
  }

  addNewKnowledgeBaseItem(): void {
    // Implement add new item logic here
    console.log('Adding new knowledge base item');
  }

  navigateBack(): void {
    // Implement navigation back to the previous page
    window.history.back();
  }
}
