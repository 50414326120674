import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { StatoSessioneService } from 'app/shared/services/stato-sessione.service';
import { ApiService } from '../../../../shared/services/api.service';
import { SpinnerService } from '../../../../shared/services/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { DownloaderComponent } from '../downloader/downloader.component';
import Swal from 'sweetalert2';
import { takeUntil, distinctUntilChanged, filter, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-test-file-upload',
  templateUrl: './test-file-upload.component.html',
  styleUrls: ['./test-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestFileUploadComponent implements OnInit, OnDestroy {
  @ViewChild('workspaceInput') workspaceInput: ElementRef<HTMLInputElement>;
  @ViewChild('fileInputDocx') fileInputDocx: ElementRef<HTMLInputElement>;

  @Input() templates: any[] = [];
  @Input() activeUploadMethod: 'story' | 'workspace' | null = null;
  @Input() projectId = ''; // Added to receive project ID from parent

  @Output() activeUploadMethodChange = new EventEmitter<'story' | 'workspace' | null>();
  @Output() templateSelected = new EventEmitter<string>();

  private destroy$ = new Subject<void>();
  fileName = ''; // Added for story file name

  readonly fileName$ = this.sessioneService.fileName$.pipe(
    filter(filename => filename !== undefined),
    distinctUntilChanged(),
    takeUntil(this.destroy$)
  );

  showMismatchContent = false;
  mismatchColumns: string[] = [];

  constructor(
    private sessioneService: StatoSessioneService,
    private spinnerService: SpinnerService,
    private toastr: ToastrService,
    private downloaderComponent: DownloaderComponent,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService, // Added for uploading story files
    private router: Router // Added for navigation
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Story upload methods
  onStorySelected(): void {
    const input = this.fileInputDocx.nativeElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.fileName = file.name;
      this.sessioneService.updateFileName(file.name);
      this.uploadFileToActivity(file);
      this.activeUploadMethodChange.emit('story');
    } else {
      this.fileName = '';
    }
  }

  uploadFileToActivity(file: File): void {
    this.spinnerService.show();

    this.apiService.uploadFile(file)
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (response: any) => {
          // Handle the contents field that can be either string or string[]
          if (Array.isArray(response.contents)) {
            // If contents is an array of strings, join them
            this.sessioneService.updateRefinedStoryString(response.contents.join('\n'));
          } else if (typeof response.contents === 'string') {
            // If contents is already a single string
            this.sessioneService.updateRefinedStoryString(response.contents);
          } else {
            this.toastr.error('Unexpected response format');
          }

          // Make sure activeUploadMethod is properly updated
          // This ensures parent components know upload was successful
          this.activeUploadMethodChange.emit('story');

          // After file is uploaded, redirect to story component
          this.router.navigate(['/projects/story', this.projectId], {
            queryParams: {
              sessionLoaded: 'true',
              source: 'file'
            }
          });

          this.spinnerService.hide();
        },
        error: (error: any) => {
          console.error('There was an error uploading the file!', error);
          this.toastr.error('Failed to upload file');
          this.spinnerService.hide();
        },
        complete: () => {
          // Ensure spinner is hidden even if something unexpected happens
          this.spinnerService.hide();
        }
      });
  }

  // Workspace upload methods
  onWorkspaceSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.resetState();

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.sessioneService.updateFileName(file.name);
      this.handleFileUpload(file);
      this.activeUploadMethodChange.emit('workspace');

      // After workspace is uploaded, navigate to the story component
      // This creates consistency with the story upload flow
      setTimeout(() => {
        this.router.navigate(['/projects/story', this.projectId], {
          queryParams: {
            sessionLoaded: 'true',
            source: 'file'
          }
        });
      }, 500); // Short delay to ensure data is processed
    }
  }

  private async handleFileUpload(file: File): Promise<void> {
    this.spinnerService.show();

    try {
      const savedState = await this.downloaderComponent.loadSavedState(file);

      if (!savedState) {
        throw new Error('Invalid file format');
      }

      const matchingTemplate = this.findMatchingTemplate(savedState.selectedTemplateColumns);
      let success = false;

      if (!matchingTemplate) {
        // If no matching template, continue but:
        // 1. Skip loading test data
        // 2. Don't update the template selection (leave the current one)
        savedState.selectedTemplate = ''; // Clear template to prevent update
        success = await this.downloaderComponent.restoreState(savedState, false, false);
        this.handleNoMatchingTemplate(savedState.selectedTemplateColumns);

        if (success) {
          this.toastr.warning(
            'Workspace restored partially without test data due to template mismatch. The current template has been preserved.',
            'Partial Restore'
          );
        }
      } else {
        // If template matches, restore everything including test data and update the template
        success = await this.downloaderComponent.restoreState(savedState, true, true);

        if (success) {
          this.templateSelected.emit(matchingTemplate.templateName);
          this.toastr.success('Workspace restored successfully', 'Restore Complete');
        }
      }

      if (!success) {
        this.toastr.error('Failed to restore workspace', 'Restore Error');
      }
    } catch (error) {
      console.error('Error handling file upload:', error);
      this.toastr.error('Failed to process the uploaded file.', 'Upload Error');
    } finally {
      this.spinnerService.hide();
    }
  }

  private findMatchingTemplate(uploadedColumns: string[]): any {
    // If uploaded columns are undefined or empty, return null immediately
    if (!uploadedColumns || !Array.isArray(uploadedColumns) || uploadedColumns.length === 0) {
      return null;
    }

    const normalizeColumns = (cols: any[]) => cols.map(col => {
      if (typeof col === 'string') { return col.toLowerCase(); }
      return (col.name || String(col)).toLowerCase();
    });

    return this.templates.find(template =>
      normalizeColumns(template.columns).length === normalizeColumns(uploadedColumns).length &&
      normalizeColumns(template.columns).every(col =>
        normalizeColumns(uploadedColumns).includes(col))
    );
  }

  private handleNoMatchingTemplate(uploadedColumns: any[]): void {
    this.mismatchColumns = Array.isArray(uploadedColumns) ? uploadedColumns.map(col => {
      if (typeof col === 'string') { return col; }
      if (col.name) { return col.name; }
      return JSON.stringify(col);
    }) : [];

    this.toastr.warning(
      'There is no matching suitable template for the uploaded file. Test data will not be loaded.',
      'Template Mismatch'
    );
    this.showMismatchContent = true;
    this.cdr.markForCheck();  // Marks the view to be checked during the next change detection run
  }

  // Common clear file method (enhanced to handle both file types)
  clearFile(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This operation will cancel the current state. Make sure to download your workspace data to save your work.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.resetState();
        this.sessioneService.resetAll();

        // Clear the story file input if it exists
        if (this.fileInputDocx?.nativeElement) {
          this.fileInputDocx.nativeElement.value = '';
          this.fileName = '';
        }

        // Clear the workspace file input if it exists
        if (this.workspaceInput?.nativeElement) {
          this.workspaceInput.nativeElement.value = '';
        }

        this.activeUploadMethodChange.emit(null);

        Swal.fire(
          'Deleted!',
          'Your workspace has been deleted.',
          'success'
        );
      }
    });
  }

  private resetState(): void {
    this.showMismatchContent = false;
    this.mismatchColumns = [];
  }
}
