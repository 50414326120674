<!-- test-table.component.html -->
<ng-container *ngIf="(generatedTests$ | async) as generatedTests">
  <div class="row-container mt-2 mb-2" *ngIf="generatedTests.length > 0">
    <div class="left-content">
      <button class="btn btn-outline-dark mr-2" (click)="toggleViewMode()">
        {{ isCompactView ? 'Switch to Batch View' : 'Switch to Compact View' }}
      </button>

      <!-- Only show this button if at least one column has a subtable -->
      <button class="btn btn-secondary mr-2" (click)="toggleAllSubTables()" *ngIf="hasSubTables(generatedTests)">
        {{ allSubTablesExpanded ? 'Hide SubTables' : 'Show SubTables' }}
      </button>
    </div>
  </div>

  <div class="test-table-container" *ngIf="generatedTests.length > 0">
    <!-- Compact View -->
    <table #compactTable class="test-styled-table" *ngIf="isCompactView">
      <thead>
      <tr>
        <th *ngFor="let column of displayColumns; let i = index"
            class="test-table-header"
            [ngStyle]="getColumnStyle(column)">
          {{ column }}
          <div class="column-resize-handle" (mousedown)="startResize($event, i, compactTable)"></div>
        </th>
        <th class="test-table-header" [ngStyle]="getColumnStyle('Actions')">
          Actions
          <div class="column-resize-handle"></div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let test of generatedTests; trackBy: trackByFn">
        <td *ngFor="let column of displayColumns"
            class="test-table-cell"
            [class.test-table-id]="column === 'ID'"
            [class.expanded-cell]="isSubTable(test.data[column]) && allSubTablesExpanded">
          <ng-container *ngIf="!isSubTable(test.data[column]); else subTableTemplate">
            <ng-container *ngIf="!isArray(test.data[column]); else listTemplate">
              <!-- Only change the ID display here -->
              <span *ngIf="column === 'ID'">{{ test.metadata.sequentialId }}</span>
              <span *ngIf="column !== 'ID'" [ngStyle]="column === 'TYPE' ? getCellStyle(column) : {}">{{ test.data[column] }}</span>
            </ng-container>
            <ng-template #listTemplate>
              <ul class="cell-list">
                <li *ngFor="let item of test.data[column]">{{ item }}</li>
              </ul>
            </ng-template>
          </ng-container>
          <ng-template #subTableTemplate>
            <ng-container *ngIf="!allSubTablesExpanded">
              <span class="sub-table-summary">{{ getSubTableRowCount(test.data[column]) }} rows</span>
            </ng-container>
            <div [class.d-none]="!allSubTablesExpanded" class="sub-table-wrapper">
              <table class="sub-table">
                <thead>
                <tr>
                  <th *ngFor="let subColumn of getSubTableColumns(test.data[column])"
                      class="sub-table-header">
                    {{ subColumn }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of getSubTableRows(test.data[column])"
                    class="sub-table-row">
                  <td *ngFor="let subColumn of getSubTableColumns(test.data[column])"
                      class="sub-table-cell">
                    {{ row[subColumn] }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-template>
        </td>
        <td class="test-table-cell test-table-actions">
          <button (click)="openEditModal(test, editModal)"
                  class="test-table-btn test-table-btn-edit">Edit</button>
          <button (click)="deleteTest(test)"
                  class="test-table-btn test-table-btn-delete">Delete</button>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Batch View -->
    <div *ngIf="!isCompactView">
      <ng-container *ngIf="(getTestsByBatch() | async) as batches">
        <div *ngFor="let batch of batches" class="batch-container mb-4">
          <ng-container *ngIf="(getCriterionForBatch(batch.groupIndex, batch.criterionIndex) | async) as criterionData">
            <app-criterion
              *ngIf="criterionData.criterion"
              [criterion]="criterionData.criterion"
              [groupInfo]="criterionData.groupInfo"
              [groupIndex]="criterionData.groupIndex"
              [criterionIndex]="criterionData.criterionIndex"
              [compact]="true"
              class="mb-3">
            </app-criterion>
            <!-- Add this fallback message for missing criteria -->
            <div *ngIf="!criterionData.criterion" class="alert alert-secondary mb-3">
              <small>Tests without associated criterion</small>
            </div>
          </ng-container>

          <table [id]="'batch-table-' + batch.groupIndex + '-' + batch.criterionIndex"
                 #batchTable
                 class="test-styled-table batch-table">
            <thead>
            <tr>
              <th *ngFor="let column of displayColumns; let i = index"
                  class="test-table-header"
                  [ngStyle]="getColumnStyle(column)">
                {{ column }}
                <div class="column-resize-handle" (mousedown)="startResize($event, i, batchTable)"></div>
              </th>
              <th class="test-table-header" [ngStyle]="getColumnStyle('Actions')">
                Actions
                <div class="column-resize-handle"></div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let test of batch.tests; trackBy: trackByFn">
              <td *ngFor="let column of displayColumns"
                  class="test-table-cell"
                  [class.test-table-id]="column === 'ID'"
                  [class.expanded-cell]="isSubTable(test.data[column]) && allSubTablesExpanded">
                <ng-container *ngIf="!isSubTable(test.data[column]); else subTableTemplate">
                  <ng-container *ngIf="!isArray(test.data[column]); else listTemplate">
                    <!-- Only change the ID display here -->
                    <span *ngIf="column === 'ID'">{{ test.metadata.sequentialId }}</span>
                    <span *ngIf="column !== 'ID'" [ngStyle]="column === 'TYPE' ? getCellStyle(column) : {}">{{ test.data[column] }}</span>
                  </ng-container>
                  <ng-template #listTemplate>
                    <ul class="cell-list">
                      <li *ngFor="let item of test.data[column]">{{ item }}</li>
                    </ul>
                  </ng-template>
                </ng-container>
                <ng-template #subTableTemplate>
                  <ng-container *ngIf="!allSubTablesExpanded">
                    <span class="sub-table-summary">{{ getSubTableRowCount(test.data[column]) }} rows</span>
                  </ng-container>
                  <div [class.d-none]="!allSubTablesExpanded" class="sub-table-wrapper">
                    <table class="sub-table">
                      <thead>
                      <tr>
                        <th *ngFor="let subColumn of getSubTableColumns(test.data[column])"
                            class="sub-table-header">
                          {{ subColumn }}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let row of getSubTableRows(test.data[column])"
                          class="sub-table-row">
                        <td *ngFor="let subColumn of getSubTableColumns(test.data[column])"
                            class="sub-table-cell">
                          {{ row[subColumn] }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </td>
              <td class="test-table-cell test-table-actions">
                <button (click)="openEditModal(test, editModal)"
                        class="test-table-btn test-table-btn-edit">Edit</button>
                <button (click)="deleteTest(test)"
                        class="test-table-btn test-table-btn-delete">Delete</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- Edit Modal (unchanged) -->
<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="testModalLabel">Edit Test Case</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="selectedTest">
      <div *ngFor="let column of displayColumns" class="form-group">
        <ng-container [ngSwitch]="getFieldType(selectedTest.data[column], column)">
          <!-- Simple inputs -->
          <ng-container *ngSwitchCase="'simple'">
            <label [for]="column">{{ column }}</label>
            <!-- Special handling for ID field -->
            <input *ngIf="column === 'ID'"
                   type="text"
                   [id]="column"
                   class="form-control"
                   [value]="selectedTest.metadata.sequentialId || 0"
                   readonly>
            <input *ngIf="column !== 'ID'"
                   [type]="getInputType(selectedTest.data[column])"
                   [id]="column"
                   class="form-control"
                   [(ngModel)]="selectedTest.data[column]">
          </ng-container>

          <!-- Long text -->
          <ng-container *ngSwitchCase="'longtext'">
            <label [for]="column">{{ column }}</label>
            <textarea [id]="column"
                      class="form-control"
                      [(ngModel)]="selectedTest.data[column]"
                      rows="3">
            </textarea>
          </ng-container>

          <!-- Arrays -->
          <ng-container *ngSwitchCase="'array'">
            <label>{{ column }}</label>
            <div *ngFor="let item of selectedTest.data[column]; let i = index; trackBy: trackByIndex"
                 class="input-group mb-2">
              <input type="text"
                     class="form-control"
                     [(ngModel)]="selectedTest.data[column][i]">
              <div class="input-group-append">
                <button class="btn btn-outline-danger"
                        type="button"
                        (click)="removeArrayItem(column, i)">
                  Remove
                </button>
              </div>
            </div>
            <button class="btn btn-outline-primary"
                    (click)="addArrayItem(column)">
              Add Item
            </button>
          </ng-container>

          <!-- Sub-tables -->
          <ng-container *ngSwitchCase="'subtable'">
            <label>{{ column }}</label>
            <table class="table table-bordered">
              <thead>
              <tr>
                <th *ngFor="let header of getSubTableColumns(selectedTest.data[column])">
                  {{ header }}
                </th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let row of getSubTableRows(selectedTest.data[column]); let i = index; trackBy: trackByIndex">
                <td *ngFor="let header of getSubTableColumns(selectedTest.data[column])">
                  <input type="text"
                         class="form-control"
                         [(ngModel)]="row[header]">
                </td>
                <td>
                  <button class="btn btn-outline-danger"
                          (click)="removeSubTableRow(column, i)">
                    Remove
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <button class="btn btn-outline-primary"
                    (click)="addSubTableRow(column)">
              Add Row
            </button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-secondary"
            (click)="modal.dismiss('Close click')">
      Close
    </button>
    <button type="button"
            class="btn btn-primary"
            (click)="saveEditedTest()">
      Save changes
    </button>
  </div>
</ng-template>
