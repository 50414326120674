<div class="dashboard-container">
  <!-- Header and Filters -->
  <div class="dashboard-header">
    <h1>Analytics Dashboard</h1>

    <form [formGroup]="filterForm" class="filters-container">
      <div class="filter-row">
        <div class="filter-group">
          <label for="timeframe">Time Range</label>
          <select id="timeframe" formControlName="timeframe" class="form-control">
            <option *ngFor="let option of timeframeOptions" [value]="option.value">
              {{option.label}}
            </option>
          </select>
        </div>

        <div class="filter-group">
          <label for="start_date">Start Date</label>
          <input type="date" id="start_date" formControlName="start_date" class="form-control">
        </div>

        <div class="filter-group">
          <label for="end_date">End Date</label>
          <input type="date" id="end_date" formControlName="end_date" class="form-control">
        </div>

        <div class="filter-group">
          <label for="project_id">Project</label>
          <input type="text" id="project_id" formControlName="project_id" class="form-control" placeholder="Filter by project...">
        </div>

        <div class="filter-group">
          <label for="email">User</label>
          <input type="text" id="email" formControlName="email" class="form-control" placeholder="Filter by email...">
        </div>

        <div class="filter-group">
          <label for="checkpoint_stage">Stage</label>
          <select id="checkpoint_stage" formControlName="checkpoint_stage" class="form-control">
            <option value="">All Stages</option>
            <option value="scenarios">Scenarios</option>
            <option value="acs">Acceptance Criteria</option>
            <option value="tests">Tests</option>
          </select>
        </div>

        <div class="filter-actions">
          <button type="button" class="btn btn-secondary" (click)="resetFilters()">Reset Filters</button>
        </div>
      </div>
    </form>
  </div>

  <!-- KPI Summary Cards -->
  <div class="summary-cards">
    <!-- Sessions Card -->
    <div class="summary-card">
      <div class="card-icon">
        <i class="fa fa-file-alt"></i>
      </div>
      <div class="card-content">
        <h3>Sessions</h3>
        <div class="card-value" *ngIf="sessionStatsData">{{sessionStatsData.total_sessions}}</div>
        <div class="card-value" *ngIf="!sessionStatsData">-</div>
        <div class="card-subtitle" *ngIf="sessionStatsData">{{sessionStatsData.active_sessions}} active in last 7 days</div>
      </div>
    </div>

    <!-- Users Card -->
    <div class="summary-card">
      <div class="card-icon">
        <i class="fa fa-users"></i>
      </div>
      <div class="card-content">
        <h3>Active Users</h3>
        <div class="card-value" *ngIf="userActivityData">{{userActivityData.active_users}}</div>
        <div class="card-value" *ngIf="!userActivityData">-</div>
      </div>
    </div>

    <!-- Projects Card -->
    <div class="summary-card">
      <div class="card-icon">
        <i class="fa fa-project-diagram"></i>
      </div>
      <div class="card-content">
        <h3>Projects</h3>
        <div class="card-value" *ngIf="projectStatsData">{{projectStatsData.total_projects}}</div>
        <div class="card-value" *ngIf="!projectStatsData">-</div>
      </div>
    </div>

    <!-- Tokens Card -->
    <div class="summary-card">
      <div class="card-icon">
        <i class="fa fa-comment-dollar"></i>
      </div>
      <div class="card-content">
        <h3>Token Usage</h3>
        <div class="card-value" *ngIf="tokenUsageData">{{tokenUsageData.total_tokens | number}}</div>
        <div class="card-value" *ngIf="!tokenUsageData">-</div>
        <div class="card-subtitle" *ngIf="tokenUsageData">{{tokenUsageData.tokens_per_test | number:'1.0-1'}} tokens per test</div>
      </div>
    </div>
  </div>

  <!-- Charts -->
  <div class="dashboard-charts">
    <!-- Timeline Chart -->
    <div class="chart-container">
      <div class="chart-header">
        <h3>Session Activity Timeline</h3>
        <div class="chart-loading" *ngIf="loading.timeline">Loading...</div>
        <div class="chart-error" *ngIf="error.timeline">{{error.timeline}}</div>
      </div>
      <div class="chart-body">
        <canvas *ngIf="timelineChartData.length && timelineChartLabels.length"
                baseChart
                [datasets]="timelineChartData"
                [labels]="timelineChartLabels"
                [options]="timelineChartOptions"
                [colors]="timelineChartColors"
                [legend]="timelineChartLegend"
                [chartType]="timelineChartType">
        </canvas>
      </div>
    </div>

    <!-- Session Stage Chart -->
    <div class="chart-container">
      <div class="chart-header">
        <h3>Sessions by Stage</h3>
        <div class="chart-loading" *ngIf="loading.sessions">Loading...</div>
        <div class="chart-error" *ngIf="error.sessions">{{error.sessions}}</div>
      </div>
      <div class="chart-body">
        <canvas *ngIf="sessionStageChartData.length && sessionStageChartLabels.length"
                baseChart
                [data]="sessionStageChartData"
                [labels]="sessionStageChartLabels"
                [options]="sessionStageChartOptions"
                [colors]="sessionStageChartColors"
                [chartType]="sessionStageChartType">
        </canvas>
      </div>
    </div>

    <!-- User Activity Chart -->
    <div class="chart-container">
      <div class="chart-header">
        <h3>Top User Activity</h3>
        <div class="chart-loading" *ngIf="loading.users">Loading...</div>
        <div class="chart-error" *ngIf="error.users">{{error.users}}</div>
      </div>
      <div class="chart-body">
        <canvas *ngIf="userActivityChartData.length && userActivityChartLabels.length"
                baseChart
                [datasets]="userActivityChartData"
                [labels]="userActivityChartLabels"
                [options]="userActivityChartOptions"
                [colors]="userActivityChartColors"
                [legend]="userActivityChartLegend"
                [chartType]="userActivityChartType">
        </canvas>
      </div>
    </div>

    <!-- Token Usage Chart -->
    <div class="chart-container">
      <div class="chart-header">
        <h3>Token Usage by Model</h3>
        <div class="chart-loading" *ngIf="loading.tokens">Loading...</div>
        <div class="chart-error" *ngIf="error.tokens">{{error.tokens}}</div>
      </div>
      <div class="chart-body">
        <canvas *ngIf="tokenUsageChartData.length && tokenUsageChartLabels.length"
                baseChart
                [datasets]="tokenUsageChartData"
                [labels]="tokenUsageChartLabels"
                [options]="tokenUsageChartOptions"
                [colors]="tokenUsageChartColors"
                [legend]="tokenUsageChartLegend"
                [chartType]="tokenUsageChartType">
        </canvas>
      </div>
    </div>
  </div>

  <!-- Project Data Table -->
  <div class="data-table-container" *ngIf="projectStatsData">
    <h3>Project Details</h3>
    <div class="table-loading" *ngIf="loading.projects">Loading projects data...</div>
    <div class="table-error" *ngIf="error.projects">{{error.projects}}</div>

    <table class="data-table" *ngIf="projectStatsData.projects.length && !loading.projects">
      <thead>
      <tr>
        <th>Project Name</th>
        <th>Sessions</th>
        <th>Tests</th>
        <th>Users</th>
        <th>Last Activity</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let project of projectStatsData.projects">
        <td>{{project.project_name}}</td>
        <td>{{project.session_count}}</td>
        <td>{{project.test_count}}</td>
        <td>{{project.user_count}}</td>
        <td>{{project.last_updated | date:'medium'}}</td>
      </tr>
      </tbody>
    </table>

    <div class="no-data" *ngIf="projectStatsData.projects.length === 0 && !loading.projects">
      No project data available for the selected filters.
    </div>
  </div>
</div>
