import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StatoSessioneService } from 'app/shared/services/stato-sessione.service';
import { ApiService } from 'app/shared/services/api.service';
import { ProgettiService, Project, ProjectDetailsUpdate } from '../../../shared/services/progetti.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { Subject, from } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr'; // Assuming you have Toastr for better notifications

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  projectId: string;
  fileName: string;
  uploadCompleted = false;
  activeTab: 'general' | 'columns' | 'manage' = 'general';
  projectForm: FormGroup;
  selectedTemplateName: string;
  projectDetails: Project; // Changed from ProjectDetails to Project

  // Subject for managing subscriptions
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private sessioneService: StatoSessioneService,
    private apiService: ApiService,
    private progettiService: ProgettiService,
    private fb: FormBuilder,
    private spinnerService: SpinnerService,
    private toastr: ToastrService // Add Toastr service
  ) { }

  ngOnInit() {
    this.projectId = this.route.snapshot.params.project_id;
    this.initForm();
    this.loadProjectDetails();
  }

  onTabChange(event: {tab: 'general' | 'columns' | 'manage', templateName?: string}): void {
    this.activeTab = event.tab;
    if (event.templateName) {
      this.selectedTemplateName = event.templateName;
    }
  }

  initForm() {
    this.projectForm = this.fb.group({
      language: ['en', Validators.required], // Default to English
      projectDescription: ['', Validators.required],
      projectManager: ['', Validators.required]
    });
  }

  loadProjectDetails() {
    this.spinnerService.show();

    // Now using getProject instead of getProjectDetails
    this.progettiService.getProject(this.projectId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.spinnerService.hide())
      )
      .subscribe({
        next: (project: Project) => {
          this.projectDetails = project;
          if (project) {
            this.projectForm.patchValue({
              language: project.language || 'en',
              projectDescription: project.projectDescription || '',
              projectManager: project.projectManager || ''
            });
          }
        },
        error: (error) => {
          console.error('Error loading project details:', error);
          this.toastr.error('Failed to load project details');
        }
      });
  }

  clearFile(): void {
    this.fileName = '';
    this.sessioneService.updateFileName('');
  }

  onSave() {
    if (!this.projectForm.valid) {
      this.toastr.warning('Please fill all required fields correctly');
      this.markFormGroupTouched(this.projectForm);
      return;
    }

    this.spinnerService.show();

    const updatedDetails: ProjectDetailsUpdate = {
      language: this.projectForm.get('language').value,
      projectDescription: this.projectForm.get('projectDescription').value,
      projectManager: this.projectForm.get('projectManager').value
    };

    this.progettiService.updateProjectDetails(this.projectId, updatedDetails)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.spinnerService.hide())
      )
      .subscribe({
        next: (project) => {
          this.projectDetails = project;
          this.toastr.success('Configuration saved successfully!');
        },
        error: (error) => {
          console.error('Error updating project details:', error);
          this.toastr.error('Failed to save configuration');
        }
      });
  }

  // Helper method to mark all form controls as touched
  private markFormGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  ngOnDestroy() {
    // Clean up subscriptions when component is destroyed
    this.destroy$.next();
    this.destroy$.complete();
  }
}
