import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {take} from 'rxjs/operators';

export interface ColumnConfig {
  key: string;
  width: number;  // Stored as percentage
  minWidth?: number;  // Stored as viewport width (vw)
  maxWidth?: number;  // Stored as viewport width (vw)
  priority?: number;  // Used for responsive behavior
}

export interface ColumnWidthState {
  [key: string]: {
    currentWidth: number;  // Percentage of table width
    minWidth: number;     // In vw units
    maxWidth?: number;    // In vw units
    fixed?: boolean;      // If true, column cannot be resized
  };
}

// Define essential column names as constants
export const ESSENTIAL_COLUMNS = {
  ID: 'ID',
  TYPE: 'TYPE',
  ACTIONS: 'Actions'
};

@Injectable({
  providedIn: 'root'
})
export class ColumnResizeService {
  private readonly defaultColumnConfig: Partial<ColumnConfig> = {
    width: 10,        // 10% of table width
    minWidth: 5,      // 5vw
    maxWidth: 30,     // 30vw
    priority: 5       // Default priority
  };

  // Updated essential column configs with fixed property
  private readonly essentialColumnConfigs: { [key: string]: Partial<ColumnConfig> & { fixed: boolean } } = {
    [ESSENTIAL_COLUMNS.ACTIONS]: { width: 11, minWidth: 11, maxWidth: 11, priority: 1, fixed: true },
    [ESSENTIAL_COLUMNS.ID]: { width: 2.5, minWidth: 2.5, maxWidth: 2.5, priority: 2, fixed: true },
    [ESSENTIAL_COLUMNS.TYPE]: { width: 9, minWidth: 9, maxWidth: 9, priority: 2, fixed: true }
  };

  private currentResizeIndex = -1;
  private startX = 0;
  private startWidths: ColumnWidthState = {};
  private tableElement: HTMLElement | null = null;
  private tableWidth = 0;

  private columnStateSubject = new BehaviorSubject<ColumnWidthState>({});
  columnState$ = this.columnStateSubject.asObservable();

  constructor() {
    this.onResizeMove = this.onResizeMove.bind(this);
    this.onResizeEnd = this.onResizeEnd.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);

    // Listen for window resize events
    window.addEventListener('resize', this.handleWindowResize);
  }

  initializeColumns(columns: string[]): void {
    const totalColumns = columns.length;
    const baseWidth = 100 / totalColumns; // Equal distribution as starting point

    const state: ColumnWidthState = {};
    let totalAllocated = 0;

    // First pass: Allocate space for essential columns
    columns.forEach(column => {
      const essentialConfig = this.essentialColumnConfigs[column];
      if (essentialConfig) {
        state[column] = {
          currentWidth: essentialConfig.width || this.defaultColumnConfig.width!,
          minWidth: essentialConfig.minWidth || this.defaultColumnConfig.minWidth!,
          maxWidth: essentialConfig.maxWidth || this.defaultColumnConfig.maxWidth,
          fixed: essentialConfig.fixed // Add fixed property
        };
        totalAllocated += state[column].currentWidth;
      }
    });

    // Second pass: Distribute remaining space among regular columns
    const remainingColumns = columns.filter(column => !state[column]);
    const remainingWidth = 100 - totalAllocated;
    const regularColumnWidth = remainingWidth / remainingColumns.length;

    remainingColumns.forEach(column => {
      state[column] = {
        currentWidth: regularColumnWidth,
        minWidth: this.defaultColumnConfig.minWidth!,
        maxWidth: this.defaultColumnConfig.maxWidth,
        fixed: false
      };
    });

    this.columnStateSubject.next(state);
  }

  // Updated to handle the fixed property
  initializeColumnsWithCustomWidths(columns: string[], customWidths: { [key: string]: number }): void {
    let currentState: ColumnWidthState = {};

    // Get current state first (one-time synchronous operation)
    this.columnStateSubject.pipe(take(1)).subscribe(state => {
      currentState = state;
    });

    const state: ColumnWidthState = {};

    columns.forEach(column => {
      // If it's an essential column and already in current state, preserve its width
      if (this.isEssentialColumn(column) && currentState[column]) {
        state[column] = { ...currentState[column], fixed: true };
      } else {
        // For non-essential columns or new essential columns, use custom width or default
        const width = customWidths[column] || this.defaultColumnConfig.width!;
        const essentialConfig = this.essentialColumnConfigs[column];

        state[column] = {
          currentWidth: width,
          minWidth: (essentialConfig?.minWidth || this.defaultColumnConfig.minWidth)!,
          maxWidth: essentialConfig?.maxWidth || this.defaultColumnConfig.maxWidth,
          fixed: essentialConfig?.fixed || false // Default to false if not essential
        };
      }
    });

    this.columnStateSubject.next(state);
  }

  startResize(event: MouseEvent, columnIndex: number, tableElement: HTMLElement): void {
    this.currentResizeIndex = columnIndex;
    this.startX = event.pageX;
    this.tableElement = tableElement;
    this.tableWidth = tableElement.getBoundingClientRect().width;
    this.startWidths = { ...this.columnStateSubject.value };

    // Check if the column is fixed before allowing resize
    const columns = Object.keys(this.startWidths);
    const currentColumn = columns[columnIndex];
    const nextColumn = columns[columnIndex + 1];

    if (!nextColumn) return;

    // Don't start resize if either column is fixed
    if (this.startWidths[currentColumn]?.fixed || this.startWidths[nextColumn]?.fixed) {
      this.currentResizeIndex = -1;
      return;
    }

    tableElement.classList.add('resizing');

    document.addEventListener('mousemove', this.onResizeMove);
    document.addEventListener('mouseup', this.onResizeEnd);

    document.body.style.cursor = 'col-resize';
    document.body.style.userSelect = 'none';

    event.preventDefault();
  }

  private onResizeMove(event: MouseEvent): void {
    if (this.currentResizeIndex === -1 || !this.tableElement) return;

    const deltaX = event.pageX - this.startX;
    const deltaPercentage = (deltaX / this.tableWidth) * 100;

    const currentState = { ...this.startWidths };
    const columns = Object.keys(currentState);
    const currentColumn = columns[this.currentResizeIndex];
    const nextColumn = columns[this.currentResizeIndex + 1];

    if (!nextColumn) return;

    // Don't allow resizing of fixed columns
    if (currentState[currentColumn]?.fixed || currentState[nextColumn]?.fixed) return;

    // Calculate new widths as percentages
    let newCurrentWidth = this.startWidths[currentColumn].currentWidth + deltaPercentage;
    let newNextWidth = this.startWidths[nextColumn].currentWidth - deltaPercentage;

    // Apply constraints
    const currentVw = this.percentageToVw(newCurrentWidth);
    const nextVw = this.percentageToVw(newNextWidth);

    const currentMinVw = currentState[currentColumn].minWidth;
    const nextMinVw = currentState[nextColumn].minWidth;
    const currentMaxVw = currentState[currentColumn].maxWidth;
    const nextMaxVw = currentState[nextColumn].maxWidth;

    // Ensure minimum and maximum widths are respected
    if (currentVw < currentMinVw || nextVw < nextMinVw ||
      (currentMaxVw && currentVw > currentMaxVw) ||
      (nextMaxVw && nextVw > nextMaxVw)) {
      return;
    }

    // Update widths
    currentState[currentColumn].currentWidth = newCurrentWidth;
    currentState[nextColumn].currentWidth = newNextWidth;

    this.columnStateSubject.next(currentState);
  }

  private onResizeEnd(): void {
    if (!this.tableElement) return;

    this.currentResizeIndex = -1;
    this.tableElement.classList.remove('resizing');
    this.tableElement = null;
    this.startWidths = {};

    document.removeEventListener('mousemove', this.onResizeMove);
    document.removeEventListener('mouseup', this.onResizeEnd);

    document.body.style.cursor = '';
    document.body.style.userSelect = '';
  }

  private handleWindowResize(): void {
    // Recalculate column widths based on new viewport size
    const currentState = this.columnStateSubject.value;
    const updatedState = { ...currentState };

    // Adjust column widths if they exceed min/max constraints with new viewport size
    Object.keys(updatedState).forEach(column => {
      const vw = this.percentageToVw(updatedState[column].currentWidth);
      if (vw < updatedState[column].minWidth) {
        updatedState[column].currentWidth = this.vwToPercentage(updatedState[column].minWidth);
      } else if (updatedState[column].maxWidth && vw > updatedState[column].maxWidth) {
        updatedState[column].currentWidth = this.vwToPercentage(updatedState[column].maxWidth);
      }
    });

    this.columnStateSubject.next(updatedState);
  }

  private percentageToVw(percentage: number): number {
    return (percentage / 100) * (window.innerWidth / 100);
  }

  private vwToPercentage(vw: number): number {
    return (vw * 100) / (window.innerWidth / 100);
  }

  getColumnStyle(column: string): object {
    const state = this.columnStateSubject.value;
    const columnState = state[column];

    if (!columnState) return {};

    // Special handling for Actions column to prevent wrapping
    if (column === ESSENTIAL_COLUMNS.ACTIONS) {
      return {
        width: `${columnState.currentWidth}%`,
        minWidth: '100px', // Enough for buttons without being too wide
        maxWidth: `${columnState.currentWidth}%`,
        flex: '0 0 auto', // Prevent flex growth/shrink
        whiteSpace: 'nowrap' // Prevent text wrapping
      };
    }

    // Special consistent styling for other essential columns
    if (this.isEssentialColumn(column)) {
      const width = `${columnState.currentWidth}%`;
      return {
        width: width,
        minWidth: width,
        maxWidth: width,
        flex: '0 0 auto' // Prevent flex growth/shrink
      };
    }

    return {
      width: `${columnState.currentWidth}%`,
      minWidth: `${columnState.minWidth}vw`,
      maxWidth: columnState.maxWidth ? `${columnState.maxWidth}vw` : 'none'
    };
  }

  // Check if a column is an essential column
  isEssentialColumn(column: string): boolean {
    return Object.values(ESSENTIAL_COLUMNS).includes(column);
  }

  // Helper function to get recommended width for subtable columns
  getRecommendedSubtableWidth(): number {
    return 25; // Default 25% for subtable columns
  }

  // New method to maximize a specific column's width
  maximizeColumnWidth(columnName: string): void {
    const currentState = { ...this.columnStateSubject.value };

    // Calculate total width of fixed columns
    let fixedColumnsWidth = 0;
    Object.keys(currentState).forEach(col => {
      if (col !== columnName && this.isEssentialColumn(col)) {
        fixedColumnsWidth += currentState[col].currentWidth;
      }
    });

    // Give the target column all remaining width
    const remainingWidth = 100 - fixedColumnsWidth;
    currentState[columnName].currentWidth = remainingWidth;

    // Set all other non-essential columns to minimum width
    Object.keys(currentState).forEach(col => {
      if (col !== columnName && !this.isEssentialColumn(col)) {
        // Set to 0 to hide them effectively
        currentState[col].currentWidth = 0;
      }
    });

    this.columnStateSubject.next(currentState);
  }

  destroy(): void {
    window.removeEventListener('resize', this.handleWindowResize);
  }
}
