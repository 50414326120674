// Define interfaces for the API response types
import {TokenCount} from '../templateGen/templateInterfaces';

export interface BaseFileResponse {
  filename: string;
  user_id: string;
  contents: string | string[];
}

export interface ImageAnalysisResponse extends BaseFileResponse {
  token_usage: TokenCount;
}

// Type guard to check if a response is an image analysis response
export function isImageAnalysisResponse(response: any): response is ImageAnalysisResponse {
  return response && typeof response === 'object' && 'token_usage' in response;
}
