// template.component.ts
import {Component, OnInit, OnDestroy, ChangeDetectorRef, Output, EventEmitter, ViewChild} from '@angular/core';
import {
  AcceptanceCriterion, Ambiguity, AnalysisContent, AnalysisResult, DataParametersResponse, MacroAnalysisResult,
  MacroScenario,
  ScenarioResponse, StoryDescriptionResponse, SupportiveAnalysisContent, TemplateGroupInfo
} from '../../../shared/templateGen/templateInterfaces';
import { UserStoryService } from '../../../shared/templateGen/template-api.service';
import Swal from 'sweetalert2';
import {catchError, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import { StatoSessioneService } from '../../../shared/services/stato-sessione.service';
import {EMPTY, Subject, Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SupportFile} from '../story/story.component';
import {TAG_DESCRIPTIONS} from './tag/tag-descriptions';
import {SessionPersistenceService} from '../session-manager/session-persistence.service';
import {ToastrService} from 'ngx-toastr';


export interface MacroScenarioWithSelection extends MacroScenario {
  selected: boolean;
  isEditing?: boolean;
  hasAmbiguities?: boolean;  // New field to track if scenario has ambiguities
}

export interface CriteriaGroup {
  group_info: TemplateGroupInfo;
  acceptance_criteria: AcceptanceCriterion[];  // Changed from 'criteria' to match ScenarioResponse
}

export interface TemplateProgress {
  groupId: string;
  status: 'pending' | 'loading' | 'complete' | 'error';
  criteria: AcceptanceCriterion[];
  error?: string;
}

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit, OnDestroy {
  @ViewChild('testDataEditModal') testDataEditModal: any;
  @ViewChild('newCriterionModal') newCriterionModal: any;
  @Output() generateTestsEvent = new EventEmitter<string>();

  private destroy$ = new Subject<void>();
  private isSubmitting = false;

  isEditingTestData = false;
  editingTestData = {
    fieldName: '',
    value: '',
    index: -1,
    currentCriterion: null as AcceptanceCriterion | null
  };
  refinedStoryString = '';
  selectedModel = '';
  acceptanceCriteria: AcceptanceCriterion[] = [];
  isGeneratingTemplates = false;
  supportFiles: SupportFile[] = [];
  // Data Parameters Analysis results
  dataParameters?: DataParametersResponse;
  // Analysis results
  isDebugVisible = false;
  coreAnalysis: AnalysisContent | null = null;
  supportiveAnalysis: SupportiveAnalysisContent | null = null;
  generateAnalysisSubscription: Subscription | null = null;
  generateScenariosSubscription: Subscription | null = null;

  macroScenariosWithSelection: MacroScenarioWithSelection[] = [];

  error = '';
  isLoading = false;
  isLoadingMacros = false;
  storyDescription?: StoryDescriptionResponse;
  // Add new loading state
  projectLanguage = 'en';
  templateProgress: Map<string, TemplateProgress> = new Map();
  criteriaGroups: CriteriaGroup[] = [];

  newScenario = '';
  isAddingNew = false;
// properties for editing
  currentCriterion: AcceptanceCriterion | null = null;
  editingCriterion: AcceptanceCriterion | null = null;
  editingGroupInfo: TemplateGroupInfo | null = null;
  currentGroupInfo: TemplateGroupInfo | null = null;
  currentGroupIndex: number | null = null;

  availableDataParamFields: string[] = [];
  selectedDataParamField = '';
  isEditingTags = false;

  private newGroupInfo: TemplateGroupInfo | null = null;
  private subscriptions: Subscription = new Subscription();
  private generateTemplateSubscription?: Subscription;
  private generateMacroScenariosSubscription?: Subscription;
  private generateEdgeCaseSubscription?: Subscription;

  constructor(
    private userStoryService: UserStoryService,
    private sessioneService: StatoSessioneService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private sessionService: SessionPersistenceService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.sessioneService.refinedStoryString$.pipe(
        takeUntil(this.destroy$),
        tap(refinedStoryString => this.refinedStoryString = refinedStoryString),
        catchError(error => {
          console.error('Error in refinedStoryString subscription:', error);
          return [];
        })
      ).subscribe()
    );

    this.subscriptions.add(
      this.sessioneService.selectedModel$.pipe(
        takeUntil(this.destroy$),
        tap(model => this.selectedModel = model),
        catchError(error => {
          console.error('Error in selectedModel subscription:', error);
          return [];
        })
      ).subscribe()
    );

    this.subscriptions.add(
      this.sessioneService.projectLanguage$.pipe(
        takeUntil(this.destroy$),
        tap(lang => this.projectLanguage = lang),
        catchError(error => {
          console.error('Error in projectLanguage subscription:', error);
          return [];
        })
      ).subscribe()
    );

    this.subscriptions.add(
      this.sessioneService.criteriaGroups$.pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          console.error('Error in criteriaGroups subscription:', error);
          return [];
        })
      ).subscribe(groups => {
        this.criteriaGroups = groups;
      })
    );

    this.subscriptions.add(
      this.sessioneService.dataParameters$.pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          console.error('Error in dataParameters subscription:', error);
          return [];
        })
      ).subscribe(dataParams => {
        this.dataParameters = dataParams;
      })
    );

    this.subscriptions.add(
      this.sessioneService.storyDescription$.pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          console.error('Error in storyDescription subscription:', error);
          return [];
        })
      ).subscribe(description => {
        this.storyDescription = description;
      })
    );

    this.subscriptions.add(
      this.sessioneService.macroScenarios$.pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          console.error('Error in macroScenarios subscription:', error);
          return [];
        })
      ).subscribe(scenarios => {
        this.macroScenariosWithSelection = scenarios;
      })
    );

    this.subscriptions.add(
      this.sessioneService.supportFiles$.pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          console.error('Error in supportFiles subscription:', error);
          return [];
        })
      ).subscribe(files => {
        this.supportFiles = files;
        if (files.length > 0) {
          console.log('Support files with purposes:',
            files.map(f => ({
              name: f.name,
              size: f.size,
              purpose: (f as SupportFile).purpose
            }))
          );
        }
      })
    );

    this.subscriptions.add(
      this.sessioneService.coreAnalysis$.pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          console.error('Error in coreAnalysis subscription:', error);
          return [];
        })
      ).subscribe(analysis => {
        this.coreAnalysis = analysis;
      })
    );

    this.subscriptions.add(
      this.sessioneService.supportiveAnalysis$.pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          console.error('Error in supportiveAnalysis subscription:', error);
          return [];
        })
      ).subscribe(analysis => {
        this.supportiveAnalysis = analysis;
      })
    );


  }

  ngOnDestroy(): void {
    // Cleanup main subscription collection
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }

    // Cleanup individual subscriptions that might not be in the collection
    this.generateTemplateSubscription?.unsubscribe();
    this.generateMacroScenariosSubscription?.unsubscribe();
    this.generateEdgeCaseSubscription?.unsubscribe();
    this.generateAnalysisSubscription?.unsubscribe();
    this.generateScenariosSubscription?.unsubscribe();

    // Signal all takeUntil operators to complete
    this.destroy$.next();
    this.destroy$.complete();

    // Call dedicated cleanup method
    this.cleanupMacroScenariosSubscriptions();

    // Close any open modals
    this.modalService.dismissAll();
  }

  // Method to save the session at the analysis checkpoint
  private saveAnalysisCheckpoint(): void {
    // Auto-generate a session name if one doesn't exist
    let sessionName = this.sessioneService.fileNameSubject.getValue();
    if (!sessionName) {
      const projectId = this.sessioneService.projectId.getValue();
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      sessionName = `Project-${projectId}-${timestamp}`;
    } else {
      // Remove file extension if present
      sessionName = sessionName.replace(/\.[^/.]+$/, '');
    }

    console.log('Saving analysis checkpoint with name:', sessionName);

    // Use the checkpoint-aware save method with 'scenarios' stage
    // Since there's no specific 'analysis' stage, we'll use 'scenarios' as it's the earliest stage
    this.sessionService.saveCurrentSession(sessionName, 'scenarios')
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          console.log('Analysis checkpoint saved successfully:', response);
          this.toastr.success('Progress saved at analysis stage');
        },
        error: (error) => {
          console.error('Error saving analysis checkpoint:', error);
          // Optional: Show an error notification, but make it non-disruptive
          this.toastr.error('Failed to save checkpoint', '', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right'
          });
        }
      });
  }

  /**
   * Generate analysis only
   */
  generateAnalysisOnly() {
    if (!this.refinedStoryString.trim()) {
      this.error = 'Please enter a user story';
      return;
    }

    // Cleanup any existing subscriptions
    this.generateAnalysisSubscription?.unsubscribe();
    this.generateAnalysisSubscription = null;

    this.isLoadingMacros = true;
    this.error = '';
    this.coreAnalysis = null;
    this.supportiveAnalysis = null;

    // Get support files from the service
    const supportFiles = this.supportFiles;

    this.generateAnalysisSubscription = this.userStoryService
      .generateAnalysis(
        this.refinedStoryString,
        this.selectedModel,
        this.projectLanguage,
        supportFiles.length > 0 ? supportFiles : undefined
      )
      .pipe(
        take(1), // Ensure we only take one response
        takeUntil(this.destroy$) // Cleanup on component destroy
      )
      .subscribe({
        next: (analysisResult: AnalysisResult) => {
          // Store analysis results in the component
          this.coreAnalysis = analysisResult.core_analysis;
          this.supportiveAnalysis = analysisResult.supportive_analysis || null;

          // Process ambiguities from analysis
          if (analysisResult.ambiguities && analysisResult.ambiguities.length > 0) {
            // Store ambiguities in the session service
            this.sessioneService.updateAmbiguities(analysisResult.ambiguities);
          }

          // Store in the session service
          this.sessioneService.updateAnalysisData(
            this.coreAnalysis,
            this.supportiveAnalysis
          );

          console.log('Analysis generated:',
            `Core scenario: ${this.coreAnalysis?.scenario_analysis.substring(0, 50)}...`,
            `Core config: ${this.coreAnalysis?.config_testdata_analysis.substring(0, 50)}...`,
            `Supportive: ${this.supportiveAnalysis ? 'Available' : 'None'}`,
            `Ambiguities: ${analysisResult.ambiguities ? analysisResult.ambiguities.length : 0}`);

          this.isLoadingMacros = false;

          // Save the session after analysis generation
          this.saveAnalysisCheckpoint();

          // Show ambiguities notification
          Swal.fire({
            title: 'Ambiguities Detected',
            text: 'Some ambiguities were identified that might need clarification.',
            icon: 'info',
            confirmButtonText: 'Review Ambiguities'
          }).then((result) => {
            if (result.isConfirmed) {
              // Existing code for ambiguities handling
            }
          });

        },
        error: (error) => {
          this.error = `Analysis error: ${error.message}`;
          this.isLoadingMacros = false;
          console.error('Error generating analysis:', error);

          // Cleanup subscription on error
          this.generateAnalysisSubscription = null;
        },
        complete: () => {
          // Cleanup subscription on completion
          this.generateAnalysisSubscription = null;
        }
      });
  }

  /**
   * Generate macro scenarios
   */
  generateMacroScenarios2() {
    if (!this.refinedStoryString.trim()) {
      this.error = 'Please enter a user story';
      return;
    }

    if (!this.coreAnalysis) {
      this.error = 'You need to generate the analysis first';
      return;
    }

    // Cleanup any existing macro scenarios subscription
    this.generateScenariosSubscription?.unsubscribe();
    this.generateScenariosSubscription = null;

    this.isLoadingMacros = true;
    this.error = '';
    this.macroScenariosWithSelection = [];

    // Get support files from the service
    const supportFiles = this.supportFiles;

    this.generateScenariosSubscription = this.userStoryService
      .generateMacroScenarios(
        this.refinedStoryString,
        this.selectedModel,
        this.projectLanguage,
        this.sessioneService.getAgenticMode(),
        this.coreAnalysis,
        this.supportiveAnalysis
      )
      .pipe(
        take(1), // Ensure we only take one response
        takeUntil(this.destroy$) // Cleanup on component destroy
      )
      .subscribe({
        next: (result: MacroAnalysisResult) => {
          // Process scenarios
          const scenariosWithSelection = result.scenarios.map(scenario => ({
            ...scenario,
            selected: true,
          }));

          // Store scenarios
          this.macroScenariosWithSelection = scenariosWithSelection;

          // Update service state
          this.sessioneService.updateMacroScenarios(scenariosWithSelection);

          this.isLoadingMacros = false;

          // Log successful processing of support files if any were provided
          if (supportFiles.length > 0) {
            console.log(`Processed macro scenarios with ${supportFiles.length} support files:`,
              supportFiles.map(f => f.name));
          }

          // CHECKPOINT: Save the session after scenario generation
          this.saveScenarioCheckpoint();

        },
        error: (error) => {
          this.error = `Scenario generation error: ${error.message}`;
          this.isLoadingMacros = false;
          console.error('Error generating macro scenarios:', error);

          // Cleanup subscription on error
          this.generateScenariosSubscription = null;
        },
        complete: () => {
          // Cleanup subscription on completion
          this.generateScenariosSubscription = null;
        }
      });
  }

  // Method to save the session at the scenarios checkpoint
  private saveScenarioCheckpoint(): void {
    // Auto-generate a session name if one doesn't exist
    let sessionName = this.sessioneService.fileNameSubject.getValue();
    if (!sessionName) {
      const projectId = this.sessioneService.projectId.getValue();
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      sessionName = `Project-${projectId}-${timestamp}`;
    } else {
      // Remove file extension if present
      sessionName = sessionName.replace(/\.[^/.]+$/, '');
    }

    // Make sure ambiguities are also stored
    // This is necessary because in the code you provided, ambiguities are set
    // in the component but not necessarily synchronized to the service yet

    console.log('Saving scenario checkpoint with name:', sessionName);

    // Use the checkpoint-aware save method with 'scenarios' stage
    // This will update the same session if it was already created
    this.sessionService.saveCurrentSession(sessionName, 'scenarios')
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          console.log('Scenario checkpoint saved successfully:', response);
          this.toastr.success('Progress saved at scenarios stage');
        },
        error: (error) => {
          console.error('Error saving scenario checkpoint:', error);
          // Optional: Show an error notification, but make it non-disruptive
          this.toastr.error('Failed to save checkpoint', '', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right'
          });
        }
      });
  }

// Helper method to clean up all generateMacroScenarios subscriptions
  private cleanupMacroScenariosSubscriptions() {
    if (this.generateAnalysisSubscription) {
      this.generateAnalysisSubscription.unsubscribe();
      this.generateAnalysisSubscription = null;
    }

    if (this.generateScenariosSubscription) {
      this.generateScenariosSubscription.unsubscribe();
      this.generateScenariosSubscription = null;
    }

    if (this.generateMacroScenariosSubscription) {
      this.generateMacroScenariosSubscription.unsubscribe();
      this.generateMacroScenariosSubscription = null;
    }
  }

  /**
   * Generate additional edge case scenarios not covered by existing scenarios
   * This method will append the new edge cases to the existing scenario list
   */
  generateEdgeCaseScenarios() {
    if (!this.refinedStoryString.trim()) {
      this.error = 'Please enter a user story';
      return;
    }

    if (!this.coreAnalysis) {
      this.error = 'Please generate macro scenarios first';
      return;
    }

    if (this.macroScenariosWithSelection.length === 0) {
      this.error = 'No existing scenarios found';
      return;
    }

    // Ask user for optional instructions
    Swal.fire({
      title: 'Generate Edge Cases',
      text: 'Would you like to provide specific instructions for edge case generation?',
      input: 'textarea',
      inputPlaceholder: 'Optional: Enter any specific focus areas, constraints, or requirements for edge cases...',
      inputAttributes: {
        'aria-label': 'Optional instructions'
      },
      showCancelButton: true,
      confirmButtonText: 'Generate Edge Cases',
      cancelButtonText: 'Cancel',
      showClass: {
        popup: 'swal2-show',
        backdrop: 'swal2-backdrop-show',
        icon: 'swal2-icon-show'
      },
      hideClass: {
        popup: 'swal2-hide',
        backdrop: 'swal2-backdrop-hide',
        icon: 'swal2-icon-hide'
      }
    }).then((result) => {
      if (result.isDismissed) {
        // User canceled the operation
        return;
      }

      // User confirmed, proceed with edge case generation
      const userInstructions = result.value ? result.value.trim() : '';
      this.processEdgeCaseGeneration(userInstructions);
    });
  }

  private processEdgeCaseGeneration(userInstructions: string = '') {
    // Format existing scenarios and ambiguities as JSON strings
    const existingScenarios = JSON.stringify({
      scenarios: this.macroScenariosWithSelection.map(scenario => ({
        id: scenario.id,
        description: scenario.description,
        source: scenario.source
      }))
    });

    // Show loading indicator
    Swal.fire({
      title: 'Generating Edge Cases',
      text: 'Please wait while we identify additional edge case scenarios...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    // Clear any existing subscriptions
    this.generateEdgeCaseSubscription?.unsubscribe();

    // Call the service to generate edge case scenarios
    this.generateEdgeCaseSubscription = this.userStoryService
      .generateEdgeCaseScenarios(
        this.refinedStoryString,
        this.selectedModel,
        this.projectLanguage,
        this.coreAnalysis,
        existingScenarios,
        '',
        this.supportiveAnalysis || undefined,
        userInstructions // Pass the new optional parameter
      )
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (result: MacroAnalysisResult) => {
          // Close loading dialog
          Swal.close();

          // If no new scenarios or ambiguities were found
          if (result.scenarios.length === 0) {
            Swal.fire({
              title: 'No Additional Edge Cases',
              text: 'No additional edge case scenarios or ambiguities were identified.',
              icon: 'info'
            });
            return;
          }

          // Process new scenarios
          const processedNewScenarios = result.scenarios.map(scenario => ({
            ...scenario,
            selected: true,
          }));

          // Add new scenarios and ambiguities to existing collections
          const updatedScenarios = [...this.macroScenariosWithSelection, ...processedNewScenarios];

          // Update component state
          this.macroScenariosWithSelection = updatedScenarios;

          // Update service state
          this.sessioneService.updateMacroScenarios(updatedScenarios);
        },
        error: (error) => {
          // Close loading dialog
          Swal.close();

          console.error('Error generating edge case scenarios:', error);
          this.error = `Error generating edge cases: ${error.message}`;

          Swal.fire({
            title: 'Error',
            text: this.error,
            icon: 'error'
          });
        }
      });

    // Add subscription to the subscriptions array for proper cleanup
    this.subscriptions.add(this.generateEdgeCaseSubscription);
  }

  generateAllTemplates() {
    const selectedScenarios = this.macroScenariosWithSelection
      .filter(scenario => scenario.selected);

    if (!selectedScenarios.length) {
      this.error = 'Please select at least one scenario';
      return;
    }

    this.isGeneratingTemplates = true;
    // this.acceptanceCriteria = [];
    // this.criteriaGroups = [];
    this.storyDescription = undefined;
    this.error = '';
    this.templateProgress.clear();

    const scenariosString = selectedScenarios
      .map(scenario => {
        let scenarioText = `${scenario.id}: ${scenario.description}`;
        if (scenario.enrichment_justification) {
          scenarioText += `\nJustification: ${scenario.enrichment_justification}`;
        }
        return scenarioText;
      })
      .join('\n\n');

    this.templateProgress.set('main', {
      groupId: 'main',
      status: 'pending',
      criteria: []
    });

    // Unsubscribe from previous subscriptions
    this.generateTemplateSubscription?.unsubscribe();

    // Create both observables with proper operators
    const descriptionObservable = this.userStoryService
      .generateStoryDescription(
        this.refinedStoryString,
        this.selectedModel,
        this.projectLanguage
      ).pipe(
        take(1),
        takeUntil(this.destroy$)
      );

    // Add data parameters observable
    const dataParametersObservable = this.userStoryService
      .generateDataParameters(
        this.refinedStoryString,
        this.selectedModel,
        this.projectLanguage,
        scenariosString,
        this.coreAnalysis?.config_testdata_analysis,
        this.supportiveAnalysis?.config_testdata_enrichments
      ).pipe(
        take(1),
        takeUntil(this.destroy$)
      );

    // Store description subscription
    this.generateTemplateSubscription = descriptionObservable.subscribe({
      next: (response: StoryDescriptionResponse) => {
        console.log('Received description response:', response);
        this.storyDescription = response;
        this.sessioneService.updateStoryDescription(response);
        this.cd.detectChanges();
      },
      error: (error) => {
        console.error('Error in description generation:', error);
        this.isGeneratingTemplates = false;
        this.error = `Error generating description: ${error.message}`;
        this.cd.detectChanges();
      }
    });

    // Store data parameters subscription
// Store data parameters subscription
    const dataParamsSubscription = dataParametersObservable.subscribe({
      next: (response: DataParametersResponse) => {
        console.log('Received data parameters response:', response);

        // Store the data parameters in component state
        this.dataParameters = response;

        // Check if the response contains presence flags, and add them if missing
        if (response.analysis_results && !response.analysis_results.hasOwnProperty('has_config_parameters')) {
          // Initialize presence flags based on content existence
          const hasConfigParams =
            response.analysis_results.configuration_parameters &&
            response.analysis_results.configuration_parameters.length > 0;

          const hasTestDataMgmt =
            response.analysis_results.test_data_management &&
            (
              (response.analysis_results.test_data_management.base_dataset && response.analysis_results.test_data_management.base_dataset.length > 0) ||
              (response.analysis_results.test_data_management.dynamic_data && response.analysis_results.test_data_management.dynamic_data.length > 0) ||
              (response.analysis_results.test_data_management.sensitive_data && response.analysis_results.test_data_management.sensitive_data.length > 0) ||
              (response.analysis_results.test_data_management.edge_cases && response.analysis_results.test_data_management.edge_cases.length > 0)
            );

          // Add presence flags to support backward compatibility
          if (!hasConfigParams && !hasTestDataMgmt) {
            console.log('Adding presence flags to data parameters response');
            response.analysis_results = {
              ...response.analysis_results,
              has_config_parameters: hasConfigParams,
              has_test_data_management: hasTestDataMgmt,
              has_base_dataset: response.analysis_results.test_data_management?.base_dataset?.length > 0,
              has_dynamic_data: response.analysis_results.test_data_management?.dynamic_data?.length > 0,
              has_sensitive_data: response.analysis_results.test_data_management?.sensitive_data?.length > 0,
              has_edge_cases: response.analysis_results.test_data_management?.edge_cases?.length > 0
            };
          }
        }

        // Store it in your service for use across components
        this.sessioneService.updateDataParameters(response);
        this.isGeneratingTemplates = false;
        this.cd.detectChanges();
      },
      error: (error) => {
        console.error('Error in data parameters generation:', error);
        this.isGeneratingTemplates = false;
        this.error = `Error generating data parameters: ${error.message}`;
        this.cd.detectChanges();
      },
      complete: () => {
        dataParamsSubscription.unsubscribe();
      }
    });

    // Add data params subscription to main subscription for cleanup
    this.subscriptions.add(dataParamsSubscription);
  }

  // scenario list edit

  toggleEdit(scenario: MacroScenarioWithSelection) {
    scenario.isEditing = !scenario.isEditing;
  }

  async removeScenario(index: number) {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      this.macroScenariosWithSelection.splice(index, 1);

      Swal.fire(
        'Deleted!',
        'The scenario has been removed.',
        'success'
      );
    }
  }

  generateACS() {
    const selectedScenarios = this.macroScenariosWithSelection
      .filter(scenario => scenario.selected);
    const formattedParams = this.sessioneService.getFormattedDataParameters();

    if (!selectedScenarios.length) {
      this.error = 'Please select at least one scenario';
      return;
    }

    this.isGeneratingTemplates = true;
    this.acceptanceCriteria = [];
    this.criteriaGroups = [];
    this.error = '';
    this.templateProgress.clear();

    const fullAnalysis = this.coreAnalysis.scenario_analysis +
      (this.supportiveAnalysis?.scenario_enrichments
        ? ` Supportive Analysis: ${this.supportiveAnalysis.scenario_enrichments}`
        : '');

    const scenariosString = selectedScenarios
      .map(scenario => {
        let scenarioText = `${scenario.id}: ${scenario.description}`;
        if (scenario.enrichment_justification) {
          scenarioText += `\nJustification: ${scenario.enrichment_justification}`;
        }
        return scenarioText;
      })
      .join('\n\n');

    this.templateProgress.set('main', {
      groupId: 'main',
      status: 'loading',
      criteria: []
    });

    // Unsubscribe from previous subscriptions
    this.generateTemplateSubscription?.unsubscribe();

    // Create templates observable with proper operators
    const templatesObservable = this.userStoryService
      .generateTemplate(
        scenariosString,
        fullAnalysis,
        this.refinedStoryString,
        formattedParams,
        this.selectedModel,
        this.projectLanguage
      )
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        catchError(error => {
          const progress = this.templateProgress.get('main');
          if (progress) {
            progress.status = 'error';
            progress.error = error.message;
            this.cd.detectChanges();
          }
          throw error;
        })
      );

    // Store template subscription
    const templateSubscription = templatesObservable.subscribe({
      next: (response: ScenarioResponse) => {
        console.log('Received template response:', response);
        const progress = this.templateProgress.get('main');

        if (progress) {
          progress.status = 'complete';
          progress.criteria = [];

          const newCriteriaGroups: CriteriaGroup[] = [];
          response.scenario_groups.forEach(group => {
            newCriteriaGroups.push({
              group_info: group.group_info,
              acceptance_criteria: group.acceptance_criteria
            });

            progress.criteria.push(...group.acceptance_criteria);
          });

          this.criteriaGroups = newCriteriaGroups;
          this.sessioneService.updateCriteriaGroups(newCriteriaGroups);
          this.acceptanceCriteria = progress.criteria;
        }

        this.isGeneratingTemplates = false;

        // CHECKPOINT: Save the session after ACS generation
        this.saveACSCheckpoint();

        this.cd.detectChanges();
      },
      error: (error) => {
        console.error('Error in template generation:', error);
        this.error = `Error generating templates: ${error.message}`;
        this.isGeneratingTemplates = false;
        this.cd.detectChanges();
      },
      complete: () => {
        templateSubscription.unsubscribe();
      }
    });

    // Add template subscription to main subscription for cleanup
    this.subscriptions.add(templateSubscription);
  }

  // Method to save the session at the ACS checkpoint
  private saveACSCheckpoint(): void {
    // Auto-generate a session name if one doesn't exist
    let sessionName = this.sessioneService.fileNameSubject.getValue();
    if (!sessionName) {
      const projectId = this.sessioneService.projectId.getValue();
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      sessionName = `Project-${projectId}-${timestamp}`;
    } else {
      // Remove file extension if present
      sessionName = sessionName.replace(/\.[^/.]+$/, '');
    }

    console.log('Saving ACS checkpoint with name:', sessionName);

    // Use the checkpoint-aware save method with 'acs' stage
    // This will update the same session if it was already created at scenarios stage
    this.sessionService.saveCurrentSession(sessionName, 'acs')
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          console.log('ACS checkpoint saved successfully:', response);
          this.toastr.success('Progress saved at ACS stage');
        },
        error: (error) => {
          console.error('Error saving ACS checkpoint:', error);
          // Show a non-intrusive error message
          this.toastr.error('Could not save checkpoint', '', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right'
          });
        }
      });
  }

  addNewScenario() {
    if (this.newScenario.trim()) {
      // Extract the highest number from existing IDs
      const maxId = Math.max(...this.macroScenariosWithSelection.map(s => {
        // Extract the number after "S-" or "S-0"
        const match = s.id.match(/S-0?(\d+)/);
        return match ? parseInt(match[1], 10) : 0;
      }), 0);

      // Create new ID with proper formatting
      const nextNumber = maxId + 1;
      const newId = nextNumber < 10 ? `S-0${nextNumber}` : `S-${nextNumber}`;

      const newScenarioObj: MacroScenarioWithSelection = {
        id: newId,
        description: this.newScenario.trim(),
        selected: true,
        isEditing: false,
        source: 'User'
      };

      this.macroScenariosWithSelection.push(newScenarioObj);
      this.newScenario = '';
      this.isAddingNew = false;
    }
  }

  cancelAdd() {
    this.newScenario = '';
    this.isAddingNew = false;
  }

  // Add these new methods
  openEditModal(criterion: AcceptanceCriterion, modal: any) {
    this.currentCriterion = criterion;
    // Create a deep copy of the criterion for editing
    this.editingCriterion = {
      ...criterion,
      given: [...(criterion.given || [])],
      when: [...(criterion.when || [])],
      then: [...(criterion.then || [])]
    };

    this.modalService.open(modal, { size: 'lg' });
  }

  saveChanges(modal: any) {
    if (this.currentCriterion && this.editingCriterion) {
      // Update the original criterion with edited values
      Object.assign(this.currentCriterion, this.editingCriterion);
      modal.close();

      // Optional: Save to backend
      // this.userStoryService.updateCriterion(this.currentCriterion).subscribe(...)
    }
  }

  // Methods to handle conditions arrays
  addCondition(array: string[]) {
    array.push('');
  }

  removeCondition(array: string[], index: number) {
    array.splice(index, 1);
  }

  // sembra una stronzata ma non hai idea di quanto sia importante
  trackByIndex(index: number): number {
    return index;
  }

  // metodi editing background preconditions

  openBackgroundModal(groupInfo: TemplateGroupInfo, modal: any) {
    this.currentGroupInfo = groupInfo;
    // Create a deep copy of the group info for editing
    this.editingGroupInfo = {
      ...groupInfo,
      background: [...groupInfo.background]
    };

    this.modalService.open(modal, { size: 'lg' });
  }

  addBackgroundCondition() {
    if (this.editingGroupInfo) {
      this.editingGroupInfo.background.push('');
    }
  }

  removeBackgroundCondition(index: number) {
    if (this.editingGroupInfo) {
      this.editingGroupInfo.background.splice(index, 1);
    }
  }

  saveBackgroundChanges(modal: any) {
    if (this.currentGroupInfo && this.editingGroupInfo) {
      // Update the original background conditions
      this.currentGroupInfo.background = [...this.editingGroupInfo.background];
      modal.close();

      // Optional: Save to backend
      // this.userStoryService.updateGroupInfo(this.currentGroupInfo).subscribe(...)
    }
  }

  generaTests() {
    console.log('Starting generaTests from template component');
    this.sessioneService.resetTests();
    // Format criteria into individual scenarios
    const formattedCriteria = this.formatAcceptanceCriteriaIndividually();
    console.log('Formatted criteria:', formattedCriteria); // Debug log

    // Store the full formatted scenario string
    this.generateTestsEvent.emit('Tags');

    // Initialize test generation with ALL criteria
    this.sessioneService.initializeTestGeneration(formattedCriteria.length, formattedCriteria);

    // Start with the first criterion
    if (formattedCriteria.length > 0) {
      this.sessioneService.initiateGenerateTestsWithParams({
        criterionText: formattedCriteria[0],
        index: 0
      });
    }
  }

  /**
   * Format acceptance criteria individually with improved test data formatting
   */
  private formatAcceptanceCriteriaIndividually(): string[] {
    const scenarios: string[] = [];

    this.criteriaGroups.forEach(group => {
      // Get the group analysis if it exists
      const groupAnalysis = group.group_info.group_analysis || '';

      // Extract background conditions to be included in each criterion's "given"
      const backgroundConditions = group.group_info.background || [];

      group.acceptance_criteria.forEach(criterion => {
        let scenarioText = `Feature: ${group.group_info.feature_name}\n\n`;

        // We no longer show the background section separately
        // The background conditions will only be included in the "Given" section

        if (criterion.name) {
          scenarioText += `Scenario: ${criterion.id} - ${criterion.name}\n`;
        }

        if (criterion.tags && criterion.tags.length > 0) {
          scenarioText += `  Tags: ${criterion.tags.join(', ')}\n`;
        }

        // Add formatted test data elements after tags
        if (criterion.test_data_elements && criterion.test_data_elements.length > 0) {
          scenarioText += `  Test Data:\n`;

          criterion.test_data_elements.forEach(dataElement => {
            const fieldName = this.getFieldName(dataElement);
            const value = this.getTooltipValue(dataElement);
            const context = this.getDataParameterContextForField(dataElement);

            scenarioText += `    - ${fieldName}: ${value}`;

            // Add context if available
            if (context) {
              scenarioText += `\n      Context: ${context}`;
            }

            scenarioText += '\n';
          });
        }

        // Combine background conditions with criterion's given conditions
        const allGivenConditions = [...backgroundConditions];

        if (criterion.given && criterion.given.length > 0) {
          allGivenConditions.push(...criterion.given);
        }

        // Format the combined given conditions
        if (allGivenConditions.length > 0) {
          scenarioText += `  Given ${allGivenConditions[0]}\n`;
          allGivenConditions.slice(1).forEach(condition => {
            scenarioText += `  And ${condition}\n`;
          });
        }

        if (criterion.when && criterion.when.length > 0) {
          scenarioText += `  When ${criterion.when[0]}\n`;
          criterion.when.slice(1).forEach(action => {
            scenarioText += `  And ${action}\n`;
          });
        }

        if (criterion.then && criterion.then.length > 0) {
          scenarioText += `  Then ${criterion.then[0]}\n`;
          criterion.then.slice(1).forEach(result => {
            scenarioText += `  And ${result}\n`;
          });
        }

        // Add group analysis if it exists
        if (groupAnalysis) {
          scenarioText += `\nGroup Analysis:\n${groupAnalysis}\n`;
        }

        scenarios.push(scenarioText);
      });
    });

    return scenarios;
  }

// Method to add a new acceptance criterion to existing group
  openNewCriterionModal(groupIndex: number, modal: any) {
    this.editingCriterion = this.initializeNewCriterion(groupIndex);
    this.currentGroupIndex = groupIndex;

    // If this is coming from a new group creation and there's no criteria yet
    if (this.newGroupInfo && this.criteriaGroups[groupIndex].acceptance_criteria.length === 0) {
      Swal.fire({
        title: 'Add First Criterion',
        text: 'Please add the first acceptance criterion for this group',
        icon: 'info'
      });
    }

    this.modalService.open(modal, { size: 'lg' });
  }

// Method to save new criterion
  saveNewCriterion(modal: any) {
    if (this.editingCriterion && typeof this.currentGroupIndex === 'number') {
      if (!this.validateCriterion(this.editingCriterion)) {
        return;
      }

      const cleanedCriterion = this.cleanCriterion(this.editingCriterion);
      this.criteriaGroups[this.currentGroupIndex].acceptance_criteria.push(cleanedCriterion);

      // Update scenario_ids
      this.criteriaGroups[this.currentGroupIndex].group_info.scenario_ids
        .push(cleanedCriterion.scenario_ref);

      this.sessioneService.updateCriteriaGroups(this.criteriaGroups);

      // Clear the newGroupInfo after successfully adding the first criterion
      this.newGroupInfo = null;

      modal.close();
    }
  }

// Method to add a new background group
  openNewGroupModal(modal: any) {
    this.editingGroupInfo = {
      feature_name: '',
      background: [''],
      group_id: `G-${this.criteriaGroups.length + 1}`,
      scenario_ids: []
    };
    this.modalService.open(modal, { size: 'lg' });
  }

// Method to save new group
  saveNewGroup(modal: any) {
    if (!this.editingGroupInfo?.feature_name?.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Feature name is required',
        icon: 'error'
      });
      return;
    }

    // Store the group info temporarily
    this.newGroupInfo = {...this.editingGroupInfo};

    // Create the new empty group
    const newGroup: CriteriaGroup = {
      group_info: {...this.editingGroupInfo},
      acceptance_criteria: []
    };

    this.criteriaGroups.push(newGroup);
    this.sessioneService.updateCriteriaGroups(this.criteriaGroups);

    // Close the current modal
    modal.close();

    // Open the criterion modal for the new group
    setTimeout(() => {
      const groupIndex = this.criteriaGroups.length - 1;
      this.openNewCriterionModal(groupIndex, this.newCriterionModal);
    });
  }

// Helper method to generate new criterion ID
  private generateNewCriterionId(groupIndex: number): string {
    const existingCriteria = this.criteriaGroups[groupIndex].acceptance_criteria;
    const maxId = Math.max(...existingCriteria.map(c => {
      const match = c.id.match(/AC-(\d+)/);
      return match ? parseInt(match[1], 10) : 0;
    }), 0);
    return `AC-${maxId + 1}`;
  }

  // Helper methods for criterion management
  private validateCriterion(criterion: AcceptanceCriterion): boolean {
    if (!criterion?.name?.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Scenario name is required',
        icon: 'error'
      });
      return false;
    }

    // Only When and Then are required
    if (!criterion.when?.[0]?.trim() || !criterion.then?.[0]?.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'At least one When action and Then result is required',
        icon: 'error'
      });
      return false;
    }

    return true;
  }

  private cleanCriterion(criterion: AcceptanceCriterion): AcceptanceCriterion {
    return {
      ...criterion,
      given: criterion.given?.filter(g => g.trim()) || [],
      when: criterion.when.filter(w => w.trim()),
      then: criterion.then.filter(t => t.trim())
    };
  }

  private initializeNewCriterion(groupIndex: number): AcceptanceCriterion {
    return {
      id: this.generateNewCriterionId(groupIndex),
      name: '',
      tags: [],
      test_data_elements: [],
      given: [], // Optional, starts empty
      when: [''], // Required, starts with one empty entry
      then: [''], // Required, starts with one empty entry
      scenario_ref: `S-${groupIndex + 1}`
    };
  }

  // Methods for handling criterion actions
  async removeAcceptanceCriterion(groupIndex: number, criterionIndex: number) {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      this.criteriaGroups[groupIndex].acceptance_criteria.splice(criterionIndex, 1);
      this.reindexCriteria(); // Add reindexing
      this.sessioneService.updateCriteriaGroups(this.criteriaGroups);

      // Also update the generated tests to match new indices
      this.updateGeneratedTestsBatchIndices();

      Swal.fire(
        'Deleted!',
        'The acceptance criterion has been removed.',
        'success'
      );
    }
  }

  async removeGroup(groupIndex: number) {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this entire group and all its acceptance criteria!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      this.criteriaGroups.splice(groupIndex, 1);
      this.reindexCriteria(); // Add reindexing
      this.sessioneService.updateCriteriaGroups(this.criteriaGroups);

      // Also update the generated tests to match new indices
      this.updateGeneratedTestsBatchIndices();

      Swal.fire(
        'Deleted!',
        'The group has been removed.',
        'success'
      );
    }
  }

  // to reindex criteria after deletion
  private reindexCriteria() {
    let globalIndex = 1;
    this.criteriaGroups.forEach(group => {
      group.acceptance_criteria.forEach(criterion => {
        criterion.id = `AC-${globalIndex}`;
        globalIndex++;
      });
    });
  }

  // to update test batch indices
  private updateGeneratedTestsBatchIndices() {
    this.sessioneService.generatedTests$.pipe(take(1)).subscribe(tests => {
      if (!tests.length) { return; }

      // Create a mapping of criterion IDs to their new indices
      const criteriaMap = new Map<string, { groupIndex: number, criterionIndex: number }>();

      this.criteriaGroups.forEach((group, groupIndex) => {
        group.acceptance_criteria.forEach((criterion, criterionIndex) => {
          // Store both the old AC ID format and the new group+criterion indices
          const oldId = criterion.id; // e.g., "AC-01"
          criteriaMap.set(oldId, {
            groupIndex,
            criterionIndex
          });
        });
      });

      // Update the metadata for each test
      const updatedTests = tests.map(test => {
        // Extract the AC part from the test's display ID (e.g., "G01-AC01-TC01" -> "AC01")
        const acMatch = test.metadata.displayId.match(/G\d+-AC(\d+)-TC\d+/);
        if (!acMatch) { return test; }

        const oldAcId = `AC-${acMatch[1]}`;
        const newIndices = criteriaMap.get(oldAcId);

        if (!newIndices) { return test; }

        // Create new metadata with updated indices but keep the same test index
        const newMetadata = this.sessioneService.testIdService.createTestId({
          groupIndex: newIndices.groupIndex,
          criterionIndex: newIndices.criterionIndex,
          testIndex: test.metadata.testIndex
        });

        return {
          ...test,
          metadata: newMetadata
        };
      });

      this.sessioneService.updateGeneratedTests(updatedTests);
    });
  }

  // test data param handling
  /**
   * Extract the field name from a test data element string
   * Format: "field_name | value_or_info"
   */
  getFieldName(dataElement: string): string {
    if (!dataElement) { return ''; }

    const parts = dataElement.split('|');
    if (parts.length < 2) { return dataElement; }

    return parts[0].trim();
  }

  /**
   * Extract only the value/info part from a test data element string for tooltip
   * Format: "field_name | value_or_info"
   */
  getTooltipValue(dataElement: string): string {
    if (!dataElement) { return ''; }

    const parts = dataElement.split('|');
    if (parts.length < 2) { return ''; }

    // Return only the value portion (everything after the first |)
    return parts.slice(1).join('|').trim();
  }

  /**
   * Get context information for a test data element from data parameters
   * @param dataElement The test data element string in format "field_name | value"
   * @returns The rules field from matching base_dataset element or empty string if not found
   */
  getDataParameterContextForField(dataElement: string): string {
    if (!dataElement) { return ''; }

    // Get the field name from the test data element
    const fieldName = this.getFieldName(dataElement).toLowerCase();

    // Get data parameters from service
    const dataParams = this.sessioneService.getDataParameters();

    if (!dataParams?.analysis_results?.test_data_management?.base_dataset) { return ''; }

    // Find matching field in base_dataset (case insensitive)
    const matchingDataset = dataParams.analysis_results.test_data_management.base_dataset.find(
      dataset => dataset.field.toLowerCase() === fieldName
    );

    // Return rules if found, otherwise empty string
    return matchingDataset?.rules || '';
  }

  /**
   * Open modal to edit test data element
   */
  openEditTestDataModal(criterion: AcceptanceCriterion, index: number): void {
    if (!criterion.test_data_elements) {
      criterion.test_data_elements = [];
    }

    const dataElement = criterion.test_data_elements[index];
    const parts = dataElement.split('|');

    this.editingTestData = {
      fieldName: parts[0].trim(),
      value: parts.length > 1 ? parts.slice(1).join('|').trim() : '',
      index: index, // Positive index indicates edit mode
      currentCriterion: criterion
    };

    // We don't need to set selectedDataParamField in edit mode since the dropdown won't be shown

    this.modalService.open(this.testDataEditModal, { size: 'md' });
  }


  /**
   * Open modal to add new test data element with field selection
   */
  openAddTestDataModal(criterion: AcceptanceCriterion): void {
    if (!criterion.test_data_elements) {
      criterion.test_data_elements = [];
    }

    // Get available fields from data parameters
    this.availableDataParamFields = [];
    const dataParams = this.sessioneService.getDataParameters();

    if (dataParams?.analysis_results?.test_data_management?.base_dataset) {
      this.availableDataParamFields = dataParams.analysis_results.test_data_management.base_dataset.map(
        dataset => dataset.field
      );
    }

    this.editingTestData = {
      fieldName: '',
      value: '',
      index: -1, // Negative index indicates add mode
      currentCriterion: criterion
    };

    this.selectedDataParamField = '';  // Reset selection

    this.modalService.open(this.testDataEditModal, { size: 'md' });
  }


  /**
   * Handle field selection from data parameters
   */
  onDataParamFieldSelected(): void {
    if (!this.selectedDataParamField) { return; }

    // Set the field name from selection
    this.editingTestData.fieldName = this.selectedDataParamField;

    // Look up valid values to suggest (optional enhancement)
    const dataParams = this.sessioneService.getDataParameters();
    if (dataParams?.analysis_results?.test_data_management?.base_dataset) {
      const selectedDataset = dataParams.analysis_results.test_data_management.base_dataset.find(
        dataset => dataset.field === this.selectedDataParamField
      );

      if (selectedDataset?.valid_values?.length) {
        // Optionally suggest using valid values in the value field
        this.editingTestData.value = 'Valid values: ' + selectedDataset.valid_values.join(', ');
      }
    }
  }

  /**
   * Save changes to test data element
   */
  saveTestDataChanges(modal: any): void {
    const { fieldName, value, index, currentCriterion } = this.editingTestData;

    if (!fieldName.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Field name is required',
        icon: 'error'
      });
      return;
    }

    if (!currentCriterion) { return; }

    if (!currentCriterion.test_data_elements) {
      currentCriterion.test_data_elements = [];
    }

    // Format the test data element
    const formattedElement = `${fieldName.trim()} | ${value.trim()}`;

    if (index >= 0) {
      // Edit existing element
      currentCriterion.test_data_elements[index] = formattedElement;
    } else {
      // Add new element
      currentCriterion.test_data_elements.push(formattedElement);
    }

    // Update the state
    this.sessioneService.updateCriteriaGroups(this.criteriaGroups);

    // Close the modal
    modal.close();
  }

  /**
   * Remove test data element
   */
  removeTestDataElement(criterion: AcceptanceCriterion, index: number): void {
    Swal.fire({
      title: 'Remove Test Data',
      text: 'Are you sure you want to remove this test data element?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it'
    }).then((result) => {
      if (result.isConfirmed) {
        if (criterion.test_data_elements) {
          criterion.test_data_elements.splice(index, 1);

          // Update the state
          this.sessioneService.updateCriteriaGroups(this.criteriaGroups);
        }
      }
    });
  }

  // tags management

  getTagTooltipContent(tag: string): { title: string, coverage: string, purpose: string, isCore: boolean } | null {
    if (!tag) { return null; }

    const baseTag = tag.replace('#', '');
    const description = TAG_DESCRIPTIONS[baseTag]; // Use your existing TAG_DESCRIPTIONS

    if (!description) { return null; }

    return {
      title: description.title,
      coverage: description.coverage,
      purpose: description.purpose,
      isCore: description.isCore
    };
  }

// Tag management methods
  public removeTag(criterion: AcceptanceCriterion, index: number): void {
    Swal.fire({
      title: 'Remove Tag',
      text: 'Are you sure you want to remove this tag?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it'
    }).then((result) => {
      if (result.isConfirmed) {
        if (criterion.tags) {
          criterion.tags.splice(index, 1);

          // Update the state in the session service
          this.sessioneService.updateCriteriaGroups(this.criteriaGroups);
        }
      }
    });
  }

  public openAddTagModal(criterion: AcceptanceCriterion): void {
    if (!criterion.tags) {
      criterion.tags = [];
    }

    // Define available tags
    const availableTags = [
      '#business_critical',
      '#security',
      '#api_integration',
      '#ui',
      '#performance',
      '#stateful',
      '#validation',
      '#async_concurrency',
      '#history_of_bugs'
    ];

    // Filter out tags that are already applied
    const unusedTags = availableTags.filter(tag => !criterion.tags?.includes(tag));

    if (unusedTags.length === 0) {
      Swal.fire({
        title: 'All Tags Used',
        text: 'You have already used all available tags for this criterion.',
        icon: 'info'
      });
      return;
    }

    // Show tag selection dialog
    Swal.fire({
      title: 'Add Tag',
      input: 'select',
      inputOptions: unusedTags.reduce((options: {[key: string]: string}, tag) => {
        options[tag] = tag.replace(/_/g, ' ');
        return options;
      }, {}),
      inputPlaceholder: 'Select a tag',
      showCancelButton: true,
      confirmButtonText: 'Add',
      inputValidator: (value) => {
        return !value ? 'You need to select a tag!' : null;
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        criterion.tags.push(result.value);

        // Update the state in the session service
        this.sessioneService.updateCriteriaGroups(this.criteriaGroups);
      }
    });
  }
}
