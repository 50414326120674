<!-- template.component.html -->
<div class="container-fluid mt-4">
  <!-- Action Buttons -->
  <div class="row mb-2">
    <div class="col d-flex justify-content-center gap-3">
      <button class="btn btn-secondary d-flex align-items-center"
              (click)="generateAnalysisOnly()"
              [disabled]="isLoadingMacros">
        <span class="spinner-border spinner-border-sm me-2" *ngIf="isLoadingMacros"></span>
        Begin Analysis
      </button>
    </div>
  </div>

  <!-- Error Alert -->
  <div class="alert alert-danger" *ngIf="error">
    {{ error }}
  </div>

  <!-- Debug Information Section -->
  <div class="debug-section mb-4" *ngIf="coreAnalysis || supportiveAnalysis">
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h3 class="h5 mb-0">Scriptor's Context Analysis</h3>
        <button class="btn btn-sm btn-outline-secondary"
                (click)="isDebugVisible = !isDebugVisible">
          <i class="fa" [ngClass]="isDebugVisible ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </button>
      </div>
      <div class="card-body-container" *ngIf="isDebugVisible">
        <!-- Core Analysis -->
        <div class="debug-content" *ngIf="coreAnalysis">
          <h4 class="h6 text-primary">Core Analysis</h4>

          <div class="mb-3">
            <h5 class="h6 text-secondary">Scenario Analysis</h5>
            <pre class="debug-code">{{ coreAnalysis.scenario_analysis }}</pre>
          </div>

          <div class="mb-3">
            <h5 class="h6 text-secondary">Configuration & Test Data Analysis</h5>
            <pre class="debug-code">{{ coreAnalysis.config_testdata_analysis }}</pre>
          </div>
        </div>

        <!-- Supportive Analysis -->
        <div class="debug-content" *ngIf="supportiveAnalysis">
          <h4 class="h6 text-primary">Supportive Analysis</h4>

          <div class="mb-3">
            <h5 class="h6 text-secondary">Scenario Enrichments</h5>
            <pre class="debug-code">{{ supportiveAnalysis.scenario_enrichments }}</pre>
          </div>

          <div class="mb-3">
            <h5 class="h6 text-secondary">Configuration & Test Data Enrichments</h5>
            <pre class="debug-code">{{ supportiveAnalysis.config_testdata_enrichments }}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Action Buttons -->
  <div class="row mb-2" *ngIf="coreAnalysis">
    <div class="col d-flex justify-content-center gap-3">
      <button class="btn btn-secondary d-flex align-items-center"
              (click)="generateMacroScenarios2()"
              [disabled]="isLoadingMacros">
        <span class="spinner-border spinner-border-sm me-2" *ngIf="isLoadingMacros"></span>
        Retrieve Scenarios
      </button>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-2">
    <button class="btn btn-danger" *ngIf="criteriaGroups.length > 0" (click)="generaTests()">
      Generate Tests from AC
    </button>
  </div>

  <!-- Macro Scenarios List -->
  <div class="macro-scenarios-container mb-4" *ngIf="macroScenariosWithSelection.length > 0">
    <h2 class="mb-0">Scenarios</h2>
    <div class="list-group mb-4">
      <div class="list-group-item list-group-item-action d-flex align-items-center gap-3"
           *ngFor="let scenario of macroScenariosWithSelection; let i = index"
           [class.has-enrichment]="scenario.enrichment_justification"
           [class.has-ambiguity]="scenario.hasAmbiguities">
        <div class="form-check flex-grow-1 d-flex align-items-center gap-3">
          <input class="form-check-input"
                 type="checkbox"
                 [id]="scenario.id"
                 [(ngModel)]="scenario.selected">

          <!-- Enrichment tooltip -->
          <div class="enrichment-tooltip" *ngIf="scenario.enrichment_justification">
            {{ scenario.enrichment_justification }}
            <span class="source">Source: {{ scenario.source }}</span>
          </div>

          <!-- Edit mode toggle -->
          <div class="flex-grow-1" *ngIf="!scenario.isEditing">
            <label class="form-check-label" [for]="scenario.id">
              <span class="text-secondary">{{ scenario.id }}: </span>
              {{ scenario.description }}
            </label>
          </div>

          <!-- Edit form -->
          <div class="flex-grow-1" *ngIf="scenario.isEditing">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="scenario.description"
                   (keyup.enter)="toggleEdit(scenario)"
                   (blur)="toggleEdit(scenario)">
          </div>
        </div>

        <div class="ms-auto d-flex gap-1">
          <button class="btn btn-link btn-sm p-1"
                  (click)="toggleEdit(scenario)"
                  *ngIf="!scenario.isEditing">
            <i class="fa fa-pencil"></i>
          </button>
          <button class="btn btn-link btn-sm p-1"
                  (click)="removeScenario(i)">
            <i class="feather icon-trash"></i>
          </button>
        </div>
      </div>

      <!-- Add new scenario section -->
      <div class="list-group-item">
        <div *ngIf="!isAddingNew">
          <button class="btn btn-outline-primary w-100"
                  (click)="isAddingNew = true">
            <i class="bi bi-plus-circle me-2"></i>Add New Scenario
          </button>
        </div>

        <div *ngIf="isAddingNew" class="d-flex gap-2">
          <input type="text"
                 class="form-control"
                 [(ngModel)]="newScenario"
                 placeholder="Enter new scenario description"
                 (keyup.enter)="addNewScenario()"
                 (keyup.escape)="cancelAdd()">

          <button class="btn btn-primary"
                  (click)="addNewScenario()"
                  [disabled]="!newScenario.trim()">
            Add
          </button>

          <button class="btn btn-outline-secondary"
                  (click)="cancelAdd()">
            Cancel
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button class="btn btn-primary d-flex align-items-center"
              (click)="generateAllTemplates()"
              [disabled]="isGeneratingTemplates">
        <span class="spinner-border spinner-border-sm me-2" *ngIf="isGeneratingTemplates"></span>
        <i class="feather icon-file-text me-2" *ngIf="!isGeneratingTemplates"></i>
        Generate Template
      </button>

      <button class="btn btn-outline-dark d-flex align-items-center ml-2"
              (click)="generateEdgeCaseScenarios()"
              [disabled]="isGeneratingTemplates">
        <span class="spinner-border spinner-border-sm me-2" *ngIf="isGeneratingTemplates"></span>
        <i class="feather icon-file-text me-2" *ngIf="!isGeneratingTemplates"></i>
        Find Additional Scenarios
      </button>
    </div>
  </div>

  <!-- User Story -->
  <div class="criteria-groups" *ngIf="storyDescription">
    <div class="section-header">
      <h2 class="h5">INFORMAZIONI GENERALI</h2>
    </div>

    <div class="criteria-item">
      <div class="criteria-details">
        <!-- Title section -->
        <div class="criteria-section">
          <div class="criteria-row">
            <span class="criteria-label">Story:</span>
            <div class="criteria-content">
              {{storyDescription.title}}
            </div>
          </div>
        </div>

        <!-- Description section -->
        <div class="criteria-section">
          <div class="criteria-row">
            <span class="criteria-label">Details:</span>
            <div class="criteria-content">
              {{storyDescription.description}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Data Parameters Section -->
  <div class="d-flex justify-content-center">
    <app-data-param-table></app-data-param-table>
  </div>

  <div class="d-flex justify-content-center" *ngIf="dataParameters">
    <button class="btn btn-primary d-flex align-items-center"
            (click)="generateACS()"
            [disabled]="isGeneratingTemplates">
      <span class="spinner-border spinner-border-sm me-2" *ngIf="isGeneratingTemplates"></span>
      <i class="feather icon-file-text me-2" *ngIf="!isGeneratingTemplates"></i>
      Generate Acceptance Criteria
    </button>
  </div>

  <!-- Generated Templates Display -->
  <div class="criteria-groups mt-3" *ngIf="criteriaGroups.length > 0">
    <div class="section-header">
      <h2 class="h5">ACCEPTANCE CRITERIA</h2>
    </div>

    <div class="criteria-group" *ngFor="let group of criteriaGroups; let i = index">
      <div class="feature-section">
        <h3 class="h5">{{ group.group_info.feature_name }}</h3>
      </div>

      <div class="background-wrapper py-2">
        <!-- Background section -->
        <div class="background-section mb-2">
          <div class="background-header d-flex justify-content-between align-items-center">
            <h4 class="h6 mb-2">Background:</h4>
            <div class="d-flex gap-2">
              <button class="btn btn-sm btn-outline-primary"
                      (click)="openBackgroundModal(group.group_info, editBackgroundModal)">
                <i class="fa fa-pencil"></i>
              </button>
              <button class="btn btn-sm btn-outline-danger ml-1"
                      (click)="removeGroup(i)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
          <div class="background-content">
            <ul class="mb-0">
              <li class="small" *ngFor="let condition of group.group_info.background">
                {{ condition }}
              </li>
            </ul>

            <!-- Group Analysis section -->
            <div *ngIf="group.group_info.group_analysis" class="group-analysis mt-2">
              <div class="analysis-label">Analysis:</div>
              <div class="analysis-text">{{ group.group_info.group_analysis }}</div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end mb-3">
          <button class="btn btn-sm btn-outline-primary"
                  (click)="openNewCriterionModal(i, newCriterionModal)">
            <i class="fa fa-plus me-1"></i> Add Criterion
          </button>
        </div>

        <div class="criteria-items-container">
          <div class="criteria-item" *ngFor="let criterion of group.acceptance_criteria; let j = index">
            <div class="criteria-details">
              <div class="criteria-section" *ngIf="criterion.name">
                <div class="id-row">
                  <span>{{criterion.id}}</span>
                  <div class="d-flex align-items-center gap-2">
                    <span class="small text-muted">(Ref: {{criterion.scenario_ref}})</span>
                  </div>
                  <div class="d-flex gap-2">
                    <button class="btn btn-sm btn-outline-primary"
                            (click)="openEditModal(criterion, editModal)">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-sm btn-outline-danger ml-1"
                            (click)="removeAcceptanceCriterion(i, j)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>

                <div class="criteria-row">
                  <span class="criteria-label">Scenario:</span>
                  <div class="criteria-content">
                    {{criterion.name}}
                  </div>
                </div>
              </div>

              <!-- Tags section -->
              <div class="criteria-section">
                <div class="criteria-row">
                  <span class="criteria-label">Tags:</span>
                  <div class="criteria-content test-data-section">
                    <span class="tag-item" *ngFor="let tag of criterion.tags; let i = index">
                      <span [ngbTooltip]="tagTooltipTemplate"
                            tooltipClass="test-data-tooltip"
                            placement="top"
                            container="body"
                            [openDelay]="300">
                        {{ tag.replace('_', ' ') }}
                      </span>
                      <i class="fa fa-times-circle delete-icon" (click)="removeTag(criterion, i)"></i>

                      <ng-template #tagTooltipTemplate>
                        <div class="tooltip-content">
                          <div class="test-data-info">
                            <div class="title">{{ tag.replace('_', ' ') }}</div>
                            <div class="section">
                              <div class="label">Coverage:</div>
                              <div class="content">{{ getTagTooltipContent(tag)?.coverage }}</div>
                            </div>
                            <div class="section">
                              <div class="label">{{ getTagTooltipContent(tag)?.isCore ? 'Why it\'s useful' : 'When to use it' }}:</div>
                              <div class="content">{{ getTagTooltipContent(tag)?.purpose }}</div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                    <button class="btn btn-sm btn-outline-secondary add-test-data-btn" (click)="openAddTagModal(criterion)">
                      <i class="fa fa-plus"></i> Add Tag
                    </button>
                  </div>
                </div>
              </div>

              <!-- Test Data Elements section -->
              <div class="criteria-section">
                <div class="criteria-row">
                  <span class="criteria-label">Test Data:</span>
                  <div class="criteria-content test-data-section">
                    <span class="test-data-item" *ngFor="let dataElement of criterion.test_data_elements; let i = index">
                      <span [ngbTooltip]="dataTooltipTemplate"
                            tooltipClass="test-data-tooltip"
                            placement="top"
                            container="body"
                            [openDelay]="300"
                            (dblclick)="openEditTestDataModal(criterion, i)">
                        {{ getFieldName(dataElement) }}
                      </span>
                      <i class="fa fa-times-circle delete-icon" (click)="removeTestDataElement(criterion, i)"></i>

                      <ng-template #dataTooltipTemplate>
                        <div class="tooltip-content">
                          <div class="test-data-info">
                            <div class="title">{{ getFieldName(dataElement) }}</div>
                            <div class="section">
                              <div class="label">Value:</div>
                              <div class="content">{{ getTooltipValue(dataElement) }}</div>
                            </div>
                            <div class="section" *ngIf="getDataParameterContextForField(dataElement)">
                              <div class="label">Context:</div>
                              <div class="content">{{ getDataParameterContextForField(dataElement) }}</div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                    <button class="btn btn-sm btn-outline-secondary add-test-data-btn" (click)="openAddTestDataModal(criterion)">
                      <i class="fa fa-plus"></i> Add Test Data
                    </button>
                  </div>
                </div>
              </div>

              <!-- Given section -->
              <div class="criteria-section" *ngIf="criterion.given?.length">
                <div class="criteria-row">
                  <span class="criteria-label">Given:</span>
                  <div class="criteria-content">{{ criterion.given[0] }}</div>
                </div>
                <div class="criteria-row" *ngFor="let condition of criterion.given.slice(1)">
                  <span class="criteria-label">And:</span>
                  <div class="criteria-content">{{ condition }}</div>
                </div>
              </div>

              <!-- When section -->
              <div class="criteria-section" *ngIf="criterion.when?.length">
                <div class="criteria-row">
                  <span class="criteria-label">When:</span>
                  <div class="criteria-content">{{ criterion.when[0] }}</div>
                </div>
                <div class="criteria-row" *ngFor="let action of criterion.when.slice(1)">
                  <span class="criteria-label">And:</span>
                  <div class="criteria-content">{{ action }}</div>
                </div>
              </div>

              <!-- Then section -->
              <div class="criteria-section" *ngIf="criterion.then?.length">
                <div class="criteria-row">
                  <span class="criteria-label">Then:</span>
                  <div class="criteria-content">{{ criterion.then[0] }}</div>
                </div>
                <div class="criteria-row" *ngFor="let result of criterion.then.slice(1)">
                  <span class="criteria-label">And:</span>
                  <div class="criteria-content">{{ result }}</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="d-flex justify-content-center mt-4" *ngIf="criteriaGroups.length > 0">
    <button class="btn btn-outline-primary" (click)="openNewGroupModal(newGroupModal)">
      <i class="fa fa-plus me-1"></i> Add New Background Group
    </button>
  </div>

  <div class="d-flex justify-content-center mt-2">
    <button class="btn btn-danger" *ngIf="criteriaGroups.length > 0" (click)="generaTests()">
      Generate Tests from AC
    </button>
  </div>
</div>


<!-- Modal Template -->
<ng-template #editModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Acceptance Criterion</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>

  <div class="modal-body" *ngIf="editingCriterion">
    <!-- Scenario Name -->
    <div class="mb-3">
      <label class="form-label">Scenario Name</label>
      <input type="text" class="form-control" [(ngModel)]="editingCriterion.name">
    </div>

    <!-- Tags -->
    <div class="mb-3">
      <label class="form-label d-block">Tags</label>
      <div class="conditions-container">
        <div *ngFor="let tag of editingCriterion.tags; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <select class="form-select"
                  [ngModel]="editingCriterion.tags[i]"
                  (ngModelChange)="editingCriterion.tags[i] = $event">
            <optgroup label="Core Tags">
              <option value="#business_critical">Business Critical</option>
              <option value="#security">Security</option>
              <option value="#api_integration">API Integration</option>
              <option value="#ui">UI</option>
              <option value="#performance">Performance</option>
              <option value="#stateful">Stateful</option>
              <option value="#validation">Validation</option>
              <option value="#async_concurrency">Async/Concurrency</option>
              <option value="#history_of_bugs">History of Bugs</option>
            </optgroup>
          </select>
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.tags, i)">
            <i class="feather icon-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.tags)">
          Add Tag
        </button>
      </div>
    </div>

    <!-- Given Conditions -->
    <div class="mb-3">
      <label class="form-label d-block">Given Conditions</label>
      <div class="conditions-container">
        <div *ngFor="let condition of editingCriterion.given; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <input type="text" class="form-control"
                 [ngModel]="editingCriterion.given[i]"
                 (ngModelChange)="editingCriterion.given[i] = $event">
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.given, i)">
            <i class="feather icon-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.given)">
          Add Given Condition
        </button>
      </div>
    </div>

    <!-- When Actions -->
    <div class="mb-3">
      <label class="form-label d-block">When Actions</label>
      <div class="conditions-container">
        <div *ngFor="let action of editingCriterion.when; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <input type="text" class="form-control"
                 [ngModel]="editingCriterion.when[i]"
                 (ngModelChange)="editingCriterion.when[i] = $event">
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.when, i)">
            <i class="feather icon-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.when)">
          Add When Action
        </button>
      </div>
    </div>

    <!-- Then Results -->
    <div class="mb-3">
      <label class="form-label d-block">Then Results</label>
      <div class="conditions-container">
        <div *ngFor="let result of editingCriterion.then; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <input type="text" class="form-control"
                 [ngModel]="editingCriterion.then[i]"
                 (ngModelChange)="editingCriterion.then[i] = $event">
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.then, i)">
            <i class="feather icon-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.then)">
          Add Then Result
        </button>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveChanges(modal)">Save Changes</button>
  </div>
</ng-template>


<!-- Background Modal Template -->
<ng-template #editBackgroundModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Background Conditions</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>

  <div class="modal-body" *ngIf="editingGroupInfo">
    <div class="mb-3">
      <label class="form-label d-block">Background Conditions</label>
      <div class="conditions-container">
        <div *ngFor="let condition of editingGroupInfo.background; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <input type="text" class="form-control"
                 [ngModel]="editingGroupInfo.background[i]"
                 (ngModelChange)="editingGroupInfo.background[i] = $event">
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeBackgroundCondition(i)">
            <i class="feather icon-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addBackgroundCondition()">
          Add Background Condition
        </button>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveBackgroundChanges(modal)">
      Save Changes
    </button>
  </div>
</ng-template>

<ng-template #newCriterionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add New Acceptance Criterion</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>

  <div class="modal-body" *ngIf="editingCriterion">
    <!-- Scenario Name -->
    <div class="mb-3">
      <label class="form-label">Scenario Name</label>
      <input type="text" class="form-control" [(ngModel)]="editingCriterion.name">
    </div>

    <!-- Tags -->
    <div class="mb-3">
      <label class="form-label d-block">Tags</label>
      <div class="conditions-container">
        <div *ngFor="let tag of editingCriterion.tags; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <select class="form-select"
                  [ngModel]="editingCriterion.tags[i]"
                  (ngModelChange)="editingCriterion.tags[i] = $event">
            <optgroup label="Core Tags">
              <option value="#business_critical">Business Critical</option>
              <option value="#security">Security</option>
              <option value="#api_integration">API Integration</option>
              <option value="#ui">UI</option>
              <option value="#performance">Performance</option>
              <option value="#stateful">Stateful</option>
            </optgroup>
            <optgroup label="Optional Tags">
              <option value="#compliance">Compliance</option>
              <option value="#validation">Validation</option>
              <option value="#async">Async</option>
              <option value="#concurrency">Concurrency</option>
              <option value="#high_variability">High Variability</option>
              <option value="#history_of_bugs">History of Bugs</option>
            </optgroup>
          </select>
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.tags, i)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.tags)">
          Add Tag
        </button>
      </div>
    </div>

    <!-- Given Conditions -->
    <div class="mb-3">
      <label class="form-label d-block">Given Conditions</label>
      <div class="conditions-container">
        <div *ngFor="let condition of editingCriterion.given; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <input type="text" class="form-control"
                 [ngModel]="editingCriterion.given[i]"
                 (ngModelChange)="editingCriterion.given[i] = $event">
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.given, i)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.given)">
          Add Given Condition
        </button>
      </div>
    </div>

    <!-- When Actions -->
    <div class="mb-3">
      <label class="form-label required d-block">When Actions</label>
      <div class="conditions-container">
        <div *ngFor="let action of editingCriterion.when; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <input type="text" class="form-control"
                 [ngModel]="editingCriterion.when[i]"
                 (ngModelChange)="editingCriterion.when[i] = $event">
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.when, i)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.when)">
          Add When Action
        </button>
      </div>
    </div>

    <!-- Then Results -->
    <div class="mb-3">
      <label class="form-label required d-block">Then Results</label>
      <div class="conditions-container">
        <div *ngFor="let result of editingCriterion.then; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <input type="text" class="form-control"
                 [ngModel]="editingCriterion.then[i]"
                 (ngModelChange)="editingCriterion.then[i] = $event">
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.then, i)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.then)">
          Add Then Result
        </button>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveNewCriterion(modal)">Add Criterion</button>
  </div>
</ng-template>


<ng-template #newGroupModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add New Background Group</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>

  <div class="modal-body">
    <!-- Feature Name -->
    <div class="mb-3">
      <label class="form-label required">Feature Name</label>
      <input type="text" class="form-control" [(ngModel)]="editingGroupInfo.feature_name">
    </div>

    <!-- Background Conditions -->
    <div class="mb-3">
      <label class="form-label">Background Conditions</label>
      <div class="conditions-container">
        <div *ngFor="let condition of editingGroupInfo.background; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <input type="text" class="form-control"
                 [ngModel]="editingGroupInfo.background[i]"
                 (ngModelChange)="editingGroupInfo.background[i] = $event">
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeBackgroundCondition(i)">
            <i class="feather icon-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addBackgroundCondition()">
          Add Background Condition
        </button>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveNewGroup(modal)">Create Group</button>
  </div>
</ng-template>

<!-- Test Data Edit Modal -->
<ng-template #testDataEditModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ editingTestData.index >= 0 ? 'Edit' : 'Add' }} Test Data</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="dataParamFieldSelect">Field From Data Parameters</label>
      <!-- Show select dropdown only in Add mode -->
      <select *ngIf="editingTestData.index < 0" class="form-control" id="dataParamFieldSelect"
              [(ngModel)]="selectedDataParamField"
              (change)="onDataParamFieldSelected()">
        <option value="">-- Select a field --</option>
        <option *ngFor="let field of availableDataParamFields" [value]="field">
          {{ field }}
        </option>
      </select>
      <!-- Show readonly field name in Edit mode -->
      <input *ngIf="editingTestData.index >= 0" type="text" class="form-control"
             [value]="editingTestData.fieldName" readonly>
    </div>

    <div class="form-group">
      <label for="testDataValue">Value/Details</label>
      <textarea class="form-control" id="testDataValue" rows="3" [(ngModel)]="editingTestData.value"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveTestDataChanges(modal)">Save</button>
  </div>
</ng-template>
