<section id="ngx">
  <!-- Add this button to the page header section, near the top of the file -->
  <div class="row">
    <div class="col-sm-12">
      <div class="d-flex align-items-center">
        <button class="btn btn-outline-primary me-3" (click)="goBackToSessions()">
          <i class="ft-arrow-left"></i> Back to Sessions
        </button>

        <button class="btn btn-outline-dark ml-2" (click)="openGeneraTestsModal(generaTestModal)">
          Generation Settings
        </button>

        <button class="btn btn-outline-dark ml-2" (click)="saveCurrentWorkspace()">
          Save Session State
        </button>

        <button
          class="btn btn-outline-dark ml-2"
          data-toggle="tooltip"
          data-placement="top"
          title="Effettua l'esportazione dei test, della story e delle note"
          (click)="downloadGeneratedTests()">
          Export Workspace
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="content-header">Scriptor for {{projectId}}</div>
    </div>
  </div>

  <!-- Configuration button -->
  <div *ngIf="isQAnalystOrManager" class="customizer">
    <a class="customizer-toggle bg-primary" (click)="openGeneraTestsModal(generaTestModal)"
       ngbTooltip="Configurazione progetto">
      <i class="ft-settings font-medium-1 white align-middle"></i>
    </a>
  </div>

  <!-- Configuration button and token count -->
  <div class="d-flex align-items-left">
    <div class="d-flex flex-column align-items-start ms-2 ml-2">
      <span *ngIf="tokenCount?.prompt_tokens" class="text-muted small me-2">Input: {{tokenCount?.prompt_tokens | number:'1.0-0'}}</span>
      <span *ngIf="tokenCount?.completion_tokens" class="text-muted small me-2">Output: {{tokenCount?.completion_tokens | number:'1.0-0'}}</span>
      <span *ngIf="tokenCost?.totalCost" class="text-muted small">Total: ${{tokenCost?.totalCost | number:'1.2-2'}}</span>
    </div>

    <div class="d-flex flex-column align-items-start ms-2 ml-2">
      <span *ngIf="tokenCount?.prompt_tokens" class="text-muted small me-2">
        Latest Input: +{{getDifferenceTokens(tokenCount?.prompt_tokens, tokenHistory.previousTokenCount.prompt_tokens) | number:'1.0-0'}}
      </span>
      <span *ngIf="tokenCount?.completion_tokens" class="text-muted small me-2">
        Latest Output: +{{getDifferenceTokens(tokenCount?.completion_tokens, tokenHistory.previousTokenCount.completion_tokens) | number:'1.0-0'}}
      </span>
      <span *ngIf="tokenCost?.totalCost" class="text-muted small">
        Latest Cost: +${{getDifferenceCost(tokenCost?.totalCost, tokenHistory.previousTokenCost.totalCost) | number:'1.2-3'}}
      </span>
    </div>
  </div>

  <div class="container-fluid px-4 py-3">
    <!-- Main Upload Container - Always visible regardless of loading state -->
    <div class="main-upload-container">
      <!-- Story Upload Section -->
      <div class="card upload-card">
        <div class="card-body">

          <!-- Story Viewer Section - Always visible if there's content -->
          <div class="story-viewer-section mt-3">
            <div class="section-header position-relative">
              <h5 class="mb-1">
                <i class="ft-book-open text-primary me-2"></i> Core Story Context
              </h5>
              <p class="text-muted small">Here you can adjust the context of your core Story</p>

              <div class="ambiguities-overlay">
                <app-ambiguities-alert></app-ambiguities-alert>
              </div>
            </div>
            <div class="story-viewer-container">
              <app-story-viewer></app-story-viewer>
            </div>
          </div>

          <!-- Support Files Section - Always visible if there are files -->
          <div class="support-files-section mt-3" *ngIf="refinedStoryString">
            <div class="section-header d-flex justify-content-between align-items-center">
              <div>
                <h5 class="mb-1">
                  <i class="ft-paperclip text-primary me-2"></i> Support Files
                </h5>
                <p class="text-muted small">Add supportive files or images</p>
              </div>
              <div>
                <button class="btn btn-outline-primary btn-sm me-2"
                        (click)="openSupportFileUpload('document')">
                  <i class="ft-file me-1"></i> Add Document
                </button>
                <button class="btn btn-outline-primary btn-sm ml-1"
                        (click)="openSupportFileUpload('image')">
                  <i class="ft-image me-1"></i> Add Image
                </button>
              </div>
            </div>

            <div class="support-files-container" [class.has-files]="supportFiles?.length > 0">
              <!-- When no files are present -->
              <div class="empty-state" *ngIf="!supportFiles?.length">
                <i class="ft-upload-cloud"></i>
                <p>Click Add File to upload additional supportive files</p>
              </div>

              <!-- When files are present -->
              <div class="support-files-list" *ngIf="supportFiles?.length > 0">
                <div class="support-file-item" *ngFor="let file of supportFiles; let i = index">
                  <!-- File Header with Meta Information -->
                  <div class="d-flex align-items-center w-100">
                    <i class="ft-file text-primary"></i>
                    <span class="filename">{{file.name}}</span>
                    <div class="flex-grow-1 mx-2">
                      <input type="text"
                             class="form-control form-control-sm"
                             placeholder="File purpose (optional)"
                             [(ngModel)]="file.purpose">
                    </div>
                    <button class="btn btn-icon" (click)="removeSupportFile(i)">
                      <i class="ft-x"></i>
                    </button>
                  </div>

                  <!-- Story Viewer Component for this file -->
                  <div class="mt-2 support-file-viewer">
                    <app-story-viewer
                      [fileName]="file.name">
                    </app-story-viewer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- This card shows the test template and results - Always visible if content exists -->
  <div class="card" *ngIf="refinedStoryString && refinedStoryString.trim() !== ''">
    <div class="card-content">
      <div class="card-body">
        <ngx-spinner>
          <p *ngIf="timing">Response time: {{ elapsedTime }} seconds</p>
        </ngx-spinner>

        <!-- Tab navigation -->
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === 'story'" id="story-tab" (click)="activeTab = 'story'" role="tab" aria-controls="story" aria-selected="true">
              <i class="ft-book"></i> Template
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === 'testing'" id="testing-tab" (click)="activeTab = 'testing'" role="tab" aria-controls="testing" aria-selected="false">
              <i class="ft-check-square"></i> Testing
            </a>
          </li>
        </ul>

        <!-- Tab content -->
        <div class="tab-content">
          <!-- Sticky button -->
          <div class="sticky-button-container" [class.sticky]="isSticky" *ngIf="activeTab === 'story'">
            <div class="form-group text-right">
              <button class="btn btn-success mr-1 mb-1 mb-sm-0" (click)="goToTests()" label="Story">Area Testing
                <span style="margin-left:10px;">
                      <i class="ft-chevron-right"></i>
                    </span>
              </button>
            </div>
          </div>
          <!-- Story tab -->
          <div *ngIf="activeTab === 'story'" class="tab-pane active" id="story" role="tabpanel" aria-labelledby="story-tab">
            <div class="" *ngIf="refinedStoryString && refinedStoryString.trim() !== ''">
              <app-template
                (generateTestsEvent)="handleGenerateTests($event)">
              </app-template>
            </div>
          </div>

          <!-- Testing tab -->
          <div *ngIf="activeTab === 'testing'" class="tab-pane active" id="testing" role="tabpanel" aria-labelledby="testing-tab">
            <app-test></app-test>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal Genera -->
<ng-template #generaTestModal let-modal>
  <div class="gtt-modal-content">
    <div class="gtt-modal-header">
      <h3 class="gtt-modal-title">Genera Test</h3>
      <button type="button" class="gtt-close-button" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
    <div class="gtt-modal-body">
      <div class="gtt-form-group">
        <label for="modelmodal" class="gtt-label">Inference Model</label>
        <ng-select id="modelmodal"
                   placeholder="Seleziona modello"
                   [items]="models"
                   [(ngModel)]="selectedModel"
                   [clearable]="false"
                   (change)="updateSelectedModel()">
        </ng-select>
      </div>

      <div class="gtt-form-group">
        <label for="modelmodal" class="gtt-label">Template</label>
        <ng-select id="templatemodal"
                   placeholder="Seleziona un template"
                   [items]="templateNames"
                   [(ngModel)]="selectedTemplate"
                   [clearable]="false"
                   (change)="updateSelectedTemplate()">
        </ng-select>
      </div>

      <!-- New checkbox for scenario generation mode -->
      <div class="gtt-form-group">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="agenticModeSwitch"
                 [(ngModel)]="isAgenticMode" (change)="updateAgenticMode()">
          <label class="form-check-label" for="agenticModeSwitch">
            Agentic Mode
            <small class="text-muted ms-2"> for scenarios generation </small>
          </label>
        </div>
      </div>

      <div class="gtt-form-group">
        <label for="input" class="gtt-label">optional test generation Instructions</label>
        <textarea id="input" class="gtt-textarea" placeholder="" [(ngModel)]="istruzioniScriviTest" (change)="updateIstruzioniScriviTest()" rows="5"></textarea>
      </div>
    </div>
    <div class="gtt-modal-footer">
    </div>
  </div>
</ng-template>
