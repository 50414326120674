<div class="modal-header">
  <h4 class="modal-title">Compare and Select Tests</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <!-- Previous Tests Section -->
  <div class="mb-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h5 class="mb-0">Previous Tests</h5>
    </div>
    <div class="test-table-container">
      <table class="test-styled-table">
        <thead>
        <tr>
          <th class="test-table-header" style="width: 50px">Select</th>
          <th *ngFor="let column of displayColumns"
              class="test-table-header"
              [ngStyle]="getColumnStyle(column)">
            {{ column }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let test of oldTests">
          <td class="test-table-cell text-center">
            <input type="checkbox"
                   [id]="'old-' + test.metadata.displayId"
                   [checked]="isTestSelected(test, true)"
                   (change)="toggleTestSelection(test, true)">
          </td>
          <td *ngFor="let column of displayColumns"
              class="test-table-cell"
              [class.test-table-id]="column === 'ID'">
            <ng-container *ngIf="!isSubTable(test.data[column]); else subTableTemplate">
              <ng-container *ngIf="!isArray(test.data[column]); else listTemplate">
                <span>{{ test.data[column] }}</span>
              </ng-container>
              <ng-template #listTemplate>
                <ul class="cell-list">
                  <li *ngFor="let item of test.data[column]">{{ item }}</li>
                </ul>
              </ng-template>
            </ng-container>
            <ng-template #subTableTemplate>
              <ng-container *ngIf="!allSubTablesExpanded">
                <span class="sub-table-summary">{{ getSubTableRowCount(test.data[column]) }} rows</span>
              </ng-container>
              <table [class.d-none]="!allSubTablesExpanded" class="sub-table">
                <thead>
                <tr>
                  <th *ngFor="let subColumn of getSubTableColumns(test.data[column])">
                    {{ subColumn }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of getSubTableRows(test.data[column])">
                  <td *ngFor="let subColumn of getSubTableColumns(test.data[column])">
                    {{ row[subColumn] }}
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- New Tests Section -->
  <div class="mb-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h5 class="mb-0">New Tests</h5>
    </div>
    <div class="test-table-container">
      <table class="test-styled-table">
        <thead>
        <tr>
          <th class="test-table-header" style="width: 50px">Select</th>
          <th *ngFor="let column of displayColumns"
              class="test-table-header"
              [ngStyle]="getColumnStyle(column)">
            {{ column }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let test of newTests">
          <td class="test-table-cell text-center">
            <input type="checkbox"
                   [id]="'new-' + test.metadata.displayId"
                   [checked]="isTestSelected(test, false)"
                   (change)="toggleTestSelection(test, false)">
          </td>
          <td *ngFor="let column of displayColumns"
              class="test-table-cell"
              [class.test-table-id]="column === 'ID'">
            <ng-container *ngIf="!isSubTable(test.data[column]); else subTableTemplate">
              <ng-container *ngIf="!isArray(test.data[column]); else listTemplate">
                <span>{{ test.data[column] }}</span>
              </ng-container>
              <ng-template #listTemplate>
                <ul class="cell-list">
                  <li *ngFor="let item of test.data[column]">{{ item }}</li>
                </ul>
              </ng-template>
            </ng-container>
            <ng-template #subTableTemplate>
              <ng-container *ngIf="!allSubTablesExpanded">
                <span class="sub-table-summary">{{ getSubTableRowCount(test.data[column]) }} rows</span>
              </ng-container>
              <table [class.d-none]="!allSubTablesExpanded" class="sub-table">
                <thead>
                <tr>
                  <th *ngFor="let subColumn of getSubTableColumns(test.data[column])">
                    {{ subColumn }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of getSubTableRows(test.data[column])">
                  <td *ngFor="let subColumn of getSubTableColumns(test.data[column])">
                    {{ row[subColumn] }}
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary"
          (click)="selectAll(true)">Select All</button>
  <button type="button" class="btn btn-outline-secondary"
          (click)="selectAll(false)">Deselect All</button>
  <button type="button" class="btn btn-outline-secondary"
          (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-primary"
          (click)="confirm()">Confirm Selection</button>
</div>
