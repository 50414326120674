import {Component, OnInit, OnDestroy} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { SessionPersistenceService, SessionMetadata, CheckpointStage } from './session-persistence.service';
import { AuthService } from '../../../shared/auth/auth.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { DownloaderComponent } from '../test/downloader/downloader.component';
import Swal from 'sweetalert2';
import { StatoSessioneService } from '../../../shared/services/stato-sessione.service';
import { ApiService } from '../../../shared/services/api.service';

@Component({
  selector: 'app-session-manager',
  templateUrl: './session-manager.component.html',
  styleUrls: ['./session-manager.component.scss']
})
export class SessionManagerComponent implements OnInit, OnDestroy {
  projectId: string;
  sessions: SessionMetadata[] = [];
  loading = true;
  private destroy$ = new Subject<void>();

  // Properties needed for test-file-upload component
  templates: any[] = [];
  activeUploadMethod: 'story' | 'workspace' | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionPersistenceService,
    private sessioneService: StatoSessioneService,
    private spinnerService: SpinnerService,
    private toastr: ToastrService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    // Reset session tracking when entering the session manager
    this.sessioneService.resetAll();
    this.sessionService.resetSessionTracking();

    this.route.params.subscribe(params => {
      this.projectId = params['project_id'];
      // Update project ID in the session service so getUserSessions() can use it
      this.sessioneService.updateProjectId(this.projectId);
      this.loadUserSessions();
      this.fetchTemplateNames(this.projectId);
    });

    // Subscribe to templates
    this.sessioneService.templates$.pipe(takeUntil(this.destroy$)).subscribe(templates => {
      this.templates = templates;
    });
  }

  navigateToConfiguration(projectId: string) {
    // Stop event propagation if needed to prevent parent click handlers from firing
    event?.stopPropagation();
    // Navigate to the project configuration page
    this.router.navigate(['/projects/configuration', projectId]);
  }

  navigateToKnowledgeBase(projectId: string) {
    // Stop event propagation if needed to prevent parent click handlers from firing
    event?.stopPropagation();
    // Navigate to the project configuration page
    this.router.navigate(['/projects/knowledge', projectId]);
  }

  fetchTemplateNames(projectId: string) {
    this.apiService.getTemplates(projectId).subscribe(
      data => {
        this.sessioneService.updateTemplates(data);
      },
      error => {
        console.error('Error fetching template names', error);
      }
    );
  }

  loadUserSessions(): void {
    this.loading = true;
    this.spinnerService.show();

    // No need to pass parameters - the service gets them from observables
    this.sessionService.getUserSessions()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (sessions) => {
          this.sessions = sessions;
          this.loading = false;
          this.spinnerService.hide();
        },
        error: (error) => {
          console.error('Error loading sessions:', error);
          this.loading = false;
          this.spinnerService.hide();
          this.toastr.error('Failed to load sessions');
        }
      });
  }

  handleUploadMethodChange(method: 'story' | 'workspace' | null): void {
    this.activeUploadMethod = method;

    // We just need to update the local state
    // Navigation is now fully handled in the TestFileUploadComponent
    console.log(`Upload method changed to: ${method}`);
  }

  onTemplateSelected(templateName: string): void {
    // This is called when the test-file-upload component selects a template
    // We don't need to do anything here since the component already updates the session service
    console.log('Template selected:', templateName);
  }

  continueWithoutSession(): void {
    // Reset session tracking to ensure we don't update an existing session
    this.sessionService.resetSessionTracking();

    // Navigate to the story component without loading a session
    this.router.navigate(['/projects/story', this.projectId]);
  }

  // Updated to use soft delete (hide) instead of hard delete
  deleteSession(sessionId: string, event: Event): void {
    event.stopPropagation(); // Prevent row click from triggering

    Swal.fire({
      title: 'Hide Session',
      text: 'Are you sure you want to hide this session? You won\'t see it in your list anymore.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, hide it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();

        // Use hideSession instead of deleteSession
        this.sessionService.hideSession(sessionId).subscribe({
          next: () => {
            this.loadUserSessions();
            this.toastr.success('Session hidden successfully');
          },
          error: (error) => {
            console.error('Error hiding session:', error);
            this.spinnerService.hide();
            this.toastr.error('Failed to hide session');
          }
        });
      }
    });
  }

  loadSession(sessionId: string): void {
    this.spinnerService.show();

    this.sessionService.loadSession(sessionId).subscribe({
      next: (session) => {
        if (!session || !session.sessionData) {
          this.spinnerService.hide();
          this.toastr.error('Invalid session data received');
          return;
        }

        // Set the current session ID and name for tracking
        if (session.sessionName) {
          this.sessionService.setCurrentSession(sessionId, session.sessionName);
        }

        // Update fileName if it exists in the metadata
        if (session.sessionData.metadata) {
          // Use session name for the file name if available
          if (session.sessionData.metadata.sessionName) {
            this.sessioneService.updateFileName(session.sessionData.metadata.sessionName);
          } else {
            this.sessioneService.updateFileName('Loaded Session');
          }
        }

        // Apply all state properties directly to the service
        this.applySessionData(session.sessionData);

        this.spinnerService.hide();
        this.toastr.success('Session loaded successfully');

        // Navigate to the appropriate view based on checkpoint stage
        const checkpointStage = session.checkpointStage || 'tests';

        // For now, navigate to the story component for all stages
        // In the future, you could navigate to different components based on stage
        this.router.navigate(['/projects/story', this.projectId], {
          queryParams: {
            sessionLoaded: 'true',
            source: 'api',
            stage: checkpointStage
          }
        });
      },
      error: (error) => {
        console.error('Error loading session:', error);
        this.spinnerService.hide();
        this.toastr.error('Failed to load session');
      }
    });
  }

  /**
   * Apply session data directly to the service based on the checkpoint stage
   */
  private applySessionData(sessionData: any): void {
    // Get the checkpoint stage from metadata or default to 'tests'
    const checkpointStage = sessionData.metadata?.checkpointStage || 'tests';

    // Always apply the basic properties regardless of stage
    if (sessionData.metadata) {
      if (sessionData.metadata.projectId) {
        this.sessioneService.updateProjectId(sessionData.metadata.projectId);
      }
      if (sessionData.metadata.selectedModel) {
        this.sessioneService.updateSelectedModel(sessionData.metadata.selectedModel);
      }
      if (typeof sessionData.metadata.agenticMode === 'boolean') {
        this.sessioneService.updateAgenticMode(sessionData.metadata.agenticMode);
      }
      if (sessionData.metadata.projectLanguage) {
        this.sessioneService.updateProjectLanguage(sessionData.metadata.projectLanguage);
      }
    }

    // Update context/story if available
    if (sessionData.context) {
      this.sessioneService.updateRefinedStoryString(sessionData.context);
    }

    // Apply data parameters and story description if available
    if (sessionData.dataParameters) {
      const dataParametersResponse = {
        analysis_results: sessionData.dataParameters.analysis_results,
        token_usage: { prompt_tokens: 0, completion_tokens: 0, total_tokens: 0 }
      };
      this.sessioneService.updateDataParameters(dataParametersResponse);
    }

    if (sessionData.storyDescription) {
      const storyDescriptionResponse = {
        story_id: sessionData.storyDescription.story_id,
        title: sessionData.storyDescription.title,
        description: sessionData.storyDescription.description,
        token_usage: { prompt_tokens: 0, completion_tokens: 0, total_tokens: 0 }
      };
      this.sessioneService.updateStoryDescription(storyDescriptionResponse);
    }

    // Apply Macro Scenarios if available
    if (sessionData.macroScenarios) {
      this.sessioneService.updateMacroScenarios(sessionData.macroScenarios);
    }

    // Apply core analysis and supportive analysis for all checkpoint stages
    // This ensures scenarios checkpoint data is properly restored
    if (sessionData.coreAnalysis) {
      // We also apply supportiveAnalysis which might be null
      this.sessioneService.updateAnalysisData(
        sessionData.coreAnalysis,
        sessionData.supportiveAnalysis || null
      );
    }

    // Apply ambiguities for all checkpoint stages
    if (sessionData.ambiguities) {
      this.sessioneService.updateAmbiguities(sessionData.ambiguities);
    }

    // For 'acs' and 'tests' stages, apply criteria groups
    if (['acs', 'tests'].includes(checkpointStage) && sessionData.criteriaGroups) {
      this.sessioneService.updateCriteriaGroups(sessionData.criteriaGroups);
    }

    // For 'tests' stage only, apply test data and other test-specific fields
    if (checkpointStage === 'tests') {
      // Update template related fields
      if (sessionData.selectedTemplate) {
        this.sessioneService.updateSelectedTemplate(sessionData.selectedTemplate);
      }

      if (sessionData.istruzioniScriviTest) {
        this.sessioneService.updateIstruzioniScriviTest(sessionData.istruzioniScriviTest);
      }

      // Update generated tests
      if (Array.isArray(sessionData.generatedTests) && sessionData.generatedTests.length > 0) {
        this.sessioneService.updateGeneratedTests(sessionData.generatedTests);
      }
    }

    console.log(`Session data applied successfully for checkpoint stage: ${checkpointStage}`, sessionData);
  }

  getFormattedDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleString();
  }

  // Helper method to get a user-friendly name for checkpoint stages
  getCheckpointStageName(stage: CheckpointStage): string {
    switch (stage) {
      case 'scenarios': return 'Scenarios';
      case 'acs': return 'Analysis & Criteria';
      case 'tests': return 'Generated Tests';
      default: return 'Unknown';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
