import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

// Updated Project interface with combined project details fields
export interface Project {
  id: string;
  name: string;
  description: string;
  assignedUsers: string[];
  // Project details fields added to main Project interface
  language: 'it' | 'en';
  projectDescription: string;
  projectManager: string;
  createdAt: string;
  updatedAt: string;
}

export interface ProjectCreate {
  name: string;
  description: string;
  assignedUsers: string[];
  // Optional project details fields for creation
  language?: 'it' | 'en';
  projectDescription?: string;
  projectManager?: string;
}

export interface ProjectUpdate {
  name?: string;
  description?: string;
  assignedUsers?: string[];
  // Project details fields included in main update
  language?: 'it' | 'en';
  projectDescription?: string;
  projectManager?: string;
}

export interface SessionInfo {
  count: number;
  last_modified: string | null;
}

export interface ProjectWithSessionData extends Project {
  session_data: SessionInfo;
}

// Keep this for backward compatibility with existing code
export interface ProjectDetailsUpdate {
  language?: 'it' | 'en';
  projectDescription?: string;
  projectManager?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProgettiService {
  private apiUrl = apiUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  /**
   * Get authentication headers with token
   */
  private getHeaders(): Observable<HttpHeaders> {
    return this.authService.token$.pipe(
      take(1),
      map(token => {
        return new HttpHeaders().set('Authorization', `Bearer ${token}`);
      })
    );
  }

  /**
   * Get all projects
   */
  getAllProjects(): Observable<Project[]> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        return this.http.get<Project[]>(`${this.apiUrl}/projects`, { headers });
      }),
      catchError(error => {
        console.error('Error getting all projects:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Get projects by user
   * @param email User's email
   */
  getProjectsByUser(email: string): Observable<Project[]> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        return this.http.get<Project[]>(`${this.apiUrl}/projects/${email}`, { headers });
      }),
      catchError(error => {
        console.error(`Error getting projects for user ${email}:`, error);
        return throwError(error);
      })
    );
  }

  /**
   * Get projects with session data (combined endpoint)
   * @param email User's email
   */
  getProjectsWithSessionData(email: string): Observable<ProjectWithSessionData[]> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        const encodedEmail = encodeURIComponent(email);
        console.log(`Making request to combined endpoint for email: ${email}`);

        return this.http.get(
          `${this.apiUrl}/projects-with-sessions/${encodedEmail}`,
          {
            headers,
            observe: 'response'  // Get full response to examine it
          }
        ).pipe(
          map(response => {
            console.log('Response status:', response.status);
            console.log('Response headers:', response.headers.keys());
            console.log('Raw response body:', response.body);

            // Check if the response body is an array
            if (Array.isArray(response.body)) {
              console.log('Response is an array with', response.body.length, 'items');

              // Check first item structure if available
              if (response.body.length > 0) {
                console.log('First item keys:', Object.keys(response.body[0]));
                console.log('Has session_data?', 'session_data' in response.body[0]);
              }

              // Return the response body
              return response.body as ProjectWithSessionData[];
            } else {
              console.error('Response is not an array!');
              return [];
            }
          })
        );
      }),
      catchError(error => {
        console.error(`Error getting projects with sessions for user ${email}:`, error);
        return throwError(error);
      })
    );
  }

  /**
   * Get a specific project by ID
   * @param projectId Project ID
   */
  getProject(projectId: string): Observable<Project> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        return this.http.get<Project>(`${this.apiUrl}/project/${projectId}`, { headers });
      }),
      catchError(error => {
        console.error(`Error getting project ${projectId}:`, error);
        return throwError(error);
      })
    );
  }

  /**
   * Create a new project
   * @param project Project data
   */
  createProject(project: ProjectCreate): Observable<Project> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        return this.http.post<Project>(`${this.apiUrl}/project`, project, { headers });
      }),
      catchError(error => {
        console.error('Error creating project:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Update a project
   * @param projectId Project ID
   * @param project Updated project data
   */
  updateProject(projectId: string, project: ProjectUpdate): Observable<Project> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        return this.http.put<Project>(`${this.apiUrl}/project/${projectId}`, project, { headers });
      }),
      catchError(error => {
        console.error(`Error updating project ${projectId}:`, error);
        return throwError(error);
      })
    );
  }

  /**
   * Assign a user to a project
   * @param projectId Project ID
   * @param email User's email
   */
  assignUserToProject(projectId: string, email: string): Observable<Project> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        return this.http.put<Project>(
          `${this.apiUrl}/project/${projectId}/assign/${email}`,
          {},
          { headers }
        );
      }),
      catchError(error => {
        console.error(`Error assigning user ${email} to project ${projectId}:`, error);
        return throwError(error);
      })
    );
  }

  /**
   * Remove a user from a project
   * @param projectId Project ID
   * @param email User's email
   */
  removeUserFromProject(projectId: string, email: string): Observable<Project> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        return this.http.put<Project>(
          `${this.apiUrl}/project/${projectId}/remove/${email}`,
          {},
          { headers }
        );
      }),
      catchError(error => {
        console.error(`Error removing user ${email} from project ${projectId}:`, error);
        return throwError(error);
      })
    );
  }

  /**
   * Delete a project
   * @param projectId Project ID
   */
  removeProject(projectId: string): Observable<void> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        return this.http.delete<void>(`${this.apiUrl}/project/${projectId}`, { headers });
      }),
      catchError(error => {
        console.error(`Error deleting project ${projectId}:`, error);
        return throwError(error);
      })
    );
  }

  /**
   * Get project details (for backward compatibility)
   * @param projectId Project ID
   * @deprecated Use getProject instead, as project details are now part of the main project object
   */
  getProjectDetails(projectId: string): Observable<Project> {
    console.warn('getProjectDetails is deprecated, use getProject instead');
    return this.getProject(projectId);
  }

  /**
   * Update project details
   * @param projectId Project ID
   * @param details Project details to update
   */
  updateProjectDetails(projectId: string, details: ProjectDetailsUpdate): Observable<Project> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        return this.http.put<Project>(
          `${this.apiUrl}/project-details/${projectId}`,
          details,
          { headers }
        );
      }),
      catchError(error => {
        console.error(`Error updating details for project ${projectId}:`, error);
        return throwError(error);
      })
    );
  }

  /**
   * Get project language
   * @param projectId Project ID
   */
  getProjectLanguage(projectId: string): Observable<string> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        return this.http.get<{language: string}>(
          `${this.apiUrl}/project-language/${projectId}`,
          { headers }
        ).pipe(
          map(response => response.language)
        );
      }),
      catchError(error => {
        console.error(`Error getting language for project ${projectId}:`, error);
        return throwError(error);
      })
    );
  }
}
