import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StoryComponent } from './story/story.component';
import { TestComponent} from './test/test.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { SessionManagerComponent } from './session-manager/session-manager.component';
import { KnowledgeBaseComponent } from './knowledge-base/knowledge-base.component';
import { DatanalysisComponent } from './datanalysis/datanalysis.component';
import {AdminGuard} from '../../admin/admin-guard.service';


const routes: Routes = [
  // route for the Session Manager
  {path: 'sessions/:project_id', component: SessionManagerComponent},
  {
    path: 'sessions',
    component: SessionManagerComponent,
    data: {
      title: 'Session Manager'
    }
  },

  // route for the Knowledge Base Manager
  {path: 'knowledge/:project_id', component: KnowledgeBaseComponent},
  {
    path: 'knowledge',
    component: KnowledgeBaseComponent,
    data: {
      title: 'Knowledge Base',
    }
  },

  {path: 'story/:project_id', component: StoryComponent},
  {
    path: 'story',
    component: StoryComponent,
    data: {
      title: 'Scriptor'
    }
  },
  {path: 'test/:project_id', component: TestComponent},
  {
    path: 'test',
    component: TestComponent,
    data: {
      title: 'Scriptor'
    }
  },
  {
    path: 'configuration',
    component: ConfigurationComponent,
    data: {
      title: 'Configuration'
    }
  },
  {path: 'configuration/:project_id', component: ConfigurationComponent},

  // Data analysis route for admins only
  {
    path: 'datanalysis',
    component: DatanalysisComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Data Analytics'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectsRoutingModule { }
