import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, map, switchMap, tap, timeout} from 'rxjs/operators';
import { apiUrl } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import {StatoSessioneService} from './stato-sessione.service';
import {BaseFileResponse, ImageAnalysisResponse, isImageAnalysisResponse} from '../interfaces/fileUploadInterfaces';

export interface NoteResponse {
  id: string;
  content: string;
  date: string;
}

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private apiUrl = apiUrl

  constructor(
    private http: HttpClient, private authService: AuthService,
    private statoSessioneService: StatoSessioneService
  ) { }

  private getHeaders(): Observable<HttpHeaders> {
    return this.authService.token$.pipe(
      switchMap(token => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return new Observable<HttpHeaders>(observer => {
          observer.next(headers);
          observer.complete();
        });
      })
    );
  }

  analyzeCoverage(story: string, test: string, model: string): Observable<string> {
    const request = { story, test, model };
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<string>(`${this.apiUrl}/analyzecoverage/`, request, { headers })
      ),
      catchError(this.handleError)
    );
  }

  saveNotes(project: string, additional_context: string): Observable<any> {
    console.log(`notes aggiornate per il progetto ${project}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post(`${this.apiUrl}/savenotes/`, { project, additional_context }, { headers })
      ),
      catchError(this.handleError)
    );
  }

  retrieveAllNotes(project: string): Observable<NoteResponse[]> {
    console.log('progetto debug: ' + project)
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<NoteResponse[]>(`${this.apiUrl}/retrieveallnotes/`, { project }, { headers })
      ),
      catchError(this.handleError)
    );
  }

  retrieveSimilarNotes(project: string, story: string): Observable<NoteResponse[]> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<NoteResponse[]>(`${this.apiUrl}/retrieve_similar_notes/`, { project, story }, { headers })
      ),
      catchError(this.handleError)
    );
  }

  deleteNote(project: string, noteId: string): Observable<any> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.delete(`${this.apiUrl}/deletenote/`, {
          headers,
          body: { project, note_id: noteId }
        })
      ),
      catchError(this.handleError)
    );
  }

  updateNote(project: string, noteId: string, newContent: string): Observable<NoteResponse> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<NoteResponse>(`${this.apiUrl}/editnote/`, { project, note_id: noteId, new_content: newContent }, { headers })
      ),
      catchError(this.handleError)
    );
  }

  uploadFile(file: File): Observable<BaseFileResponse | ImageAnalysisResponse> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    console.log('Starting file upload');

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<BaseFileResponse | ImageAnalysisResponse>(`${this.apiUrl}/uploadfile/`, formData, { headers }).pipe(
          map((response) => {
            // Check if token_usage exists in the response (image analysis)
            if (isImageAnalysisResponse(response)) {
              // If image analysis was performed, update token usage
              this.statoSessioneService.updateTokenUsage(response.token_usage);
              console.log('Token usage:', response.token_usage);
            }
            return response;
          }),
          tap(response => {
            console.log('Server response:', response);
            console.log('File upload completed');
          })
        )
      ),
      catchError(error => {
        console.error('Error during upload:', error);
        return this.handleError(error);
      })
    );
  }

  generateTestCases(
    project: string,
    user: string,
    model: string,
    language: string,
    templateDesc: string | null | undefined,
    template: any[],
    story: string,
    extisting_tests: string,
    style: string,
    instructions?: string,
  ): Observable<any[]> {
    const finalTemplateDesc = (typeof templateDesc === 'string' && templateDesc.trim() !== '')
      ? templateDesc.trim()
      : 'No description provided';

    console.log(`generateTestCases called with parameters:
    modelName: ${model},
    style: ${style},
    instructions: ${instructions},
    templateDesc: ${finalTemplateDesc}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<any>(`${this.apiUrl}/generatest/`, {
          project,
          user,
          model,
          language,
          templateDesc: finalTemplateDesc,
          template,
          story,
          extisting_tests,
          style,
          instructions
        }, { headers })
          .pipe(
            timeout(300000), // 5 minutes timeout
            map(response => {
              // Handle token usage if present
              if (response.token_usage) {
                console.log('Token usage:', response.token_usage);
                this.statoSessioneService.updateTokenUsage({
                  prompt_tokens: response.token_usage?.prompt_tokens || 0,
                  completion_tokens: response.token_usage?.completion_tokens || 0,
                  total_tokens: response.token_usage?.total_tokens || 0
                });
              }

              // Extract tests array from response
              if (response.tests && Array.isArray(response.tests)) {
                return response.tests;
              }

              if (Array.isArray(response)) {
                return response;
              }

              console.warn('Unexpected response format:', response);
              return [];
            }),
            tap(results => {
              console.log('Processed response from generateTestCases:', results);
            }),
            catchError(error => {
              if (error.name === 'TimeoutError') {
                console.error('Request timed out. Data might have been saved but not received.');
              }
              return throwError(error);
            })
          )
      ),
      catchError(this.handleError)
    );
  }

  saveConfiguration(projectId: string, templateName: string, templateDesc: string, columns: any): Observable<any> {
    const url = `${this.apiUrl}/saveConfiguration/`;
    console.log(`saveConfiguration called with projectId: ${projectId}, templateName: ${templateName}, templateDesc: ${templateDesc}, columns: ${JSON.stringify(columns)}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<any>(url, { projectId, templateName, templateDesc, columns }, { headers })
      ),
      catchError(this.handleError)
    );
  }

  getTemplates(projectId: string): Observable<any> {
    const url = `${this.apiUrl}/getTemplates/${projectId}`;
    console.log(`getTemplates called with projectId: ${projectId}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get<any>(url, { headers })
      ),
      catchError(this.handleError)
    );
  }

  setDefaultTemplate(projectId: string, templateName: string): Observable<any> {
    const url = `${this.apiUrl}/setDefaultTemplate/${encodeURIComponent(projectId)}/${encodeURIComponent(templateName)}`;
    console.log(`setDefaultTemplate called with projectId: ${projectId}, templateName: ${templateName}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.put<any>(url, {}, { headers })
      ),
      catchError(this.handleError)
    );
  }

  getTemplateDetails(projectId: string, templateName: string): Observable<any> {
    const url = `${this.apiUrl}/getTemplateDetails/${projectId}/${templateName}`;
    console.log(`getTemplateDetails called with projectId: ${projectId}, templateName: ${templateName}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get<any>(url, { headers })
      ),
      catchError(this.handleError)
    );
  }

  deleteConfig(projectId: string, templateName: string): Observable<any> {
    const url = `${this.apiUrl}/deleteConfig/${encodeURIComponent(projectId)}/${encodeURIComponent(templateName)}`;
    console.log(`deleteConfig called with projectId: ${projectId}, templateName: ${templateName}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.delete(url, { headers })
      ),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // Network error (server is off or no network connection)
      console.error('Network error:', error.message);
    } else if (error.status >= 400 && error.status < 500) {
      // Client-side error
      console.error(`Client-side error: ${error.status}, body was: ${error.error}`);
    } else if (error.status >= 500) {
      // Server-side error
      console.error(`Server-side error: ${error.status}, body was: ${error.error}`);
    } else {
      // Other errors
      console.error(`Unexpected error: ${error.status}, body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
