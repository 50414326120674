<ng-template #ambiguitiesManagerModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Ambiguities Manager</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
      <i class="ft-x"></i>
    </button>
  </div>

  <div class="modal-body">
    <!-- KPI Dashboard Cards -->
    <div class="dashboard-cards">
      <!-- Unanswered Ambiguities -->
      <div class="row mb-4">
        <div class="col-12 mb-3">
          <h5 class="section-title">Unanswered Ambiguities</h5>
        </div>

        <!-- Technical Terms KPI Card -->
        <div class="col-md-6 mb-3">
          <div class="kpi-card"
               [class.empty]="getAmbiguitiesByStateAndType('unanswered', 'technical').length === 0"
               (click)="showFilteredAmbiguities('unanswered', 'technical')">
            <div class="kpi-icon">
              <i class="ft-book"></i>
            </div>
            <div class="kpi-content">
              <div class="kpi-label">Technical Terms</div>
              <div class="kpi-value">{{getAmbiguitiesByStateAndType('unanswered', 'technical').length}}</div>
            </div>
          </div>
        </div>

        <!-- Hidden Workflows KPI Card -->
        <div class="col-md-6 mb-3">
          <div class="kpi-card"
               [class.empty]="getAmbiguitiesByStateAndType('unanswered', 'workflow').length === 0"
               (click)="showFilteredAmbiguities('unanswered', 'workflow')">
            <div class="kpi-icon">
              <i class="ft-git-branch"></i>
            </div>
            <div class="kpi-content">
              <div class="kpi-label">Hidden Workflows</div>
              <div class="kpi-value">{{getAmbiguitiesByStateAndType('unanswered', 'workflow').length}}</div>
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="col-12 text-center mt-2">
          <button class="btn resolution-btn" (click)="findAutomaticKbMatches()">
            <i class="ft-upload me-2"></i>
            Find bindings from Knowledge Base
          </button>
        </div>
      </div>

      <!-- Answered Ambiguities -->
      <div class="row mb-4">
        <div class="col-12 mb-3">
          <h5 class="section-title">Answered Ambiguities</h5>
        </div>

        <!-- Technical Terms KPI Card -->
        <div class="col-md-6 mb-3">
          <div class="kpi-card"
               [class.empty]="getAmbiguitiesByStateAndType('answered', 'technical').length === 0"
               (click)="showFilteredAmbiguities('answered', 'technical')">
            <div class="kpi-icon">
              <i class="ft-book"></i>
            </div>
            <div class="kpi-content">
              <div class="kpi-label">Technical Terms</div>
              <div class="kpi-value">{{getAmbiguitiesByStateAndType('answered', 'technical').length}}</div>
            </div>
          </div>
        </div>

        <!-- Hidden Workflows KPI Card -->
        <div class="col-md-6 mb-3">
          <div class="kpi-card"
               [class.empty]="getAmbiguitiesByStateAndType('answered', 'workflow').length === 0"
               (click)="showFilteredAmbiguities('answered', 'workflow')">
            <div class="kpi-icon">
              <i class="ft-git-branch"></i>
            </div>
            <div class="kpi-content">
              <div class="kpi-label">Hidden Workflows</div>
              <div class="kpi-value">{{getAmbiguitiesByStateAndType('answered', 'workflow').length}}</div>
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="col-12 text-center mt-2" *ngIf="hasAnsweredAmbiguities()">
          <button class="btn resolution-btn" (click)="submitResolutions()">
            <i class="ft-upload me-2"></i>
            Submit All Resolutions
          </button>
        </div>
      </div>

      <!-- Solved Ambiguities -->
      <div class="row" *ngIf="getAmbiguitiesByState('solved').length > 0">
        <div class="col-12 mb-3">
          <h5 class="section-title">Solved Ambiguities</h5>
        </div>

        <!-- Technical Terms KPI Card -->
        <div class="col-md-6 mb-3">
          <div class="kpi-card solved"
               [class.empty]="getAmbiguitiesByStateAndType('solved', 'technical').length === 0"
               (click)="showFilteredAmbiguities('solved', 'technical')">
            <div class="kpi-icon">
              <i class="ft-book"></i>
            </div>
            <div class="kpi-content">
              <div class="kpi-label">Technical Terms</div>
              <div class="kpi-value">{{getAmbiguitiesByStateAndType('solved', 'technical').length}}</div>
            </div>
          </div>
        </div>

        <!-- Hidden Workflows KPI Card -->
        <div class="col-md-6 mb-3">
          <div class="kpi-card solved"
               [class.empty]="getAmbiguitiesByStateAndType('solved', 'workflow').length === 0"
               (click)="showFilteredAmbiguities('solved', 'workflow')">
            <div class="kpi-icon">
              <i class="ft-git-branch"></i>
            </div>
            <div class="kpi-content">
              <div class="kpi-label">Hidden Workflows</div>
              <div class="kpi-value">{{getAmbiguitiesByStateAndType('solved', 'workflow').length}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
  </div>
</ng-template>

<!-- Filtered Ambiguities Modal -->
<ng-template #filteredAmbiguitiesModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{filterTitle}}
      <span class="badge ms-2" [ngClass]="getStateBadgeClass(currentFilter.state)">
        {{currentFilter.state | titlecase}}
      </span>
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
      <i class="ft-x"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="ambiguities-list">
      <div
        *ngFor="let ambiguity of filteredAmbiguities; let i = index"
        class="ambiguity-item"
        [class.solved]="ambiguity.stato === 'solved'"
        (click)="openAmbiguityDetails(ambiguity)">
        <div class="header-section">
          <span class="badge me-2" [ngClass]="{'solved-badge': ambiguity.stato === 'solved'}">
            {{ambiguity.id}}
          </span>
          <h5 class="d-inline ml-1">{{ambiguity.description}}</h5>
          <span class="badge bg-info ms-2" *ngIf="hasLinkedKbItems(ambiguity)">
            <i class="ft-link me-1"></i> {{getLinkedKbItemsCount(ambiguity)}} KB Items
          </span>
        </div>
        <div class="content-section">
          <div class="section-label clarification-label">
            <i class="ft-help-circle text-purple-500"></i>
            <strong>Clarification Needed</strong>
          </div>
          <div class="section-content">
            {{ambiguity.clarification_needed}}
          </div>
        </div>
      </div>

      <!-- Empty state message -->
      <div class="empty-state" *ngIf="filteredAmbiguities.length === 0">
        <i class="ft-info"></i>
        <p>No ambiguities found for the selected criteria.</p>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="backToManager()">
      <i class="ft-arrow-left me-1"></i> Back to Manager
    </button>
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="currentFilter.state === 'answered'"
      (click)="submitFilteredResolutions()">
      <i class="ft-upload me-1"></i> Submit All
    </button>
  </div>
</ng-template>

<!-- Ambiguity Details Modal (Updated with KB integration) -->
<ng-template #ambiguityDetailsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Ambiguity Details
      <span class="badge ms-2" [ngClass]="getStateBadgeClass(selectedAmbiguity.stato)">
        {{ selectedAmbiguity.stato | titlecase }}
      </span>
    </h4>

    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
      <i class="ft-x"></i>
    </button>
  </div>

  <div class="modal-body position-relative min-height-modal">
    <!-- Main Content -->
    <div class="ambiguity-item">
      <div class="header-section d-flex justify-content-between align-items-start">
        <div>
          <span class="badge me-2">{{selectedAmbiguity.id}}</span>
          <h5 class="d-inline ml-1">{{selectedAmbiguity.description}}</h5>
        </div>
        <button
          class="btn btn-link text-danger p-0"
          (click)="deleteAmbiguity(selectedAmbiguity.id)"
          title="Delete this ambiguity">
          <i class="ft-trash-2" style="font-size: 24px;"></i>
        </button>
      </div>

      <div class="content-section mt-3">
        <div class="section-label clarification-label">
          <i class="ft-help-circle text-purple-500"></i>
          <strong>Clarification Needed</strong>
        </div>
        <div class="section-content">
          {{selectedAmbiguity.clarification_needed}}
        </div>

        <div class="section-label impact-label mt-3">
          <i class="ft-alert-triangle text-orange-500"></i>
          <strong>Impact</strong>
        </div>
        <div class="section-content">
          {{selectedAmbiguity.impact}}
        </div>

        <!-- Knowledge Base Link Section -->
        <div class="section-label kb-label mt-3" *ngIf="hasLinkedKbItems(selectedAmbiguity)">
          <i class="ft-link text-blue-500"></i>
          <strong>Linked Knowledge Base Items ({{getLinkedKbItemsCount(selectedAmbiguity)}})</strong>
        </div>
        <div class="kb-items-container" *ngIf="hasLinkedKbItems(selectedAmbiguity)">
          <div class="kb-content p-3 bg-light rounded border mb-2" *ngFor="let kbItem of selectedAmbiguity.linkedKbItems">
            <div class="d-flex justify-content-between align-items-start">
              <h6 class="mb-2">{{kbItem.title}}</h6>
              <button
                class="btn btn-sm btn-outline-danger"
                (click)="removeKnowledgeBaseLink(kbItem.kbItemId)">
                <i class="ft-x-circle"></i>
              </button>
            </div>
            <div class="mb-2">
              <strong>Term:</strong> {{kbItem.term}}
            </div>
            <div>
              <strong>Definition:</strong> {{kbItem.definition}}
            </div>
            <div *ngIf="kbItem.examples && kbItem.examples.length > 0" class="mt-2">
              <strong>Examples:</strong>
              <ul class="mb-0 ps-3">
                <li *ngFor="let example of kbItem.examples">{{example}}</li>
              </ul>
            </div>
          </div>
        </div>

        <!-- KB Link Button (shown when no KB item is linked and the ambiguity is not solved) -->
        <div class="d-flex justify-content-center my-3" *ngIf="selectedAmbiguity.stato !== 'solved'">
          <button class="btn btn-outline-primary" (click)="openKnowledgeBaseSelector()">
            <i class="ft-link me-1"></i> Link Knowledge Base Item
          </button>
        </div>

        <!-- Resolution Section (for manual resolution when not solved and no KB item linked) -->
        <div class="resolution-section" *ngIf="selectedAmbiguity.stato !== 'solved' && !hasLinkedKbItems(selectedAmbiguity)">
          <div class="section-label resolution-label mt-3">
            <i class="ft-message-square text-green-500"></i>
            <strong>Your Resolution</strong>
          </div>
          <textarea
            class="form-control resolution-input"
            rows="3"
            placeholder="Add your clarification for this ambiguity..."
            [(ngModel)]="selectedAmbiguity.resolution"
            (input)="updateAmbiguityState(selectedAmbiguity)">
          </textarea>
        </div>

        <!-- Resolution Display (when ambiguity has a manual resolution but no KB link) -->
        <div class="resolution-section" *ngIf="selectedAmbiguity.resolution && selectedAmbiguity.stato !== 'solved' && !hasLinkedKbItems(selectedAmbiguity)">
          <div class="section-label resolution-label mt-3">
            <i class="ft-message-square text-green-500"></i>
            <strong>Resolution Preview</strong>
          </div>
          <div class="section-content bg-light p-3 rounded">
            {{selectedAmbiguity.resolution}}
          </div>
        </div>

        <!-- Resolution Display for solved ambiguities -->
        <div class="resolution-display" *ngIf="selectedAmbiguity.stato === 'solved'">
          <div class="section-label resolution-label mt-3">
            <i class="ft-message-square text-green-500"></i>
            <strong>Resolution</strong>
          </div>
          <div class="section-content">
            {{selectedAmbiguity.resolution}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="backToFiltered()">Back</button>
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="selectedAmbiguity.stato === 'answered'"
      (click)="saveCurrentSession()">
      Save
    </button>
  </div>
</ng-template>

<!-- Knowledge Base Selector Modal -->
<ng-template #knowledgeBaseModalSelector let-modal>
  <div class="modal-header">
    <h4 id="kb-selector-title" class="modal-title">Select Knowledge Base Item</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()" tabindex="0">
      <i class="ft-x"></i>
    </button>
  </div>

  <div class="modal-body">
    <!-- Search Box -->
    <div class="mb-4">
      <div class="input-group">
        <span class="input-group-text">
          <i class="ft-search"></i>
        </span>
        <input
          type="text"
          class="form-control"
          placeholder="Search knowledge base items..."
          [(ngModel)]="searchTerm"
          (input)="searchKnowledgeBaseItems()">
      </div>
    </div>

    <!-- Loading Indicator -->
    <div class="text-center my-5" *ngIf="isLoadingKnowledgeBase">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <p class="mt-2">Loading knowledge base items...</p>
    </div>

    <!-- Error Message -->
    <div class="alert alert-danger" *ngIf="knowledgeBaseError">
      <i class="ft-alert-circle me-2"></i>
      {{knowledgeBaseError}}
    </div>

    <!-- Empty State -->
    <div class="empty-state" *ngIf="!isLoadingKnowledgeBase && filteredKnowledgeBaseItems.length === 0 && !knowledgeBaseError">
      <i class="ft-database"></i>
      <p>No knowledge base items found. Try adjusting your search criteria.</p>
    </div>

    <!-- KB Items List -->
    <div class="kb-items-list">
      <div
        *ngFor="let item of filteredKnowledgeBaseItems"
        class="kb-item"
        [class.kb-item-selected]="isItemAlreadyLinked(item._id)"
        (click)="selectKnowledgeBaseItem(item)">
        <div class="kb-item-header">
          <h5>{{item.title}}</h5>
          <div>
        <span class="badge bg-success me-2" *ngIf="isItemAlreadyLinked(item._id)">
          <i class="ft-check me-1"></i> Selected
        </span>
            <span class="badge bg-info" *ngIf="item.type">{{item.type | titlecase}}</span>
          </div>
        </div>
        <div class="kb-item-content">
          <div class="term-section">
            <span class="term-label">Term:</span>
            <span class="term-value">{{item.term}}</span>
          </div>
          <div class="definition-section mt-2">
            <span class="definition-label">Definition:</span>
            <p class="definition-value">{{item.definition}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Knowledge Base Selector Modal - Updated footer with explicit done button -->
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="finishKbSelection()">
      <i class="ft-check me-1"></i> Done Selecting
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="cancelKbSelection()">Cancel</button>
  </div>
</ng-template>

<!-- Button to trigger ambiguities manager -->
<div class="d-flex justify-content-between align-items-center mb-3">
  <div class="position-relative">
    <button *ngIf="ambiguities.length > 0"
            class="btn ambiguities-btn"
            (click)="openAmbiguitiesManager()">
      <i class="fa fa-question fa-lg"></i>
    </button>
    <div *ngIf="ambiguities.length > 0"
         class="speech-bubble">
      {{ambiguities.length}} ambiguities
    </div>
  </div>
</div>
