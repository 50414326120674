<div class="criteria-group" [class.compact]="compact">
  <div class="background-wrapper py-2">
    <div class="criteria-items-container">
      <div class="criteria-details">
        <div class="criteria-item">
          <div class="criteria-details">
            <!-- criterion.component.html -->
            <div class="criteria-section" *ngIf="criterion?.name">
              <div class="id-row">
                <span>{{criterion?.id}}</span>
                <div class="d-flex align-items-center gap-2">
                  <span class="small text-muted">(Ref: {{criterion?.scenario_ref}})</span>
                </div>
                <div class="d-flex gap-2">
                  <button class="btn btn-sm btn-outline-primary"
                          (click)="openEditModal(editModal)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button class="btn btn-sm btn-outline-success ml-2"
                          (click)="regenerateTests()">
                    <i class="fa fa-refresh"></i>
                  </button>
                </div>
              </div>

              <div class="criteria-row">
                <span class="criteria-label">Scenario:</span>
                <div class="criteria-content">
                  {{criterion?.name}}
                </div>
              </div>
            </div>

            <!-- Tags section -->
            <div class="criteria-section">
              <div class="criteria-row">
                <span class="criteria-label">Tags:</span>
                <div class="criteria-content tags-section">
                  <span class="tag-item" *ngFor="let tag of criterion?.tags; let i = index">
                    <span>
                      {{ tag.replace('_', ' ') }}
                    </span>
                    <i class="fa fa-times-circle delete-icon" (click)="removeTag(criterion, i)"></i>
                  </span>
                  <button class="btn btn-sm btn-outline-secondary add-test-data-btn" (click)="openAddTagModal(criterion)">
                    <i class="fa fa-plus"></i> Add Tag
                  </button>
                </div>
              </div>
            </div>

            <!-- Test Data Elements section -->
            <div class="criteria-section">
              <div class="criteria-row">
                <span class="criteria-label">Test Data:</span>
                <div class="criteria-content test-data-section">
                  <span class="test-data-item" *ngFor="let dataElement of criterion?.test_data_elements; let i = index">
                    <span (dblclick)="openEditTestDataModal(i)">
                      {{ getFieldName(dataElement) }}
                    </span>
                    <i class="fa fa-times-circle delete-icon" (click)="removeTestDataElement(i)"></i>
                  </span>
                  <button class="btn btn-sm btn-outline-secondary add-test-data-btn" (click)="openAddTestDataModal()">
                    <i class="fa fa-plus"></i> Add Test Data
                  </button>
                </div>
              </div>
            </div>

            <!-- Given section -->
            <div class="criteria-section" *ngIf="criterion?.given?.length">
              <div class="criteria-row">
                <span class="criteria-label">Given:</span>
                <div class="criteria-content">{{ criterion?.given[0] }}</div>
              </div>
              <div class="criteria-row" *ngFor="let condition of criterion?.given.slice(1)">
                <span class="criteria-label">And:</span>
                <div class="criteria-content">{{ condition }}</div>
              </div>
            </div>

            <!-- When section -->
            <div class="criteria-section" *ngIf="criterion?.when?.length">
              <div class="criteria-row">
                <span class="criteria-label">When:</span>
                <div class="criteria-content">{{ criterion?.when[0] }}</div>
              </div>
              <div class="criteria-row" *ngFor="let action of criterion?.when.slice(1)">
                <span class="criteria-label">And:</span>
                <div class="criteria-content">{{ action }}</div>
              </div>
            </div>

            <!-- Then section -->
            <div class="criteria-section" *ngIf="criterion?.then?.length">
              <div class="criteria-row">
                <span class="criteria-label">Then:</span>
                <div class="criteria-content">{{ criterion?.then[0] }}</div>
              </div>
              <div class="criteria-row" *ngFor="let result of criterion?.then.slice(1)">
                <span class="criteria-label">And:</span>
                <div class="criteria-content">{{ result }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Edit Modal Template -->
<ng-template #editModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Acceptance Criterion</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>

  <div class="modal-body" *ngIf="editingCriterion">
    <!-- Scenario Name -->
    <div class="mb-3">
      <label class="form-label">Scenario Name</label>
      <input type="text" class="form-control" [(ngModel)]="editingCriterion.name">
    </div>

    <!-- Tags -->
    <div class="mb-3">
      <label class="form-label d-block">Tags</label>
      <div class="conditions-container">
        <div *ngFor="let tag of editingCriterion.tags; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <select class="form-select"
                  [ngModel]="editingCriterion.tags[i]"
                  (ngModelChange)="editingCriterion.tags[i] = $event">
            <optgroup label="Core Tags">
              <option value="#business_critical">Business Critical</option>
              <option value="#security">Security</option>
              <option value="#api_integration">API Integration</option>
              <option value="#ui">UI</option>
              <option value="#performance">Performance</option>
              <option value="#stateful">Stateful</option>
              <option value="#validation">Validation</option>
              <option value="#async_concurrency">Async/Concurrency</option>
              <option value="#history_of_bugs">History of Bugs</option>
            </optgroup>
          </select>
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.tags, i)">
            <i class="feather icon-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.tags)">
          Add Tag
        </button>
      </div>
    </div>

    <!-- Test Data Elements -->
    <div class="mb-3">
      <label class="form-label d-block">Test Data Elements</label>
      <div class="test-data-items">
        <div *ngFor="let dataElement of editingCriterion.test_data_elements; let i = index"
             class="d-flex gap-2 mb-2 align-items-center">
          <div class="flex-grow-1">
            {{ getFieldName(dataElement) }}: {{ getTooltipValue(dataElement) }}
          </div>
          <div class="d-flex gap-2">
            <button type="button" class="btn btn-sm btn-outline-primary"
                    (click)="openEditTestDataModal(i)">
              <i class="fa fa-pencil"></i>
            </button>
            <button type="button" class="btn btn-sm btn-outline-danger"
                    (click)="removeTestDataElement(i)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="openAddTestDataModal()">
          Add Test Data
        </button>
      </div>
    </div>

    <!-- Given Conditions -->
    <div class="mb-3">
      <label class="form-label d-block">Given Conditions</label>
      <div class="conditions-container">
        <div *ngFor="let condition of editingCriterion.given; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <input type="text" class="form-control"
                 [ngModel]="editingCriterion.given[i]"
                 (ngModelChange)="editingCriterion.given[i] = $event">
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.given, i)">
            <i class="feather icon-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.given)">
          Add Given Condition
        </button>
      </div>
    </div>

    <!-- When Actions -->
    <div class="mb-3">
      <label class="form-label d-block">When Actions</label>
      <div class="conditions-container">
        <div *ngFor="let action of editingCriterion.when; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <input type="text" class="form-control"
                 [ngModel]="editingCriterion.when[i]"
                 (ngModelChange)="editingCriterion.when[i] = $event">
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.when, i)">
            <i class="feather icon-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.when)">
          Add When Action
        </button>
      </div>
    </div>

    <!-- Then Results -->
    <div class="mb-3">
      <label class="form-label d-block">Then Results</label>
      <div class="conditions-container">
        <div *ngFor="let result of editingCriterion.then; let i=index; trackBy: trackByIndex"
             class="d-flex gap-2 mb-2">
          <input type="text" class="form-control"
                 [ngModel]="editingCriterion.then[i]"
                 (ngModelChange)="editingCriterion.then[i] = $event">
          <button type="button" class="btn btn-outline-danger"
                  (click)="removeCondition(editingCriterion.then, i)">
            <i class="feather icon-trash"></i>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary"
                (click)="addCondition(editingCriterion.then)">
          Add Then Result
        </button>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveChanges(modal)">Save Changes</button>
  </div>
</ng-template>

<!-- Test Data Edit Modal -->
<ng-template #testDataEditModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ editingTestData.index >= 0 ? 'Edit' : 'Add' }} Test Data</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group mb-3">
      <label for="dataParamFieldSelect">Field Name</label>
      <!-- Show select dropdown only in Add mode -->
      <select *ngIf="editingTestData.index < 0 && availableDataParamFields.length > 0"
              class="form-control form-select mb-2"
              id="dataParamFieldSelect"
              [(ngModel)]="selectedDataParamField"
              (change)="onDataParamFieldSelected()">
        <option value="">-- Select a field --</option>
        <option *ngFor="let field of availableDataParamFields" [value]="field">
          {{ field }}
        </option>
      </select>

      <!-- Custom field input for both add and edit modes -->
      <div *ngIf="editingTestData.index < 0" class="mt-2">
        <label>Or enter custom field name:</label>
        <input type="text" class="form-control" [(ngModel)]="editingTestData.fieldName">
      </div>

      <!-- Read-only field name in Edit mode -->
      <input *ngIf="editingTestData.index >= 0"
             type="text"
             class="form-control"
             [(ngModel)]="editingTestData.fieldName"
             [readonly]="true">
    </div>

    <div class="form-group mb-3">
      <label for="testDataValue">Value/Details</label>
      <textarea class="form-control" id="testDataValue" rows="3" [(ngModel)]="editingTestData.value"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveTestDataChanges(modal)">Save</button>
  </div>
</ng-template>
