import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  ScenarioResponse,
  GroupingResponse,
  MacroScenario,
  StoryDescriptionResponse,
  MacroAnalysisResult,
  AnalysisResult,
  SupportiveAnalysisContent,
  AnalysisContent,
  DataParametersResponse,
  ExtractionResultModel,
  KnowledgeBaseItem,
  Ambiguity,
  FindKbMatchesResponse, ProcessAmbiguitiesResponse
} from './templateInterfaces';
import { apiUrl } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { SupportFile } from '../../modules/projects/story/story.component';
import { StatoSessioneService } from '../services/stato-sessione.service';

@Injectable({
  providedIn: 'root'
})
export class UserStoryService {
  private apiUrl = apiUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private statoSessioneService: StatoSessioneService
  ) {}

  private getHeaders(): Observable<HttpHeaders> {
    return this.authService.token$.pipe(
      switchMap(token => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return new Observable<HttpHeaders>(observer => {
          observer.next(headers);
          observer.complete();
        });
      })
    );
  }

  /**
   * Generate analysis from user story text
   * @param story - The user story text
   * @param model - The model identifier to use for analysis
   * @param language - The language for the response (e.g., 'en', 'it')
   * @param supportFiles - Optional support files to provide context
   * @returns Observable of AnalysisResult containing structured core, supportive analysis and ambiguities
   */
  generateAnalysis(story: string, model: string, language: string, supportFiles?: SupportFile[]): Observable<AnalysisResult> {
    return this.getHeaders().pipe(
      switchMap((headers) => {
        const formData = new FormData();
        formData.append('story', story);
        formData.append('model', model);
        formData.append('language', language);

        if (supportFiles && supportFiles.length > 0) {
          // Send content strings, purposes, and names of files as separate arrays
          supportFiles.forEach((file, index) => {
            formData.append('support_files_contents', file.content || '');
            formData.append('support_files_purposes', file.purpose || '');
            formData.append('support_files_names', file.name);
          });
        }

        console.log('Sending analysis request with formData:', {
          story,
          model,
          language,
          filesCount: supportFiles?.length || 0,
          purposes: supportFiles?.map(f => f.purpose)
        });

        const authHeader = headers.get('Authorization');
        return this.http.post<AnalysisResult>(
          `${this.apiUrl}/getanalysis/`,
          formData,
          {
            headers: new HttpHeaders({
              'Authorization': authHeader || ''
            })
          }
        );
      }),
      map((response) => {
        // Map the response to ensure consistent structure
        return {
          core_analysis: {
            scenario_analysis: response.core_analysis?.scenario_analysis || '',
            config_testdata_analysis: response.core_analysis?.config_testdata_analysis || ''
          },
          supportive_analysis: response.supportive_analysis ? {
            scenario_enrichments: response.supportive_analysis.scenario_enrichments || '',
            config_testdata_enrichments: response.supportive_analysis.config_testdata_enrichments || ''
          } : undefined,
          ambiguities: response.ambiguities || [], // Handle ambiguities with fallback to empty array
          token_usage: {
            prompt_tokens: response.token_usage?.prompt_tokens || 0,
            completion_tokens: response.token_usage?.completion_tokens || 0,
            total_tokens: response.token_usage?.total_tokens || 0
          }
        };
      }),
      tap(result => {
        console.log('Token usage:', result.token_usage);
        this.statoSessioneService.updateTokenUsage(result.token_usage);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Generate macro scenarios and ambiguity analysis from provided analysis
   * @param story - The user story text
   * @param model - The model identifier to use for analysis
   * @param language - The language for the response (e.g., 'en', 'it')
   * @param agentic_mode
   * @param analysis - The structured core analysis
   * @param supportiveAnalysis - Optional structured supportive analysis
   * @returns Observable of MacroAnalysisResult containing scenarios and ambiguities
   */
  generateMacroScenarios(
    story: string,
    model: string,
    language: string,
    agentic_mode: boolean,
    analysis: AnalysisContent,
    supportiveAnalysis?: SupportiveAnalysisContent
  ): Observable<MacroAnalysisResult> {
    return this.getHeaders().pipe(
      switchMap((headers) => {
        // Ensure analysis is properly JSON stringified
        // The server expects JSON strings for these fields
        const requestData = {
          story: story,
          model: model,
          language: language,
          agentic_mode: agentic_mode,
          // Create a JSON string representation of analysis.scenario_analysis
          analysis: JSON.stringify(analysis.scenario_analysis),
          // Create a JSON string representation of supportiveAnalysis if present
          supportiveAnalysis: supportiveAnalysis ? JSON.stringify(supportiveAnalysis.scenario_enrichments) : null
        };

        console.log('Sending macro scenarios request with:', {
          story: story.substring(0, 50) + '...',
          model,
          language,
          agentic_mode,
          analysis_preview: analysis.scenario_analysis.substring(0, 50) + '...',
          supportiveAnalysis: supportiveAnalysis ? 'provided' : 'none'
        });

        const authHeader = headers.get('Authorization');
        return this.http.post<MacroAnalysisResult>(
          `${this.apiUrl}/getmacroscenarios/`,
          requestData,
          {
            headers: new HttpHeaders({
              'Authorization': authHeader || '',
              'Content-Type': 'application/json'
            })
          }
        );
      }),
      map((response) => {
        return {
          scenarios: response.scenarios || [],
          token_usage: {
            prompt_tokens: response.token_usage?.prompt_tokens || 0,
            completion_tokens: response.token_usage?.completion_tokens || 0,
            total_tokens: response.token_usage?.total_tokens || 0
          }
        };
      }),
      tap(result => {
        console.log('Token usage:', result.token_usage);
        this.statoSessioneService.updateTokenUsage(result.token_usage);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Generate additional edge case scenarios not covered by existing scenarios
   * @param story - The user story text
   * @param model - The model identifier to use for analysis
   * @param language - The language for the response (e.g., 'en', 'it')
   * @param analysis - The structured core analysis
   * @param existingScenarios - JSON string containing all existing scenarios
   * @param existingAmbiguities - JSON string containing all existing ambiguities
   * @param supportiveAnalysis - Optional structured supportive analysis
   * @param instructions - Optional user instructions for edge case generation
   * @returns Observable of MacroAnalysisResult containing additional edge case scenarios and ambiguities
   */
  generateEdgeCaseScenarios(
    story: string,
    model: string,
    language: string,
    analysis: AnalysisContent,
    existingScenarios: string,
    existingAmbiguities: string,
    supportiveAnalysis?: SupportiveAnalysisContent,
    instructions?: string
  ): Observable<MacroAnalysisResult> {
    return this.getHeaders().pipe(
      switchMap((headers) => {
        const formData = new FormData();
        formData.append('story', story);
        formData.append('model', model);
        formData.append('language', language);

        // Core analysis
        formData.append('analysis', analysis.scenario_analysis);

        // Existing scenarios and ambiguities
        formData.append('existing_scenarios', existingScenarios);
        formData.append('existing_ambiguities', existingAmbiguities);

        // Optional supportive analysis
        if (supportiveAnalysis) {
          formData.append('scenario_enrichments', supportiveAnalysis.scenario_enrichments);
        }

        // Optional user instructions
        if (instructions && instructions.trim()) {
          formData.append('instructions', instructions.trim());
        }

        console.log('Sending edge case scenarios request with:', {
          story: story.substring(0, 50) + '...',
          model,
          language,
          scenario_analysis: analysis.scenario_analysis.substring(0, 50) + '...',
          config_analysis: analysis.config_testdata_analysis.substring(0, 50) + '...',
          existing_scenarios: existingScenarios.substring(0, 50) + '...',
          existing_ambiguities: existingAmbiguities.substring(0, 50) + '...',
          supportiveAnalysis: supportiveAnalysis ? 'provided' : 'none',
          instructions: instructions ? (instructions.length > 50 ? instructions.substring(0, 50) + '...' : instructions) : 'none'
        });

        const authHeader = headers.get('Authorization');
        return this.http.post<MacroAnalysisResult>(
          `${this.apiUrl}/getedgecasescenarios/`,
          formData,
          {
            headers: new HttpHeaders({
              'Authorization': authHeader || ''
            })
          }
        );
      }),
      map((response) => {
        return {
          scenarios: response.scenarios || [],
          token_usage: {
            prompt_tokens: response.token_usage?.prompt_tokens || 0,
            completion_tokens: response.token_usage?.completion_tokens || 0,
            total_tokens: response.token_usage?.total_tokens || 0
          }
        };
      }),
      tap(result => {
        console.log('Edge case scenarios found:', result.scenarios.length);
        console.log('Token usage:', result.token_usage);
        this.statoSessioneService.updateTokenUsage(result.token_usage);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Generate structured acceptance criteria from scenario group and story context
   * @param scenarios - The scenarios
   * @param analysis
   * @param story - The user story providing additional context
   * @param test_data_elements
   * @param model - The model identifier to use for generation
   * @param language - The desired response language (en/it)
   * @returns Observable of the structured acceptance criteria document
   */
  generateTemplate(scenarios: string, analysis: string, story: string, test_data_elements: string, model: string, language: string): Observable<ScenarioResponse> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<ScenarioResponse>(
          `${this.apiUrl}/gettemplate/`,
          { scenarios, analysis, story, test_data_elements, model, language },
          { headers }
        )
      ),
      map((response) => {
        return {
          ...response,
          token_usage: {
            prompt_tokens: response.token_usage?.prompt_tokens || 0,
            completion_tokens: response.token_usage?.completion_tokens || 0,
            total_tokens: response.token_usage?.total_tokens || 0
          }
        };
      }),
      tap(result => {
        console.log('Token usage:', result.token_usage);
        this.statoSessioneService.updateTokenUsage(result.token_usage);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Generate a user story description with title and formatted description
   * @param context - The context for the story generation
   * @param model - The model identifier to use for generation
   * @param language - The desired response language (en/it)
   * @returns Observable of the structured story description
   */
  generateStoryDescription(context: string, model: string, language: string): Observable<StoryDescriptionResponse> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<StoryDescriptionResponse>(
          `${this.apiUrl}/getstorydescription/`,
          { context, model, language },
          { headers }
        )
      ),
      map((response) => {
        return {
          ...response,
          token_usage: {
            prompt_tokens: response.token_usage?.prompt_tokens || 0,
            completion_tokens: response.token_usage?.completion_tokens || 0,
            total_tokens: response.token_usage?.total_tokens || 0
          }
        };
      }),
      tap(result => {
        console.log('Token usage:', result.token_usage);
        this.statoSessioneService.updateTokenUsage(result.token_usage);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Generate configuration parameters and test data management analysis
   * @param story - The user story text
   * @param model - The model identifier to use for analysis
   * @param language - The language for the response (e.g., 'en', 'it')
   * @param scenarios - Optional string containing scenario descriptions
   * @param analysis - Optional core analysis text
   * @param supportiveAnalysis - Optional supportive analysis text
   * @returns Observable containing configuration parameters and test data management
   */
  generateDataParameters(
    story: string,
    model: string,
    language: string,
    scenarios?: string,
    analysis?: string,
    supportiveAnalysis?: string
  ): Observable<DataParametersResponse> {
    return this.getHeaders().pipe(
      switchMap((headers) => {
        // Create a JSON payload
        const payload = {
          story,
          model,
          language,
          scenarios,
          analysis,
          supportiveAnalysis
        };

        // Remove undefined or null properties
        Object.keys(payload).forEach(key => {
          if (payload[key] === undefined || payload[key] === null) {
            delete payload[key];
          }
        });

        console.log('Sending data parameters request with:', {
          story: story.substring(0, 50) + '...',
          model,
          language,
          scenarios: scenarios ? 'provided' : 'none',
          analysis: analysis ? 'provided' : 'none',
          supportiveAnalysis: supportiveAnalysis ? 'provided' : 'none'
        });

        const authHeader = headers.get('Authorization');
        return this.http.post<DataParametersResponse>(
          `${this.apiUrl}/getdataparams/`,
          payload,
          {
            headers: new HttpHeaders({
              'Authorization': authHeader || '',
              'Content-Type': 'application/json'
            })
          }
        );
      }),
      map((response) => {
        // Handle the flexible response structure with presence flags
        return {
          analysis_results: {
            // Get the section presence flags or default to false
            has_config_parameters: response.analysis_results?.has_config_parameters ?? false,
            has_test_data_management: response.analysis_results?.has_test_data_management ?? false,
            has_base_dataset: response.analysis_results?.has_base_dataset ?? false,
            has_dynamic_data: response.analysis_results?.has_dynamic_data ?? false,
            has_sensitive_data: response.analysis_results?.has_sensitive_data ?? false,
            has_edge_cases: response.analysis_results?.has_edge_cases ?? false,

            // Get the actual data or use empty arrays
            configuration_parameters: response.analysis_results?.configuration_parameters || [],
            test_data_management: {
              base_dataset: response.analysis_results?.test_data_management?.base_dataset || [],
              dynamic_data: response.analysis_results?.test_data_management?.dynamic_data || [],
              sensitive_data: response.analysis_results?.test_data_management?.sensitive_data || [],
              edge_cases: response.analysis_results?.test_data_management?.edge_cases || []
            }
          },
          token_usage: {
            prompt_tokens: response.token_usage?.prompt_tokens || 0,
            completion_tokens: response.token_usage?.completion_tokens || 0,
            total_tokens: response.token_usage?.total_tokens || 0
          }
        };
      }),
      tap(result => {
        console.log('Data parameters results:', result.analysis_results);
        console.log('Token usage:', result.token_usage);
        this.statoSessioneService.updateTokenUsage(result.token_usage);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Process ambiguities to create knowledge base items and generate updated analysis
   * @param story - The user story text
   * @param analysis - The existing analysis text
   * @param model - The model identifier to use
   * @param language - The language for the response (e.g., 'en', 'it')
   * @param projectName - The name of the project
   * @param userName - The name of the user
   * @param ambiguities - Array of ambiguity objects with resolutions
   * @returns Observable of the updated analysis with knowledge base items count
   */
  processAmbiguities(
    story: string,
    analysis: string,
    model: string,
    language: string,
    projectName: string,
    userName: string,
    ambiguities: Ambiguity[]
  ): Observable<ProcessAmbiguitiesResponse> {
    return this.getHeaders().pipe(
      switchMap((headers) => {
        const payload = {
          story,
          analysis,
          model,
          language,
          projectName,
          userName,
          ambiguities
        };

        console.log('Sending process ambiguities request with JSON payload:', {
          ...payload,
          story: story.substring(0, 50) + '...',
          analysis: analysis.substring(0, 50) + '...',
          ambiguities: `${ambiguities.length} ambiguities`
        });

        return this.http.post<ProcessAmbiguitiesResponse>(
          `${this.apiUrl}/processambiguities/`,
          payload,
          { headers: headers }
        );
      }),
      map((response) => {
        return {
          core_analysis: {
            scenario_analysis: response?.core_analysis?.scenario_analysis || '',
            config_testdata_analysis: response?.core_analysis?.config_testdata_analysis || ''
          },
          token_usage: {
            prompt_tokens: response?.token_usage?.prompt_tokens || 0,
            completion_tokens: response?.token_usage?.completion_tokens || 0,
            total_tokens: response?.token_usage?.total_tokens || 0
          },
          kb_items_created: response?.kb_items_created || 0,
          kb_item_mappings: response?.kb_item_mappings || {}
        };
      }),
      tap(result => {
        console.log('KB items created:', result.kb_items_created);
        console.log('KB item mappings:', result.kb_item_mappings);
        console.log('Token usage:', result.token_usage);
        if (this.statoSessioneService) {
          this.statoSessioneService.updateTokenUsage(result.token_usage);
        }
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Retrieve all knowledge base items for a specific user and project
   * @param userName - The username of the owner
   * @param projectName - The project name the knowledge base items belong to
   * @returns Observable of an array of knowledge base items
   */
  getKnowledgeBaseItems(
    userName: string,
    projectName: string
  ): Observable<KnowledgeBaseItem[]> {
    return this.getHeaders().pipe(
      switchMap((headers) => {
        console.log('Fetching knowledge base items for:', {
          userName,
          projectName
        });

        const params = {
          userName,
          projectName
        };

        const authHeader = headers.get('Authorization');
        return this.http.get<KnowledgeBaseItem[]>(
          `${this.apiUrl}/knowledgebase/`,
          {
            headers: new HttpHeaders({
              'Authorization': authHeader || ''
            }),
            params
          }
        );
      }),
      tap(result => {
        console.log('Knowledge base items retrieved:', result.length);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Finds potential Knowledge Base item matches for a list of ambiguities.
   * @param ambiguities - An array of Ambiguity objects (expected without linkedKbItems).
   * @param userName - The username for KB lookup.
   * @param projectName - The project name for KB lookup.
   * @returns Observable of FindKbMatchesResponse containing updated ambiguities and token usage.
   */
  findKbMatches(
    ambiguities: Ambiguity[],
    userName: string,
    projectName: string
  ): Observable<FindKbMatchesResponse> { // <--- UPDATED return type
    return this.getHeaders().pipe(
      switchMap((headers) => {
        const url = `${this.apiUrl}/findkbmatches/`; // Matches your FastAPI endpoint path
        const requestBody = {
          ambiguities: ambiguities
        };
        const params = new HttpParams()
          .set('userName', userName)
          .set('projectName', projectName);

        console.log('Sending find KB matches request:', {
          url: url,
          userName: userName,
          projectName: projectName,
          ambiguityCount: ambiguities.length
        });

        // <--- UPDATED expected response type in http.post<>
        return this.http.post<FindKbMatchesResponse>(
          url,
          requestBody,
          {
            headers: headers,
            params: params
          }
        );
      }),
      tap((response: FindKbMatchesResponse) => { // <--- UPDATED parameter type in tap
        // Extract data from the response object
        const updatedAmbiguities = response.ambiguities;
        const tokenUsage = response.token_usage; // <-- Extract token usage

        // Log ambiguity processing results
        const linkedCount = updatedAmbiguities.filter(a => a.linkedKbItems && a.linkedKbItems.length > 0).length;
        console.log(`Find KB matches response received. ${updatedAmbiguities.length} ambiguities processed, ${linkedCount} ambiguities now have potential KB links.`);

        // Log and process token usage (consistent with other methods)
        console.log('Token usage for findKbMatches:', tokenUsage);
        if (this.statoSessioneService && tokenUsage) {
          this.statoSessioneService.updateTokenUsage(tokenUsage); // <-- Update session state
          console.log('Session token usage updated via findKbMatches.');
        } else {
          console.warn('StatoSessioneService not available or tokenUsage missing, cannot update session tokens.');
        }
      }),
      catchError(this.handleError) // Reuse your existing error handler
    );
  }

  /**
   * Handle HTTP errors
   * @param error - The HTTP error response
   * @returns An observable with a user-facing error message
   */
  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An error occurred. Please try again later.';

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      switch (error.status) {
        case 400:
          errorMessage = error.error.detail || 'Invalid request. Please check your input.';
          break;
        case 401:
          errorMessage = 'Unauthorized. Please log in again.';
          break;
        case 403:
          errorMessage = 'Access denied. You don\'t have permission to perform this action.';
          break;
        case 500:
          errorMessage = 'Server error. Please try again later.';
          break;
      }
    }

    console.error('Error in template-api service:', error);
    return throwError(() => new Error(errorMessage));
  }
}
