// tag-descriptions.ts
export interface TagDescription {
  title: string;
  coverage: string;
  purpose: string;
  isCore: boolean;
}

export const TAG_DESCRIPTIONS: Record<string, TagDescription> = {
  'business_critical': {
    title: 'Business Critical',
    coverage: 'Core business flows (e.g., payments, checkout processes, contracts, orders).',
    purpose: 'Identifies core functionality. These tests must have highest priority (smoke tests, regression tests).',
    isCore: true
  },
  'security': {
    title: 'Security',
    coverage: 'Authentication, authorizations, input injection (SQL, XSS), data protection and session management.',
    purpose: 'Security is critical for most applications handling sensitive data or transactions.',
    isCore: true
  },
  'api_integration': {
    title: 'API Integration',
    coverage: 'Integration with external services (REST, SOAP, payment gateways, microservices), network error handling (4xx, 5xx), timeouts, idempotency.',
    purpose: 'Many modern apps are API-centric. Distinguishing integration tests helps ensure error handling and robustness.',
    isCore: true
  },
  'ui': {
    title: 'UI',
    coverage: 'User interface elements (layout, forms, buttons, navigation, basic user experience).',
    purpose: 'UI is often the main point of contact with users; any defects affect usability and satisfaction.',
    isCore: true
  },
  'performance': {
    title: 'Performance',
    coverage: 'Response times, load, throughput, stress testing, spike testing.',
    purpose: 'Even if not all apps have strict requirements, performance is often an important competitive factor.',
    isCore: true
  },
  'stateful': {
    title: 'Stateful',
    coverage: 'Multi-step or transactional processes, workflows (e.g., orders with subsequent states, approval flows, rollbacks).',
    purpose: 'States increase complexity: specific tests are needed for transitions, concurrency, possible rollbacks in case of errors.',
    isCore: true
  },
  'compliance': {
    title: 'Compliance',
    coverage: 'Regulatory requirements (GDPR, HIPAA, PCI-DSS, etc.), audit logs, encryption, data retention.',
    purpose: 'Use when the app handles sensitive data or is subject to specific regulations.',
    isCore: false
  },
  'validation': {
    title: 'Validation',
    coverage: 'Input validation (email formats, numeric ranges, edge case dates), edge cases.',
    purpose: 'Use when the criterion describes complex inputs or if validation errors impact workflow and security.',
    isCore: false
  },
  'async': {
    title: 'Async',
    coverage: 'Asynchronous processes (email notifications, batches, message queues) and/or concurrency scenarios.',
    purpose: 'Use when the app has scheduled jobs, processing queues, or needs concurrency management testing.',
    isCore: false
  },
  'concurrency': {
    title: 'Concurrency',
    coverage: 'Race condition scenarios and simultaneous resource access.',
    purpose: 'Use when the app has scheduled jobs, processing queues, or needs concurrent access testing.',
    isCore: false
  },
  'high_variability': {
    title: 'High Variability',
    coverage: 'Situations with wide range of parameters or configurations (combinatorial testing, pairwise testing).',
    purpose: 'Use when functionality involves many variables (highly configurable products, forms with many options, etc.).',
    isCore: false
  },
  'history_of_bugs': {
    title: 'History of Bugs',
    coverage: 'Parts of the app where bugs or regressions have frequently occurred in the past.',
    purpose: 'Use when the organization adopts a risk-based approach, aiming to prevent reintroduction of issues in "fragile" areas.',
    isCore: false
  }
};
