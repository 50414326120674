import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash-es';
import { Observable, Subscription, of } from 'rxjs';
import { take, map, filter, debounceTime } from 'rxjs/operators';
import { CriteriaGroup } from '../../template/template.component';
import { AcceptanceCriterion, TemplateGroupInfo } from '../../../../shared/templateGen/templateInterfaces';
import { StatoSessioneService } from '../../../../shared/services/stato-sessione.service';
import {ColumnResizeService, ColumnWidthState, ESSENTIAL_COLUMNS} from './column-resize.service';
import { TestWithMetadata } from '../test-id.service';

interface TestItem {
  ID: string;
  batchIndex: number;
  [key: string]: any;
}

@Component({
  selector: 'app-test-table',
  templateUrl: './test-table.component.html',
  styleUrls: ['./test-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestTableComponent implements OnInit, OnDestroy {
  @ViewChild('compactTable') compactTable: ElementRef;
  @ViewChildren('batchTable') batchTables: QueryList<ElementRef>;

  readonly generatedTests$: Observable<TestWithMetadata[]> = this.sessioneService.generatedTests$;
  readonly criteriaGroups$: Observable<CriteriaGroup[]> = this.sessioneService.criteriaGroups$;

  @Input() columns: string[] = [];

  protected allSubTablesExpanded = false;
  isCompactView = false;
  selectedTest: TestWithMetadata | null = null;

  private subscription = new Subscription();
  private memoizedSubTableColumns = new Map<string, string[]>();
  private memoizedSubTableRows = new Map<string, any[]>();

  // Cache for hasSubTables check
  private hasSubTablesCache = new Map<string, boolean>();

  constructor(
    private modalService: NgbModal,
    private sessioneService: StatoSessioneService,
    public columnResizeService: ColumnResizeService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscription.add(
      this.generatedTests$.pipe(
        filter(tests => tests.length > 0),
        take(1),
        debounceTime(0)
      ).subscribe(() => {
        this.resetColumns();
        this.cdr.markForCheck();
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.memoizedSubTableColumns.clear();
    this.memoizedSubTableRows.clear();
    this.hasSubTablesCache.clear();
    this.columnResizeService.destroy();
  }

  toggleViewMode(): void {
    this.isCompactView = !this.isCompactView;
    setTimeout(() => this.resetColumns(), 0);
  }

  deleteTest(test: TestWithMetadata): void {
    if (!confirm('Are you sure you want to delete this test case?')) return;
    this.sessioneService.deleteTest(test.metadata.displayId);
  }

  toggleAllSubTables(): void {
    this.allSubTablesExpanded = !this.allSubTablesExpanded;

    // Force column layout update after toggling subtables
    setTimeout(() => {
      // When expanding subtables, we need to expand the columns containing subtables
      if (this.allSubTablesExpanded) {
        this.recalculateColumnsForSubtables();
      } else {
        // When collapsing, reset to normal column distribution
        this.resetColumns();
      }
      this.cdr.markForCheck();
    }, 150);
  }

  // method to recalculate column widths when subtables are expanded
  private recalculateColumnsForSubtables(): void {
    this.generatedTests$.pipe(take(1)).subscribe(tests => {
      if (tests.length === 0) return;

      // Get all columns
      const dataColumns = Object.keys(tests[0].data).filter(col => col !== 'metadata');

      // Separate essential columns from other columns
      const essentialColumns = dataColumns.filter(col =>
        this.columnResizeService.isEssentialColumn(col)
      );
      const nonEssentialColumns = dataColumns.filter(col =>
        !this.columnResizeService.isEssentialColumn(col)
      );

      // Find columns with subtables (only among non-essential columns)
      const subtableColumns = new Set<string>();
      tests.forEach(test => {
        nonEssentialColumns.forEach(column => {
          if (this.isSubTable(test.data[column])) {
            subtableColumns.add(column);
          }
        });
      });

      // Get current column state to preserve essential column widths
      let currentState: ColumnWidthState = {};

      // Use subscription to get current state safely
      this.columnResizeService.columnState$.pipe(take(1)).subscribe(state => {
        currentState = state;
      });

      // Calculate new width distribution
      const columnConfig: { [key: string]: number } = {};

      // First, keep the existing widths for all essential columns
      essentialColumns.forEach(col => {
        // Use current width if available, otherwise use default
        if (currentState[col]) {
          columnConfig[col] = currentState[col].currentWidth;
        } else {
          // Fallback default values if not in current state
          if (col === ESSENTIAL_COLUMNS.ID) {
            columnConfig[col] = 4;
          } else if (col === ESSENTIAL_COLUMNS.TYPE) {
            columnConfig[col] = 9;
          }
        }
      });

      // Always set Actions column explicitly
      columnConfig[ESSENTIAL_COLUMNS.ACTIONS] = 11;

      // Calculate total width already allocated to essential columns
      const essentialColumnsWidth = essentialColumns.reduce((total, col) => {
        return total + (columnConfig[col] || 0);
      }, 0) + columnConfig[ESSENTIAL_COLUMNS.ACTIONS];

      // Remaining width to distribute among non-essential columns
      const remainingWidth = 100 - essentialColumnsWidth;

      // For columns that have subtables
      const subtableColumnsArray = Array.from(subtableColumns);
      const regularColumns = nonEssentialColumns.filter(col => !subtableColumns.has(col));

      // Check if subtable column is the only non-essential column
      const onlySubtableColumns = subtableColumnsArray.length > 0 && regularColumns.length === 0;

      // Special handling for the case when the subtable column is the only non-essential column
      if (onlySubtableColumns) {
        // If there's only one subtable column, give it all remaining space
        if (subtableColumnsArray.length === 1) {
          const subtableColumn = subtableColumnsArray[0];
          columnConfig[subtableColumn] = remainingWidth;
        } else {
          // If multiple subtable columns but no regular columns, distribute evenly
          const widthPerColumn = remainingWidth / subtableColumnsArray.length;
          subtableColumnsArray.forEach(col => {
            columnConfig[col] = widthPerColumn;
          });
        }
      }
      // When we have both subtable columns and regular columns, allocate space more evenly
      else if (subtableColumnsArray.length > 0 && regularColumns.length > 0) {
        // Ensure regular columns get minimum readable width
        const minRegularColumnWidth = 10; // Minimum percentage to keep regular columns readable
        const totalMinWidthForRegular = regularColumns.length * minRegularColumnWidth;

        // Determine space available for subtable columns
        const spaceForSubtables = Math.max(remainingWidth - totalMinWidthForRegular, 0);

        if (spaceForSubtables > 0) {
          // Allocate space to subtable columns - make sure they don't get too much
          const maxSubtableWidth = 25; // Maximum percentage for subtable column
          const widthPerSubtable = Math.min(
            spaceForSubtables / subtableColumnsArray.length,
            maxSubtableWidth
          );

          subtableColumnsArray.forEach(col => {
            columnConfig[col] = widthPerSubtable;
          });

          // Distribute remaining width to regular columns
          const totalSubtableWidth = widthPerSubtable * subtableColumnsArray.length;
          const remainingForRegular = remainingWidth - totalSubtableWidth;

          if (remainingForRegular > 0 && regularColumns.length > 0) {
            const widthPerRegular = remainingForRegular / regularColumns.length;
            regularColumns.forEach(col => {
              columnConfig[col] = widthPerRegular;
            });
          }
        } else {
          // Not enough space - allocate minimum width to regular columns
          regularColumns.forEach(col => {
            columnConfig[col] = minRegularColumnWidth;
          });

          // Distribute whatever is left to subtable columns
          const remainingForSubtables = Math.max(remainingWidth - totalMinWidthForRegular, 0);
          if (remainingForSubtables > 0 && subtableColumnsArray.length > 0) {
            const widthPerSubtable = remainingForSubtables / subtableColumnsArray.length;
            subtableColumnsArray.forEach(col => {
              columnConfig[col] = widthPerSubtable;
            });
          } else {
            // Extreme case - not enough space for minimum widths
            const equalWidth = remainingWidth / nonEssentialColumns.length;
            nonEssentialColumns.forEach(col => {
              columnConfig[col] = equalWidth;
            });
          }
        }
      } else {
        // No subtable columns, distribute evenly among non-essential columns
        if (nonEssentialColumns.length > 0) {
          const widthPerColumn = remainingWidth / nonEssentialColumns.length;
          nonEssentialColumns.forEach(col => {
            columnConfig[col] = widthPerColumn;
          });
        }
      }

      // Update column resize service with new widths
      this.columnResizeService.initializeColumnsWithCustomWidths([
        ...dataColumns,
        ESSENTIAL_COLUMNS.ACTIONS
      ], columnConfig);
    });
  }

  // New method to check if any column has a subtable
  hasSubTables(tests: TestWithMetadata[]): boolean {
    // Use cache if we have a result for this set of tests
    const cacheKey = tests.map(t => t.metadata.displayId).join('-');
    if (this.hasSubTablesCache.has(cacheKey)) {
      return this.hasSubTablesCache.get(cacheKey)!;
    }

    if (!tests || tests.length === 0) {
      this.hasSubTablesCache.set(cacheKey, false);
      return false;
    }

    // Check if any test has a subtable in any column
    const hasSubTable = tests.some(test => {
      if (!test.data) { return false; }
      return Object.values(test.data).some(value => this.isSubTable(value));
    });

    // Cache the result
    this.hasSubTablesCache.set(cacheKey, hasSubTable);
    return hasSubTable;
  }

  openEditModal(test: TestWithMetadata, modal: any): void {
    console.log('Opening edit modal for test:', test);
    this.selectedTest = cloneDeep(test);

    // Debug log for data structure
    console.log('Cloned test:', this.selectedTest);

    // Check each column for subtable structure
    if (this.selectedTest) {
      Object.keys(this.selectedTest.data).forEach(column => {
        const value = this.selectedTest!.data[column];
        console.log(`Column ${column} type check:`, {
          isSubTable: this.isSubTable(value),
          isArray: this.isArray(value),
          isSimpleInput: this.isSimpleInput(value),
          isLongText: this.isLongText(value),
          value
        });
      });
    }

    this.modalService.open(modal, {
      ariaLabelledBy: 'testModalLabel',
      size: 'xl'
    });
  }

  saveEditedTest(): void {
    if (!this.selectedTest) { return; }

    this.generatedTests$.pipe(
      take(1),
      map(currentTests => currentTests.map(test =>
        test.metadata.displayId === this.selectedTest?.metadata.displayId
          ? this.selectedTest
          : test
      ))
    ).subscribe(updatedTests => {
      this.sessioneService.updateGeneratedTests(updatedTests);
      this.selectedTest = null;
      this.modalService.dismissAll();

      // Clear caches to force re-evaluation
      this.hasSubTablesCache.clear();
    });
  }

  get displayColumns(): string[] {
    return this.columns.filter(col => col !== 'metadata');
  }

  // Type determination for the modal
  getFieldType(value: any, columnName: string): string {
    console.log(`Checking field type for column ${columnName}:`, value);

    // Check for subtable
    if (this.isSubTable(value)) {
      console.log(`${columnName} is a subtable`);
      return 'subtable';
    }

    // Check for array
    if (this.isArray(value) && !this.isSubTable(value)) {
      console.log(`${columnName} is an array`);
      return 'array';
    }

    // Check for long text
    if (this.isLongText(value)) {
      console.log(`${columnName} is long text`);
      return 'longtext';
    }

    // Default to simple input
    console.log(`${columnName} is a simple input`);
    return 'simple';
  }

  // Table data handling methods
  isSubTable(value: any): boolean {
    const result = value &&
      typeof value === 'object' &&
      (
        (value.headers && Array.isArray(value.headers) && value.rows && Array.isArray(value.rows)) ||
        (Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' && !Array.isArray(value[0]))
      );

    return result;
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  getSubTableColumns(subTable: any): string[] {
    if (!subTable) return [];

    const cacheKey = JSON.stringify(subTable);

    if (this.memoizedSubTableColumns.size > 100) {
      this.memoizedSubTableColumns.clear();
    }

    if (this.memoizedSubTableColumns.has(cacheKey)) {
      return this.memoizedSubTableColumns.get(cacheKey)!;
    }

    let columns: string[] = [];

    if (subTable.headers) {
      columns = [...subTable.headers]; // Create a copy to avoid modifying the original
    } else if (Array.isArray(subTable) && subTable.length > 0) {
      // Get unique keys from all objects in the array (in case different rows have different properties)
      const allKeys = new Set<string>();
      subTable.forEach(row => {
        if (row && typeof row === 'object') {
          Object.keys(row).forEach(key => allKeys.add(key));
        }
      });
      columns = Array.from(allKeys);
    }

    this.memoizedSubTableColumns.set(cacheKey, columns);
    return columns;
  }

  getSubTableRows(subTable: any): any[] {
    if (!subTable) return [];

    const cacheKey = JSON.stringify(subTable);

    if (this.memoizedSubTableRows.size > 100) {
      this.memoizedSubTableRows.clear();
    }

    if (this.memoizedSubTableRows.has(cacheKey)) {
      return this.memoizedSubTableRows.get(cacheKey)!;
    }

    let rows: any[] = [];

    // Handle case where we have headers and array rows
    if (subTable.headers && Array.isArray(subTable.rows)) {
      rows = subTable.rows.map((row: any[]) => {
        // Create an object combining headers with row values
        return subTable.headers.reduce((obj: any, header: string, index: number) => {
          obj[header] = row[index];
          return obj;
        }, {});
      });
    } else if (Array.isArray(subTable)) {
      rows = subTable;
    } else if (subTable.rows && Array.isArray(subTable.rows)) {
      rows = subTable.rows;
    }

    this.memoizedSubTableRows.set(cacheKey, rows);
    return rows;
  }

  // Form handling methods
  isSimpleInput(value: any): boolean {
    return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
  }

  isLongText(value: any): boolean {
    return typeof value === 'string' && value.length > 100;
  }

  getInputType(value: any): string {
    if (typeof value === 'number') return 'number';
    if (typeof value === 'boolean') return 'checkbox';
    return 'text';
  }

  // Array manipulation methods
  addArrayItem(column: string): void {
    if (!this.selectedTest) return;

    if (!Array.isArray(this.selectedTest.data[column])) {
      this.selectedTest.data[column] = [];
    }
    this.selectedTest.data[column] = [...this.selectedTest.data[column], ''];
    this.cdr.markForCheck();
  }

  removeArrayItem(column: string, index: number): void {
    if (!this.selectedTest) return;

    this.selectedTest.data[column] = [
      ...this.selectedTest.data[column].slice(0, index),
      ...this.selectedTest.data[column].slice(index + 1)
    ];
    this.cdr.markForCheck();
  }

  // SubTable manipulation methods
  addSubTableRow(column: string): void {
    if (!this.selectedTest) return;

    const headers = this.getSubTableColumns(this.selectedTest.data[column]);
    const newRow = Object.fromEntries(headers.map(header => [header, '']));

    if (Array.isArray(this.selectedTest.data[column])) {
      this.selectedTest.data[column] = [...this.selectedTest.data[column], newRow];
    } else if (this.selectedTest.data[column].rows) {
      // Check if rows are arrays (each row is an array of values)
      if (this.selectedTest.data[column].rows.length > 0 &&
        Array.isArray(this.selectedTest.data[column].rows[0])) {
        // Create array of empty values for each header
        const newRowArray = headers.map(() => '');
        this.selectedTest.data[column] = {
          ...this.selectedTest.data[column],
          rows: [...this.selectedTest.data[column].rows, newRowArray]
        };
      } else {
        // Rows are objects
        this.selectedTest.data[column] = {
          ...this.selectedTest.data[column],
          rows: [...this.selectedTest.data[column].rows, newRow]
        };
      }
    } else {
      this.selectedTest.data[column] = [newRow];
    }

    // Clear cache
    this.memoizedSubTableRows.delete(JSON.stringify(this.selectedTest.data[column]));

    this.cdr.markForCheck();
  }

  removeSubTableRow(column: string, index: number): void {
    if (!this.selectedTest) return;

    if (Array.isArray(this.selectedTest.data[column])) {
      this.selectedTest.data[column] = [
        ...this.selectedTest.data[column].slice(0, index),
        ...this.selectedTest.data[column].slice(index + 1)
      ];
    } else if (this.selectedTest.data[column].rows) {
      this.selectedTest.data[column] = {
        ...this.selectedTest.data[column],
        rows: [
          ...this.selectedTest.data[column].rows.slice(0, index),
          ...this.selectedTest.data[column].rows.slice(index + 1)
        ]
      };
    }

    // Clear cache
    this.memoizedSubTableRows.delete(JSON.stringify(this.selectedTest.data[column]));

    this.cdr.markForCheck();
  }

  // Add a separate trackByIndex function for arrays within the edit modal
  trackByIndex(index: number): number {
    return index;
  }

  // Batch handling methods
  getTestsByBatch(): Observable<Array<{
    groupIndex: number;
    criterionIndex: number;
    tests: TestWithMetadata[];
  }>> {
    return this.generatedTests$.pipe(
      map(tests => {
        if (!tests.length) return [];

        // Group tests by group and criterion indices from metadata
        const groupedTests = tests.reduce((acc, test) => {
          // Always use the metadata fields directly for grouping
          const key = `${test.metadata.groupIndex}-${test.metadata.criterionIndex}`;

          if (!acc[key]) {
            acc[key] = {
              groupIndex: test.metadata.groupIndex,
              criterionIndex: test.metadata.criterionIndex,
              tests: []
            };
          }
          acc[key].tests.push(test);
          return acc;
        }, {} as Record<string, {
          groupIndex: number;
          criterionIndex: number;
          tests: TestWithMetadata[];
        }>);

        // Sort by group and criterion indices
        return Object.values(groupedTests).sort((a, b) => {
          if (a.groupIndex !== b.groupIndex) {
            return a.groupIndex - b.groupIndex;
          }
          return a.criterionIndex - b.criterionIndex;
        });
      })
    );
  }

  getCriterionForBatch(groupIndex: number, criterionIndex: number): Observable<{
    criterion: AcceptanceCriterion | null;
    groupInfo: TemplateGroupInfo | null;
    groupIndex: number;
    criterionIndex: number;
  }> {
    return this.criteriaGroups$.pipe(
      map(groups => {
        // Handle case when group doesn't exist
        if (!groups || groupIndex < 0 || groupIndex >= groups.length) {
          // Instead of warning, silently handle the case
          return {
            criterion: null,
            groupInfo: null,
            groupIndex,
            criterionIndex
          };
        }

        const group = groups[groupIndex];

        // Handle case when criterion doesn't exist
        if (!group.acceptance_criteria ||
          criterionIndex < 0 ||
          criterionIndex >= group.acceptance_criteria.length) {
          // Instead of warning, silently handle the case
          return {
            criterion: null,
            groupInfo: group.group_info || null,
            groupIndex,
            criterionIndex
          };
        }

        const criterion = group.acceptance_criteria[criterionIndex];

        return {
          criterion: criterion,
          groupInfo: group.group_info,
          groupIndex,
          criterionIndex
        };
      })
    );
  }

  // Utility methods
  trackByFn(index: number, item: any): string | number {
    // Safely handle both TestWithMetadata objects and regular objects
    if (item && item.metadata && item.metadata.displayId) {
      return item.metadata.displayId;
    } else if (item && item.ID) {
      return item.ID;
    } else {
      return index; // Fallback to index if no ID is found
    }
  }

  getSubTableRowCount(subTableData: any): number {
    if (Array.isArray(subTableData)) {
      return subTableData.length;
    }
    return subTableData?.rows?.length || 0;
  }

  resetColumns(): void {
    setTimeout(() => {
      // Get first test to determine columns from its data property
      this.generatedTests$.pipe(take(1)).subscribe(tests => {
        if (tests.length > 0) {
          // Get columns from the data property of the first test
          const dataColumns = Object.keys(tests[0].data);
          // Filter out any metadata properties
          const displayColumns = dataColumns.filter(col => col !== 'metadata');

          // Set initial default widths - essential columns get fixed widths
          const initialWidths: { [key: string]: number } = {};

          // Set fixed widths for essential columns
          if (displayColumns.includes(ESSENTIAL_COLUMNS.ID)) {
            initialWidths[ESSENTIAL_COLUMNS.ID] = 4;
          }

          if (displayColumns.includes(ESSENTIAL_COLUMNS.TYPE)) {
            initialWidths[ESSENTIAL_COLUMNS.TYPE] = 9;
          }

          // Always set Actions column
          initialWidths[ESSENTIAL_COLUMNS.ACTIONS] = 5;

          // Calculate remaining width
          const essentialWidth = Object.values(initialWidths).reduce((sum, w) => sum + w, 0);
          const remainingWidth = 100 - essentialWidth;

          // Count non-essential columns
          const nonEssentialColumns = displayColumns.filter(col =>
            !this.columnResizeService.isEssentialColumn(col)
          );

          // Distribute remaining width evenly
          if (nonEssentialColumns.length > 0) {
            const widthPerColumn = remainingWidth / nonEssentialColumns.length;
            nonEssentialColumns.forEach(col => {
              initialWidths[col] = widthPerColumn;
            });
          }

          // Initialize the columns in the resize service
          this.columnResizeService.initializeColumnsWithCustomWidths([
            ...displayColumns,
            ESSENTIAL_COLUMNS.ACTIONS
          ], initialWidths);

          this.cdr.markForCheck();
        }
      });
    }, 0);
  }

  getCellStyle(column: string): object {
    if (column === ESSENTIAL_COLUMNS.TYPE) {
      return {
        fontSize: '0.65rem'
      };
    }

    return {};
  }

  getColumnStyle(column: string): object {
    return this.columnResizeService.getColumnStyle(column);
  }

  // Add method to handle column resize events
  startResize(event: MouseEvent, columnIndex: number, tableElement: HTMLElement): void {
    this.columnResizeService.startResize(event, columnIndex, tableElement);
  }
}
