import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';
import { AuthService, UserData, UserRole } from '../auth/auth.service';
import { ProgettiService, Project } from './progetti.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import {StatoSessioneService} from './stato-sessione.service';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  private routesSubject: BehaviorSubject<RouteInfo[]>;
  public routes$: Observable<RouteInfo[]>;

  private readonly baseRoutes: RouteInfo[] = [
    {
      path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    }
  ];

  constructor(
    private authService: AuthService,
    private projectService: ProgettiService,
    private statoSessioneService: StatoSessioneService,
    private router: Router
  ) {
    this.routesSubject = new BehaviorSubject<RouteInfo[]>([]);
    this.routes$ = this.routesSubject.asObservable();

    this.initializeRoutes();
    // this.setupRouteChangeListener();
  }

  private setupRouteChangeListener() {
    // Listen for route changes to reset state when navigating between projects
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Reset state when navigating to a new project
      if (event.url.includes('/projects/')) {
        this.statoSessioneService.resetAll();
      }
    });
  }

  // When changing user, refresh the routes to projects
  private initializeRoutes() {
    this.authService.getCurrentUserData().pipe(
      switchMap((userData: UserData | null) => {
        if (!userData) {
          return of([this.baseRoutes, false, []]);
        }
        return combineLatest([
          of(this.baseRoutes),
          this.authService.hasRole('Admin'),
          this.projectService.getProjectsByUser(userData.uid)
        ]);
      })
    ).subscribe(([initialRoutes, isAdmin, userProjects]: [RouteInfo[], boolean, Project[]]) => {
      const projectsRoute: RouteInfo = {
        path: '', title: 'Projects', icon: 'ft-box', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-info float-right mr-1 mt-1', isExternalLink: false,
        submenu: userProjects.map(project => ({
          path: `/projects/sessions/${project.name}`,
          title: project.name,
          icon: 'ft-layout',
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        }))
      };

      const updatedRoutes = [...initialRoutes, projectsRoute];

      if (isAdmin) {
        // Original admin route
        const adminRoute: RouteInfo = {
          path: '/admin', title: 'Admin Panel', icon: 'ft-shield', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        };

        // New data analysis route for admins only
        const datanalysisRoute: RouteInfo = {
          path: '/projects/datanalysis',  // Change from '/datanalysis' to '/projects/datanalysis'
          title: 'Data Analytics',
          icon: 'ft-bar-chart-2',
          class: '',
          badge: 'NEW',
          badgeClass: 'badge badge-pill badge-success float-right mr-1 mt-1',
          isExternalLink: false,
          submenu: []
        };

        // Add both admin routes
        updatedRoutes.push(adminRoute, datanalysisRoute);
      }

      this.routesSubject.next(updatedRoutes);
    });
  }

  /**
   * Force refresh of routes (useful after project changes)
   */
  public refreshRoutes(): void {
    this.initializeRoutes();
  }
}
