import {Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { StatoSessioneService } from 'app/shared/services/stato-sessione.service';
import {ApiService} from '../../../shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import {merge, Subject} from 'rxjs';
import {filter, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr'; // Import Subscription
import { ProgettiService } from '../../../shared/services/progetti.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import {AuthService} from '../../../shared/auth/auth.service';
import {DownloaderComponent} from './downloader/downloader.component';
import Swal from 'sweetalert2';
import {SessionPersistenceService} from '../session-manager/session-persistence.service';


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit, OnDestroy {
  @ViewChild('stepTesting') testing: ElementRef;
  private destroy$: Subject<void> = new Subject<void>();
  uid: string | null = null;
  hasSubtable = false;
  models: string[];
  testStyle: string;
  templates: any[];
  templateNames: any[];
  istruzioniScriviTest: string;
  testFileName: string;
  storyFileName: string;
  selectedModel: string;
  selectedTemplate: string;
  refinedStoryString: string;
  generatedTests: any[] = [];
  columns: string[] = [];
  testCount = 0;
  @ViewChild('testFileInputHtml') fileInputHtml: ElementRef<HTMLInputElement>;
  i: number;
  projectId: string;
  projectLanguage = 'en';
  isGeneratingTests = false;
  totalCriteria = 0;
  completedCriteria = 0;

  // In your TestComponent, add this property:
  filteredProgress$ = this.sessioneService.testGenerationProgress$.pipe(
    filter(progress => progress?.completedCriteria > 0)
  );

  constructor(
    private apiService: ApiService,
    protected sessioneService: StatoSessioneService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public spinnerService: SpinnerService,
    private toastr: ToastrService,
    private progettiService: ProgettiService,
    private authService: AuthService,
    private downloader: DownloaderComponent,
    private sessionPersistence: SessionPersistenceService
  ) {}

  ngOnInit(): void {
    this.initializeData();
    this.setupSubscriptions();
  }

  private initializeData(): void {
    this.testFileName = '';
    this.istruzioniScriviTest = '';
    this.models = ['Haiku', 'Sonnet', 'Sonnet 3.5', 'Omni', 'Gemini Flash', 'Gemini Pro'];
    this.checkForSubtable();
  }

  private setupSubscriptions(): void {
    const subscriptions = [
      this.sessioneService.testGenerationProgress$.pipe(
        tap((progress) => {
          this.isGeneratingTests = progress.completedCriteria < progress.totalCriteria;
          this.totalCriteria = progress.totalCriteria;
          this.completedCriteria = progress.completedCriteria;
        })
      ),
      this.sessioneService.refinedStoryString$.pipe(
        tap(refinedStoryString => this.refinedStoryString = refinedStoryString)
      ),
      this.sessioneService.selectedTemplate$.pipe(
        tap(template => {
          this.selectedTemplate = template;
          this.checkForSubtable();
        })
      ),
      this.sessioneService.templates$.pipe(
        tap(templates => {
          this.templates = templates;
          this.templateNames = this.templates.map(template => template.templateName);
          if (!this.selectedTemplate && this.templateNames.length > 0) {
            this.sessioneService.updateSelectedTemplate(this.templateNames[0]);
          }
          this.checkForSubtable();
        })
      ),
      this.sessioneService.fileName$.pipe(
        tap(storyFileName => this.storyFileName = storyFileName)
      ),
      this.sessioneService.testFileName$.pipe(
        tap(testFileName => this.testFileName = testFileName)
      ),
      this.sessioneService.selectedModel$.pipe(
        tap(model => this.selectedModel = model)
      ),
      this.sessioneService.projectId$.pipe(
        tap(projectId => this.projectId = projectId)
      ),
      this.sessioneService.projectLanguage$.pipe(
        tap(lang => this.projectLanguage = lang)
      ),
      this.sessioneService.selectedTestStyle$.pipe(
        tap(style => this.testStyle = style)
      ),
      this.sessioneService.istruzioniScriviTest$.pipe(
        tap(instruct => this.istruzioniScriviTest = instruct)
      ),
      this.authService.email$.pipe(
        tap(email => this.uid = email)
      ),
      // Keep only the minimal subscription for generated tests in parent
      this.sessioneService.generatedTests$.pipe(
        tap((generatedTests: any[]) => {
          this.generatedTests = generatedTests;
        })
      ),
      this.sessioneService.testCount$.pipe(
        tap(count => this.testCount = count)
      ),
      this.sessioneService.columns$.pipe(
        tap((columns: string[]) => {
          this.columns = columns;
        })
      )
    ];

    merge(...subscriptions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {},
        error => console.error('Error in TestComponent:', error)
      );
  }

  checkForSubtable(): void {
    if (this.selectedTemplate && this.templates) {
      const selectedTemplateObj = this.templates.find(template => template.templateName === this.selectedTemplate);
      if (selectedTemplateObj) {
        this.hasSubtable = this.templateHasSubtable(selectedTemplateObj);
      }
    }
  }

  templateHasSubtable(template: any): boolean {
    if (template && template.columns && Array.isArray(template.columns)) {
      return template.columns.some(column =>
        column.list && Array.isArray(column.subColumns) && column.subColumns.length > 0
      );
    }
    return false;
  }

  generateTests(testStyle: string = '') {
    this.modalService.dismissAll();

    const testParams = this.sessioneService.getCurrentTestParams();
    if (!testParams) { return; }

    if (testParams.index === 0) {
      this.spinnerService.show();
    }

    // Get all groups
    const groups = this.sessioneService.getCriteriaGroups();
    let currentGroupIndex = -1;
    let currentCriterionIndex = -1;
    let globalIndex = 0;

    // Find the group and criterion indices
    for (let groupIdx = 0; groupIdx < groups.length; groupIdx++) {
      const group = groups[groupIdx];
      for (let critIdx = 0; critIdx < group.acceptance_criteria.length; critIdx++) {
        if (globalIndex === testParams.index) {
          currentGroupIndex = groupIdx;
          currentCriterionIndex = critIdx;
          break;
        }
        globalIndex++;
      }
      if (currentGroupIndex !== -1) { break; }
    }

    // Get existing tests for the same group and then make the API call
    this.sessioneService.getTestsForGroup(currentGroupIndex)
      .pipe(take(1))
      .subscribe(existingTests => {
        const existingTestsData = existingTests.length > 0
          ? JSON.stringify(existingTests.map(t => t.data), null, 2)
          : '';

        const selectedTemplateObj = this.templates.find(
          template => template.templateName === this.selectedTemplate
        );
        const selectedTemplateColumns = selectedTemplateObj?.columns || [];
        const selectedTemplateDesc = selectedTemplateObj?.templateDescription || '';

        // Create a separate Subject to handle cleanup for this specific call
        const cleanup$ = new Subject<void>();
        this.apiService.generateTestCases(
          this.projectId,
          this.uid,
          this.selectedModel,
          this.projectLanguage,
          selectedTemplateDesc,
          selectedTemplateColumns,
          testParams.criterionText,
          existingTestsData,
          testStyle,
          this.istruzioniScriviTest
        ).pipe(
          take(1),
          takeUntil(cleanup$),
          takeUntil(this.destroy$)
        ).subscribe({
          next: (response) => {
            this.sessioneService.updateTestGenerationProgress(response, testParams.index);

            if (testParams.index === 0) {
              this.spinnerService.hide();
            }

            cleanup$.next();
            cleanup$.complete();

            const nextParams = this.sessioneService.getNextTestParams();
            if (nextParams) {
              this.sessioneService.initiateGenerateTestsWithParams(nextParams);
              this.generateTests(testStyle);
            } else {
              // All tests are generated, save the session automatically
              // Create a name based on the test or story file name
              const sessionName = `${this.testFileName || this.storyFileName || 'Session'}_${new Date().toISOString().slice(0, 10)}`;
              this.saveToBackend(sessionName);
            }
          },
          error: (error) => {
            // Existing error handling remains unchanged...

            const nextParams = this.sessioneService.getNextTestParams();
            if (nextParams) {
              this.sessioneService.initiateGenerateTestsWithParams(nextParams);
              this.generateTests(testStyle);
            }
            // Don't save on error
          }
        });
      });
  }

  onTemplateSelected(templateName: string): void {
    this.selectedTemplate = templateName;
    this.sessioneService.updateSelectedTemplate(templateName);
    this.checkForSubtable();
  }

  downloadGeneratedTests() {
    this.downloader.saveCurrentState(
      this.uid,
      this.testFileName,
    );
  }

  downloadCSVGeneratedTests() {
    // Filter out metadata column if present
    const availableColumns = this.columns.filter(col => col !== 'metadata');
    const selectedColumns = [...availableColumns]; // Default all selected

    // Create checkbox HTML for each column
    const columnsHtml = availableColumns.map((column, index) => {
      return `
      <div class="form-check my-2">
        <input type="checkbox" class="form-check-input swal-column-checkbox"
               id="column-${index}" value="${column}" checked>
        <label class="form-check-label" for="column-${index}">${column}</label>
      </div>
    `;
    }).join('');

    // Create select for delimiter
    const delimiterHtml = `
    <div class="form-group mt-4">
      <label for="delimiter">Delimiter:</label>
      <select id="delimiter" class="form-control">
        <option value="|">Pipe (|)</option>
        <option value=",">Comma (,)</option>
        <option value=";">Semicolon (;)</option>
        <option value="\t">Tab</option>
      </select>
    </div>
  `;

    // Create select/deselect all
    const selectAllHtml = `
    <div class="form-check mb-3">
      <input type="checkbox" class="form-check-input" id="select-all" checked>
      <label class="form-check-label" for="select-all">Select/Deselect All</label>
    </div>
    <hr>
  `;

    Swal.fire({
      title: 'Select Columns to Export',
      html: `
      <div class="text-left">
        ${selectAllHtml}
        <div style="max-height: 300px; overflow-y: auto;">
          ${columnsHtml}
        </div>
        ${delimiterHtml}
      </div>
    `,
      showCancelButton: true,
      confirmButtonText: 'Export',
      cancelButtonText: 'Cancel',
      didOpen: () => {
        // Add select all functionality
        const selectAllCheckbox = document.getElementById('select-all') as HTMLInputElement;
        const columnCheckboxes = document.querySelectorAll('.swal-column-checkbox') as NodeListOf<HTMLInputElement>;

        selectAllCheckbox.addEventListener('change', () => {
          columnCheckboxes.forEach(checkbox => {
            checkbox.checked = selectAllCheckbox.checked;
          });
        });
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // Get selected columns
        const selectedColumns = Array.from(
          document.querySelectorAll('.swal-column-checkbox:checked')
        ).map((checkbox: HTMLInputElement) => checkbox.value);

        // Get selected delimiter
        const delimiterSelect = document.getElementById('delimiter') as HTMLSelectElement;
        const delimiter = delimiterSelect.value;

        if (selectedColumns.length === 0) {
          this.toastr.warning('Please select at least one column to export');
          return;
        }

        // Call the download method with selected columns
        this.downloader.exportTestsAsCSVWithColumns(
          this.testFileName || this.storyFileName || 'exported-tests',
          selectedColumns,
          delimiter
        );
      }
    });
  }

  downloadStory() {
  }

  private removeTestBeforeStory(fileName: string): string {
    const testBeforeStoryRegex = /Tests\W*Story/;
    if (testBeforeStoryRegex.test(fileName)) {
      return fileName.replace(/Tests\W*(?=Story)/, '');
    }
    return fileName;
  }

  /**
   * Export tests to Excel with flattened subtable rows using SweetAlert for column selection
   */
  downloadExcelGeneratedTests() {
    // Filter out metadata column if present
    const availableColumns = this.columns.filter(col => col !== 'metadata');
    const selectedColumns = [...availableColumns]; // Default all selected

    // Create checkbox HTML for each column
    const columnsHtml = availableColumns.map((column, index) => {
      return `
      <div class="form-check my-2">
        <input type="checkbox" class="form-check-input swal-column-checkbox"
               id="column-${index}" value="${column}" checked>
        <label class="form-check-label" for="column-${index}">${column}</label>
      </div>
    `;
    }).join('');

    // Create select/deselect all
    const selectAllHtml = `
    <div class="form-check mb-3">
      <input type="checkbox" class="form-check-input" id="select-all" checked>
      <label class="form-check-label" for="select-all">Select/Deselect All</label>
    </div>
    <hr>
  `;

    // Create Excel options
    const excelOptionsHtml = `
    <div class="form-group mt-4">
      <label for="sheetName">Sheet Name:</label>
      <input type="text" class="form-control" id="sheetName" value="Tests" placeholder="Sheet name">
    </div>
    <div class="form-check mt-3">
      <input type="checkbox" class="form-check-input" id="addFilters" checked>
      <label class="form-check-label" for="addFilters">Add filters to Excel columns</label>
    </div>
    <div class="form-check mt-2">
      <input type="checkbox" class="form-check-input" id="freezeHeader" checked>
      <label class="form-check-label" for="freezeHeader">Freeze header row</label>
    </div>
  `;

    Swal.fire({
      title: 'Select Columns for Excel Export',
      html: `
      <div class="text-left">
        <div class="alert alert-info" style="font-size: 0.9rem;">
          This export will create an Excel file where each row in the subtable
          will be expanded into separate rows, duplicating the parent test data.
        </div>
        ${selectAllHtml}
        <div style="max-height: 300px; overflow-y: auto; padding-right: 10px;">
          ${columnsHtml}
        </div>
        ${excelOptionsHtml}
      </div>
    `,
      showCancelButton: true,
      confirmButtonText: 'Export',
      cancelButtonText: 'Cancel',
      width: '600px',
      customClass: {
        container: 'my-swal'
      },
      didOpen: () => {
        // Add select all functionality
        const selectAllCheckbox = document.getElementById('select-all') as HTMLInputElement;
        const columnCheckboxes = document.querySelectorAll('.swal-column-checkbox') as NodeListOf<HTMLInputElement>;

        selectAllCheckbox.addEventListener('change', () => {
          columnCheckboxes.forEach(checkbox => {
            checkbox.checked = selectAllCheckbox.checked;
          });
        });

        // Add event listeners for individual checkboxes too
        columnCheckboxes.forEach(checkbox => {
          checkbox.addEventListener('change', () => {
            // Update select all checkbox if all individual checkboxes are checked or unchecked
            selectAllCheckbox.checked = Array.from(columnCheckboxes).every(cb => cb.checked);
          });
        });
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // Get selected columns
        const selectedColumns = Array.from(
          document.querySelectorAll('.swal-column-checkbox:checked')
        ).map((checkbox: HTMLInputElement) => checkbox.value);

        // Get Excel options
        const sheetName = (document.getElementById('sheetName') as HTMLInputElement).value || 'Tests';
        const addFilters = (document.getElementById('addFilters') as HTMLInputElement).checked;
        const freezeHeader = (document.getElementById('freezeHeader') as HTMLInputElement).checked;

        if (selectedColumns.length === 0) {
          this.toastr.warning('Please select at least one column to export');
          return;
        }

        // Show spinner while processing (for large datasets)
        this.spinnerService.show();

        // Generate a filename based on test/story filename or default
        const filename = this.testFileName || this.storyFileName || 'exported-tests';

        // Call the Excel export method with options
        this.downloader.exportTestsAsExcelWithSubtables(
          filename,
          selectedColumns,
          {
            sheetName,
            addFilters,
            freezeHeader
          }
        );

        // Hide spinner after a short delay to ensure file generation completes
        setTimeout(() => {
          this.spinnerService.hide();
          // Show success message
          this.toastr.success('Excel export completed successfully!');
        }, 500);
      }
    });
  }
  // Add new method to save to backend
  saveToBackend(sessionName: string): void {
    // Use a default name if none provided
    const name = sessionName || `Session_${new Date().toISOString().slice(0, 10)}`;

    this.spinnerService.show();
    this.sessionPersistence.saveCurrentSession(name).subscribe({
      next: (response) => {
        console.log('State saved successfully', response);
        this.spinnerService.hide();
        this.toastr.success('Session saved successfully!');
      },
      error: (error) => {
        console.error('Error saving state:', error);
        this.spinnerService.hide();
        this.toastr.error('Failed to save session');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
