import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, combineLatest, of, from } from 'rxjs';
import { take, switchMap, tap, map, catchError } from 'rxjs/operators';
import { apiUrl } from '../../../../environments/environment';
import { AuthService } from '../../../shared/auth/auth.service';
import { StatoSessioneService } from '../../../shared/services/stato-sessione.service';
import { TokenCount } from '../../../shared/templateGen/templateInterfaces';

export type CheckpointStage = 'scenarios' | 'acs' | 'tests';

export interface SessionMetadata {
  sessionId: string;
  sessionName: string;
  createdAt: string;
  lastModified: string;
  checkpointStage: CheckpointStage;
  tokenUsage?: TokenCount; // Add token usage to metadata
}

export interface SaveSessionRequest {
  email: string;
  projectId: string;
  sessionName: string;
  sessionData: any; // This will be your SavedAppState
  checkpointStage: CheckpointStage;
  sessionIdToUpdate?: string; // Optional: If set, update this session
  tokenUsage?: TokenCount; // Add token usage to save request
}

@Injectable({
  providedIn: 'root'
})
export class SessionPersistenceService {
  private apiUrl = apiUrl;

  // Store session ID to allow updating the same session
  private currentSessionId: string | null = null;
  private currentSessionName: string | null = null;

  // Flag to indicate a new workflow
  private isNewWorkflow = true;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private sessioneService: StatoSessioneService
  ) {}

  /**
   * Gets authentication headers with token
   * @returns Observable of HttpHeaders with the auth token
   */
  private getHeaders(): Observable<HttpHeaders> {
    return this.authService.token$.pipe(
      take(1),
      map(token => {
        return new HttpHeaders().set('Authorization', `Bearer ${token}`);
      })
    );
  }

  /**
   * Save or update the current session
   * @param sessionName Name to identify this session
   * @param checkpointStage The checkpoint stage
   * @returns Observable with the response
   */
  saveCurrentSession(sessionName: string, checkpointStage: CheckpointStage = 'tests'): Observable<any> {
    console.log(`Saving session at checkpoint: ${checkpointStage}, name: ${sessionName}, current ID: ${this.currentSessionId}`);

    // Use existing session name if we're updating, or create a unique name for new workflow
    let finalSessionName = this.currentSessionName;

    // If we don't have a current session name or we're starting a new workflow
    if (!finalSessionName || this.isNewWorkflow) {
      finalSessionName = this.generateUniqueSessionName(sessionName);
      // We're no longer in a new workflow after first save
      this.isNewWorkflow = false;
    }

    return this.getHeaders().pipe(
      switchMap(headers => {
        return combineLatest([
          this.authService.email$.pipe(take(1)),
          this.sessioneService.projectId$.pipe(take(1)),
          this.sessioneService.tokenUsage$.pipe(take(1)) // Get current token usage
        ]).pipe(
          switchMap(([email, projectId, tokenUsage]) => {
            if (!email || !projectId) {
              throw new Error('Missing email or project ID');
            }

            console.log('Including token usage in save request:', tokenUsage);

            // Convert Promise to Observable
            return from(this.getAppStateForCheckpoint(email, finalSessionName, checkpointStage)).pipe(
              switchMap(sessionData => {
                const request: SaveSessionRequest = {
                  email,
                  projectId,
                  sessionName: finalSessionName,
                  sessionData,
                  checkpointStage,
                  tokenUsage // Include token usage in the save request
                };

                // If we have a session ID and we're not starting a new workflow, include it for update
                if (this.currentSessionId && !this.isNewWorkflow) {
                  request.sessionIdToUpdate = this.currentSessionId;
                  console.log(`Including session ID for update: ${this.currentSessionId}`);
                }

                console.log(`Sending request to ${this.apiUrl}/persistsession/`);

                return this.http.post(`${this.apiUrl}/persistsession/`, request, { headers }).pipe(
                  tap((response: any) => {
                    console.log('Received response:', response);
                    // Store session ID and name for future updates
                    if (response && response.sessionId) {
                      this.currentSessionId = response.sessionId;
                      this.currentSessionName = finalSessionName;
                      console.log(`Updated tracking - ID: ${this.currentSessionId}, Name: ${this.currentSessionName}`);
                    }
                  }),
                  catchError(error => {
                    console.error('Error saving session:', error);
                    throw error;
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  /**
   * Generate a unique session name by adding a timestamp
   */
  private generateUniqueSessionName(baseName: string): string {
    // Remove file extension if present
    const nameWithoutExtension = baseName.replace(/\.[^/.]+$/, '');

    // Add a timestamp to ensure uniqueness
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    return `${nameWithoutExtension} (${timestamp})`;
  }

  /**
   * Reset the session tracking to allow creation of a new session
   */
  resetSessionTracking(): void {
    console.log('Resetting session tracking');
    this.currentSessionId = null;
    this.currentSessionName = null;
    this.isNewWorkflow = true;
  }

  /**
   * Set the current session ID and name for updating
   * Used when loading an existing session
   */
  setCurrentSession(sessionId: string, sessionName: string): void {
    console.log(`Setting current session - ID: ${sessionId}, Name: ${sessionName}`);
    this.currentSessionId = sessionId;
    this.currentSessionName = sessionName;
    this.isNewWorkflow = false; // We're working with an existing session
  }

  /**
   * Get all sessions for the current user and project
   * @returns Observable with array of session metadata
   */
  getUserSessions(): Observable<SessionMetadata[]> {
    return this.getHeaders().pipe(
      switchMap(headers => {
        return combineLatest([
          this.authService.email$.pipe(take(1)),
          this.sessioneService.projectId$.pipe(take(1))
        ]).pipe(
          switchMap(([email, projectId]) => {
            if (!email || !projectId) {
              throw new Error('Missing email or project ID');
            }

            return this.http.get<SessionMetadata[]>(
              `${this.apiUrl}/persistsession/list?email=${email}&projectId=${projectId}`,
              { headers }
            );
          })
        );
      })
    );
  }

  /**
   * Load a specific session by ID
   * @param sessionId The ID of the session to load
   * @returns Observable with the session data
   */
  loadSession(sessionId: string): Observable<any> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get(`${this.apiUrl}/persistsession/${sessionId}`, { headers }).pipe(
          tap((session: any) => {
            // Set current session ID and name for future updates
            if (session) {
              this.currentSessionId = sessionId;
              this.currentSessionName = session.sessionName;
              console.log(`Loaded session - ID: ${this.currentSessionId}, Name: ${this.currentSessionName}`);
            }
          })
        )
      )
    );
  }

  /**
   * Hide a specific session by ID (soft delete)
   * @param sessionId The ID of the session to hide
   */
  hideSession(sessionId: string): Observable<any> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.put(`${this.apiUrl}/persistsession/${sessionId}/hide`, {}, { headers })
      )
    );
  }

  /**
   * Delete a specific session by ID (hard delete, admin only)
   * @param sessionId The ID of the session to delete
   */
  deleteSession(sessionId: string): Observable<any> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.delete(`${this.apiUrl}/persistsession/${sessionId}`, { headers })
      )
    );
  }

  /**
   * Uses the existing state aggregation logic but selects only the fields
   * that are relevant for the current checkpoint stage
   */
  private async getAppStateForCheckpoint(
    author: string,
    sessionName: string,
    checkpointStage: CheckpointStage
  ): Promise<any> {
    console.log(`Getting app state for checkpoint: ${checkpointStage}`);

    // Get token usage to include in the state
    const tokenUsage = await this.sessioneService.tokenUsage$.pipe(take(1)).toPromise();

    // For scenarios checkpoint, we need project data and scenario data
    if (checkpointStage === 'scenarios') {
      return new Promise((resolve, reject) => {
        combineLatest([
          this.sessioneService.projectId$,
          this.sessioneService.selectedModel$,
          this.sessioneService.isAgenticMode$,
          this.sessioneService.projectLanguage$,
          this.sessioneService.refinedStoryString$,
          this.sessioneService.macroScenarios$,
          this.sessioneService.dataParameters$,
          this.sessioneService.storyDescription$,
          this.sessioneService.coreAnalysis$,
          this.sessioneService.supportiveAnalysis$,
          this.sessioneService.ambiguities$
        ]).pipe(
          take(1)
        ).subscribe({
          next: ([
                   projectId,
                   selectedModel,
                   agenticMode,
                   projectLanguage,
                   context,
                   macroScenarios,
                   dataParameters,
                   storyDescription,
                   coreAnalysis,
                   supportiveAnalysis,
                   ambiguities
                 ]) => {
            const state = {
              version: '1.1.0',
              timestamp: new Date().toISOString(),
              metadata: {
                projectId,
                author,
                sessionName,
                selectedModel,
                agenticMode,
                projectLanguage,
                checkpointStage,
                sessionId: this.currentSessionId,
                tokenUsage // Include token usage in the metadata
              },
              context,
              macroScenarios,
              dataParameters,
              storyDescription,
              coreAnalysis,
              supportiveAnalysis,
              ambiguities
            };

            console.log(`Created state for scenarios checkpoint, data size: ${JSON.stringify(state).length}`);
            resolve(state);
          },
          error: (error) => {
            console.error('Error creating state for scenarios checkpoint:', error);
            reject(error);
          }
        });
      });
    } else if (checkpointStage === 'acs') {
      // Similar implementation for ACS checkpoint with token usage added
      return new Promise((resolve, reject) => {
        combineLatest([
          this.sessioneService.projectId$,
          this.sessioneService.selectedModel$,
          this.sessioneService.isAgenticMode$,
          this.sessioneService.projectLanguage$,
          this.sessioneService.refinedStoryString$,
          this.sessioneService.macroScenarios$,
          this.sessioneService.ambiguities$,
          this.sessioneService.coreAnalysis$,
          this.sessioneService.supportiveAnalysis$,
          this.sessioneService.dataParameters$,
          this.sessioneService.storyDescription$,
          this.sessioneService.criteriaGroups$
        ]).pipe(
          take(1)
        ).subscribe({
          next: ([
                   projectId,
                   selectedModel,
                   agenticMode,
                   projectLanguage,
                   context,
                   macroScenarios,
                   ambiguities,
                   coreAnalysis,
                   supportiveAnalysis,
                   dataParameters,
                   storyDescription,
                   criteriaGroups
                 ]) => {
            const state = {
              version: '1.1.0',
              timestamp: new Date().toISOString(),
              metadata: {
                projectId,
                author,
                sessionName,
                selectedModel,
                agenticMode,
                projectLanguage,
                checkpointStage,
                sessionId: this.currentSessionId,
                tokenUsage // Include token usage in the metadata
              },
              context,
              macroScenarios,
              ambiguities,
              coreAnalysis,
              supportiveAnalysis,
              dataParameters,
              storyDescription,
              criteriaGroups
            };

            console.log(`Created state for ACS checkpoint, data size: ${JSON.stringify(state).length}`);
            resolve(state);
          },
          error: (error) => {
            console.error('Error creating state for ACS checkpoint:', error);
            reject(error);
          }
        });
      });
    } else {
      return this.getAppState(author, sessionName, checkpointStage);
    }
  }

  /**
   * Uses the existing state aggregation logic from DownloaderComponent
   * to collect all the current state data
   */
  private async getAppState(
    author: string,
    sessionName: string,
    checkpointStage: CheckpointStage
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      // Collect all necessary state from sessioneService including token usage
      combineLatest([
        this.sessioneService.generatedTests$,
        this.sessioneService.criteriaGroups$,
        this.sessioneService.selectedTemplate$,
        this.sessioneService.templates$,
        this.sessioneService.projectId$,
        this.sessioneService.selectedModel$,
        this.sessioneService.isAgenticMode$,
        this.sessioneService.projectLanguage$,
        this.sessioneService.istruzioniScriviTest$,
        this.sessioneService.refinedStoryString$,
        this.sessioneService.ambiguities$,
        this.sessioneService.coreAnalysis$,
        this.sessioneService.supportiveAnalysis$,
        this.sessioneService.macroScenarios$,
        this.sessioneService.dataParameters$,
        this.sessioneService.storyDescription$,
        this.sessioneService.tokenUsage$ // Add token usage to collected state
      ]).pipe(
        take(1)
      ).subscribe({
        next: ([
                 tests,
                 criteriaGroups,
                 selectedTemplate,
                 templates,
                 projectId,
                 selectedModel,
                 agenticMode,
                 projectLanguage,
                 istruzioniScriviTest,
                 context,
                 ambiguities,
                 coreAnalysis,
                 supportiveAnalysis,
                 macroScenarios,
                 dataParameters,
                 storyDescription,
                 tokenUsage
               ]) => {
          // Format data similar to how it's done in the generateHTMLFile method
          const state = {
            version: '1.1.0', // Use the same version as in DownloaderComponent
            timestamp: new Date().toISOString(),
            metadata: {
              projectId,
              author,
              sessionName,
              selectedModel,
              agenticMode,
              projectLanguage,
              templateName: selectedTemplate,
              totalTests: Array.isArray(tests) ? tests.length : 0,
              checkpointStage,
              sessionId: this.currentSessionId,
              tokenUsage // Include token usage in the metadata
            },
            criteriaGroups,
            generatedTests: tests,
            selectedTemplate,
            selectedTemplateColumns: Array.isArray(templates)
              ? templates.find(t => t && typeof t === 'object' && 'templateName' in t && t.templateName === selectedTemplate)?.columns || []
              : [],
            context,
            istruzioniScriviTest,
            ambiguities,
            coreAnalysis,
            supportiveAnalysis,
            macroScenarios,
            dataParameters,
            storyDescription
          };

          console.log(`Created state for tests checkpoint, data size: ${JSON.stringify(state).length}`);
          resolve(state);
        },
        error: (error) => {
          console.error('Error creating full state:', error);
          reject(error);
        }
      });
    });
  }

  /**
   * Saves the current session state on demand with a custom name
   * @param sessionName Optional custom name for this save, defaults to current session name or timestamp
   * @param checkpointStage Optional stage identifier, defaults to the current workflow stage
   * @returns Observable with the save response
   */
  saveSessionOnDemand(sessionName?: string, checkpointStage?: CheckpointStage): Observable<any> {
    console.log('Manual session save requested');

    // If no name provided, use existing or generate a timestamp-based one
    const saveName = sessionName || this.currentSessionName || `Manual save ${new Date().toLocaleString()}`;

    // Determine which checkpoint stage we're in based on available data
    // or use the provided checkpoint stage
    return this.determineCurrentStage().pipe(
      take(1),
      switchMap(detectedStage => {
        const stage = checkpointStage || detectedStage || 'tests';
        console.log(`Saving on-demand with name: "${saveName}" at stage: ${stage}`);

        return this.saveCurrentSession(saveName, stage);
      }),
      tap(response => {
        console.log('On-demand save completed:', response);
      }),
      catchError(error => {
        console.error('Error during on-demand save:', error);
        throw error;
      })
    );
  }

  /**
   * Determines the current stage of the workflow based on available data
   * @returns Observable with the detected stage
   */
  private determineCurrentStage(): Observable<CheckpointStage> {
    return combineLatest([
      this.sessioneService.criteriaGroups$.pipe(
        map(criteriaGroups => {
          // Check if criteriaGroups is not only present but also has meaningful content
          if (!criteriaGroups) { return false; }

          // If it's an array, check if it has any elements
          if (Array.isArray(criteriaGroups)) {
            return criteriaGroups.length > 0;
          }

          // If it's an object, check if it has any properties
          if (typeof criteriaGroups === 'object') {
            return Object.keys(criteriaGroups).length > 0;
          }

          return false;
        })
      ),
      this.sessioneService.generatedTests$.pipe(
        map(tests => !!tests && Array.isArray(tests) && tests.length > 0)
      )
    ]).pipe(
      take(1),
      map(([hasCriteriaGroups, hasTests]) => {
        if (hasTests) {
          return 'tests';
        } else if (hasCriteriaGroups) {
          return 'acs';
        } else {
          return 'scenarios';
        }
      })
    );
  }
}
