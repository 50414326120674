<div class="container-fluid">
  <!-- Navigation header -->
  <div class="nav-header mb-3">
    <div class="nav-buttons">
      <button class="btn btn-light nav-btn" title="Back" (click)="navigateBack()">
        <i class="fa fa-arrow-left"></i>
        <span>Back</span>
      </button>
      <button class="btn btn-light nav-btn active" title="Knowledge Base">
        <i class="fa fa-book"></i>
        <span>Knowledge Base</span>
      </button>
      <button class="btn btn-light nav-btn" title="Configuration" (click)="navigateBack()">
        <i class="fa fa-gear"></i>
        <span>Configuration</span>
      </button>
      <button class="btn btn-light nav-btn" title="Dashboard Analytics">
        <i class="fa ft-bar-chart"></i>
        <span>Analytics</span>
      </button>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h2>Knowledge Base for {{projectId}}</h2>
      <p>Manage domain-specific terms and definitions for your project</p>
    </div>
    <div class="card-body">
      <!-- Loading Indicator -->
      <div *ngIf="loading" class="text-center p-4">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p class="mt-2">Loading knowledge base items...</p>
      </div>

      <!-- Error Message -->
      <div *ngIf="error && !loading" class="alert alert-danger" role="alert">
        {{ error }}
      </div>

      <!-- Add New Item Button -->
      <div class="d-flex justify-content-end mb-3">
        <button class="btn btn-primary" (click)="addNewKnowledgeBaseItem()">
          <i class="fa fa-plus"></i> Add New Term
        </button>
      </div>

      <!-- Empty State -->
      <div *ngIf="!loading && !error && knowledgeBaseItems.length === 0" class="empty-state">
        <i class="fa fa-book"></i>
        <p>No knowledge base items found for this project.</p>
        <button class="btn btn-primary mt-3" (click)="addNewKnowledgeBaseItem()">
          Add Your First Term
        </button>
      </div>

      <!-- Knowledge Base Items Table -->
      <div *ngIf="!loading && !error && knowledgeBaseItems.length > 0" class="sessions-container">
        <div class="table-responsive sessions-list">
          <table class="table table-hover">
            <thead>
            <tr>
              <th>Term</th>
              <th>Definition</th>
              <th>Last Modified</th>
              <th class="text-center">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of knowledgeBaseItems"
                (click)="viewKnowledgeBaseItem(item)"
                class="session-row">
              <td>{{ item.term }}</td>
              <td>{{ item.definition | slice:0:100 }}{{ item.definition.length > 100 ? '...' : '' }}</td>
              <td>{{ getFormattedDate(item.lastModified) }}</td>
              <td class="text-center actions-cell">
                <button class="btn btn-sm btn-outline-primary me-2" (click)="editKnowledgeBaseItem(item.id, $event)">
                  <i class="fa fa-edit"></i> Edit
                </button>
                <button class="btn btn-sm btn-danger" (click)="deleteKnowledgeBaseItem(item.id, $event)">
                  <i class="fa fa-trash"></i> Delete
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Details Modal -->
      <div *ngIf="showDetailsModal && selectedItem" class="modal-overlay">
        <div class="modal-container">
          <div class="modal-header">
            <h3>{{ selectedItem.term }}</h3>
            <button type="button" class="btn-close" (click)="closeDetailsModal()"></button>
          </div>
          <div class="modal-body">
            <div class="detail-section">
              <div class="detail-label">Term ID:</div>
              <div class="detail-value">{{ selectedItem.kbId }}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Title:</div>
              <div class="detail-value">{{ selectedItem.title }}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Definition:</div>
              <div class="detail-value">{{ selectedItem.definition }}</div>
            </div>

            <div *ngIf="selectedItem.examples && selectedItem.examples.length > 0" class="detail-section">
              <div class="detail-label">Examples:</div>
              <ul class="detail-list">
                <li *ngFor="let example of selectedItem.examples">{{ example }}</li>
              </ul>
            </div>

            <div *ngIf="selectedItem.related_terms && selectedItem.related_terms.length > 0" class="detail-section">
              <div class="detail-label">Related Terms:</div>
              <div class="detail-value">{{ selectedItem.related_terms.join(', ') }}</div>
            </div>

            <div *ngIf="selectedItem.scope" class="detail-section">
              <div class="detail-label">Scope:</div>
              <div class="detail-value">{{ selectedItem.scope }}</div>
            </div>

            <div *ngIf="selectedItem.type" class="detail-section">
              <div class="detail-label">Type:</div>
              <div class="detail-value">{{ selectedItem.type }}</div>
            </div>

            <div *ngIf="selectedItem.source_story_id" class="detail-section">
              <div class="detail-label">Source Story ID:</div>
              <div class="detail-value">{{ selectedItem.source_story_id }}</div>
            </div>

            <div *ngIf="selectedItem.original_ambiguity_id" class="detail-section">
              <div class="detail-label">Original Ambiguity ID:</div>
              <div class="detail-value">{{ selectedItem.original_ambiguity_id }}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Created:</div>
              <div class="detail-value">{{ getFormattedDate(selectedItem.createdAt) }}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Last Modified:</div>
              <div class="detail-value">{{ getFormattedDate(selectedItem.lastModified) }}</div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-outline-primary me-2" (click)="editKnowledgeBaseItem(selectedItem.id, $event)">
              <i class="fa fa-edit"></i> Edit
            </button>
            <button class="btn btn-secondary" (click)="closeDetailsModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
