import { Component, Input, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { AcceptanceCriterion, TemplateGroupInfo, Ambiguity } from '../../../../shared/templateGen/templateInterfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatoSessioneService } from '../../../../shared/services/stato-sessione.service';
import { ApiService } from '../../../../shared/services/api.service';
import { SpinnerService } from '../../../../shared/services/spinner.service';
import {Observable, combineLatest, throwError} from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {AuthService} from '../../../../shared/auth/auth.service';
import {RigeneraTestModalComponent} from '../../elements/modals/rigenera-test-modal/rigenera-test-modal.component';

interface Template {
  templateName: string;
  columns: string[];
  templateDescription: string;
}

@Component({
  selector: 'app-criterion',
  templateUrl: './criterion.component.html',
  styleUrls: ['./criterion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CriterionComponent {
  @Input() criterion!: AcceptanceCriterion;
  @Input() readonly groupInfo!: TemplateGroupInfo;
  @Input() readonly compact = false;
  @Input() readonly groupIndex!: number;
  @Input() readonly criterionIndex!: number;
  @ViewChild('editModal') editModal: any;
  @ViewChild('testDataEditModal') testDataEditModal: any;

  editingCriterion: AcceptanceCriterion | null = null;

  // Test data editing state
  isEditingTestData = false;
  editingTestData = {
    fieldName: '',
    value: '',
    index: -1,
    currentCriterion: null as AcceptanceCriterion | null
  };
  availableDataParamFields: string[] = [];
  selectedDataParamField = '';

  // Session data as observables
  readonly selectedModel$ = this.sessioneService.selectedModel$;
  readonly projectId$ = this.sessioneService.projectId$;
  readonly projectLanguage$ = this.sessioneService.projectLanguage$;
  readonly uid$ = this.authService.email$;
  readonly selectedTemplate$ = this.sessioneService.selectedTemplate$;
  readonly templates$ = this.sessioneService.templates$;
  readonly istruzioniScriviTest$ = this.sessioneService.istruzioniScriviTest$;

  isRegenerating = false;

  constructor(
    private modalService: NgbModal,
    private sessioneService: StatoSessioneService,
    private authService: AuthService,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private cd: ChangeDetectorRef
  ) {}

  openEditModal(modal: any) {
    this.editingCriterion = JSON.parse(JSON.stringify(this.criterion));

    this.editingCriterion.tags = this.editingCriterion.tags || [];
    this.editingCriterion.given = this.editingCriterion.given || [];
    this.editingCriterion.when = this.editingCriterion.when || [];
    this.editingCriterion.then = this.editingCriterion.then || [];

    // Ensure test_data_elements exists
    this.editingCriterion.test_data_elements = this.editingCriterion.test_data_elements || [];

    this.modalService.open(modal, { size: 'lg' });
  }

  saveChanges(modal: any) {
    if (!this.editingCriterion || !this.validateCriterion(this.editingCriterion)) {
      return;
    }

    const cleanedCriterion = this.cleanCriterion(this.editingCriterion);

    this.sessioneService.updateCriterion(
      this.groupIndex,
      this.criterionIndex,
      cleanedCriterion
    );

    this.criterion = cleanedCriterion;
    this.cd.markForCheck();

    modal.close();

    Swal.fire({
      title: 'Success',
      text: 'Changes saved successfully',
      icon: 'success',
      timer: 1500
    });
  }

  regenerateTests() {
    if (!this.criterion) {
      Swal.fire({
        title: 'Error',
        text: 'Missing criterion information',
        icon: 'error'
      });
      return;
    }

    Swal.fire({
      title: 'Regenerating tests...',
      html: 'Please wait',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const allCurrentTests = this.sessioneService.getCurrentGeneratedTests();
    const otherGroupTests = allCurrentTests.filter(test =>
      test.metadata.groupIndex !== this.groupIndex
    );
    const existingTests = allCurrentTests.filter(test =>
      test.metadata.groupIndex === this.groupIndex &&
      test.metadata.criterionIndex === this.criterionIndex
    );
    const sameGroupDifferentACTests = allCurrentTests.filter(test =>
      test.metadata.groupIndex === this.groupIndex &&
      test.metadata.criterionIndex !== this.criterionIndex
    );

    const existingTestsString = sameGroupDifferentACTests.length > 0
      ? JSON.stringify(sameGroupDifferentACTests.map(t => t.data), null, 2)
      : '';

    let isHandled = false;

    combineLatest([
      this.projectId$ as Observable<string>,
      this.uid$ as Observable<string>,
      this.selectedModel$ as Observable<string>,
      this.projectLanguage$ as Observable<string>,
      this.selectedTemplate$ as Observable<string>,
      this.templates$ as Observable<Template[]>,
      this.istruzioniScriviTest$ as Observable<string>
    ]).pipe(
      take(1),
      switchMap(([projectId, uid, selectedModel, projectLanguage, selectedTemplate, templates, istruzioniScriviTest]) => {
        if (!uid || !projectId) {
          throw new Error('Missing required session data');
        }

        if (!Array.isArray(templates)) {
          console.error('Expected templates to be an array, but got:', templates);
          return throwError(() => new Error('Invalid templates data'));
        }

        const selectedTemplateObj = templates.find(
          template => template.templateName === selectedTemplate
        );

        const selectedTemplateColumns = selectedTemplateObj?.columns || [];
        const selectedTemplateDesc = selectedTemplateObj?.templateDescription || '';

        const criterionText = this.formatCriterionForGeneration(this.criterion);
        return this.apiService.generateTestCases(
          projectId.toString(),
          uid.toString(),
          selectedModel.toString(),
          projectLanguage.toString(),
          selectedTemplateDesc,
          selectedTemplateColumns,
          criterionText,
          existingTestsString,
          'Tags',
          istruzioniScriviTest.toString()
        ).pipe(take(1));
      })
    ).subscribe({
      next: (response) => {
        if (isHandled) { return; }
        isHandled = true;

        Swal.close();

        const newTests = response.map((testData, testIndex) =>
          this.sessioneService.testIdService.createTestWithMetadata(
            testData,
            {
              groupIndex: this.groupIndex,
              criterionIndex: this.criterionIndex,
              testIndex
            }
          )
        );

        const modalRef = this.modalService.open(RigeneraTestModalComponent, {
          size: 'lg',
          backdrop: 'static'
        });

        modalRef.componentInstance.oldTests = existingTests;
        modalRef.componentInstance.newTests = newTests;

        modalRef.result.then((selectedTests) => {
          const recalculatedTests = selectedTests.map((test, index) =>
            this.sessioneService.testIdService.createTestWithMetadata(
              test.data,
              {
                groupIndex: this.groupIndex,
                criterionIndex: this.criterionIndex,
                testIndex: index
              }
            )
          );

          const updatedTests = this.sessioneService.testIdService.sortTests([
            ...otherGroupTests,
            ...sameGroupDifferentACTests,
            ...recalculatedTests
          ]);

          this.sessioneService.updateGeneratedTests(updatedTests);

          Swal.fire({
            title: 'Success',
            text: 'Tests updated successfully',
            icon: 'success',
            timer: 1500
          });
        }).catch(() => {
          Swal.fire({
            title: 'Cancelled',
            text: 'Test selection was cancelled',
            icon: 'info',
            timer: 1500
          });
        });
      },
      error: (error) => {
        console.error('Error regenerating tests:', error);
        this.isRegenerating = false;
        this.cd.markForCheck();

        Swal.fire({
          title: 'Error',
          text: 'Failed to regenerate tests. Please try again.',
          icon: 'error'
        });
      }
    });
  }

  addCondition(array: string[]) {
    if (array) {
      array.push('');
    }
  }

  removeCondition(array: string[], index: number) {
    if (array) {
      array.splice(index, 1);
    }
  }

  trackByIndex(index: number): number {
    return index;
  }

  removeTag(criterion: AcceptanceCriterion, index: number): void {
    Swal.fire({
      title: 'Remove Tag',
      text: 'Are you sure you want to remove this tag?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it'
    }).then((result) => {
      if (result.isConfirmed) {
        if (criterion.tags) {
          criterion.tags.splice(index, 1);

          // Update the criterion in the session service
          this.sessioneService.updateCriterion(
            this.groupIndex,
            this.criterionIndex,
            criterion
          );

          this.cd.markForCheck();
        }
      }
    });
  }

  openAddTagModal(criterion: AcceptanceCriterion): void {
    if (!criterion.tags) {
      criterion.tags = [];
    }

    // Define available tags
    const availableTags = [
      '#business_critical',
      '#security',
      '#api_integration',
      '#ui',
      '#performance',
      '#stateful',
      '#validation',
      '#async_concurrency',
      '#history_of_bugs'
    ];

    // Filter out tags that are already applied
    const unusedTags = availableTags.filter(tag => !criterion.tags?.includes(tag));

    if (unusedTags.length === 0) {
      Swal.fire({
        title: 'All Tags Used',
        text: 'You have already used all available tags for this criterion.',
        icon: 'info'
      });
      return;
    }

    // Show tag selection dialog
    Swal.fire({
      title: 'Add Tag',
      input: 'select',
      inputOptions: unusedTags.reduce((options: {[key: string]: string}, tag) => {
        options[tag] = tag.replace(/_/g, ' ');
        return options;
      }, {}),
      inputPlaceholder: 'Select a tag',
      showCancelButton: true,
      confirmButtonText: 'Add',
      inputValidator: (value) => {
        return !value ? 'You need to select a tag!' : null;
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        criterion.tags.push(result.value);

        // Update the criterion in the session service
        this.sessioneService.updateCriterion(
          this.groupIndex,
          this.criterionIndex,
          criterion
        );

        this.cd.markForCheck();
      }
    });
  }

  // Test data management methods
  openEditTestDataModal(index: number): void {
    // Ensure test_data_elements exists
    if (!this.criterion.test_data_elements) {
      this.criterion.test_data_elements = [];
    }

    const dataElement = this.criterion.test_data_elements[index];
    if (!dataElement) return;

    const parts = dataElement.split('|');

    this.editingTestData = {
      fieldName: parts[0].trim(),
      value: parts.length > 1 ? parts.slice(1).join('|').trim() : '',
      index: index, // Positive index indicates edit mode
      currentCriterion: this.criterion
    };

    this.modalService.open(this.testDataEditModal, { size: 'md' });
  }

  openAddTestDataModal(): void {
    // Ensure test_data_elements exists
    if (!this.criterion.test_data_elements) {
      this.criterion.test_data_elements = [];
    }

    // Get available fields from data parameters
    this.availableDataParamFields = [];
    const dataParams = this.sessioneService.getDataParameters();

    if (dataParams?.analysis_results?.test_data_management?.base_dataset) {
      this.availableDataParamFields = dataParams.analysis_results.test_data_management.base_dataset.map(
        dataset => dataset.field
      );
    }

    this.editingTestData = {
      fieldName: '',
      value: '',
      index: -1, // Negative index indicates add mode
      currentCriterion: this.criterion
    };

    this.selectedDataParamField = '';  // Reset selection

    this.modalService.open(this.testDataEditModal, { size: 'md' });
  }

  onDataParamFieldSelected(): void {
    if (!this.selectedDataParamField) return;

    // Set the field name from selection
    this.editingTestData.fieldName = this.selectedDataParamField;

    // Look up valid values to suggest
    const dataParams = this.sessioneService.getDataParameters();
    if (dataParams?.analysis_results?.test_data_management?.base_dataset) {
      const selectedDataset = dataParams.analysis_results.test_data_management.base_dataset.find(
        dataset => dataset.field === this.selectedDataParamField
      );

      if (selectedDataset?.valid_values?.length) {
        // Suggest using valid values in the value field
        this.editingTestData.value = "Valid values: " + selectedDataset.valid_values.join(', ');
      }
    }
  }

  saveTestDataChanges(modal: any): void {
    const { fieldName, value, index, currentCriterion } = this.editingTestData;

    if (!fieldName.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Field name is required',
        icon: 'error'
      });
      return;
    }

    if (!currentCriterion) return;

    if (!currentCriterion.test_data_elements) {
      currentCriterion.test_data_elements = [];
    }

    // Format the test data element
    const formattedElement = `${fieldName.trim()} | ${value.trim()}`;

    if (index >= 0) {
      // Edit existing element
      currentCriterion.test_data_elements[index] = formattedElement;
    } else {
      // Add new element
      currentCriterion.test_data_elements.push(formattedElement);
    }

    // Update the criterion in the session service
    this.sessioneService.updateCriterion(
      this.groupIndex,
      this.criterionIndex,
      currentCriterion
    );

    this.cd.markForCheck();

    // Close the modal
    modal.close();
  }

  removeTestDataElement(index: number): void {
    Swal.fire({
      title: 'Remove Test Data',
      text: 'Are you sure you want to remove this test data element?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it'
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.criterion.test_data_elements) {
          this.criterion.test_data_elements.splice(index, 1);

          // Update the criterion in the session service
          this.sessioneService.updateCriterion(
            this.groupIndex,
            this.criterionIndex,
            this.criterion
          );

          this.cd.markForCheck();
        }
      }
    });
  }

  getFieldName(dataElement: string): string {
    if (!dataElement) return '';

    const parts = dataElement.split('|');
    if (parts.length < 2) return dataElement;

    return parts[0].trim();
  }

  getTooltipValue(dataElement: string): string {
    if (!dataElement) return '';

    const parts = dataElement.split('|');
    if (parts.length < 2) return '';

    // Return only the value portion (everything after the first |)
    return parts.slice(1).join('|').trim();
  }

  private validateCriterion(criterion: AcceptanceCriterion): boolean {
    if (!criterion?.name?.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Scenario name is required',
        icon: 'error'
      });
      return false;
    }

    if (!criterion.when?.[0]?.trim() || !criterion.then?.[0]?.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'At least one When action and Then result is required',
        icon: 'error'
      });
      return false;
    }

    return true;
  }

  private cleanCriterion(criterion: AcceptanceCriterion): AcceptanceCriterion {
    return {
      ...criterion,
      id: criterion.id,
      name: criterion.name.trim(),
      scenario_ref: criterion.scenario_ref,
      tags: (criterion.tags || []).filter(t => t?.trim()),
      given: (criterion.given || []).filter(g => g?.trim()),
      when: (criterion.when || []).filter(w => w?.trim()),
      then: (criterion.then || []).filter(t => t?.trim()),
      test_data_elements: criterion.test_data_elements || []
    };
  }

  /**
   * Format a criterion for test generation with improved test data formatting
   */
  private formatCriterionForGeneration(criterion: AcceptanceCriterion): string {
    let formattedString = '';

    if (this.groupInfo) {
      formattedString += `Feature: ${this.groupInfo.feature_name}\n\n`;
    }

    if (criterion.name) {
      formattedString += `Scenario: ${criterion.id} - ${criterion.name}\n`;
    }

    // Add tags if present
    if (criterion.tags && criterion.tags.length > 0) {
      formattedString += `  Tags: ${criterion.tags.join(', ')}\n`;
    }

    // Add formatted test data elements with context
    if (criterion.test_data_elements && criterion.test_data_elements.length > 0) {
      formattedString += `  Test Data:\n`;

      criterion.test_data_elements.forEach(dataElement => {
        // Extract field name and value
        const fieldName = this.getFieldName(dataElement);
        const value = this.getTooltipValue(dataElement);

        formattedString += `    - ${fieldName}: ${value}`;

        // Add context from data parameters if available
        const context = this.getContextFromDataParameters(fieldName);
        if (context) {
          formattedString += `\n      Context: ${context}`;
        }

        formattedString += '\n';
      });
    }

    // Combine background conditions with criterion's given conditions
    const allGivenConditions: string[] = [];

    // Add background conditions if they exist
    if (this.groupInfo && this.groupInfo.background && this.groupInfo.background.length > 0) {
      allGivenConditions.push(...this.groupInfo.background);
    }

    // Add criterion's given conditions if they exist
    if (criterion.given && criterion.given.length > 0) {
      allGivenConditions.push(...criterion.given);
    }

    // Format the combined given conditions
    if (allGivenConditions.length > 0) {
      formattedString += `  Given ${allGivenConditions[0]}\n`;
      allGivenConditions.slice(1).forEach(condition => {
        formattedString += `  And ${condition}\n`;
      });
    }

    if (criterion.when && criterion.when.length > 0) {
      formattedString += `  When ${criterion.when[0]}\n`;
      criterion.when.slice(1).forEach(action => {
        formattedString += `  And ${action}\n`;
      });
    }

    if (criterion.then && criterion.then.length > 0) {
      formattedString += `  Then ${criterion.then[0]}\n`;
      criterion.then.slice(1).forEach(result => {
        formattedString += `  And ${result}\n`;
      });
    }

    // Add group analysis if it exists
    if (this.groupInfo && this.groupInfo.group_analysis) {
      formattedString += `\nGroup Analysis:\n${this.groupInfo.group_analysis}\n`;
    }

    return formattedString;
  }

  /**
   * Helper function to get context (rules) from data parameters
   * @param fieldName Field name to look up in data parameters
   * @returns Rules field as context or empty string if not found
   */
  protected getContextFromDataParameters(fieldName: string): string {
    // Get data parameters from service
    const dataParams = this.sessioneService.getDataParameters();

    if (!dataParams?.analysis_results?.test_data_management?.base_dataset) return '';

    // Find matching field in base_dataset (case insensitive)
    const matchingDataset = dataParams.analysis_results.test_data_management.base_dataset.find(
      dataset => dataset.field.toLowerCase() === fieldName.toLowerCase()
    );

    // Return rules if found, otherwise empty string
    return matchingDataset?.rules || '';
  }

  private getBatchIndexFromCriterionId(id: string): number {
    const match = id.match(/AC-(\d+)/);
    return match ? parseInt(match[1], 10) - 1 : 0;
  }
}
