import {Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../shared/services/api.service';
import { Router } from '@angular/router';
import { SpinnerService } from '../../../../shared/services/spinner.service';

interface Column {
  id: number;
  name: string;
  description: string;
  list: boolean;
  subColumns?: Column[];
  expanded?: boolean;
  readonly?: boolean;
}

interface PreviewColumn {
  name: string;
  value?: string;
  subColumns?: PreviewColumn[];
  expanded: boolean;
  readonly?: boolean;
}

interface Template {
  value: string;
  name: string;
}

type TabName = 'general' | 'columns' | 'manage';

@Component({
  selector: 'app-columns',
  templateUrl: './columns.component.html',
  styleUrls: ['./columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsComponent implements OnInit {
  @Output() tabChange = new EventEmitter<{tab: 'general' | 'columns' | 'manage', templateName: string}>();

  templateDescription = '';
  projectId: string;
  columnsForm: FormGroup;
  selectedTemplate = 'default';
  templateName = 'Default Template';  // New property for the template name
  previewColumns: PreviewColumn[] = [];
  isSubmitting = false;
  templateOptions: Template[] = [
    { value: 'default', name: 'Default Template' },
    { value: 'actions', name: 'Actions Template' },
    { value: 'bdd', name: 'BDD Template (GIVEN-WHEN-THEN)' },
    { value: 'custom', name: 'Custom Template' }
  ];

  // Default standard columns that will be included in all templates
  standardColumns: Column[] = [
    { id: 1, name: 'ID', description: 'Unique identifier for the test case', list: false, readonly: true },
    { id: 2, name: 'TYPE', description: 'Test Typology', list: false, readonly: true }
  ];

  templates = {
    default: [
      { id: 3, name: 'TITLE', description: 'Brief title of the test case', list: false },
      { id: 4, name: 'OBJECTIVE', description: 'The goal or purpose of the test case', list: false },
      { id: 5, name: 'PRECONDITIONS', description: 'Any prerequisites or setup list for the test', list: true },
      { id: 6, name: 'STEPS', description: 'Detailed steps to execute the test', list: true },
      { id: 7, name: 'POSTCONDITION', description: 'The expected state after test execution', list: true },
    ],
    actions: [
      { id: 3, name: 'TITLE', description: 'Brief title of the test case', list: false },
      { id: 4, name: 'OBJECTIVE', description: 'The goal or purpose of the test case', list: false },
      { id: 5, name: 'PRECONDITIONS', description: 'Any prerequisites or setup list for the test', list: true },
      { id: 6, name: 'STEPS', description: 'Detailed steps to execute the test', list: true, subColumns: [
          { id: 61, name: 'ACTION', description: 'The action to be performed', list: false },
          { id: 62, name: 'DATA', description: 'Any data inputs list for the action', list: false },
          { id: 63, name: 'EXPECTED RESULT', description: 'The expected outcome of the action', list: false },
        ]},
      { id: 7, name: 'POSTCONDITION', description: 'The expected state after test execution', list: true },
    ],
    bdd: {
      description: `Always use Gherkin syntax in each column. If writing in a language other than English, keep the keywords in English while maintaining the rest of the content in the selected language.
Specifically Given, When, Then, And, But
Given: Sets up the initial context or preconditions for the scenario.
Example: Given I am logged into my account
When: Describes the action or event that triggers the scenario.
Example: When I click the "Submit" button
Then: Specifies the expected outcome or result of the action.
Example: Then I should see a confirmation message
And: Used to add additional context, actions, or outcomes to any of the above steps.
Example: And I have entered valid payment information
But: Similar to "And," but used to introduce a contrasting step or condition.
Example: But I should not be charged for the service`,
      columns: [
        { id: 3, name: 'TITLE', description: 'Brief title of the test case', list: false },
        { id: 4, name: 'GIVEN', description: 'Describes the initial context of the system or the preconditions of the scenario.', list: true },
        { id: 5, name: 'WHEN', description: 'Specifies the key action or event that the user performs or that occurs in the system.', list: true },
        { id: 6, name: 'THEN', description: 'Describes the expected outcome or result of the action specified in the WHEN clause.', list: true },
      ]
    },
    custom: []
  };

  constructor(private router: Router, private fb: FormBuilder,
              private route: ActivatedRoute, private apiService: ApiService,
              private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.initForm();
    this.loadTemplate();
    this.projectId = this.route.snapshot.params.project_id;
  }

  initForm(): void {
    this.columnsForm = this.fb.group({
      columns: this.fb.array([])
    });
  }

  loadTemplate(): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    columnsArray.clear();

    // First add the standard columns that all templates must have
    this.standardColumns.forEach(column => {
      columnsArray.push(this.createColumnGroup(column));
    });

    if (this.selectedTemplate === 'bdd') {
      this.templates.bdd.columns.forEach(column => {
        columnsArray.push(this.createColumnGroup(column));
      });
      this.templateDescription = this.templates.bdd.description;
    } else {
      this.templates[this.selectedTemplate].forEach(column => {
        columnsArray.push(this.createColumnGroup(column));
      });
      this.templateDescription = ''; // Clear description for other templates
    }

    this.templateName = this.templateOptions.find(template => template.value === this.selectedTemplate)?.name || 'Custom Template';
    this.updatePreview();
  }

  createColumnGroup(column: Column): FormGroup {
    const group = this.fb.group({
      id: [column.id],
      name: [column.name],
      description: [column.description],
      list: [column.list],
      expanded: [column.expanded || false],
      readonly: [column.readonly || false],
      subColumns: this.fb.array([])
    });

    if (column.subColumns) {
      column.subColumns.forEach(subColumn => {
        (group.get('subColumns') as FormArray).push(this.createColumnGroup(subColumn));
      });
    }

    return group;
  }

  handleTemplateChange(): void {
    this.loadTemplate();
  }

  addColumn(): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    const newId = Math.max(...columnsArray.value.map((c: Column) => c.id), 0) + 1;
    columnsArray.push(this.createColumnGroup({ id: newId, name: '', description: '', list: false }));
    this.updatePreview();
  }

  removeColumn(index: number): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    const column = columnsArray.at(index);

    // Prevent removing standard columns
    if (column.get('readonly').value) {
      alert('Standard columns cannot be removed.');
      return;
    }

    columnsArray.removeAt(index);
    this.updatePreview();
  }

  addSubColumn(columnIndex: number): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    const column = columnsArray.at(columnIndex);

    // Prevent adding subcolumns to standard columns
    if (column.get('readonly').value) {
      alert('Standard columns cannot be modified.');
      return;
    }

    const subColumnsArray = column.get('subColumns') as FormArray;
    const newId = Math.max(...subColumnsArray.value.map((sf: Column) => sf.id), columnsArray.at(columnIndex).value.id * 10) + 1;
    subColumnsArray.push(this.createColumnGroup({ id: newId, name: '', description: '', list: false }));
    columnsArray.at(columnIndex).patchValue({ expanded: true });
    this.updatePreview();
  }

  removeSubColumn(columnIndex: number, subColumnIndex: number): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    const subColumnsArray = columnsArray.at(columnIndex).get('subColumns') as FormArray;
    subColumnsArray.removeAt(subColumnIndex);
    this.updatePreview();
  }

  toggleExpand(columnForm: FormGroup): void {
    columnForm.patchValue({ expanded: !columnForm.get('expanded').value });
  }

  updatePreview(): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    this.previewColumns = columnsArray.value.map((column: Column) => {
      const previewColumn: PreviewColumn = {
        name: column.name,
        expanded: false,
        readonly: column.readonly
      };

      if (column.subColumns && column.subColumns.length > 0) {
        previewColumn.subColumns = column.subColumns.map(subColumn => ({
          name: subColumn.name,
          value: 'Test',
          expanded: false
        }));
      } else {
        previewColumn.value = 'Test';
      }

      return previewColumn;
    });
  }

  togglePreviewExpand(column: PreviewColumn): void {
    column.expanded = !column.expanded;
  }

  previewConfiguration(): void {
    this.updatePreview();
  }

  saveConfiguration(): void {
    // Add a flag to prevent multiple submissions
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    this.spinnerService.show();

    // First, fetch all templates to check if the name already exists
    this.apiService.getTemplates(this.projectId).subscribe(
      templates => {
        const templateExists = templates.some(template => template.templateName === this.templateName);

        if (templateExists) {
          this.spinnerService.hide();
          this.isSubmitting = false;
          alert('A template with this name already exists. Please choose a different name.');
        } else {
          // If the template doesn't exist, proceed with saving
          this.apiService.saveConfiguration(
            this.projectId,
            this.templateName,
            this.templateDescription,
            this.columnsForm.value.columns
          ).subscribe(
            response => {
              this.spinnerService.hide();
              this.isSubmitting = false;
              alert('Configuration saved successfully!');
              console.log('Configuration saved successfully:', response);
              this.tabChange.emit({tab: 'manage', templateName: this.templateName});
            },
            error => {
              this.spinnerService.hide();
              this.isSubmitting = false;
              alert('Error saving configuration.');
              console.error('Error saving configuration:', error);
            }
          );
        }
      },
      error => {
        this.spinnerService.hide();
        this.isSubmitting = false;
        alert('Error retrieving existing templates.');
        console.error('Error retrieving existing templates:', error);
      }
    );
  }
}

