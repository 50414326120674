import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Note} from '../../modules/projects/story/notes/notes.component';
import {
  AcceptanceCriterion, Ambiguity,
  AnalysisContent,
  DataParametersResponse, ExtractedSubstring,
  StoryDescriptionResponse, SupportiveAnalysisContent,
  TokenCount
} from '../templateGen/templateInterfaces';
import {CriteriaGroup, MacroScenarioWithSelection} from '../../modules/projects/template/template.component';
import {TestIdService, TestWithMetadata} from '../../modules/projects/test/test-id.service';
import {SupportFile} from '../../modules/projects/story/story.component';

export interface TestGenerationProgress {
  totalCriteria: number;
  completedCriteria: number;
  results: any[];
}

export interface GenerateTestsParams {
  criterionText: string;
  index: number;
  scenarioInfo?: {
    id: string;
    name: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class StatoSessioneService {
  private tokenUsageSubject = new BehaviorSubject<TokenCount>({
    prompt_tokens: 0,
    completion_tokens: 0,
    total_tokens: 0
  });
  tokenUsage$ = this.tokenUsageSubject.asObservable();

  private triggerGenerateTests = new Subject<boolean>();

  private selectedModel = new BehaviorSubject<string>('' );
  selectedModel$ = this.selectedModel.asObservable();

  private templates = new BehaviorSubject<any[]>([] );
  templates$ = this.templates.asObservable();

  private isAgenticModeSubject = new BehaviorSubject<boolean>(false);
  isAgenticMode$ = this.isAgenticModeSubject.asObservable();

  private selectedTemplate = new BehaviorSubject<string>('' );
  selectedTemplate$ = this.selectedTemplate.asObservable();

  private selectedTestStyle = new BehaviorSubject<string>('' );
  selectedTestStyle$ = this.selectedTestStyle.asObservable();

  private extractedSubstringsSubject = new BehaviorSubject<ExtractedSubstring[]>([]);
  extractedSubstrings$ = this.extractedSubstringsSubject.asObservable();

  private istruzioniScriviTest = new BehaviorSubject<string>('' );
  istruzioniScriviTest$ = this.istruzioniScriviTest.asObservable();

  private notesAdded = false;

  private additionalContextSubject = new BehaviorSubject<string>('');
  additionalContext$ = this.additionalContextSubject.asObservable();

  fileNameSubject = new BehaviorSubject<string>('');
  fileName$ = this.fileNameSubject.asObservable();

  private testFileNameSubject = new BehaviorSubject<string>('');
  testFileName$ = this.testFileNameSubject.asObservable();

  private refinedStoryStringSubject = new BehaviorSubject<string>('');
  refinedStoryString$ = this.refinedStoryStringSubject.asObservable();

  private generatedTestsSubject = new BehaviorSubject<TestWithMetadata[]>([]);
  generatedTests$ = this.generatedTestsSubject.asObservable();

  private columnsSubject = new BehaviorSubject<string[]>([]);
  columns$ = this.columnsSubject.asObservable();

  private retrievedNotes = new BehaviorSubject<Note[]>([]);
  retrievedNotes$ = this.retrievedNotes.asObservable();

  projectId = new BehaviorSubject<string>('' );
  projectId$ = this.projectId.asObservable();

  projectLanguage = new BehaviorSubject<string>('' );
  projectLanguage$ = this.projectLanguage.asObservable();

  private supportFilesSubject = new BehaviorSubject<SupportFile[]>([]);
  supportFiles$ = this.supportFilesSubject.asObservable();

  // subject for supportive file contents
  private supportFileContentsSubject = new BehaviorSubject<Map<string, string>>(new Map());
  supportFileContents$ = this.supportFileContentsSubject.asObservable();

  testCount$: Observable<number> = this.generatedTests$.pipe(
    map(tests => tests.length)
  );

  // STATO TEMPLATE COMPONENT
  private criteriaGroupsSubject = new BehaviorSubject<CriteriaGroup[]>([]);
  criteriaGroups$ = this.criteriaGroupsSubject.asObservable();

  private storyDescriptionSubject = new BehaviorSubject<StoryDescriptionResponse | undefined>(undefined);
  storyDescription$ = this.storyDescriptionSubject.asObservable();

  private macroScenariosSubject = new BehaviorSubject<MacroScenarioWithSelection[]>([]);
  macroScenarios$ = this.macroScenariosSubject.asObservable();

  private dataParametersSubject = new BehaviorSubject<DataParametersResponse | undefined>(undefined);
  dataParameters$ = this.dataParametersSubject.asObservable();

  private currentTestParams?: GenerateTestsParams;
  private allCriteria: string[] = [];

  // BehaviorSubjects for analysis data
  private coreAnalysisSubject = new BehaviorSubject<AnalysisContent | null>(null);
  coreAnalysis$ = this.coreAnalysisSubject.asObservable();

  private supportiveAnalysisSubject = new BehaviorSubject<SupportiveAnalysisContent | null>(null);
  supportiveAnalysis$ = this.supportiveAnalysisSubject.asObservable();

  // BehaviorSubject for ambiguities
  private ambiguitiesSubject = new BehaviorSubject<Ambiguity[]>([]);
  ambiguities$ = this.ambiguitiesSubject.asObservable();

  // Derived observable to quickly check if there are ambiguities
  hasAmbiguities$ = this.ambiguitiesSubject.pipe(
    map(ambiguities => ambiguities.length > 0)
  );

  private testGenerationProgress = new BehaviorSubject<TestGenerationProgress>({
    totalCriteria: 0,
    completedCriteria: 0,
    results: []
  });
  testGenerationProgress$ = this.testGenerationProgress.asObservable();

  constructor(public testIdService: TestIdService) {}

  resetAll() {
    // Reset basic string/array subjects
    this.isAgenticModeSubject.next(true);
    this.additionalContextSubject.next('');
    this.fileNameSubject.next('');
    this.testFileNameSubject.next('');
    this.refinedStoryStringSubject.next('');
    this.generatedTestsSubject.next([]);
    this.supportFilesSubject.next([]);
    this.istruzioniScriviTest.next('');
    this.columnsSubject.next([]);
    // this.templates.next([]); // Remove this line
    this.selectedTemplate.next('');
    this.supportFilesSubject.next([]);
    this.supportFileContentsSubject.next(new Map());
    this.extractedSubstringsSubject.next([]);

    // Reset analysis data
    this.coreAnalysisSubject.next(null);
    this.supportiveAnalysisSubject.next(null);
    this.ambiguitiesSubject.next([]);

    // Reset template component state
    this.criteriaGroupsSubject.next([]);
    this.storyDescriptionSubject.next(undefined);
    this.macroScenariosSubject.next([]);
    this.dataParametersSubject.next(undefined);

    // Reset test generation state
    this.currentTestParams = undefined;
    this.allCriteria = [];
    this.testGenerationProgress.next({
      totalCriteria: 0,
      completedCriteria: 0,
      results: []
    });
  }

  resetTests() {
    // Reset basic string/array subjects
    this.generatedTestsSubject.next([]);
    // this.columnsSubject.next([]);
    // this.selectedTemplate.next('');

    // Reset test generation state
    // this.currentTestParams = undefined;
    this.testGenerationProgress.next({
      totalCriteria: 0,
      completedCriteria: 0,
      results: []
    });
  }

  // Method to update token usage
  updateTokenUsage(newTokens: TokenCount): void {
    const currentUsage = this.tokenUsageSubject.getValue();

    const updatedUsage: TokenCount = {
      prompt_tokens: currentUsage.prompt_tokens + newTokens.prompt_tokens,
      completion_tokens: currentUsage.completion_tokens + newTokens.completion_tokens,
      total_tokens: currentUsage.total_tokens + newTokens.total_tokens
    };

    this.tokenUsageSubject.next(updatedUsage);
  }

  /**
   * Get the current token usage
   * @returns Current token usage counters
   */
  getCurrentTokenUsage(): TokenCount {
    return this.tokenUsageSubject.getValue();
  }

  /**
   * Reset token usage to zero
   */
  resetTokenUsage(): void {
    this.tokenUsageSubject.next({
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0
    });
  }

  /**
   * Add token usage to the current running total
   * @param newTokens Token usage to add
   */
  addTokenUsage(newTokens: TokenCount): void {
    const currentUsage = this.tokenUsageSubject.getValue();

    this.tokenUsageSubject.next({
      prompt_tokens: currentUsage.prompt_tokens + newTokens.prompt_tokens,
      completion_tokens: currentUsage.completion_tokens + newTokens.completion_tokens,
      total_tokens: currentUsage.total_tokens + newTokens.total_tokens
    });
  }

  // Method for Component A to call
  getCurrentTestParams(): GenerateTestsParams | undefined {
    return this.currentTestParams;
  }

  getNextTestParams(): GenerateTestsParams | undefined {
    if (!this.currentTestParams) { return undefined; }

    const progress = this.testGenerationProgress.value;
    const nextIndex = this.currentTestParams.index + 1;

    // Check if we have more criteria to process
    if (nextIndex < progress.totalCriteria) {
      // Return params for next criterion
      return {
        criterionText: this.allCriteria[nextIndex],
        index: nextIndex
      };
    }

    return undefined;
  }

  initiateGenerateTests() {
    this.triggerGenerateTests.next(true);
  }

  initiateGenerateTestsWithParams(params: GenerateTestsParams) {
    this.currentTestParams = params;
    this.triggerGenerateTests.next(true);
  }

  initializeTestGeneration(totalCriteria: number, criteria: string[] = []) {
    this.allCriteria = criteria;
    this.testGenerationProgress.next({
      totalCriteria,
      completedCriteria: 0,
      results: []
    });
  }

  updateExtractedSubstrings(substrings: ExtractedSubstring[]): void {
    this.extractedSubstringsSubject.next(substrings);
  }

  getExtractedSubstrings(): ExtractedSubstring[] {
    return this.extractedSubstringsSubject.getValue();
  }

  updateDataParameters(dataParameters: DataParametersResponse | undefined) {
    // Ensure backward compatibility by adding presence flags if they don't exist
    if (dataParameters?.analysis_results && !dataParameters.analysis_results.hasOwnProperty('has_config_parameters')) {
      // Check if the configuration parameters exist and have items
      const hasConfigParams =
        dataParameters.analysis_results.configuration_parameters &&
        dataParameters.analysis_results.configuration_parameters.length > 0;

      // Check if test data management exists and has any items in its arrays
      const hasTestDataMgmt =
        dataParameters.analysis_results.test_data_management &&
        (
          (dataParameters.analysis_results.test_data_management.base_dataset && dataParameters.analysis_results.test_data_management.base_dataset.length > 0) ||
          (dataParameters.analysis_results.test_data_management.dynamic_data && dataParameters.analysis_results.test_data_management.dynamic_data.length > 0) ||
          (dataParameters.analysis_results.test_data_management.sensitive_data && dataParameters.analysis_results.test_data_management.sensitive_data.length > 0) ||
          (dataParameters.analysis_results.test_data_management.edge_cases && dataParameters.analysis_results.test_data_management.edge_cases.length > 0)
        );

      // Add the presence flags to the analysis results
      dataParameters.analysis_results = {
        ...dataParameters.analysis_results,
        has_config_parameters: hasConfigParams,
        has_test_data_management: hasTestDataMgmt,
        has_base_dataset: dataParameters.analysis_results.test_data_management?.base_dataset?.length > 0,
        has_dynamic_data: dataParameters.analysis_results.test_data_management?.dynamic_data?.length > 0,
        has_sensitive_data: dataParameters.analysis_results.test_data_management?.sensitive_data?.length > 0,
        has_edge_cases: dataParameters.analysis_results.test_data_management?.edge_cases?.length > 0
      };
    }

    // Update the subject with the modified data parameters
    this.dataParametersSubject.next(dataParameters);

    // If you want to update token usage when data parameters are received
    if (dataParameters?.token_usage) {
      this.updateTokenUsage(dataParameters.token_usage);
    }
  }

  getDataParameters(): DataParametersResponse | undefined {
    return this.dataParametersSubject.getValue();
  }

  getFormattedDataParameters(): string {
    const dataParams = this.dataParametersSubject.getValue();
    if (!dataParams || !dataParams.analysis_results) {
      return '';
    }

    let formattedString = '';
    const analysis = dataParams.analysis_results;

    // Format Configuration Parameters
    if (analysis.configuration_parameters && analysis.configuration_parameters.length > 0) {
      formattedString += 'Configuration Parameters:\n';
      analysis.configuration_parameters.forEach(param => {
        formattedString += `- Parameter: ${param.parameter}\n`;
        formattedString += `  Type: ${param.type}\n`;

        if (Array.isArray(param.allowed_values)) {
          formattedString += `  Allowed Values: ${param.allowed_values.join(', ')}\n`;
        } else if (param.allowed_values) {
          formattedString += `  Allowed Values: ${param.allowed_values}\n`;
        }

        formattedString += `  Default: ${param.default}\n`;
        formattedString += `  Used In: ${Array.isArray(param.used_in) ? param.used_in.join(', ') : param.used_in}\n`;
        formattedString += `  Impact: ${param.impact}\n\n`;
      });
    }

    // Format Test Data Management
    if (analysis.test_data_management) {
      const tdm = analysis.test_data_management;

      // Base Dataset
      if (tdm.base_dataset && tdm.base_dataset.length > 0) {
        formattedString += 'Base Dataset:\n';
        tdm.base_dataset.forEach(data => {
          formattedString += `- Field: ${data.field}\n`;

          // Handle valid_values that might not be an array
          if (Array.isArray(data.valid_values)) {
            formattedString += `  Valid Values: ${data.valid_values.join(', ')}\n`;
          } else if (data.valid_values) {
            formattedString += `  Valid Values: ${data.valid_values}\n`;
          } else {
            formattedString += `  Valid Values: N/A\n`;
          }

          // Handle invalid_values that might not be an array
          if (Array.isArray(data.invalid_values)) {
            formattedString += `  Invalid Values: ${data.invalid_values.join(', ')}\n`;
          } else if (data.invalid_values) {
            formattedString += `  Invalid Values: ${data.invalid_values}\n`;
          } else {
            formattedString += `  Invalid Values: N/A\n`;
          }

          formattedString += `  Rules: ${data.rules}\n\n`;
        });
      }

      // Dynamic Data
      if (tdm.dynamic_data && tdm.dynamic_data.length > 0) {
        formattedString += 'Dynamic Data:\n';
        tdm.dynamic_data.forEach(data => {
          formattedString += `- Field: ${data.field}\n`;
          formattedString += `  Generation: ${data.generation}\n\n`;
        });
      }

      // Sensitive Data
      if (tdm.sensitive_data && tdm.sensitive_data.length > 0) {
        formattedString += 'Sensitive Data:\n';
        tdm.sensitive_data.forEach(data => {
          formattedString += `- Type: ${data.type}\n`;
          formattedString += `  Handling: ${data.handling}\n\n`;
        });
      }

      // Edge Cases
      if (tdm.edge_cases && tdm.edge_cases.length > 0) {
        formattedString += 'Edge Cases:\n';
        tdm.edge_cases.forEach(edgeCase => {
          formattedString += `- Scenario: ${edgeCase.scenario}\n`;
          formattedString += `  Relevance: ${edgeCase.relevance}\n\n`;
        });
      }
    }

    return formattedString;
  }

  updateTestGenerationProgress(newResults: any[], index: number) {
    const currentProgress = this.testGenerationProgress.value;
    const updatedResults = [...currentProgress.results];

    // Add the results for this criterion
    updatedResults[index] = newResults;

    this.testGenerationProgress.next({
      ...currentProgress,
      completedCriteria: currentProgress.completedCriteria + 1,
      results: updatedResults
    });

    // Get the criteria groups to determine proper group and criterion indices
    const groups = this.getCriteriaGroups();
    let globalIndex = 0;
    let targetGroupIndex = -1;
    let targetCriterionIndex = -1;

    // Find the correct group and criterion indices
    for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
      const group = groups[groupIndex];
      for (let criterionIndex = 0; criterionIndex < group.acceptance_criteria.length; criterionIndex++) {
        if (globalIndex === index) {
          targetGroupIndex = groupIndex;
          targetCriterionIndex = criterionIndex;
          break;
        }
        globalIndex++;
      }
      if (targetGroupIndex !== -1) { break; }
    }

    // Convert the results to TestWithMetadata format
    const testsWithMetadata = newResults.map((test, testIndex) =>
      this.testIdService.createTestWithMetadata(test, {
        groupIndex: targetGroupIndex,
        criterionIndex: targetCriterionIndex,
        testIndex: testIndex
      })
    );

    // Get existing tests and update them
    const currentTests = this.generatedTestsSubject.getValue();
    const otherTests = currentTests.filter(test =>
      !(test.metadata.groupIndex === targetGroupIndex &&
        test.metadata.criterionIndex === targetCriterionIndex)
    );

    // Combine and sort all tests
    const allTests = this.testIdService.sortTests([...otherTests, ...testsWithMetadata]);

    // Update generated tests
    this.updateGeneratedTests(allTests);
  }

  updateGeneratedTests(tests: TestWithMetadata[]): void {
    // Ensure tests have sequential IDs
    const testsWithSequentialIds = this.testIdService.updateSequentialIds(tests);
    this.generatedTestsSubject.next(testsWithSequentialIds);

    if (testsWithSequentialIds.length > 0) {
      // Extract columns from the first test's data
      this.columnsSubject.next(Object.keys(testsWithSequentialIds[0].data));
    } else {
      this.columnsSubject.next([]);
    }
  }

  updateSupportFiles(files: SupportFile[]): void {
    this.supportFilesSubject.next(files);
  }

  /**
   * Returns the current array of support files
   */
  getSupportFiles(): SupportFile[] {
    return this.supportFilesSubject.getValue();
  }

  // method to update a specific support file's content
  updateSupportFileContent(fileName: string, content: string): void {
    const currentFiles = this.supportFilesSubject.getValue();
    const updatedFiles = currentFiles.map(file => {
      if (file.name === fileName) {
        return Object.assign(file, { content }) as SupportFile;
      }
      return file;
    });

    this.supportFilesSubject.next(updatedFiles);

    // Also update the contents map
    const currentContents = this.supportFileContentsSubject.getValue();
    const newContents = new Map(currentContents);
    newContents.set(fileName, content);
    this.supportFileContentsSubject.next(newContents);
  }

  // Method to get a specific file's content
  getSupportFileContent(fileName: string): string | undefined {
    const fileContents = this.supportFileContentsSubject.getValue();
    return fileContents.get(fileName);
  }

  // Method to get all support file contents as a formatted string
  getAllSupportFileContents(): string {
    const files = this.supportFilesSubject.getValue();
    let result = '';

    files.forEach((file: SupportFile) => {
      // Explicitly cast to SupportFile to access the content property
      const supportFile = file as SupportFile;
      if (supportFile.content) {
        result += `==== ${supportFile.name} ====\n`;
        result += supportFile.content;
        result += '\n\n';
      }
    });

    return result;
  }

  updateSelectedModel(selectedModel: string) {
    this.selectedModel.next(selectedModel);
  }

  // getter method for selected model
  get currentSelectedModel(): string {
    return this.selectedModel.getValue();
  }

  updateTemplates(templates: any[]) {
    const currentTemplates = this.templates.getValue();
    console.log('Template update:', {
      previousCount: currentTemplates.length,
      newCount: templates.length,
      previousTemplates: currentTemplates.map(t => t.templateName),
      newTemplates: templates.map(t => t.templateName)
    });
    this.templates.next(templates);
  }

  updateSelectedTemplate(selectedTemplate: string) {
    this.selectedTemplate.next(selectedTemplate);
  }

  updateRetrievedNotes(notes: Note[]) {
    this.retrievedNotes.next(notes);
  }

  updateProjectId(projectId: string) {
    this.resetAll();
    this.projectId.next(projectId);
  }

  updateProjectLanguage(language: string) {
    this.projectLanguage.next(language);
  }

  updateIstruzioniScriviTest(istruzioniScriviTest: string) {
    this.istruzioniScriviTest.next(istruzioniScriviTest);
  }

  private getCurrentDateString(): string {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    return `${day}_${month}_${year}`;
  }

  private updateDateInFileName(fileName: string): string {
    const dateString = this.getCurrentDateString();
    // Regular expression to match the date pattern
    const datePattern = /\d{2}_\d{2}_\d{4}/;

    if (datePattern.test(fileName)) {
      // If a date exists, replace it
      return fileName.replace(datePattern, dateString);
    } else {
      // If no date exists, add it before the file extension or at the end
      const parts = fileName.split('.');
      if (parts.length > 1) {
        const extension = parts.pop();
        return `${parts.join('.')}_${dateString}.${extension}`;
      }
      return `${fileName}_${dateString}`;
    }
  }

  updateFileName(fileName: string) {
    if (fileName === '') {
      this.fileNameSubject.next(fileName);
      this.updateTestFileName(fileName);
    } else {
      const baseFileName = this.removeExtensionAndSuffix(fileName, 'Story');
      const updatedFileName = this.addSuffixIfMissing(baseFileName, '_Story');
      const fileNameWithDate = this.updateDateInFileName(updatedFileName);
      this.fileNameSubject.next(fileNameWithDate);
      this.updateTestFileName(baseFileName);
    }
  }

  updateTestFileName(fileName: string) {
    if (fileName === '') {
      this.testFileNameSubject.next(fileName);
    } else {
      const baseFileName = this.removeExtensionAndSuffix(fileName, 'Tests');
      const updatedFileName = this.addSuffixIfMissing(baseFileName, '_Tests');
      const fileNameWithDate = this.updateDateInFileName(updatedFileName);
      this.testFileNameSubject.next(fileNameWithDate);
    }
  }

  private removeExtensionAndSuffix(fileName: string, suffix: string): string {
    // Remove file extension
    const nameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
    // Remove suffix if it exists
    const suffixRegex = new RegExp(`${suffix}$`);
    return nameWithoutExtension.replace(suffixRegex, '');
  }

  private addSuffixIfMissing(fileName: string, suffix: string): string {
    return fileName.endsWith(suffix) ? fileName : `${fileName}${suffix}`;
  }

  updateRefinedStoryString(refinedStory: string) {
    this.refinedStoryStringSubject.next(refinedStory);
  }

  updateAdditionalContext(additionalContext: string) {
    this.additionalContextSubject.next(additionalContext);

    const refinedStoryValue = this.refinedStoryStringSubject.getValue();
    if (refinedStoryValue) {
      let refinedStory = refinedStoryValue;

      if (!this.notesAdded) {
        refinedStory += '\n\nNotes:\n';
        this.notesAdded = true;
      }

      // Update the refined story string with the new additional context
      refinedStory = refinedStory.split('\n\nNotes:\n')[0] + '\n\nNotes:\n' + additionalContext;
      console.log('DEBUG T - stai cercando di aggiornare la refined story con additional context ! ')
      // this.updateRefinedStoryString(refinedStory);
    }
  }

  getColumns(): string[] {
    return this.columnsSubject.getValue();
  }

  // Update the entire criteria groups
  updateCriteriaGroups(groups: CriteriaGroup[]) {
    this.criteriaGroupsSubject.next(groups);
  }

  updateCriterion(groupIndex: number, criterionIndex: number, updatedCriterion: AcceptanceCriterion) {
    const currentGroups = [...this.criteriaGroupsSubject.getValue()];

    if (!currentGroups[groupIndex]) {
      console.error('Group not found');
      return;
    }

    // Create new references all the way down
    currentGroups[groupIndex] = {
      ...currentGroups[groupIndex],
      acceptance_criteria: [...currentGroups[groupIndex].acceptance_criteria]
    };

    currentGroups[groupIndex].acceptance_criteria[criterionIndex] = { ...updatedCriterion };

    // Debug log
    console.log('Updating criterion:', {
      groupIndex,
      criterionIndex,
      criterion: updatedCriterion,
      newGroups: currentGroups
    });

    // Emit the new value
    this.criteriaGroupsSubject.next(currentGroups);
  }

  updateStoryDescription(description: StoryDescriptionResponse | undefined) {
    this.storyDescriptionSubject.next(description);
  }

  updateMacroScenarios(scenarios: MacroScenarioWithSelection[]) {
    this.macroScenariosSubject.next(scenarios);
  }

  // agentic scenario gen
  updateAgenticMode(isAgentic: boolean) {
    this.isAgenticModeSubject.next(isAgentic);
  }
  // agentic scenario gen
  getAgenticMode(): boolean {
    return this.isAgenticModeSubject.getValue();
  }

  getCurrentGeneratedTests(): any[] {
    return this.generatedTestsSubject.getValue();
  }

  deleteTest(testId: string): void {
    const identifier = this.testIdService.parseTestId(testId);
    if (!identifier) { return; }

    const currentTests = this.generatedTestsSubject.getValue();
    const updatedTests = currentTests.filter(test =>
      test.metadata.displayId !== testId
    );

    this.updateGeneratedTests(updatedTests);
  }

  getTestsForGroup(groupIndex: number): Observable<TestWithMetadata[]> {
    return this.generatedTests$.pipe(
      map(tests => this.testIdService.getGroupTests(tests, groupIndex))
    );
  }

  // Method to update ambiguities
  updateAmbiguities(ambiguities: Ambiguity[]) {
    this.ambiguitiesSubject.next(ambiguities);
  }

  // Method to update both analysis objects at once (convenience method)
  updateAnalysisData(coreAnalysis: AnalysisContent | null, supportiveAnalysis: SupportiveAnalysisContent | null) {
    this.coreAnalysisSubject.next(coreAnalysis);
    this.supportiveAnalysisSubject.next(supportiveAnalysis);
  }

  /**
   * Updates the core analysis data using just scenario and config/testdata analysis strings.
   * This creates a simplified AnalysisContent object with just the provided strings.
   *
   * @param scenario_analysis The scenario analysis text
   * @param config_testdata_analysis The configuration and test data analysis text
   */
  updateCoreAnalysisStrings(scenario_analysis: string, config_testdata_analysis: string): void {
    // Create a new AnalysisContent object with the provided strings
    const analysisContent: AnalysisContent = {
      scenario_analysis,
      config_testdata_analysis
    };

    // Update the core analysis subject
    this.coreAnalysisSubject.next(analysisContent);

    // If we're updating only the core analysis, we might want to leave supportive analysis as is
    // But if you want to ensure they stay in sync, you could reset the supportive analysis:
    // this.supportiveAnalysisSubject.next(null);

    // Log the update for debugging purposes
    console.log('Analysis data updated with string inputs:', {
      scenario_analysis_length: scenario_analysis.length,
      config_testdata_analysis_length: config_testdata_analysis.length
    });
  }

  /**
   * Get the current ambiguities list
   * @returns Current array of ambiguities
   */
  getAmbiguities(): Ambiguity[] {
    return this.ambiguitiesSubject.getValue();
  }

  /**
   * Get the current criteria groups
   * @returns Current array of criteria groups
   */
  getCriteriaGroups(): CriteriaGroup[] {
    return this.criteriaGroupsSubject.getValue();
  }

  /**
   * Get a flattened list of all acceptance criteria from all groups
   * @returns Array of all acceptance criteria
   */
  getAllAcceptanceCriteria(): AcceptanceCriterion[] {
    const groups = this.criteriaGroupsSubject.getValue();
    return groups.reduce((allCriteria, group) => {
      return [...allCriteria, ...group.acceptance_criteria];
    }, [] as AcceptanceCriterion[]);
  }

  /**
   * Get core analysis data
   * @returns Current core analysis object or null
   */
  getCoreAnalysis(): AnalysisContent | null {
    return this.coreAnalysisSubject.getValue();
  }

  /**
   * Get supportive analysis data
   * @returns Current supportive analysis object or null
   */
  getSupportiveAnalysis(): SupportiveAnalysisContent | null {
    return this.supportiveAnalysisSubject.getValue();
  }

  /**
   * Get macro scenarios
   * @returns Current macro scenarios array
   */
  getMacroScenarios(): MacroScenarioWithSelection[] {
    return this.macroScenariosSubject.getValue();
  }

}
